import React from "react";

interface FylloImageProps {
  src: string;
}

function FylloImage({ src }: FylloImageProps) {
  if (!src) return null;

  return (
    <img src={src} style={{ display: "none" }} width="1" height="1" alt="" />
  );
}

export default FylloImage;
