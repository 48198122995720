import { takeLatest, select } from "redux-saga/effects";
import { SET_KIOSK_DISPENSARY } from "./types";

import { medicalDispensaries as medicalDispensariesSelector } from "../profile/selectors";
//import { isKioskSelector } from "../system/selectors";

function* setKioskDispensary(action: any) {
  //const isKiosk = yield select(isKioskSelector);
  //Earlier the below condition was (action.dispensary.trim() !== "" && isKiosk)

  if (action.dispensary.trim() !== "") {
    window.localStorage.removeItem("forage_cart_dispensary");
    window.localStorage.removeItem("forage_cart_dispensary_type");
    window.localStorage.removeItem("forage_accepting_preorders");
    window.sessionStorage.removeItem("forage_token_timestamp");
    window.sessionStorage.removeItem("forage_token");

    const medicalDispensaries = yield select(medicalDispensariesSelector);

    window.localStorage.setItem("forage_cart_dispensary", action.dispensary);

    if (medicalDispensaries) {
      const dispensaryType =
        medicalDispensaries.indexOf(action.dispensary) !== -1
          ? "medical"
          : "recreational";
      window.localStorage.setItem(
        "forage_cart_dispensary_type",
        dispensaryType
      );
    }
  }
}

function* kioskSaga() {
  yield takeLatest(SET_KIOSK_DISPENSARY, setKioskDispensary);
}

export default kioskSaga;
