import React        from 'react';
import PagesContent from 'constants/pages';
import './Page.scss';
interface ContentPageProps {
  type: string
}
function ContentPage({type}:ContentPageProps) {
  function createMarkup(markup:string) {
    return {__html: markup};
  }
  return(
    <section className="c-page l-main">
      <div className="l-maxwidth ">
        <h1>{PagesContent[type].title}</h1>
        <div className="c-page__content" dangerouslySetInnerHTML={createMarkup(PagesContent[type].content)}></div>
      </div>
    </section>
  )
}

export default ContentPage;