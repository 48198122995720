import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const PreRolls: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--prerolls" version="1.1" x="0px" y="0px" viewBox="0 0 84.1 37.9">
      <g>
        <g>
          <g>
            <path className="i-product-type--prerolls--st0" stroke={color} d="M0.5,32.8c0-0.7,0.6-1.3,1.3-1.4c5.2-0.7,24.5-3.2,36.7-3.2c14.2,0,33.1,1.4,33.1,1.4V36
              c0,0-18.9,1.4-33.1,1.4c-12.2,0-31.5-2.5-36.7-3.2C1.1,34.1,0.5,33.5,0.5,32.8L0.5,32.8z"/>
            <line className="i-product-type--prerolls--st0" stroke={color} x1="17.3" y1="29.6" x2="22.7" y2="36.6"/>
            <line className="i-product-type--prerolls--st0" stroke={color} x1="54.6" y1="28.7" x2="61" y2="36.6"/>
            <line className="i-product-type--prerolls--st0" stroke={color} x1="34.7" y1="28.6" x2="41.6" y2="37.4"/>
          </g>
        </g>
        <path className="i-product-type--prerolls--st0" stroke={color} d="M71.7,29.7c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1"/>
      </g>
      <g>
        <path className="i-product-type--prerolls--st0" stroke={color} d="M64.8,13.1c0,0-2.6,2.2-1.7,4.6s3.6,2.5,3.6,2.5s-0.1,2.3,1.8,2.7"/>
        <path className="i-product-type--prerolls--st0" stroke={color} d="M77.9,17.4c0,0,2.4-0.2,2.2-3.5c0,0,3.3-0.9,3.4-3.4s-2.1-3.8-2.1-3.8s1-2-0.5-3.2"/>
        <path className="i-product-type--prerolls--st0" stroke={color} d="M71.5,12.1c-0.4,1.6,1.2,4.6,1.6,6.2c0.5,1.6-0.5,3.4-0.5,3.4"/>
        <path className="i-product-type--prerolls--st0" stroke={color} d="M73.4,0.5c0,0-0.8,3,0,4.6s2.5,3.2,2.6,4.7s-0.7,3.9-0.7,3.9"/>
      </g>
    </svg>
  )
}

export default PreRolls;