import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAddress } from "utils/maps";
import { RootState } from "store";
import { FetchPreorder } from "store/orders/actions";
import { OrderTotals } from "components/Order";
import { LoadingOrderHistoryOrder } from "components/Loading";
import "./OrderHistoryOrder.scss";
import classNames from "classnames";

interface OrderHistoryOrderProps {
  order?: any;
  orderId?: number;
  loading?: boolean;
  showCheckoutLoder?: boolean;
  orderErr?: boolean;
}

type PreordesStatusType =
  | "New"
  | "Received"
  | "In Progress"
  | "Filled"
  | "Completed"
  | "Rejected";

enum PreorderStatus {
  "New" = "New",
  "Received" = "Received",
  "In Progress" = "In progress",
  "Filled" = "Ready for pickup",
  "Completed" = "Order collected",
  "Rejected" = "Cancelled",
}

interface OrderType {
  customerName: string;
  orderId: string;
  products: any[];
}

function OrderHistoryOrder({
  order,
  orderId,
  loading,
  showCheckoutLoder = false,
  orderErr = false,
}: OrderHistoryOrderProps) {
  const dispatch = useDispatch();
  const historyOrder = useSelector((state: RootState) =>
    order ? order : orderId ? state.orders.preorders[orderId] : false
  );
  const [preorder, setPreorder] = useState(historyOrder);
  const [orderTotals, setOrderTotals] = useState({});
  const [orderItems, setOrderItems] = useState<any>(null);
  const [orders, setOrders] = useState<OrderType[]>([]);

  const getItems = useCallback((preorder: any) => {
    const itemArray: any = [];

    preorder.productIds.forEach((name: string, index: number) => {
      const duplicateDiscounts =
        preorder.discountNames[index] && preorder.discountAmounts[index]
          ? countDuplicateDisc(
              preorder.discountNames[index],
              preorder.discountAmounts[index]
            )
          : [];

      const itemObj = {
        productName: preorder.productNames[index],
        unitPrice: preorder.prices[index],
        quantity: preorder.quantities[index],
        vendor: preorder.vendorNames[index],
        productId: preorder.productIds[index],
        discount: preorder.discountAmounts[index]
          ? parseInt(totalDiscounts(preorder.discountAmounts[index]), 10)
          : false,
        customerName: preorder.customer.name,
        orderId: preorder.orderId,
        discounts: duplicateDiscounts,
      };
      itemArray.push(itemObj);
    });
    return itemArray;
  }, []);

  const countDuplicateDisc = (arr: any, arr2: any) => {
    const res: any = {};
    arr.forEach((obj: any, index: number) => {
      const key = `${obj}${arr2[index]}`;
      if (!res[key]) {
        res[key] = { name: obj, amount: arr2[index], count: 0 };
      }
      res[key].count += 1;
    });
    return Object.values(res);
  };

  useEffect(() => {
    if (!historyOrder && orderId) {
      dispatch(FetchPreorder(orderId, "test"));
    }
    setPreorder(historyOrder);
  }, [historyOrder, orderId, dispatch]);

  useEffect(() => {
    if (preorder) {
      setOrderTotals({
        subTotal: preorder.subTotal,
        total: preorder.total,
        discount: preorder.discount,
        salesTax: preorder.salesTax,
      });
      const items: any = getItems(preorder);
      setOrderItems(items);
    }
  }, [preorder, getItems]);

  const splitOrders = useCallback((): OrderType[] => {
    const initialOrders: OrderType[] = [];

    if (orderItems?.length > 0) {
      return orderItems.reduce((acc: OrderType[], item: any) => {
        let added = false;
        if (acc?.length > 0) {
          const newAcc = acc.map((accItem: OrderType) => {
            if (
              accItem.customerName === item.customerName &&
              accItem.orderId === item.orderId
            ) {
              added = true;
              return { ...accItem, products: [...accItem.products, item] };
            }

            return accItem;
          });

          return added
            ? newAcc
            : [
                ...newAcc,
                {
                  customerName: item.customerName,
                  orderId: item.orderId,
                  products: [item],
                },
              ];
        }

        return [
          ...acc,
          {
            customerName: item.customerName,
            orderId: item.orderId,
            products: [item],
          },
        ];
      }, initialOrders);
    }

    return initialOrders;
  }, [orderItems]);

  useEffect(() => {
    if (orderItems) {
      setOrders(splitOrders());
    }
  }, [orderItems, setOrders, splitOrders]);

  function totalDiscounts(discounts: any) {
    return discounts.reduce(
      // (a: any, b: any) => parseInt(a, 10) + parseInt(b, 10),
      (a: any, b: any) => a + b,
      0
    );
  }

  function getOrderDate(date: any) {
    const formattedDate = moment(date).format("M/D/YYYY");
    return formattedDate;
  }

  const orderProduct = (item: any) => {
    return (
      <>
        <div className="c-order-history-order__item">
          <div className="c-order-history-order__item__details">
            <span className="c-order-history-order__item__name">
              {item.productName} x {item.quantity}{" "}
            </span>
            <span className="c-order-history-order__item__vendor">
              {item.vendor}
            </span>
            {/* <div className="c-order-history-order__item__meta">
                <span className="c-order-history-order__item__meta__quantity">
                  x {item.quantity}
                </span>
              </div> */}
          </div>
          <div className="c-order-history-order__item__price">
            {item.discount ? (
              <>
                <span className="c-order-history-order__item__name--strikethrough">
                  ${item.unitPrice * item.quantity}
                </span>
                <span className="c-order-history-order__item__name">
                  ${item.unitPrice * item.quantity - item.discount}
                </span>
              </>
            ) : (
              <span className="c-order-history-order__item__name">
                ${item.unitPrice * item.quantity}
              </span>
            )}
          </div>
        </div>

        {item.discounts?.length ? (
          <>
            <p className="c-order-history-order__item__name">Discount:</p>
            {item.discounts.map((discount: any, index: number) => (
              <div key={index} className="c-order-history-order__dics">
                <div className="c-order-history-order__item__details">
                  {discount.name}
                </div>
                <div className="c-order-history-order__item__price">
                  ${discount.amount * discount.count}
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      {preorder && !loading ? (
        <article className="c-order-history-order">
          <div className="c-order-history-order__status">
            {/* {
              orderErr &&
              <>
                <p className="slug--red">Something went wrong. please contact <a href="mailto:support@forage.io" target="_blank" rel="noopener noreferrer">support@forage.io</a></p>
                <br />
              </>
            } */}
            <div
              className={classNames("slug", {
                "slug--red": preorder?.status === "Rejected",
              })}
            >
              {PreorderStatus[preorder.status as PreordesStatusType]}{" "}
              {getOrderDate(preorder.orderDate)}
            </div>
            {preorder?.status === "Rejected" && preorder?.rejectedReason && (
              <div className="c-order-history-order__status--reason">
                <b>Reason:</b> {preorder?.rejectedReason?.toLowerCase()}
              </div>
            )}
            {preorder.dispensary && (
              <div className="c-order-history-order__dispensary">
                {preorder?.dispensary?.slug && (
                  <Link to={"/dispensary/" + preorder?.dispensary?.slug}>
                    <strong>{preorder.dispensary.name}</strong>
                  </Link>
                )}
                {preorder.dispensary?.googlePlace?.googlePlaceLink && (
                  <a
                    href={preorder.dispensary.googlePlace.googlePlaceLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getAddress(preorder.dispensary)}
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="c-order-history-order__details">
            <div className="slug">Your Order</div>
            <div className="c-order-history-order__items">
              {orders?.length &&
                orders.map((order: OrderType, index) => {
                  return (
                    <div key={index + " " + order?.orderId}>
                      <div className="c-order-history-order__item c-order-history-order__item__pickup">
                        <div className="c-order-history-order__item__description">
                          <span className="c-order-history-order__item__description__name">
                            PICKUP NAME
                          </span>
                          <span className="c-order-history-order__item__description__value">
                            {order.customerName}
                          </span>
                        </div>
                        <div className="c-order-history-order__item__description">
                          <span className="c-order-history-order__item__description__name">
                            ORDER NUMBER
                          </span>
                          <span className="c-order-history-order__item__description__value">
                            {order.orderId || "Pending"}
                          </span>
                        </div>
                      </div>
                      {order.products &&
                        order.products.map((item: any, id) => {
                          return (
                            <div
                              key={index + " " + id + " " + item.productId}
                              className="c-order-history-order__card"
                            >
                              {preorder?.dispensary?.slug && item.productId ? (
                                <Link
                                  to={
                                    "/dispensary/" +
                                    preorder.dispensary.slug +
                                    "/product/" +
                                    item.productId
                                  }
                                >
                                  {orderProduct(item)}
                                </Link>
                              ) : (
                                orderProduct(item)
                              )}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
            <OrderTotals totals={orderTotals} />
          </div>
        </article>
      ) : (
        <LoadingOrderHistoryOrder
          orderErr={orderErr}
          showCheckoutLoder={showCheckoutLoder}
        />
      )}
    </>
  );
}

export default OrderHistoryOrder;
