import React from "react";
import "./PDPTable.scss";
import { InventoryProps } from "constants/inventory";
interface PDPTableProps {
  product: InventoryProps;
}

function PDPTable({ product }: PDPTableProps) {
  let thcCBD = [];
  let strength = "";

  if (product?.product?.thcContent) {
    thcCBD.push(
      "THC " + product.product.thcContent + product.product.thcContentUnit
    );
    if (product.product.thcContent < 15) {
      strength = "Light";
    } else if (product.product.thcContent < 36) {
      strength = "Medium";
    } else {
      strength = "High";
    }
  }
  if (product?.product?.cbdContent) {
    thcCBD.push(
      "CBD " + product.product.cbdContent + product.product.cbdContentUnit
    );
  }

  let tableRows = [];
  if (strength) {
    tableRows.push({
      label: "Strength",
      value: [strength],
    });
  }
  if (product?.product?.flavor) {
    tableRows.push({
      label: "Taste Notes",
      value: [product.product.flavor],
    });
  }
  if (product?.strainType) {
    tableRows.push({
      label: "Type",
      value: [product.strainType],
    });
  }
  if (thcCBD.length) {
    tableRows.push({
      label: "Average Cannabinoids",
      value: thcCBD,
    });
  }
  if (product?.terpenes && product?.terpenes.length) {
    tableRows.push({
      label: "Terpenes",
      value: [product.terpenes.join(", ")],
    });
  }
  if (product?.size) {
    tableRows.push({
      label: "Package Size",
      value: [product.size],
    });
  }
  if (product?.strain) {
    tableRows.push({
      label: "Strain",
      value: [product.strain],
    });
  }
  return (
    <section className="c-pdp-table">
      {tableRows.map((row) => (
        <div key={row.label} className="c-pdp-table__row">
          <div className="c-pdp-table__row__label slug">{row.label}</div>
          <div className="c-pdp-table__row__value">
            {row.value.map((v) => (
              <span key={v}>{v}</span>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
}

export default PDPTable;
