import React            from 'react';
import classNames       from 'classnames';
import { useSelector }  from 'react-redux';
import { Link }         from 'react-router-dom';
import { RootState }            from 'store';
import Footer                   from 'components/Footer';
import LocationIcon             from 'components/svgs/Location';
import RightCaretIcon           from 'components/svgs/RightCaret';
import {GradientCardLayout2} from 'components/Gradients/Cards';
import './MobileNavigation.scss';

function MobileNavigation() {
  const mobileNavOpen     = useSelector((state: RootState) => state.system.mobileNavOpen);
  const geoLocating       = useSelector((state: RootState) => state.system.geoLocating);
  const activeDispensary  = useSelector((state: RootState) => state.cart.activeDispensary);
  const dispensaries      = useSelector((state:RootState) => state.locations.dispensaries);
  const userLocationZip   = useSelector((state: RootState) => state.user.userLocationZip);
  const customer:any      = useSelector((state: RootState) => state.customer.customer);

  const CanvasIcon = () => {
    const profileFeelings = ['creative', 'focused', 'talkative'];
    return (
      <div className="c-mobile-nav__profile-icon">
        <GradientCardLayout2 feelings={profileFeelings} animate={true} />
      </div>
    )
  }

  const renderLocString = () => {
    if (geoLocating) {
      return 'Locating...'
    } else if (userLocationZip) {
      return `Browsing from: ${userLocationZip}`
    } else if (dispensaries && activeDispensary && dispensaries[activeDispensary]) {
      return `${dispensaries[activeDispensary]?.name}`;
    }

    return 'Select location';
  };

  return (
    <nav className={classNames('c-mobile-nav', {'c-mobile-nav--open': mobileNavOpen})}>
      <div className="c-mobile-nav__menu">
        {
          !customer &&
          <Link to="/account/signin" className="c-mobile-nav__menu__option">Sign In</Link>
        }
        <div className="c-header__location">
          <Link to="/locations">
            <div className="c-header__location__button">
              <LocationIcon variant="charcoal" />
              <span className="c-header__location__value">
                {renderLocString()}
              </span>
              <RightCaretIcon variant="white" />
            </div>
          </Link>
        </div>
        <div className="c-mobile-nav__menu__options">
          {
            customer &&
            <Link to="/account/profile" className="c-mobile-nav__menu__option c-mobile-nav__menu__option--profile">
              <CanvasIcon />
              {customer.fullName}
            </Link>
          }
          <Link to="/recommendations" className="c-mobile-nav__menu__option">Recommendations</Link>
          { 
            !customer && 
            <Link to={"/create-profile"} className="c-mobile-nav__menu__option">Create Profile</Link> 
          }
          <Link to="/order" className="c-mobile-nav__menu__option">Your Order</Link>

        </div>
        {
          activeDispensary &&
          <Link to={activeDispensary ? "/dispensary/" + activeDispensary + "/products": "/locations"} className="c-mobile-nav__menu__option c-mobile-nav__menu__option--small">All Products</Link>
        }
        <Link to="/locations" className="c-mobile-nav__menu__option c-mobile-nav__menu__option--small">All Locations</Link>
      </div>
      <Footer />
    </nav>
  )
}

export default MobileNavigation;
