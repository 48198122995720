import React          from 'react';
import RightCaretIcon from 'components/svgs/RightCaret';
import 'components/Dispensaries/DispensaryLocation/DispensaryLocation.scss';
import '../Loading.scss';
function LoadingDispensaryLocation() {
  return(
    <article  className="c-dispensary-location c-loading--dispensary-location">
      <div className="c-dispensary-location__inner">
        <figure className="c-dispensary-location__figure"></figure>
        <div className="c-dispensary-location__details">
          <div className="c-dispensary-location__details__left">
            <h3 className="c-loading--loading-type--h3">{''}</h3>
            <div className="c-dispensary-location__distance-address body--xsmall">
            </div>
          </div>
        </div>
        <div className="c-dispensary-location__caret">
          <RightCaretIcon />
        </div>
      </div>
    </article>
  )
}

export default LoadingDispensaryLocation;