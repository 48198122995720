import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootState } from 'store';
import { FetchProfile, UpdateProfile } from 'store/profile/actions';
import EditProfileForm from 'components/Account/EditProfileForm';
import {GradientCardLayout2} from 'components/Gradients/Cards';
import './Profile.scss';


function  Profile({history}: RouteComponentProps) {
  
  const dispatch        = useDispatch();
  const customer:any    = useSelector((state: RootState) => state.customer.customer);
  const savingProfile   = useSelector((state: RootState) => state.profile.savingProfile); 
  const profileSaved    = useSelector((state: RootState) => state.profile.profileSaved); 
  const current         = useSelector((state: RootState) => state.profile.current); 
  const profileError    = useSelector((state: RootState) => state.profile.profileError); 
  const profileLoading  = useSelector((state: RootState) => state.profile.profileLoading); 
  const hasProfile      = useSelector((state: RootState) => state.profile.hasProfile); 
  
  const [saving, setSaving] = useState(false);
  
  const profileFeelings = ['creative', 'focused', 'talkative'];

  useEffect(() => {
    if(!customer){
      history.push('/account/signin');
    } else if(!profileLoading && !profileError && !current && hasProfile !== false){
      dispatch(FetchProfile());
    } else if(profileError){
      if(profileError === 'Not Logged In'){
        history.push('/account/signin');
      } else {
        history.push('/create-profile');
      }
    }
  }, [customer, current, hasProfile, profileError, profileLoading, history, dispatch]);

  useEffect(() => {
    if(hasProfile === false){
      history.replace('/create-profile');
    }
  }, [hasProfile, history]);

  useEffect(() => {
    if(savingProfile){
      setSaving(true);
    }
    if(saving && profileSaved){
      history.push('/account');
    }
  }, [savingProfile, profileSaved, saving, history]);
  
  function handleSubmit(values:any) {
    dispatch(UpdateProfile());
  }
  
  return (
    <section className="c-account-profile l-main">
      {
        current?.firstName &&
        <Helmet>
          <title>{`${current?.firstName}'s Forage Profile`}</title>
        </Helmet>
      }
      {
        (customer && current) &&
          <div className="l-maxwidth l-maxwidth--small">
            <div className="c-account-profile__name">
              <figure>
                <GradientCardLayout2 feelings={profileFeelings} animate={false} />
              </figure>
              <h1>
                {current.firstName} {current.lastName}
              </h1>
            </div>
            {
              current &&
                <EditProfileForm onSubmit={handleSubmit} />
            }
          </div>
      }
    </section>
  )
}

export default withRouter(Profile);