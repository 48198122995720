import {
  InventoryState,
  InventoryActionTypes,
  SET_INVENTORY_SORT,
  SET_INVENTORY_FILTER,
  SET_INVENTORY_PAGE,
  FETCH_INVENTORY,
  FETCH_INVENTORY_SUCCESS,
  FETCH_INVENTORY_FAILED,
  FETCH_PRODUCT,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILED,
  SET_SEARCH,
  SET_SLUG,
  FETCH_DISCOUNTS,
  FETCH_DISCOUNTS_SUCCESS,
  FETCH_DISCOUNTS_FAILED,
  SET_INVENTORY_CHANGING,
  RESET_INVENTORY,
  RESET_NO_RESULTS,
} from "./types";
const initialState: InventoryState = {
  sort: "alpha_ascending",
  filter: {},
  loadingInventory: false,
  inventory: [],
  inventoryIds: [],
  inventoryError: false,
  inventoryDispensary: null,
  totalInventory: null,
  startRow: null,
  limitRow: null,
  searchQuery: "",
  slug: "",
  page: 1,
  noResults: false,
  discountsLoading: false,
  discountProducts: [],
  discountsError: false,
  inventoryChanging: false,
  noDiscounts: null,
};

export function inventoryReducer(
  state = initialState,
  action: InventoryActionTypes
): InventoryState {
  switch (action.type) {
    case SET_INVENTORY_SORT: {
      return {
        ...state,
        loadingInventory: true,
        sort: action.sort,
        page: 1,
      };
    }

    case RESET_NO_RESULTS: {
      return {
        ...state,
        noResults: false,
      };
    }
    case SET_INVENTORY_FILTER: {
      return {
        ...state,
        filter: action.filter,
        loadingInventory: true,
        page: 1,
        searchQuery: "",
      };
    }
    case FETCH_INVENTORY: {
      return {
        ...state,
        inventoryDispensary: action.slug,
        loadingInventory: true,
        inventoryError: false,
        inventoryIds: [],
        inventory: [],
        page: 1,
      };
    }
    case FETCH_INVENTORY_SUCCESS: {
      let inventoryObj: any = {};
      let inventoryArray: any = [];
      action.inventory.inventory.forEach((item: any) => {
        if (inventoryArray.indexOf(item.id) === -1) {
          inventoryArray.push(item.id);
          if (item.bestForTokens) {
            const bestForTokens = item.bestForTokens.split(",");
            const bestForValues = item.bestForValues.split(",");
            const bestFor = bestForTokens.map(
              (token: string, index: number) => {
                return {
                  title: token,
                  percentage: bestForValues[index],
                };
              }
            );
            item.bestFor = bestFor;
          } else {
            item.bestFor = null;
          }
          delete item.bestForTokens;
          delete item.bestForValues;

          if (item.feelings && item.feelingValues) {
            const allowedFeelings = [
              "energetic",
              "focused",
              "uplifted",
              "euphoric",
              "talkative",
              "creative",
              "happy",
              "hungry",
              "giggly",
              "relaxed",
              "sleepy",
              "aroused",
              "tingly",
            ];

            let feelingData: any = [];
            item.feelings.forEach((feeling: string, index: number) => {
              if (allowedFeelings.indexOf(feeling.toLowerCase()) >= 0) {
                feelingData.push({
                  feeling: feeling,
                  value: item.feelingValues[index],
                });
              }
            });
            feelingData = feelingData.sort((itemA: any, itemB: any) =>
              parseInt(itemA.value, 10) < parseInt(itemB.value, 10) ? 1 : -1
            );
            item.feelingData = feelingData;
          } else {
            item.feelingData = null;
          }
          delete item.feelings;
          delete item.feelingValues;

          inventoryObj[item.id] = item;
        }
      });
      return {
        ...state,
        inventory: { ...inventoryObj },
        inventoryIds: [...inventoryArray],
        noResults: inventoryArray.length === 0 ? true : false,
        loadingInventory: false,
        inventoryError: false,
        totalInventory: action.inventory.totalMatches,
        startRow: action.inventory.startRow,
        limitRow: action.inventory.limitRow,
      };
    }
    case FETCH_INVENTORY_FAILED: {
      return {
        ...state,
        loadingInventory: false,
        inventoryError: true,
      };
    }
    case FETCH_PRODUCT: {
      return {
        ...state,
        loadingInventory: true,
        inventoryError: false,
      };
    }
    case FETCH_PRODUCT_SUCCESS: {
      let product = action.product.length ? action.product[0] : null;

      if (!product) {
        return {
          ...state,
          loadingInventory: false,
          inventoryError: false,
          noResults: true,
        };
      }

      if (
        product.feelings &&
        product.feelings.length > 0 &&
        product.feelingValues &&
        product.feelingValues.length > 0
      ) {
        if (product.bestForTokens) {
          const bestForTokens = product.bestForTokens.split(",");
          const bestForValues = product.bestForValues.split(",");
          const bestFor = bestForTokens.map((token: string, index: number) => {
            return {
              title: token,
              percentage: bestForValues[index],
            };
          });
          product.bestFor = bestFor;
        } else {
          product.bestFor = null;
        }
        delete product.bestForTokens;
        delete product.bestForValues;

        const allowedFeelings = [
          "energetic",
          "focused",
          "uplifted",
          "euphoric",
          "talkative",
          "creative",
          "happy",
          "hungry",
          "giggly",
          "relaxed",
          "sleepy",
          "aroused",
          "tingly",
        ];
        let feelingData: any = [];
        product.feelings.forEach((feeling: string, index: number) => {
          if (allowedFeelings.indexOf(feeling.toLowerCase()) >= 0) {
            feelingData.push({
              feeling: feeling,
              value: product.feelingValues[index],
            });
          }
        });
        feelingData = feelingData.sort((itemA: any, itemB: any) =>
          parseInt(itemA.value, 10) < parseInt(itemB.value, 10) ? 1 : -1
        );
        product.feelingData = feelingData;
      } else {
        product.feelingData = null;
      }
      delete product.feelings;
      delete product.feelingValues;

      let newInventory = { ...state.inventory };
      newInventory[product.product.productId] = product;

      return {
        ...state,
        inventory: { ...newInventory },
        loadingInventory: false,
        inventoryError: false,
      };
    }
    case FETCH_PRODUCT_FAILED: {
      return {
        ...state,
        loadingInventory: false,
        inventoryError: true,
      };
    }

    case SET_SEARCH: {
      const { searchQuery } = action;
      return {
        ...state,
        loadingInventory: true,
        filter: {},
        page: 1,
        searchQuery,
      };
    }

    case FETCH_DISCOUNTS: {
      return {
        ...state,
        discountsLoading: true,
        discountsError: false,
      };
    }

    case FETCH_DISCOUNTS_SUCCESS: {
      let discounts: any = [];
      let discountIds: any = [];
      action.discountProducts.forEach((item: any) => {
        const {
          product: { productId },
        } = item;
        if (discountIds.indexOf(productId) < 0) {
          if (item.bestForTokens) {
            const bestForTokens = item.bestForTokens.split(",");
            const bestForValues = item.bestForValues.split(",");
            const bestFor = bestForTokens.map(
              (token: string, index: number) => {
                return {
                  title: token,
                  percentage: bestForValues[index],
                };
              }
            );
            item.bestFor = bestFor;
          } else {
            item.bestFor = null;
          }
          delete item.bestForTokens;
          delete item.bestForValues;

          if (item.feelings && item.feelingValues) {
            const allowedFeelings = [
              "energetic",
              "focused",
              "uplifted",
              "euphoric",
              "talkative",
              "creative",
              "happy",
              "hungry",
              "giggly",
              "relaxed",
              "sleepy",
              "aroused",
              "tingly",
            ];
            let feelingData: any = [];
            item.feelings.forEach((feeling: string, index: number) => {
              if (allowedFeelings.indexOf(feeling.toLowerCase()) >= 0) {
                feelingData.push({
                  feeling: feeling,
                  value: item.feelingValues[index],
                });
              }
            });
            feelingData = feelingData.sort((itemA: any, itemB: any) =>
              parseInt(itemA.value, 10) < parseInt(itemB.value, 10) ? 1 : -1
            );
            item.feelingData = feelingData;
          } else {
            item.feelingData = null;
          }
          delete item.feelings;
          delete item.feelingValues;

          discounts.push(item);
        }
      });
      discounts = discounts.slice(0, 8);
      return {
        ...state,
        discountsLoading: false,
        discountsError: false,
        discountProducts: discounts,
        noDiscounts: discounts.length === 0,
      };
    }

    case FETCH_DISCOUNTS_FAILED: {
      return {
        ...state,
        discountsLoading: false,
        discountsError: true,
        discountProducts: [],
        noDiscounts: true,
      };
    }

    case SET_SLUG: {
      const { slug } = action;
      return {
        ...state,
        slug,
      };
    }

    case SET_INVENTORY_PAGE: {
      const { page } = action;
      return {
        ...state,
        loadingInventory: true,
        page,
      };
    }

    case SET_INVENTORY_CHANGING: {
      const { inventoryChanging } = action;
      return {
        ...state,
        inventoryChanging,
      };
    }

    case RESET_INVENTORY: {
      return {
        ...state,
        inventoryIds: [],
        inventory: [],
      };
    }

    default:
      return state;
  }
}
