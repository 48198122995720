import React from 'react';
import { useDispatch } from 'react-redux';
import { setKioskDispensary } from '../../../store/kiosk/actions';
import './index.style.scss';

interface ForageEmbedModalProps {
    embedDispensaries: {
        medical: string | null,
        recreational: string | null
    }
}

function ForageEmbedModal({embedDispensaries}:ForageEmbedModalProps) {

    const dispatch = useDispatch();
    if( !(embedDispensaries?.medical || embedDispensaries.recreational)){
        return null
    }

    const {medical, recreational} = embedDispensaries;

    return (
            <div className='c-forage-embed'>
                    <div
                        className='c-forage-embed__cta-Wrapper'
                    >
                        <h2 className='c-forage-embed__title'>How will you be shopping today?</h2>
                        {
                            medical &&
                            <button
                                onClick={() => {
                                    dispatch(setKioskDispensary(medical));
                                    window.location.reload();
                                }}
                                className="c-button" type="button"><span className="cta--primary">Medical Patient</span></button>
                        }
                        {
                            recreational &&
                            <button
                                onClick={() => {
                                    dispatch(setKioskDispensary(recreational));
                                    window.location.reload();
                                }}
                                className="c-button" type="button"><span className="cta--primary">Adult Use</span></button>
                        }
                    </div>
                    {/* <div style={{ textAlign: 'center' }}>
                        <input
                            type="checkbox"
                            id="dont_ask_again"
                            name="dont_ask_again"
                            value="dont_ask_again"
                        />
                        <label htmlFor="dont_ask_again">Don't ask again</label>
                    </div> */}
            </div>
    );
}

export default ForageEmbedModal;
