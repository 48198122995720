const pages: any = {
  about: {
    title: "About Forage",
    content: `
        <p>Forage is a cannabis discovery tool designed to make recommendations based on your individual context.</p>
        <p>Whether you’re a first time user, self-professed expert, looking to solve back pain or party through the weekend. We guide you through the process, providing options as you refine your fit.</p>
        <a class="c-button" href="/recommendations">Find the Right Cannabis For You</a>
      `,
  },
  terms: {
    title: "Forage Terms of Use",
    content: `
        <p>These Terms of User are effective as of November 1, 2020.</p>
        <p>Please read these terms and conditions ("terms", "terms and conditions") carefully before using www.forage.io (the "service") operated by Columbia Care Inc. ("us", 'we", "our", the “Company”).</p>

        <h3>Conditions of Use</h3>
        <p>We will provide the services to you, which are subject to the conditions stated below in this document. Every time you visit this website, use its services, or place an order, you accept the following conditions. Therefore, we urge you to read them carefully.  If you do not agree with the Terms, you may not use our Services.</p>

        <h3>Privacy Policy</h3>
        <p>Before you continue using our website, we advise you to read our <a href="/privacy-policy">privacy policy</a> and <a href="/cookie-policy">cookie policy</a> regarding our user data collection. It will help you better understand our practices.
        </p>
        <h3>Copyright</h3>
        <p>Content published on this website (digital downloads, images, texts, graphics, logos) is the property of the Company and/or its content creators and protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of the Company, with copyright authorship for this compilation by the Company.</p>

        <h3>Applicable Law</h3>
        <p>By visiting this website, you agree that the laws of the State of New York, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between the Company and you, or its business partners and associates.</p>

        <h3>Disputes</h3>
        <p>Any dispute related in any way to your visit to this website or to products you order from us shall be arbitrated by state or federal court in New York and you consent to exclusive jurisdiction and venue of such courts.</p>

        <h3>Comments, Reviews, and Emails</h3>
        <p>Visitors may communicate with the Company by email if it is not obscene, illegal, defamatory, threatening, infringing of intellectual property rights, invasive of privacy or injurious in any other way to third parties. Content must be free of software viruses, political campaign, and commercial solicitation.</p>

        <h3>License and Site Access</h3>
        <p>We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it. This may be done only with written consent from us.</p>

        <h3>User Account</h3>
        <p>You must be at least 21 years old to register for an account or use the Services.  In no event may anyone under 21 years of age may use the site.</p>
        <p>You agree that the information that you provide to us upon registration, at the time of placing an order and at all other times will be true, accurate, current and complete.  You also agree tha you will ensure that this information is kept accurate and up to date at all times.  This is especially important with respect to your email address, since that is the primary way in which we will communicate with you about your account and your orders.</p>
        <p>When you register an account, you will be asked to provide a password.  You are responsible for safeguarding the password and you agree not to disclose your password to any third party.  You agree that you shall be solely responsible for any activities or actions under your password, whether or not you have authorized such activities or actions.  You shall immediately notify the Company of any unauthorized use of your password.</p>
        <p>We reserve all rights to terminate accounts, edit or remove content and cancel orders in our sole discretion.</p>


        <h3>Restricted User Behavior</h3>
        <p>You agree not to use the Service:</p>
        <ol>
          <li>For any unlawful purpose;</li>
          <li>To transmit any material that is obscene, offensive, blasphemous, pornographic, unlawful, threatening, menacing, abusive, harmful, an invasion of privacy or publicity rights, defamatory, libelous, vulgar, illegal or otherwise objectionable;</li>
          <li>To transmit any material that infringes any copyright, trademark, patent or other intellectual property right or any moral right or artists right of any third party including, but not limited to the Company or to facilitate the unlawful distribution or copyrighted content or illegal content;</li>
          <li>To harm minors in any way, including, but not limited to, transmitting content that violates child pornography laws, child sexual exploitation laws or laws prohibiting the depiction of minors engaged in sexual conduct, or submitting any personally identifiable information about any child under the age of 13;</li>
          <li>To forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Service;</li>
          <li>To transmit any material which is likely to cause harm to the Company or any else’s computer systems, including but not limited to that which contains any virus, code, worm, data or other files or programs designed ot damage or allow unauthorized access to the Service which may cause and defect, error, malfunction or corruption to the Service;</li>
          <li>For any commercial purpose, except as expressly permitted under these Terms;</li>
          <li>To sell access to the Service on any other website or to use the Service on another website for the primary purpose of gaining advertising or subscription revenue other than a personal blog or social network where the primary purpose is to display content from the Company by hyperlink and not to compete with the Company.</li>
        </ol>

        <h3>Disclaimer or Warranty and Limitation of Liability</h3>
        <p>YOUR ACCESS TO AND USE OF THE SERVICES ARE AT YOUR OWN RISK.  YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS.  THE COMPANY MAKES NO REPRESENTATION OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED AS TO THE OPERATION OF THE SERVICE, OR THE CONTENT OR PRODUCTS, PROVIDED THROUGH THE SERVICE.  YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK.  THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OR MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, TO THE FULLEST EXTENT PERMITTED BY LAW.  THE COMPANY MAKES NO WARRANTY AS TO THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF THIS SERVICE.  YOU SPECIFICALLY ACKNOWLEDGE THAT THE COMPANY IS NOT LIABLE FOR YOUR DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT, OR SUCH CONDUCT BY THIRD PARTIES, AN YOU EXPRESSLY ASSUME ALL RISKS AND RESPONSIBILITY FOR DAMAGES AN LOSSES ARISING FROM SUCH CONDUCT, EXPECT FOR THE EXPRESS, LIMITED REMEDIES PROVIDED HEREIN, AND TO THE FULLEST EXTENT ALLOWED BY LAW, THE COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  THE FORGOING DISCLAIMERS, WAIVERS AND LIMITATIONS SHALL APPLY NOTWITHSTANDING A FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON CERTAIN WARRANTIES OR DAMAGES.  THEREFORE, SOME OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU.  IN NO EVENT SHALL THE COMPANY’S AGGREGATE LIABILITY TO YOU EXCEED THE AMOUNTS PAID BY YOU TO THE COMPANY PURSUANT TO THIS AGREEMENT.</p>

        <h3>Indemnification</h3>
        <p>You hereby indemnify to the fullest extent the Company from and against any and all liabilities, costs, demands, causes of action, damages, and expenses, including reasonable attorney’s fees, arising out of or in any way related to your breach of any of the provisions of these Terms.
        </p>
        <h3>Severability</h3>
        <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.
        </p>
        <h3>Variation of Terms</h3>
        <p>The Company is permitted to revise these Terms at any time as it sees fit, and by using this Service you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing the Service.
        </p>
        <h3>Assignment</h3>
        <p>The Company shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or Consent required.  However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these terms.
        </p>
        <h3>Entire Agreement</h3>
        <p>These Terms, including any legal notices and disclaimers contained in this Service, constitute the entire agreement between the Company and you in your use of this Service, and supersede all prior agreements and understandings with respect to the same.
        </p>
      `,
  },
  privacy: {
    title: "FORAGE PRIVACY POLICY",
    content: `
    <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://www.col-care.com, &nbsp;https://www.gocannabist.com or other websites owned and operated by Columbia Care (the “Sites”).</p>
    <p>Certain uses or disclosure of personal information may be governed by state law and other privacy notices.&nbsp;&nbsp;Please see the information below regarding California and Illinois.&nbsp;</p>
    <p><strong>PERSONAL INFORMATION WE COLLECT</strong></p>
    <p>For purpose of this Privacy Policy “personal information” means information that may identify you as an individual.&nbsp; This includes Device Information and Order Information.</p>
    <p>When you visit the Sites, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Sites, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Sites, and information about how you interact with the Sites. We refer to this automatically-collected information as “Device Information.”</p>
    <p>We collect Device Information using the following technologies:</p>
    <p>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</p>
    <p>&nbsp;“Log files” track actions occurring on the Sites, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
    <p>Additionally, when you make a purchase, attempt to make a purchase or request information from us through the Sites, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers and/or bank account information, email address, and phone number.&nbsp; We refer to this information as “Order Information.”</p>
    <p><strong>HOW DO WE USE YOUR PERSONAL INFORMATION?</strong></p>
    <p>We use the Order Information that we collect generally to fulfill any orders placed through the Sites (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).&nbsp; Additionally, we use this Order Information to:</p>
    <p>Communicate with you;</p>
    <p>Screen our orders for potential risk or fraud; and</p>
    <p>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</p>
    <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Sites (for example, by generating analytics about how our customers browse and interact with the Sites, and to assess the success of our marketing and advertising campaigns).</p>

    <p><strong>SHARING YOUR PERSONAL INFORMATION</strong></p>
    <p>We share your personal information with third parties to help us use your personal information, as described above.&nbsp; For example, we use BigCommerce to power our online store–you can read more about how BigCommerce uses your personal information here:&nbsp; https://www.bigcommerce.com/privacy/.</p>
    <p>We use Jane Technologies, Inc. to power our website reservation system.&nbsp; You can read more about how Jane Technologies uses your personal information here:&nbsp; https://www.iheartjane.com/privacy-policy.</p>

    <p>We also use Google Analytics to help us understand how our customers use the Sites–you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. &nbsp; You can also opt-out of Google Analytics here:&nbsp; https://tools.google.com/dlpage/gaoptout.</p>
    <p>We also use Content Square, a solution which aggregates usage and frequency data to improve user experience. Statistics created are anonymous.</p>
    <p>Finally, we may also share your personal information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

    <p><strong>BEHAVIORAL ADVERTISING</strong></p>
    <p>As described above, we use your personal information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.&nbsp; For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</p>
    <p>&nbsp; COMMON LINKS INCLUDE:</p>
    <p>&nbsp;&nbsp;&nbsp; FACEBOOK – https://www.facebook.com/settings/?tab=ads</p>
    <p>&nbsp;&nbsp;&nbsp; GOOGLE – https://www.google.com/settings/ads/anonymous</p>
    <p>&nbsp;&nbsp;&nbsp; BING – https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</p>
    <p><strong>MINORS</strong></p>
    <p>The Sites are not intended for individuals under the age of 21</p>
    <p><strong>DO NOT TRACK</strong></p>
    <p>Please note that we do not alter our Sites’ data collection and use practices when we see a Do Not Track signal from your browser.</p>
    <p><strong>YOUR RIGHTS</strong></p>
    <p>You may always direct us not to share your personal information with third parties, not to use your personal information to provide you with information or offers, or not to send you communications by contacting us by: (i) email at  privacy@col-care.com or (ii) following the removal instructions in the communication that you receive. Your opt-out request will be processed within 30 days of the date on which we receive it.</p>

    <p>If you wish to verify, correct, or update any of your Personal Information collected through the Sites, you may contact us by mail at the address below or e-mail at privacy@col-care.com. &nbsp;In accordance with our routine record keeping, we may delete certain records that contain Personal Information you have submitted through the Sites. We are under no obligation to store such Personal Information indefinitely and disclaim any liability arising out of, or related to, the destruction of such Personal Information. In addition, you should be aware that it is not always possible to completely remove or delete all your information from our databases without some residual data because of backups and other reasons.</p>
    <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>
    <p>Additionally, if you are a European resident, we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Sites), or otherwise to pursue our legitimate business interests listed above.&nbsp; Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>
    <p><strong>DATA RETENTION</strong></p>
    <p>When you place an order through the Sites, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>

    <p><strong>SECURITY OF YOUR INFORMATION</strong></p>
    <p>We use certain reasonable security measures to help protect your Personal Information or other information which we may obtain from your use of the Sites. However, no electronic data transmission or storage of information can be guaranteed to be 100% secure. Please note that we cannot ensure or warrant the security of any information you transmit to us or other information which we may obtain from your use of the Sites, and you use the Sites and provide us with your information at your own risk.</p>

    <p><strong>OTHER SITES</strong></p>
    <p>The Sites may contain links to other sites that we do not own or operate. This includes links from service providers, advertisers, sponsors and partners that may use our logo(s) as part of a co-branding agreement. We do not control, recommend or endorse, and are not responsible for these sites or their content, products, services or privacy policies or practices. These other websites may send their own cookies to your Device, they may independently collect data or solicit Personal Information and may or may not have their own published privacy policies. You should also independently assess the authenticity of any site which appears or claims that it is one of our sites (including those linked to through an email or social networking page).</p>
    <p><strong>CALIFORNIA RESIDENTS</strong></p>
    <p>California residents have certain rights regarding our collection and use of personal information under the California Consumer Privacy Act (“CCPA” or the “Act”).&nbsp; For purposes of this “CCPA Section,” the term “personal information” means personal information under Cal. Civ. Code 1798.140(o).&nbsp; To the extent that any other provision of this Privacy Policy conflicts a provision of this CCPA Section, the provision in this section controls as to California residents.</p>
    <p><em>Rights:</em></p>
    <p>California residents may:</p>
    <p>Request that we delete their personal information.</p>
    <p>Opt-out of the sale of their personal information.</p>

    <p>Request information about:</p>
    <p>(i) the categories and specific pieces of personal information we have collected about them;</p>
    <p>(ii) the categories of sources from which we have collected their personal information;</p>
    <p>(iii) the business or commercial purpose for collecting or selling their personal information;</p>
    <p>(iv) the categories of personal information we have sold about them; and</p>
    <p>(v) the categories of third parties with whom we have shared, disclosed for a business purpose, or sold their personal information, and which categories of personal information we have sold to which categories of third parties.</p>

    <p>We will not discriminate against California residents for exercising their rights under the CCPA.&nbsp; Consistent with Section 1798.125 of the Act, we may charge California Residents different prices, or provide different levels or quality of goods or services, if the difference is reasonably related to the value of your data.&nbsp; We may also offer financial incentives for the collection, sale, or deletion of your personal information.</p>
    <p><em>Requests for Information</em></p>
    <p>California residents may request that we provide certain information about our use of their personal information, as specified above.&nbsp; Upon receiving a request from a California resident, we will take reasonable steps to verify the person’s identity. We will respond to a verified person’s request by: (a) providing the requested information; or (b) explaining to the requestor why the Act does not require us to provide the requested information.</p>
    <p>We will respond to requests for information within 45 days. If a response requires additional time, we will notify the requestor of the basis for the delay and may extend our response period up to an additional 90 days.&nbsp; If the Act requires us to provide the information requested to the person, we will provide the information covering the 12 months preceding the request, free of charge, and in a readily useable portable format.&nbsp; We have no obligation to provide personal information to a California resident more than twice in a 12-month period.&nbsp; If a request or series of requests are manifestly unfounded or excessive, we may charge a reasonable fee for processing the request(s) or may refuse to process the request(s).</p>

    <p><em>Requests to Opt Out</em></p>
    <p>California users may opt out of the sale of their personal information by contacting us at privacy@col-care.com.</p>
    <p>Once we have received a directive from a California resident to not sell their personal information, we will not sell their personal information unless and until the person subsequently provides express authorization for the sale of their personal information.&nbsp; We will not ask for their for permission to sell their personal information for 12 months after receiving a request to opt out.&nbsp; We will only use information that they provided in submitting a request to opt out for purposes of processing the opt-out request.</p>

    <p><em>Requests to Delete</em></p>
    <p>California residents may request that we delete their personal information.&nbsp; Upon receiving a request from a California resident, we will take reasonable steps to verify the person’s identity.&nbsp; We will respond to a verified person’s request by: (a) deleting their personal information and, if applicable, directing our service providers to delete their personal information; or (ii) explaining to the requestor why the Act does not require us to delete their personal information.</p>

    <p>If a request or series of requests are manifestly unfounded or excessive, we may charge a reasonable fee for processing the request(s), or may refuse to process the request(s).</p>

    <p><strong>ILLINOIS RESIDENTS</strong></p>
    <p>If you are a medical cannabis patient in Illinois, personal information about you that constitutes Protected Health Information (PHI) pursuant to the Health Insurance Portability and Accountability Act (HIPAA) is governed by our Notice of Privacy Practices for Illinois Medical Cannabis. You can access this Notice at https://col-care.com/notice-of-privacy-practices/</p>

    <p><strong>CHANGES</strong></p>

    <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
    <p>This policy was last updated on January 11, 2022</p>
    <p><strong>CONTACT US</strong></p>
    <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at privacy@col-care.com or by mail at:</p>
    <p>Columbia Care Inc <br />
    Attn:&nbsp; Privacy/Legal <br />
    321 Billerica Road, Suite 204 <br />
    Chelmsford, MA 01824</p>
    `,
  },
  cookies: {
    title: "Forage Cookies Policy",
    content: `
      <p>This Cookies Policy explains how Forage use cookies and similar technologies. Whenever you use Forage’s website. Forage is committed to protecting the personal information collected when you use our Services.  This policy was last updated on October 12, 2020.
      </p>
      <h3>What are cookies and similar technologies?</h3>

      <p>A cookie is a small data file, often including a unique identifier, which is sent to your computer, mobile phone or tablet device (referred to in this policy as a “device”) by the web server so the website can remember some information about your browsing activity on the website. The cookie will collect information relating to your use of our Services, information about your device, e.g. the device’s IP address and browser type, broad location and, if you arrived at our site via a link from third party site, the URL of the linking page.
      </p>
      <p>Cookies record information about your online preferences and help us to tailor our Services to your interests. Information provided by cookies can help us to analyze your use of our Services and help us to provide you with a better user experience.
      </p>

      <h3>What are the different types of cookies?</h3>
      <ul>
        <li><strong>Session cookies</strong> are only stored for the duration of your visit to a website and are deleted from your device when you close your browser;
        </li>
        <li><strong>Persistent cookies</strong> are saved on your device for a fixed period of time after the browser has closed and are used where we (or a third party) need to identify you for a later browsing session. The fixed period of time is typically 30-90 days for targeting or behavioral advertising cookies and may be up to 26 months for analytical performance and measurement cookies;
        </li>
        <li><strong>First party cookies</strong> are set by the website you are visiting; and
        </li>
        <li><strong>Third party cookies</strong> are cookies used within a Service which are set by other organizations. These include cookies from external analytics services which help site owners understand the use of our sites, or by advertisers so that they can track the effectiveness of their advertisements.
        </li>
      </ul>

      <h3>What are cookies generally used for?</h3>
      <h4>1. Functional Cookies</h4>
      <p>Functional cookies are essential to the running of our Services. They are used to remember your preferences on our websites and to provide enhanced, more personal features. The information collected by these cookies is usually anonymized, so we cannot identify you personally. Functional cookies do not track your internet usage or gather information which could be used for selling advertising, but they do help with serving advertising. These cookies are usually session cookies which will expire when you close your browsing session.
      </p>

      <h4>2. Essential or ‘Strictly Necessary’ Cookies</h4>
      <p>These cookies are essential for the running of our Services. Without these cookies, parts of our websites would not function. These cookies do not track where you have been on the internet and do remember preferences beyond your current visit and do not gather information about you that could be used for marketing purposes. These cookies are usually session cookies which will expire when you close your browsing session.
      </p>

      <h4>3. Analytical Performance and Measurement Cookies</h4>
      <p>Analytical performance cookies are used to monitor the performance of our Services, for example, to determine the number of pageviews and the number of unique users a website has. Web analytics services may be designed and operated by third parties. The information provided by these cookies allows us to analyze patterns of user behavior and we use that information to enhance user experience or identify areas of the website which may require maintenance. The information is anonymous (i.e. it cannot be used to identify you and does not contain personal information such as your name and email address) and it is only used for statistical purposes
      </p>

      <h4>4. Targeting or ‘Behavioral Advertising’ Cookies</h4>
      <p>These cookies, which may be placed on your device by us or our trusted third party service providers, remember that you have visited a website and use that information to provide you with content or advertising which is tailored to your interests. This is often called online behavioral advertising (OBA) and is done by grouping together shared interests based upon web browsing history. Your web browsing history can be used to infer things about you (e.g. your age, gender etc.), and this information may also be used to make advertising on websites more relevant to you. Without these cookies, content and advertisements you encounter may be less relevant to you and your interests.
      </p>

      <h3>How does Forage use cookies?</h3>
      <p>Forage uses cookies in combination with other information we maintain for a number of purposes, including the following:</p>

      <table class="cookie-table">
        <tr>
          <th>Cookie Name</th>
          <th>Description</th>
          <th>Type</th>
          <th>Function</th>
        </tr>
        <tr>
          <td>_dd_s, _cid, _ga</td>
          <td>Datadog session cookies used to provide web performance analytics</td>
          <td>Session</td>
          <td>Analytical</td>
        </tr>
        <tr>
        <td>.com.auth0</td>
        <td>Auth0 cookies used to remember user information so that users do not have to log in each time they use the site.</td>
        <td>Persistent</td>
        <td>Functional</td>
        </tr>
      </table>
      <h3>How can users manage or opt out of cookies?</h3>
      <p>Some people find the idea of a website storing information on their device a little intrusive, in particular when the information is stored and used by a third party. For example, you may object to being served advertising which is targeted to your interests based on your browsing history. If you would prefer to opt out of cookies, it is possible to control cookies by following the steps below, however you should be aware that you might lose some features and functionality of the website if you do so.
      </p>
      <p>Cookies, including those which have already been set, can be deleted from your hard drive. You can also change the preferences/settings in your web browser to control cookies. Some internet browsers have a ‘Do Not Track or ‘DNT’ setting; this sends a signal to websites asking them not to track your browsing. The following links may be helpful:
      </p>

      <ul>
        <li><a target="_blank" href = "https://support.google.com/chrome/answer/95647"><span class="c-page__link">https://support.google.com/chrome/answer/95647</span></a> (Chrome)</li>
        <li><a target="_blank" href = "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"><span class="c-page__link">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</span></a> (Firefox)</li>
        <li><a target="_blank" href = "https://www.opera.com/help/tutorials/security/cookies/"><span class="c-page__link">https://www.opera.com/help/tutorials/security/cookies/</span></a> (Opera)</li>
        <li><a target="_blank" href = "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"><span class="c-page__link">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</span></a> (Internet Explorer)</li>
        <li><a target="_blank" href = "https://support.apple.com/en-us/HT210675"><span class="c-page__link">https://support.apple.com/en-us/HT210675</span></a> (Safari)</li>
        <li><a target="_blank" href = "https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"><span class="c-page__link">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</span></a> (Edge)</li>
      </ul>

      <p>In some cases, you can choose to accept cookies from the primary site but block them from third parties. In others, you can block cookies from specific advertisers, or clear out all cookies. Deleting or blocking cookies may reduce functionality of the site. To learn more about how to reject cookies, visit http://www.allaboutcookies.org/ or go to the help menu within your internet browser. If you experience any problems having deleted cookies, you should contact the supplier of your web browser.
      </p>
      <h3>Further information and contact details:</h3>

      <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailto:info@col-care.com">info@col-care.com</a> or by mail using the details provided below:
      </p>

      <p>Columbia Care Inc<br>745 Fifth Avenue<br>New York, NY 10151</p>
      `,
  },
  support: {
    title: "Support",
    content: `
        <div class="c-page__faq">
          <h3 class="c-page__faq__question">Do I need a medical marijuana license to place an order?</h3>
          <p class="c-page__faq__answer">It depends on which dispensary you are placing an order at. Each dispensary is marked as "recreational", "medical" or both. If it is indicated as just "medical" you are required to have a medical license to place an order there. You can view all of our locations here. </p>
        </div>
        <div class="c-page__faq">
          <h3 class="c-page__faq__question">How do I get a medical marijuana license?</h3>
          <p class="c-page__faq__answer">This is specific to state regulations. On each of our dispensary pages we have a link to the requirements for that state. You can view all of our locations here. </p>
        </div>
        <div class="c-page__faq">
          <h3 class="c-page__faq__question">I submitted an order but can't pick it up today. Will my order still be available tomorrow?
          <p class="c-page__faq__answer">Unfortunately, we're not able to hold orders overnight. Please re-submit your order on the day you are able to make the pick up and we'll make sure it's ready for you when you arrive.</p>
        </div>
        <div class="c-page__faq">
          <h3 class="c-page__faq__question">What identification do I need to pick up an order?</h3>
          <p class="c-page__faq__answer">If the dispensary you are ordering from is a medical dispensary you'll need a medical license to pick up an order. Otherwise you'll either need a drivers license or passport to confirm that you are over the age of 21.</p>
        </div>
        <div class="c-page__faq">
          <h3 class="c-page__faq__question">After placing an order, when will it be ready?</h3>
          <p class="c-page__faq__answer">After you place an order you'll receive a confirmation email with an estimated pick up time. Most orders will be ready within 2 hours.</p>
        </div>
        <div class="c-page__faq">
          <h3 class="c-page__faq__question">What hours are you open?</h3>
          <p class="c-page__faq__answer">This is dependent on the specific dispensary you are visiting. You can find the hours for a dispensary near you by visiting our Locations page.</p>
        </div>
        <div class="c-page__faq">
          <h3 class="c-page__faq__question">Can I modify my order once I arrive to pick it up?</h3>
          <p class="c-page__faq__answer">Yes, your order is not complete until you check out, in person, at the dispensary.</p>
        </div>
      `,
  },
  contact: {
    title: "Contact Us",
    content: `
      <h3>We’re here to help. Drop us a message and we’ll get back to you within 24 hours.</h3>
      <a href="mailto:foragesupport@col-care.com" target="_blank" rel="noopener noreferrer" class="c-button">Email Us</a>
      `,
  },
};
export default pages;
