import React                                from 'react';
import classNames                           from 'classnames';
import { RouteComponentProps, withRouter }  from 'react-router-dom';
import { useSelector }                      from 'react-redux';
import { Helmet }                           from "react-helmet";
import { RootState }  from 'store';
import Header         from 'components/Header';
import Footer         from 'components/Footer';
import './PageWrapper.scss';

interface PageWrapperProps extends RouteComponentProps {
  omitHeader?:  boolean
  title?: string
  children:     any
}

function PageWrapper({
  omitHeader = false, 
  children, 
  match, 
  location, 
  title
}: PageWrapperProps) {
  const isKiosk     = useSelector((state: RootState) => state.system.isKiosk);
  const isMenuOpen  = useSelector((state: RootState) => state.system.mobileNavOpen);

  let normalizedPathName  = match.path.replace(/[/:]/g ,'-').substring(1);
  const locationPath      = location.pathname;

  if(!normalizedPathName){
    normalizedPathName = locationPath === '/' ? 'home' : 'not-found';
  }
  
  return (
    <div className={classNames("c-page-wrapper c-page-wrapper--" + normalizedPathName, {'c-page-wrapper--kiosk': isKiosk, 'c-page-wrapper--menu-open': isMenuOpen})}>
      {
        !!title &&
        <Helmet>
          <title>{title}</title>
        </Helmet>
      }
      {
        !omitHeader &&
        <Header />
      }
        {children}
      {
        !omitHeader &&
        <Footer />
      }
    </div>
  )
}

export default withRouter(PageWrapper);