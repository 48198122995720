import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const Avatar: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.60938" cy="6.06348" r="4.89453" stroke={color} strokeWidth="1.5"/>
      <path d="M11.5039 16.8311C11.5039 14.1279 9.31255 11.9365 6.60937 11.9365C3.9062 11.9365 1.71484 14.1279 1.71484 16.8311" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default Avatar;