import {DispensariesType, HoursOfOperationType} from 'constants/dispensary';
import { 
  SET_DISPENSARIES, 
  SET_LOCATION_FILTER, 
  SET_LOCATION_DISTANCE, 
  SET_LOCATION_DISTANCES, 
  SET_LOADING_DISTANCES, 
  FETCH_DISPENSARIES, 
  FETCH_DISPENSARIES_SUCCESS,
  FETCH_DISPENSARIES_FAILED,
  SET_DISPENSARY_IS_OPEN, 
  SET_DISPENSARY_IS_OPEN_SUCCESS,
  SET_DISPENSARY_IS_OPEN_FAILED,
  LocationsActionTypes, 
  RESET_HAS_DISTANCES
} from './types';

export function SetLocations(dispensaries:Array<DispensariesType>): LocationsActionTypes {
  return {
    type:         SET_DISPENSARIES,
    dispensaries: dispensaries
  }
}
export function SetLocationFilter(filter:string): LocationsActionTypes {
  return {
    type:   SET_LOCATION_FILTER,
    filter: filter
  }
}

export function SetLocationDistance(dispensaryId:string|null|undefined, distance:string): LocationsActionTypes {
  return {
    type:         SET_LOCATION_DISTANCE,
    dispensaryId: dispensaryId,
    distance:     distance
  }
}

export function SetLocationDistances(allDistances:any): LocationsActionTypes {
  return {
    type:         SET_LOCATION_DISTANCES,
    allDistances: allDistances
  }
}

export function SetLoadingDistances(userLocationID:string): LocationsActionTypes {
  return {
    type:           SET_LOADING_DISTANCES,
    userLocationID: userLocationID
  }
}

export function FetchDispensaries() : LocationsActionTypes {
  return {
    type: FETCH_DISPENSARIES
  }
}

export function FetchDispensariesSuccess(dispensaries:any) : LocationsActionTypes {
  return {
    type:         FETCH_DISPENSARIES_SUCCESS,
    dispensaries: dispensaries
  }
}

export function FetchDispensariesFailed(error:string) : LocationsActionTypes {
  return {
    type:   FETCH_DISPENSARIES_FAILED,
    error:  error
  }
}

export function SetDispensaryIsOpen(slug:string, hoursOfOperation: HoursOfOperationType, timezone: string) : LocationsActionTypes {
  return {
    type:                 SET_DISPENSARY_IS_OPEN,
    slug:                 slug,
    hoursOfOperation:     hoursOfOperation,
    timezone:             timezone,
  }
}

export function SetDispensaryIsOpenSuccess(isOpen:boolean, slug:string) : LocationsActionTypes {
  return {
    type:   SET_DISPENSARY_IS_OPEN_SUCCESS,
    isOpen: isOpen,
    slug:   slug
  }
}

export function SetDispensaryIsOpenFailed(error:string) : LocationsActionTypes {
  return {
    type:   SET_DISPENSARY_IS_OPEN_FAILED,
    error:  error
  }
}

export function ResetHasDistances(): LocationsActionTypes {
  return {
    type: RESET_HAS_DISTANCES
  }
}