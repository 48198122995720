const GA_TRACKING_CODE = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '';

export const initializeGA = () => {
  const GTMScript = document.createElement('script');
  GTMScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_CODE}`;
  GTMScript.async = true;

  const GTAGScript = document.createElement('script');
  GTAGScript.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${GA_TRACKING_CODE}');`;

  document.head.appendChild(GTMScript);
  document.head.appendChild(GTAGScript);
};

export default initializeGA;
