
export interface InventoryFilterProps {
  flags?:     any
  prices?:    any
  formats?:   any
  feelings?:  any
}

export interface InventoryState {
  sort:                 string
  filter:               InventoryFilterProps
  inventory:            any
  inventoryIds:         any
  loadingInventory:     boolean
  inventoryError:       boolean
  inventoryDispensary:  string | null
  totalInventory:       null | number
  startRow:             null | number
  limitRow:             null | number
  searchQuery:          string
  slug:                 string
  page:                 number
  noResults:            boolean
  discountsLoading:     boolean
  discountsError:       boolean
  discountProducts:     any,
  inventoryChanging:    boolean,
  noDiscounts:          null | boolean
}

export const SET_INVENTORY_SORT       = 'SET_INVENTORY_SORT';
export const SET_INVENTORY_FILTER     = 'SET_INVENTORY_FILTER';
export const SET_INVENTORY_PAGE       = 'SET_INVENTORY_PAGE';
export const FETCH_INVENTORY          = 'FETCH_INVENTORY';
export const FETCH_INVENTORY_SUCCESS  = 'FETCH_INVENTORY_SUCCESS';
export const FETCH_INVENTORY_FAILED   = 'FETCH_INVENTORY_FAILED';
export const FETCH_PRODUCT            = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_SUCCESS    = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILED     = 'FETCH_PRODUCT_FAILED';
export const FETCH_DISCOUNTS          = 'FETCH_DISCOUNTS';
export const FETCH_DISCOUNTS_SUCCESS  = 'FETCH_DISCOUNTS_SUCCESS';
export const FETCH_DISCOUNTS_FAILED   = 'FETCH_DISCOUNTS_FAILED';
export const SET_SLUG                 = 'SET_SLUG';
export const SET_SEARCH               = 'SET_SEARCH';
export const SET_INVENTORY_CHANGING   = 'SET_INVENTORY_CHANGING';
export const RESET_INVENTORY          = 'RESET_INVENTORY';
export const RESET_NO_RESULTS         = 'RESET_NO_RESULTS';

interface SetInventorySort {
  type: typeof SET_INVENTORY_SORT,
  sort: string
}

interface SetInventoryFilter {
  type:   typeof SET_INVENTORY_FILTER,
  filter: InventoryFilterProps
}

interface FetchInventory {
  type: typeof FETCH_INVENTORY,
  slug: string
}

interface FetchInventorySuccess {
  type:       typeof FETCH_INVENTORY_SUCCESS,
  dispensary: string,
  inventory:  any
}

interface FetchInventoryFailed {
  type: typeof FETCH_INVENTORY_FAILED,
}

interface FetchProduct {
  type: typeof FETCH_PRODUCT,
  slug: string,
  id:   number | string
}

interface FetchProductSuccess {
  type:       typeof FETCH_PRODUCT_SUCCESS,
  dispensary: string,
  product:    any
}

interface FetchProductFailed {
  type: typeof FETCH_PRODUCT_FAILED,
}

interface SetSearch {
  type:         typeof SET_SEARCH,
  searchQuery:  string
}

interface SetSlug {
  type: typeof SET_SLUG,
  slug: string
}

interface SetInventoryPage {
  type: typeof SET_INVENTORY_PAGE,
  page: number
}

interface FetchDiscounts {
  type: typeof FETCH_DISCOUNTS
}

interface FetchDiscountsSuccess {
  type:             typeof FETCH_DISCOUNTS_SUCCESS
  discountProducts: any
}

interface FetchDiscountsFailed {
  type: typeof FETCH_DISCOUNTS_FAILED
}

interface SetInventoryChanging {
  type:               typeof SET_INVENTORY_CHANGING,
  inventoryChanging:  boolean
}

interface ResetInventory {
  type: typeof RESET_INVENTORY
}

interface ResetNoResults {
  type: typeof RESET_NO_RESULTS,
}

export type InventoryActionTypes = 
  SetInventoryFilter | 
  SetInventorySort | 
  FetchInventory | 
  FetchInventorySuccess | 
  FetchInventoryFailed | 
  FetchProduct | 
  FetchProductSuccess | 
  FetchProductFailed |
  SetSearch |
  SetSlug |
  SetInventoryPage |
  FetchDiscounts |
  FetchDiscountsSuccess |
  FetchDiscountsFailed |
  SetInventoryChanging |
  ResetInventory |
  ResetNoResults;