import React          from 'react';
import { Link }       from 'react-router-dom';
import BackArrowIcon  from 'components/svgs/BackArrow';
import './BackButton.scss';
interface BackButtonType {
  to?:      string
  onClick?: any
}

function BackButton({ 
  to, 
  onClick 
} : BackButtonType) {
  return(
    <>
    {
      to ?
      <Link to={to} className="c-back-button">
        <BackArrowIcon/>
        <span>Back</span>
      </Link>
      :
      <button className="c-back-button" onClick={onClick}>
        <BackArrowIcon/>
        <span>Back</span>
      </button>
    }
    </>
  )
}

export default BackButton;