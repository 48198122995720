import { CartState } from '../cart/types';

export interface OrdersState {
  placingOrder:     boolean
  orderPlaced:      boolean
  latestOrder:      any
  error:            any
  loadingPreorder:  boolean
  preorder:         any
  preorders:        any
}

export const PLACE_ORDER            = 'PLACE_ORDER';
export const PLACE_ORDER_SUCCESS    = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILED     = 'PLACE_ORDER_FAILED';
export const RESET_ORDER_PLACED     = 'RESET_ORDER_PLACED';
export const FETCH_PREORDER         = 'FETCH_PREORDER';
export const FETCH_PREORDER_SUCCESS = 'FETCH_PREORDER_SUCCESS';
export const FETCH_PREORDER_FAILED  = 'FETCH_PREORDER_FAILED'

interface PlaceOrder {
  type: typeof PLACE_ORDER,
  cart: CartState
}

interface PlaceOrderSuccess {
  type:   typeof PLACE_ORDER_SUCCESS,
  order:  any
}

interface PlaceOrderFailed {
  type:   typeof PLACE_ORDER_FAILED,
  error:  any
}

interface FetchPreorder {
  type:           typeof FETCH_PREORDER,
  orderId:        number,
  dispensarySlug: string
}

interface FetchPreorderSuccess {
  type:     typeof FETCH_PREORDER_SUCCESS,
  preorder: any,
  orderId:  string
}

interface FetchPreorderFailed {
  type:   typeof FETCH_PREORDER_FAILED,
  error:  any
}

interface ResetOrderPlaced {
  type: typeof RESET_ORDER_PLACED
}

export type OrdersActionTypes = 
  PlaceOrder | 
  PlaceOrderSuccess | 
  PlaceOrderFailed | 
  ResetOrderPlaced | 
  FetchPreorder | 
  FetchPreorderSuccess | 
  FetchPreorderFailed;