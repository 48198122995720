import {
  LocationsState,
  LocationsActionTypes,
  SET_LOCATION_FILTER,
  SET_DISPENSARIES,
  SET_LOCATION_DISTANCE,
  SET_LOCATION_DISTANCES,
  SET_LOADING_DISTANCES,
  FETCH_DISPENSARIES,
  FETCH_DISPENSARIES_SUCCESS,
  FETCH_DISPENSARIES_FAILED,
  SET_DISPENSARY_IS_OPEN,
  SET_DISPENSARY_IS_OPEN_SUCCESS,
  SET_DISPENSARY_IS_OPEN_FAILED,
  RESET_HAS_DISTANCES,
} from "./types";

const initialState: LocationsState = {
  userLocationID: "",
  userLocationString: null,
  filter: "all",
  dispensaries: null,
  hasDistances: false,
  loadingDistances: false,
  originID: "",
  loadingDispensaries: false,
  loadingSetDispensaries: false,
  error: "",
  storeId: "",
};

export function locationsReducer(
  state = initialState,
  action: LocationsActionTypes
): LocationsState {
  switch (action.type) {
    case SET_DISPENSARY_IS_OPEN: {
      let dispensaries: any = state.dispensaries;
      const slugToUpdate = Object.keys(dispensaries).find(
        (dispensarySlug) => dispensarySlug === action.slug
      );
      if (slugToUpdate) {
        dispensaries[slugToUpdate].checkingOpen = true;
      }
      return {
        ...state,
        dispensaries: { ...dispensaries },
      };
    }
    case SET_DISPENSARY_IS_OPEN_SUCCESS: {
      let dispensaries: any = state.dispensaries;
      const slugToUpdate = Object.keys(dispensaries).find(
        (dispensarySlug) => dispensarySlug === action.slug
      );
      if (slugToUpdate) {
        dispensaries[slugToUpdate].isOpen = action.isOpen;
        dispensaries[slugToUpdate].checkingOpen = false;
      }
      return {
        ...state,
        dispensaries: { ...dispensaries },
      };
    }
    case SET_DISPENSARY_IS_OPEN_FAILED: {
      return {
        ...state,
      };
    }
    case FETCH_DISPENSARIES: {
      return {
        ...state,
        loadingDispensaries: true,
        loadingSetDispensaries: false,
      };
    }
    case FETCH_DISPENSARIES_SUCCESS: {
      const dispensaries = action.dispensaries?.reduce(function (
        map: any,
        dispensary: any
      ) {
        dispensary.image =
          dispensary.image ||
          process.env.REACT_APP_STATIC_URL +
            "/locations/" +
            dispensary.slug +
            ".jpg";
        map[dispensary.slug] = dispensary;
        return map;
      },
      {});
      return {
        ...state,
        dispensaries: dispensaries,
        loadingSetDispensaries:true
      };
    }
    case FETCH_DISPENSARIES_FAILED: {
      return {
        ...state,
        error: action.error,
      };
    }
    case SET_DISPENSARIES: {
      let disps: any = action.dispensaries;
      disps.map((disp: any) => {
        disp.slug = disp.dispensary
          .replace(/(\.)/g, "")
          .replace(/(\s)/g, "-")
          .toLowerCase();
        return disp;
      });
      const dispensaries = disps.reduce(function (map: any, dispensary: any) {
        map[dispensary.id] = dispensary;
        return map;
      }, {});
      return {
        ...state,
        dispensaries: { ...dispensaries },
      };
    }
    case SET_LOCATION_FILTER: {
      return {
        ...state,
        filter: action.filter,
      };
    }
    case SET_LOCATION_DISTANCE: {
      if (!action.dispensaryId) {
        return { ...state };
      }
      let dispensaries: any = state.dispensaries;
      dispensaries[action.dispensaryId].distance = action.distance;
      return {
        ...state,
        dispensaries: { ...dispensaries },
      };
    }
    case SET_LOADING_DISTANCES: {
      return {
        ...state,
        hasDistances: false,
        loadingDistances: true,
        originID: action.userLocationID,
      };
    }
    case SET_LOCATION_DISTANCES: {
      if (!action.allDistances) {
        return { ...state };
      }
      let dispensaries: any = state.dispensaries;
      Object.keys(dispensaries).forEach((key) => {
        const distance = action.allDistances.find((location: any) => {
          return location.slug === key;
        });
        if (distance && distance.distance) {
          let distanceString: any = parseFloat(
            distance.distance.text.replace(/,/g, "")
          );
          if (distanceString > 10) {
            distanceString = distanceString.toFixed(0);
          } else {
            distanceString = distanceString.toFixed(1);
          }
          dispensaries[key].distance = distanceString;
          dispensaries[key].distanceMeters = distance.distance.value;
        } else {
          console.log("Distance without distance: ", distance);
        }
      });
      return {
        ...state,
        dispensaries: { ...dispensaries },
        hasDistances: true,
        loadingDistances: false,
      };
    }
    case RESET_HAS_DISTANCES: {
      return {
        ...state,
        hasDistances: false,
      };
    }

    default:
      return state;
  }
}
