import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const CheckMark: FunctionComponent<IconType> = ({ variant = "charcoal"}) => {
  const color = colors[variant];
  return(
    <svg className="checkmark-icon" width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3.5L3.5 6L7.5 1" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CheckMark;