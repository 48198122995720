import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const Phone: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="phone-icon" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6293 11.6L10.7855 10.1937C10.664 10.1339 10.529 10.1214 10.4007 10.1578C10.2725 10.1943 10.158 10.2778 10.0745 10.3958L8.81512 12.1713C6.83861 11.096 5.24798 9.26064 4.31607 6.98005L5.85477 5.5269C5.95727 5.43071 6.02982 5.29861 6.06143 5.15059C6.09304 5.00257 6.082 4.8467 6.02997 4.70657L4.8112 1.42527C4.7541 1.27421 4.65311 1.15088 4.52564 1.07654C4.39817 1.0022 4.25222 0.981507 4.11294 1.01803L1.47227 1.72117C1.338 1.75695 1.2182 1.84418 1.13242 1.96864C1.04665 2.0931 0.999969 2.24743 1 2.40644C1 9.92122 6.2788 16.0004 12.7815 16.0004C12.9193 16.0005 13.0531 15.9467 13.161 15.8477C13.2689 15.7487 13.3446 15.6105 13.3756 15.4555L13.985 12.4086C14.0164 12.2471 13.9981 12.078 13.9332 11.9304C13.8683 11.7828 13.7608 11.666 13.6293 11.6V11.6Z" stroke={color} strokeWidth="1.5"/>
    </svg>
  )
}

export default Phone;