import React, { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import {
  SetRecFinderPreference,
  SetRecPreferenceAdjustment,
} from "store/recommendations/actions";
import CheckmarkIcon from "components/svgs/Checkmark";
import "./Steps.scss";
import Button from "../../../Button";
import { CSSTransition } from "react-transition-group";
import { recPreferencesLimit } from "constants/recommendations";

type StepProps = {
  adjustment?: any;
  goToResults?: () => void;
  nextStep?: () => void;
};
export type Ref = HTMLDivElement;
const Step4 = forwardRef<Ref, StepProps>(
  ({ adjustment, goToResults, nextStep }, ref) => {
    const dispatch = useDispatch();
    const existingOccasions = useSelector((state: RootState) =>
      adjustment
        ? state.recs.recPreferencesAdjustments.occasions
        : state.recs.recPreferences.occasions
    );

    const [selectedOccasions, setSelectedOccasions] = useState<any>(
      existingOccasions
    );
    const [occasionreferenceMap, setOccasionPreferenceMap] = useState(
      existingOccasions
    );

    const occasions = [
      { id: "goingToConcert", label: "Going To A Concert" },
      { id: "houseParty", label: "House Party" },
      { id: "havingFunOutdoors", label: "Having Fun Outdoor" },
      { id: "watchingMovie", label: "Watching A Movie" },
      { id: "prePostWorkout", label: "Pre/Post Workout" },
      { id: "relaxingAtHome", label: "Relaxing At Home" },
      { id: "complimentWork", label: "Complement Work" },
      { id: "helpingMeSleep", label: "Helping Me Sleep" },
      { id: "sharingWithFriends", label: "Sharing With Friends" },
      { id: "nothingInParticular", label: "Nothing In Particular" },
    ];

    useEffect(() => {
      setSelectedOccasions(existingOccasions);
    }, [existingOccasions]);

    function selectOccasions(occasion: any) {
      const newOccasions: any = { ...occasionreferenceMap };
      newOccasions[occasion.id] = !newOccasions[occasion.id];
      setOccasionPreferenceMap({ ...newOccasions });
      if (adjustment) {
        dispatch(SetRecPreferenceAdjustment("occasions", { ...newOccasions }));
      } else {
        dispatch(SetRecFinderPreference("occasions", { ...newOccasions }));
        dispatch(SetRecFinderPreference("limit", recPreferencesLimit));
      }
    }

    return (
      <div className="c-mood-selector__step c-mood-selector__step--4">
        <div className="c-mood-selector__step__inner" ref={ref}>
          <div className="l-main l-maxwidth l-maxwidth--small">
            <header className="c-mood-selector__header">
              <h1>What’s the occasion?</h1>
              <p className="c-mood-selector__question-progress">
                Question 4 of 5
              </p>
            </header>
            <div className="c-mood-selector__occasions">
              {occasions.map((occasion: any) => {
                return (
                  <button
                    key={adjustment ? "adjustment-" + occasion.id : occasion.id}
                    onClick={() => selectOccasions(occasion)}
                    className={classNames("c-mood-selector__occasion", {
                      "c-mood-selector__occasion--selected":
                        selectedOccasions[occasion.id],
                    })}
                  >
                    <span>{occasion.label}</span>
                    <div className="c-mood-selector__occasion__check">
                      <CheckmarkIcon variant="white" />
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <CSSTransition
          classNames="c-mood-selector__action"
          timeout={500}
          appear
          in
        >
          <div
            className={classNames(
              "c-mood-selector__standard-action c-mood-selector__standard-action__step"
            )}
          >
            <Button type="button" onClick={nextStep} variant="">
              Next
            </Button>
            <Button type="button" onClick={goToResults} variant="outlined">
              View Recommendations
            </Button>
          </div>
        </CSSTransition>
      </div>
    );
  }
);

export default Step4;
