import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import App from "./App";
import rootReducer from "store";
import forageSagas from "store/sagas";
import { UnsupportedBrowserDetection } from "./utils/unsupportedBrowserDetection";
import "./index.css";
import UnsupportedBrowser from "./components/UnsupportedBrowser";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const sagaMiddleware = createSagaMiddleware();

const checkEnv =
  typeof window !== "undefined" ? sessionStorage.getItem("enabled") : false;
const composeEnhancers =
  ((process?.env?.NODE_ENV !== "production" || checkEnv) &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

const supportedBrowser = new UnsupportedBrowserDetection().isSupported();

const renderApp = () => {
  if (supportedBrowser) {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  } else {
    return <UnsupportedBrowser />;
  }
};

sagaMiddleware.run(forageSagas);
ReactDOM.render(renderApp(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
