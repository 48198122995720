import React, { FunctionComponent } from 'react';
import colors from 'sass/utils/colors.module.scss';

type LogoProps = {
  variant?: string
}

const LinkOut: FunctionComponent<LogoProps> = ({ variant = 'charcoal' }) => {
  let fillColor = colors[variant] || colors['charcoal'];
  return ( 
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4299 9.41635L9.77308 15.0732" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.9194 14.7196V8.92676H10.1265" stroke={fillColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
export default LinkOut;