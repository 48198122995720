import React, { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { throttle } from "throttle-debounce";
import { RootState } from "store";
import { SetDrawerOpen } from "store/system/actions";
import Button from "components/Button";
import FilterIcon from "components/svgs/Filter";
import Pill from "components/Pill";
import { Link } from "react-router-dom";
import { PDPGoodFor, PDPTopper } from "components/PDP";
import AddToCart from "components/AddToCart";
import "./Steps.scss";
import ProductIcon from "../../../ProductIcon";
import DotPulseLoader from "components/Loading/PulseLoader";
import { MAXIMUM_RECS_COUNT } from "../index";

type StepProps = {
  goToPDP: any;
  goToRelatedProducts: () => void;
  onSelectorOpen: any;
};
export type Ref = HTMLDivElement;

const Step6 = forwardRef<Ref, StepProps>(
  ({ goToPDP, onSelectorOpen, goToRelatedProducts }, ref) => {
    const dispatch = useDispatch();

    const isMobile = useSelector((state: RootState) => state.system.isMobile);
    const isTablet = useSelector((state: RootState) => state.system.isTablet);
    const drawerOpen = useSelector(
      (state: RootState) => state.system.drawerOpen
    );
    const moodSelectorStep = useSelector(
      (state: RootState) => state.system.moodSelectorStep
    );

    const activeDispensary = useSelector(
      (state: RootState) => state.cart.activeDispensary
    );

    const recCount = useSelector((state: RootState) => state.recs.recCount);
    const recs = useSelector((state: RootState) => state.recs.recs);
    const recCountLoading = useSelector(
      (state: RootState) => state.recs.recCountLoading
    );
    const recsLoading = useSelector(
      (state: RootState) => state.recs.recsLoading
    );
    const recProductIds = useSelector(
      (state: RootState) => state.recs.recProductIds
    );

    const [showTitle, setShowTitle] = useState(false);
    const [recommendationsCount, setRecommendationsCount] = useState<number>(0);
    const [
      productIndexInViewport,
      setProductIndexInViewport,
    ] = useState<number>(0);

    const checkActiveProductIndex = () => {
      const items = document.querySelectorAll(
        ".c-recommended-products__product"
      );

      if (items.length < 1) {
        return;
      }

      items.forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        if (
          rect.top >= 0 &&
          rect.top + 200 <=
            (window.innerHeight || document.documentElement.clientHeight)
        ) {
          setProductIndexInViewport(index);
        }
      });

      // const index = Array.from(items).findIndex((item: any) => {
      //   const rect = item.getBoundingClientRect();
      //   return (
      //     rect.bottom <=
      //       (window.innerHeight || document.documentElement.clientHeight) &&
      //     rect.top >= 0
      //   );
      // });

      // setProductIndexInViewport(index);
    };

    useEffect(() => {
      const scrollFn = throttle(200, false, checkActiveProductIndex);
      window.addEventListener("scroll", scrollFn);

      return () => {
        window.removeEventListener("scroll", scrollFn);
      };
    }, []);

    useEffect(() => {
      if (recCount) {
        setRecommendationsCount(
          recCount > MAXIMUM_RECS_COUNT ? MAXIMUM_RECS_COUNT : recCount
        );
      }
    }, [recCount]);

    useEffect(() => {
      if (moodSelectorStep === 6) {
        setShowTitle(true);
      }
    }, [moodSelectorStep]);

    function goPDP(path: string) {
      setShowTitle(false);
      setTimeout(() => {
        goToPDP(path);
      }, 500);
    }

    const thcInfo = (product: any) =>
      (product?.thcContent || product?.cbdContent) && (
        <>
          <p className="slug">Average Cannabinoids</p>
          <p className="c-recommended-products__thcContent body--xsmall">
            {product?.thcContent > 0 ? (
              <span>
                {"THC : " + product.thcContent + product.thcContentUnit}
              </span>
            ) : (
              ""
            )}
            <br />
            {product?.cbdContent > 0 ? (
              <span>
                {"CBD : " + product.cbdContent + product.cbdContentUnit}
              </span>
            ) : (
              ""
            )}
          </p>
        </>
      );

    return (
      <div className="c-mood-selector__step c-mood-selector__step--6">
        <div className="c-mood-selector__step__inner" ref={ref}>
          <div
            className={classNames(
              "l-main l-maxwidth c-mood-selector__step--6__title",
              { "c-mood-selector__step6-title--visible": showTitle }
            )}
          >
            <h1>
              {(recsLoading || recCountLoading) && !recProductIds?.length
                ? "Loading"
                : recommendationsCount}{" "}
              Recommendations
            </h1>
            <Pill
              Icon={FilterIcon}
              active={drawerOpen}
              onClick={() => dispatch(SetDrawerOpen(true))}
            >
              <span className="body--xsmall">Adjust</span>
            </Pill>
          </div>

          <div className="c-recommended-products__container">
            {(recsLoading || recCountLoading) && !recProductIds?.length && (
              <DotPulseLoader />
            )}
            {!recProductIds?.length ? (
              <>
                {[...Array(recommendationsCount || MAXIMUM_RECS_COUNT)].map(
                  (e, i) => (
                    <div
                      key={"c-recommended-products__loading-" + i}
                      className="c-recommended-products c-recommended-products__item c-recommended-products__item--loading"
                    ></div>
                  )
                )}
              </>
            ) : (
              recProductIds
                ?.slice(0, MAXIMUM_RECS_COUNT)
                .map((recKey: any, index: number) => {
                  const rec = recs[recKey];
                  return (
                    <div
                      className={`c-recommended-products c-recommended-products__item c-recommended-products__product`}
                      key={
                        String(recKey) + rec.product.productName + String(index)
                      }
                    >
                      <>
                        {activeDispensary ? (
                          isMobile ? (
                            <Link
                              to={
                                "/dispensary/" +
                                activeDispensary +
                                "/product/" +
                                recKey
                              }
                              className="c-recommended-products__item__link"
                            >
                              <PDPTopper
                                gradientOnly={isTablet}
                                dispensarySlug={activeDispensary}
                                productId={recKey}
                                recs={true}
                                animateGradient={false}
                              />
                              <div className="c-recommended-products__item__card">
                                {thcInfo(rec.product)}
                                <PDPGoodFor
                                  goodFor={rec.bestFor}
                                  animate={productIndexInViewport === index}
                                />
                              </div>
                            </Link>
                          ) : (
                            <>
                              <PDPTopper
                                gradientOnly={isTablet}
                                dispensarySlug={activeDispensary}
                                productId={recKey}
                                recs={true}
                                animateGradient={false}
                              />
                              <div className="c-recommended-products__item__card">
                                {thcInfo(rec.product)}
                                <PDPGoodFor
                                  goodFor={rec.bestFor}
                                  animate={productIndexInViewport === index}
                                />
                                <Button
                                  type="button"
                                  onClick={() =>
                                    goPDP(
                                      "/dispensary/" +
                                        activeDispensary +
                                        "/product/" +
                                        recKey
                                    )
                                  }
                                  variant="outlined"
                                >
                                  See more details
                                </Button>
                              </div>
                            </>
                          )
                        ) : null}
                        {(isMobile || isTablet) && activeDispensary && (
                          <AddToCart
                            product={rec}
                            dispensarySlug={activeDispensary}
                          />
                        )}
                      </>
                    </div>
                  );
                })
            )}

            {recProductIds?.length ? (
              <div className="c-recommended-products c-recommended-products__item c-recommended-products__item--related-products">
                <div className="c-pdp-topper c-pdp-topper__related-products">
                  <div className="c-recommended__category-image-container">
                    <div className="c-recommended__category-image c-recommended__category-image--1">
                      <ProductIcon iconId="pills" variant="gray80" />
                    </div>
                    <div className="c-recommended__category-image c-recommended__category-image--2">
                      <ProductIcon iconId="oil" variant="gray80" />
                    </div>
                    <div className="c-recommended__category-image c-recommended__category-image--3">
                      <ProductIcon iconId="beverages" variant="gray80" />
                    </div>
                  </div>
                  <p className="c-recommended__text">
                    For more products that can get you where you want to be.
                  </p>
                  <Button
                    type="button"
                    onClick={goToRelatedProducts}
                    variant=""
                  >
                    See Similar Products
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default Step6;
