import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Form, Field, reduxForm, InjectedFormProps } from "redux-form";
import Button from "components/Button";
import { Input, Checkbox } from "components/Forms/Fields";
import { AgegateValidator as validate } from "components/Forms/Validators";
import { normalizeNumber } from "components/Forms/Normalizers";
import {
  DAY_MAX_LENGTH,
  MONTH_MAX_LENGTH,
  YEAR_MAX_LENGTH,
} from "components/Forms/Fields/constants";

function AgeGateForm({ submitFailed, handleSubmit, error }: InjectedFormProps) {
  const monthInput = useRef<HTMLDivElement>(null);
  const dayInput = useRef<HTMLDivElement>(null);
  const yearInput = useRef<HTMLDivElement>(null);

  // onKeyDown check if backspace, if field is empty retreat the focus to previous input
  function checkKey(e: any) {
    const inputTarget = e.target as HTMLInputElement;
    if (e.keyCode === 8) {
      if (inputTarget.value === "") {
        if (inputTarget.name === "day" && null !== monthInput.current) {
          monthInput.current.getElementsByTagName("input")[0].focus();
        } else if (inputTarget.name === "year" && null !== dayInput.current) {
          dayInput.current.getElementsByTagName("input")[0].focus();
        }
      }
    }
  }

  function keyUp(e: any) {
    if (
      !(
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      )
    ) {
      return;
    }
    const inputName = e.currentTarget.getAttribute("name");
    const inputValue = e.target.value;
    if (inputName === "month" && inputValue) {
      const onlyNums = inputValue.replace(/[^\d]/g, "");
      const trueValue = parseInt(onlyNums);
      if (
        null !== dayInput.current &&
        trueValue <= 12 &&
        onlyNums.length === 2
      ) {
        dayInput.current.getElementsByTagName("input")[0].focus();
      }
    }

    if (inputName === "day" && inputValue) {
      const onlyNums = inputValue.replace(/[^\d]/g, "");
      const trueValue = parseInt(onlyNums);
      if (
        null !== yearInput.current &&
        trueValue <= 31 &&
        onlyNums.length === 2
      ) {
        yearInput.current.getElementsByTagName("input")[0].focus();
      }
    }
  }

  const CookiesLabel = function () {
    return (
      <span>
        By checking this box you agree to the{" "}
        <Link to="/terms-of-use">terms of service</Link> and{" "}
        <Link to="/cookie-policy">cookie policies</Link>
      </span>
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="agegate__form" autoComplete="off">
      <div className="agegate__fieldset">
        <div ref={monthInput} className="agegate__form-column">
          <Field
            component={Input}
            name="month"
            type="number"
            placeholder="mm"
            normalize={normalizeNumber}
            onKeyDown={checkKey}
            onKeyUp={keyUp}
            className="agegate__month-input"
            parse={(val: any) => parseInt(val.substring(0, 2), 10)}
            maxlength={MONTH_MAX_LENGTH}
          />
          <label htmlFor="month" className="agegate__label">
            Month
          </label>
        </div>
        <div ref={dayInput} className="agegate__form-column">
          <Field
            component={Input}
            name="day"
            type="number"
            placeholder="dd"
            normalize={normalizeNumber}
            onKeyDown={checkKey}
            onKeyUp={keyUp}
            className="agegate__day-input"
            parse={(val: any) => parseInt(val.substring(0, 2), 10)}
            maxlength={DAY_MAX_LENGTH}
          />
          <label htmlFor="day" className="agegate__label">
            Day
          </label>
        </div>
        <div
          ref={yearInput}
          className="agegate__form-column agegate__form-column--wide"
        >
          <Field
            component={Input}
            name="year"
            type="number"
            placeholder="yyyy"
            normalize={normalizeNumber}
            onKeyDown={checkKey}
            className="agegate__year-input"
            parse={(val: any) => parseInt(val.substring(0, 4), 10)}
            maxlength={YEAR_MAX_LENGTH}
          />
          <label htmlFor="year" className="agegate__label">
            Year
          </label>
        </div>
      </div>
      <Field
        component={Checkbox}
        name="cookies"
        options={[{ name: "cookies", labelHTML: CookiesLabel }]}
        className="i-checkbox-group--two-wide"
      />
      {submitFailed && error && <p className="agegate__error">{error}</p>}
      <Button disabled={false} variant="default" type="submit">
        Let's Go!
      </Button>
    </Form>
  );
}
export default reduxForm({
  form: "agegate",
  validate,
})(AgeGateForm);
