import React from "react";
import "./PDPImage.scss";

interface PDPImageProps {
  photos: {
    url?: string;
  };
  productName?: string;
}
function PDPImage({ photos, productName }: PDPImageProps) {
  return (
    <>
      {photos?.url && (
        <figure className="c-pdp-image__figure">
          <img src={photos?.url} alt={productName || ""} />
        </figure>
      )}
    </>
  );
}

export default PDPImage;
