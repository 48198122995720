import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import './CardGradients.scss';
import Noise from 'images/noise.png';
import {isIOS, isSafari} from 'react-device-detect';
import feelingColors from 'sass/utils/feelingColors.module.scss';
import chroma from 'chroma-js';
import { renderGradientPlaceholder } from "./index";

const BLUR = 20;

function blob1(ctx: any, productColors: any) {

  if (!productColors[1]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[1]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[1]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[1]]).alpha(0.0).css();
  // blob1/Path
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(630.2, 882.7);
  ctx.bezierCurveTo(559.8, 430.6, 438.8, 377.8, 395.0, 265.8);
  ctx.bezierCurveTo(267.3, -60.9, 124.2, -37.8, 0.0, 78.1);
  ctx.lineTo(-300.0, 534.1);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  gradient = ctx.createRadialGradient(507.7, 412.4, 0.0, 507.7, 412.4, 423.5);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

function blob2(ctx: any, productColors: any) {

  if (!productColors[2]) {
    return
  }

  const colorStep1 = chroma(feelingColors[productColors[2]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[2]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[2]]).alpha(0.0).css();
  var gradient;

  // blob2/Path
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(869.1, 39.2);
  ctx.bezierCurveTo(806.3, 149.3, 606.2, 498.9, 496.1, 436.2);
  ctx.bezierCurveTo(385.9, 373.4, -56.7, 233.3, 6.1, 123.2);
  ctx.bezierCurveTo(68.8, 13.0, 413.9, -32.6, 524.1, 30.2);
  ctx.bezierCurveTo(634.2, 92.9, 931.8, -71.0, 869.1, 39.2);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  gradient = ctx.createRadialGradient(388.2, 7.6, 0.0, 388.2, 7.6, 466.2);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

function blob3(ctx: any, productColors: any) {

  if (!productColors[2]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[2]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[2]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[2]]).alpha(0.0).css();
  // blob3/Path
  ctx.beginPath();
  ctx.save();
  ctx.moveTo(1205.1, 338.2);
  ctx.bezierCurveTo(1205.1, 528.0, 349.8, 806.2, 113.1, 806.2);
  ctx.bezierCurveTo(-123.7, 806.2, 188.0, 486.4, 43.1, 529.2);
  ctx.bezierCurveTo(402.1, 264.2, 577.1, 249.2, 774.1, 220.2);
  ctx.bezierCurveTo(1008.3, 185.7, 1205.1, 148.3, 1205.1, 338.2);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  ctx.transform(1.247, 0.000, 0.000, -1.000, -1031.5, 122.0);
  gradient = ctx.createRadialGradient(1072.4, -225.3, 0.0, 1072.4, -225.3, 503.5);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();

}

interface GCL2Props {
  feelings: Array<string>,
  isActive?: boolean
  animate: boolean
}

function GradientCardLayout2({feelings, isActive = false, animate}: GCL2Props) {
  const gradRef = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLCanvasElement>(null);
  const [ref1Transform, setRef1Transform] = useState('');
  const [productColors, setProductColors] = useState<any>([]);

  useEffect(() => {
    if (productColors !== null && productColors.length === 0 && feelings !== null && feelings.length > 0) {
      setProductColors(feelings);
    }
  }, [productColors, feelings]);

  function animateRef1() {
    const scaleInt = 1 + (Math.random() * (100 - 0) + 0) / 100;
    const rotateInt = 10 * (Math.random() * (60 - 0) + 0) / 100;
    setRef1Transform('scale(' + scaleInt + ') rotate(' + rotateInt + 'deg)');
  }

  useEffect(() => {
    let animationId = 0;

    if (ref1.current) {
      var ctx = ref1.current.getContext("2d");
      if (ctx) {
        animationId = requestAnimationFrame(() => {
          blob1(ctx, productColors);
          blob2(ctx, productColors);
          blob3(ctx, productColors);
          animateRef1();
        });
      }
    }
    return () => cancelAnimationFrame(animationId);

  }, [productColors]);


  return (
    <>
      {renderGradientPlaceholder(productColors)}
      {
        productColors && productColors.length &&
        <div className="c-card-gradient c-card-gradient--layout-2"
             style={{backgroundColor: feelingColors[productColors[0]]}}
             ref={gradRef}>
          <div style={{backgroundImage: 'url(' + Noise + ')'}}
               className={classNames('c-card-gradient__noise', {'c-card-gradient__noise--safari': isIOS || isSafari})}></div>
          <canvas id="canvas" style={{transform: animate ? ref1Transform : ''}}
                  className={classNames('', {'c-card-gradient--safari-blur': isIOS || isSafari})} width="631"
                  height="535"
                  ref={ref1}></canvas>
        </div>
      }
    </>
  )
}

export default GradientCardLayout2;