import React from "react";
import "./PDPDetails.scss";
interface PDPDetailsProps {
  product: any;
}
function PDPDetails({ product }: PDPDetailsProps) {
  const hasDetails =
    product.product.thcContent ||
    product.product.cbdContent ||
    product.product.flavor ||
    product.product.strainType;
  return (
    <>
      {hasDetails && (
        <section className="c-pdp-details">
          {product.product.thcContent ? (
            <div className="c-pdp-details__item">
              <div className="slug">Strength</div>
              <span>
                {product.product.thcContent < 15
                  ? "Light"
                  : product.product.thcContent < 36
                  ? "Medium"
                  : "High"}
              </span>
            </div>
          ) : (
            ""
          )}
          {product.product.flavor && (
            <div className="c-pdp-details__item">
              <div className="slug">Taste Notes</div>
              <span>{product.product.flavor}</span>
            </div>
          )}
          {(product?.product?.thcContent || product?.product?.cbdContent) && (
            <div className="c-pdp-details__item">
              <div className="slug">Average Cannabinoids</div>
              {product?.product?.thcContent > 0 ? (
                <span>
                  {"THC " +
                    product.product.thcContent +
                    product.product.thcContentUnit}{" "}
                </span>
              ) : (
                ""
              )}
              {product?.product?.cbdContent > 0 ? (
                <span>
                  {"CBD " +
                    product.product.cbdContent +
                    product.product.cbdContentUnit}{" "}
                </span>
              ) : (
                ""
              )}
            </div>
          )}
          {product.product.strainType && (
            <div className="c-pdp-details__item">
              <div className="slug">Type</div>
              <span>{product.product.strainType}</span>
            </div>
          )}
        </section>
      )}
    </>
  );
}

export default PDPDetails;
