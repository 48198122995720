import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Input } from "components/Forms/Fields";
import IconButton from "components/IconButton";
import { TermsOfService } from "./index";

interface ProfileExperienceDetailProps {
  activeStep: string;
  goNext: any;
  change: any;
}

function ExperienceDetail({
  activeStep,
  goNext,
  change,
}: ProfileExperienceDetailProps) {
  const formState = {
    ...useSelector((state: RootState) => state.form.profileCreation),
  };
  const activeStepIndex = useSelector(
    (state: RootState) => state.system.profileStepIndex
  );

  const [isPrev, setIsPrev] = useState(false);
  const [newbie, setNewbie] = useState(false);

  const { values } = formState;
  const isActive = activeStep === "experience-detail";

  useEffect(() => {
    let timer: any = null;

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 4);
      }, 1000);
    } else {
      setIsPrev(activeStepIndex > 4);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPrev, activeStepIndex]);

  useEffect(() => {
    if (values) {
      setNewbie(
        values.experienceLevel === "BEGINNER" ||
          values.experienceLevel === "NONE"
      );
    }
  }, [values]);

  function setExperience(value: string) {
    if (newbie) {
      change("howToFlower", value);
      goNext();
    } else {
      change("strengthPreference", value);
      goNext();
    }
  }

  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? "c-profile__step--prev-" : "c-profile__step-"}
      timeout={1000}
      appear
    >
      <div className={classNames("c-profile__step")}>
        <div className="slug">Question 4 of 8</div>
        {newbie ? (
          <>
            <h3>Do you know how to use flower?</h3>
            <Field
              component={Input}
              name="howToFlower"
              type="hidden"
              label={""}
              required
              className="firstName__input"
            />
            <IconButton
              tabIndex={isActive ? 0 : -1}
              onClick={() => setExperience("YES")}
              variant="select-dispensary"
              type="button"
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              Yes
            </IconButton>
            <IconButton
              tabIndex={isActive ? 0 : -1}
              onClick={() => setExperience("NO")}
              variant="select-dispensary"
              type="button"
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              No
            </IconButton>
            <IconButton
              tabIndex={isActive ? 0 : -1}
              onClick={() => setExperience("I_WANT_TO_LEARN")}
              variant="select-dispensary"
              type="button"
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              I want to learn
            </IconButton>
            <IconButton
              tabIndex={isActive ? 0 : -1}
              onClick={() => setExperience("WHATS_FLOWER")}
              variant="select-dispensary"
              type="button"
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              What's flower?
            </IconButton>
          </>
        ) : (
          <>
            <h3>How strong do you like your cannabis?</h3>
            <Field
              component={Input}
              name="strengthPreference"
              type="hidden"
              label={""}
              required
              className="firstName__input"
            />
            <IconButton
              tabIndex={isActive ? 0 : -1}
              onClick={() => setExperience("LIGHT")}
              variant="select-dispensary"
              type="button"
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              Light
            </IconButton>
            <IconButton
              tabIndex={isActive ? 0 : -1}
              onClick={() => setExperience("MEDIUM")}
              variant="select-dispensary"
              type="button"
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              Medium
            </IconButton>
            <IconButton
              tabIndex={isActive ? 0 : -1}
              onClick={() => setExperience("STRONG")}
              variant="select-dispensary"
              type="button"
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              Strong
            </IconButton>
            <IconButton
              tabIndex={isActive ? 0 : -1}
              onClick={() => setExperience("SEND_ME_TO_THE_MOON")}
              variant="select-dispensary"
              type="button"
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              Send me to the moon
            </IconButton>
            <TermsOfService />
          </>
        )}
      </div>
    </CSSTransition>
  );
}

export default ExperienceDetail;
