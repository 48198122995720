import React              from 'react';
import classNames         from 'classnames';
import { CheckboxProps }  from './types';
import './Fields.scss';

interface RadioProps extends CheckboxProps {
  handleChange: any
}

// General input to be used by forms
// Placeholders or labels are to be used independent of one another as the desired design would clash otherwise.
const renderField = ({
  input, 
  className = '', 
  options, 
  meta: {
    active, 
    touched, 
    invalid, 
    dirty, 
    submitFailed, 
    initial
  } 
}: CheckboxProps ) => {

  const showInvalid = (invalid && touched) || submitFailed;
  const formControlClass = 'form-control form-control--radio';
  return (
    <div className={"i-radio-group " + className}>
    {
      options.map((option, index) => {
        const isChecked = input.value.indexOf(option.name) !== -1;
        return (
          <div key={option.name} className={classNames(formControlClass, {'form-control--invalid': showInvalid, 'form-control--active': active, 'form-control--dirty': (dirty||initial)})}>
            <label className="form-control__radio-label">
              <input type="radio"
                name={input.name}
                value={option.name}
                checked={input.value === option.name}
                onChange={() => {
                  return input.onChange(option.name);
                }}
              />
              <div className={classNames("form-control__styled-radio", {'form-control__styled-radio--checked': isChecked})}></div>
              <span>{option.label}</span>
            </label>
          </div>
        )
      })
    }
    </div>
  )
}
export default renderField;