import React from 'react';
import './DetailsTable.scss';
interface DetailsTableRowProps {
  label: string
  value: string | number
}

interface DetailsTableProps {
  rows: Array<DetailsTableRowProps>
};

function DetailsTable({
  rows
}:DetailsTableProps) {
  return (
    <section className="c-details-table">
      {rows.map((row: DetailsTableRowProps) => (
        <article className="c-details-table__row" key={row.label+row.value}>
          <span className="c-details-table__row__label">{row.label}</span>
          <span className="c-details-table__row__value">{row.value}</span>
        </article>
      ))
      }
    </section>
  )
}

export default DetailsTable;