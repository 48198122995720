import { call, put, takeLatest, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import axios from "axios";

import {
  CREATE_PROFILE,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_SUCCESS_ONSIGNUP,
  CREATE_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE,
  FETCH_PROFILE_CONFIG,
  FETCH_PROFILE_CONFIG_SUCCESS,
  FETCH_PROFILE_CONFIG_FAILED,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILED,
} from "./types";
import { sagaURL } from "store/sagas";
import { setAuthorizationHeader } from "../../utils/auth";
import * as customerSelectors from "../customer/selectors";

function callCreateProfile(
  preferences: any,
  customer: any,
  activeDispensary: any
) {
  let authId = sessionStorage.getItem("signUpAuth") || null;

  let profilePreferences = {
    ...preferences,
    accessToken: customer?.accessToken || null,
    productFormatsToAvoid: preferences.productFormatsToAvoid || [],
    productFormatsToTry: preferences.productFormatsToTry || [],
    treatmentGoals: preferences.treatmentGoals || [],
    dispensarySlug: activeDispensary,
    auth0Id: authId,
  };

  delete profilePreferences.month;
  delete profilePreferences.day;
  delete profilePreferences.year;
  delete profilePreferences.dateOfBirth;
  delete profilePreferences.location;
  delete profilePreferences.stateName;

  setAuthorizationHeader(customer?.accessToken || null);

  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: activeDispensary,
      },
      query: `mutation createProfile (
      $data: ProfileCreateInput
    ) {
      createProfile(data: $data){
        id
        userId
        activeFlag
        firstName
        lastName
        street
        streetCont
        city
        state
        zip
        dispensarySlug
        latitude
        longitude
        experienceLevel
        howToFlower
        strengthPreference
        usePreference
        treatmentGoals
        productFormatsToTry
        productFormatsToAvoid
    }
  }`,
      variables: {
        data: profilePreferences,
      },
    })
    .then(
      ({
        data: {
          data: { createProfile },
        },
      }) => {
        sessionStorage.removeItem("forage_profile_preferences");
        sessionStorage.removeItem("forage_profile_address");
        return createProfile;
      }
    )
    .catch((error) => {
      throw error;
    });
}

function callUpdateProfile(
  preferences: any,
  customer: any,
  activeDispensary: any
) {
  let profilePreferences = {
    ...preferences,
    dispensarySlug: activeDispensary,
    accessToken: customer.accessToken,
  };

  delete profilePreferences.month;
  delete profilePreferences.day;
  delete profilePreferences.year;
  delete profilePreferences.location;
  delete profilePreferences.id;
  delete profilePreferences.userId;
  delete profilePreferences.activeFlag;
  delete profilePreferences.dateOfBirth;

  setAuthorizationHeader(customer.accessToken);
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: activeDispensary,
      },
      query: `mutation updateProfile (
      $data: ProfileUpdateInput
    ) {
      updateProfile(data: $data){
        id
        userId
        activeFlag
        firstName
        lastName
        street
        streetCont
        city
        state
        zip
        dispensarySlug
        latitude
        longitude
        experienceLevel
        howToFlower
        strengthPreference
        usePreference
        treatmentGoals
        productFormatsToTry
        productFormatsToAvoid
    }
  }`,
      variables: {
        data: profilePreferences,
      },
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
}

function callFetchProfileConfig(accessToken = null) {
  setAuthorizationHeader(accessToken);
  let config: any = {};
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
      },
      query: `query {
      productFormats { 
        slug
        name
        displayOrder
      }
    }`,
    })
    .then(
      ({
        data: {
          data: { productFormats },
        },
      }) => {
        config.formats = productFormats;
        return axios
          .post(sagaURL, {
            headers: {
              "Content-Type": "application/json",
            },
            query: `query {
        treatmentGoals { 
          slug
          name
          displayOrder
        }
      }`,
          })
          .then(
            ({
              data: {
                data: { treatmentGoals },
              },
            }) => {
              config.treatments = treatmentGoals;
              return config;
            }
          );
      }
    )
    .catch((error) => {
      throw error;
    });
}

function callFetchProfile(customer: any) {
  setAuthorizationHeader(customer.accessToken);
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
      },
      query: `query profile (
      $accessToken: String!
    ) {
      profile(accessToken: $accessToken){
        id
        userId
        activeFlag
        firstName
        lastName
        street
        streetCont
        city
        state
        zip
        latitude
        longitude
        experienceLevel
        howToFlower
        strengthPreference
        usePreference
        treatmentGoals
        productFormatsToTry
        productFormatsToAvoid
    }
  }`,
      variables: {
        accessToken: customer.accessToken,
      },
    })
    .then(
      ({
        data: {
          data: { profile },
        },
      }) => {
        return profile;
      }
    )
    .catch((error) => {
      throw error;
    });
}

function* createProfile(action: any) {
  const profilePreferences: any = yield select(selectors.profilePreferences);
  const customer: any = yield select(selectors.customer);
  const activeDispensary: any = yield select(selectors.activeDispensary);

  try {
    const profile = yield call(
      callCreateProfile,
      profilePreferences,
      customer,
      activeDispensary
    );

    const signUpAuth = sessionStorage.getItem("signUpAuth");
    if (!signUpAuth) {
      yield put({ type: CREATE_PROFILE_SUCCESS, config: profile });
    } else {
      yield put({ type: CREATE_PROFILE_SUCCESS_ONSIGNUP, config: profile });
    }
  } catch (e) {
    console.log("Create Profile error:", e);
    yield put({ type: CREATE_PROFILE_FAILED, error: e });
  }
}

function* updateProfile(action: any) {
  const profilePreferences: any = yield select(selectors.profilePreferences);
  const customer: any = yield select(selectors.customer);
  const activeDispensary: any = yield select(selectors.activeDispensary);

  try {
    const profile = yield call(
      callUpdateProfile,
      profilePreferences,
      customer,
      activeDispensary
    );
    yield put({ type: UPDATE_PROFILE_SUCCESS, config: profile });
  } catch (e) {
    console.log("Create Profile error:", e);
    yield put({ type: UPDATE_PROFILE_FAILED, error: e });
  }
}

function* fetchProfileConfig(action: any) {
  try {
    const accessToken = yield select(customerSelectors.accessToken);
    const config = yield call(callFetchProfileConfig, accessToken);
    yield put({ type: FETCH_PROFILE_CONFIG_SUCCESS, config: config });
  } catch (e) {
    console.log("Create Profile error:", e);
    yield put({ type: FETCH_PROFILE_CONFIG_FAILED, error: e });
  }
}

function* fetchProfile(action: any) {
  const customer: any = yield select(selectors.customer);
  if (customer && customer.accessToken) {
    try {
      const profile = yield call(callFetchProfile, customer);
      yield put({ type: FETCH_PROFILE_SUCCESS, profile: profile });
    } catch (e) {
      console.log("Fetch Profile error:", e);
      yield put({ type: FETCH_PROFILE_FAILED, error: "Profile Not Found" });
    }
  } else {
    yield put({ type: FETCH_PROFILE_FAILED, error: "Not logged in" });
  }
}

function* profileSaga() {
  yield takeLatest(CREATE_PROFILE, createProfile);
  yield takeLatest(UPDATE_PROFILE, updateProfile);
  yield takeLatest(FETCH_PROFILE_CONFIG, fetchProfileConfig);
  yield takeLatest(FETCH_PROFILE, fetchProfile);
}

export default profileSaga;
