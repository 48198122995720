import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const ProfileSetup: FunctionComponent<IconType> = ({ variant = "charcoal"}) => {
  const color = colors[variant];
  return(
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="14" stroke={color} strokeWidth="1.5"/>
      <path d="M23 15C23 19.4183 19.4183 23 15 23C10.5817 23 7 19.4183 7 15" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ProfileSetup;