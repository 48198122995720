;

module.exports = function () {
  var ctx = this.context;

	if(ctx) {

		switch (this.direction) {
			case 'diagonal':
				return ctx.createLinearGradient(this.x1 * this.gradientPosition[0], this.y1 * this.gradientPosition[1], this.x1 * this.gradientPosition[2], this.y1 * this.gradientPosition[3]);

			case 'left-right':
				return ctx.createLinearGradient(0, 0, this.x1, 0);

			case 'top-bottom':
				return ctx.createLinearGradient(this.x1 / 2, 0, this.x1 / 2, this.y1);

			case 'radial':
				return ctx.createRadialGradient(this.gradientPosition[0], this.gradientPosition[1], this.gradientPosition[2], this.gradientPosition[3], this.gradientPosition[4], this.gradientPosition[5]);

			case 'custom':
				return ctx.createLinearGradient(
					getCustomCoordinateInPixels(this.customDirection.x0, this.x1),
					getCustomCoordinateInPixels(this.customDirection.y0, this.y1),
					getCustomCoordinateInPixels(this.customDirection.x1, this.x1),
					getCustomCoordinateInPixels(this.customDirection.y1, this.y1)
				);
			default:
				break;
		}
	}
};

function getCustomCoordinateInPixels(coordinate, size) {
  return coordinate.indexOf('%') > -1
    ? size / 100 * parseInt(coordinate.split('%')[0], 10)
    : parseInt(coordinate.split('px')[0], 10);
}
