import React from "react";
import chroma from 'chroma-js';

import GradientCardLayout1 from './Layout1';
import GradientCardLayout2 from './Layout2';
import GradientCardLayout3 from './Layout3';
import GradientCardLayout4 from './Layout4';
import GradientCardLayout5 from './Layout5';
import DefaultGradient from './Default';
import feelingColors from "../../../sass/utils/feelingColors.module.scss";

const renderGradientPlaceholder = (productColors: any[]) => {
  if (!productColors) {
    return null;
  }

  const gradient1 = productColors?.length >= 1 ? `${chroma(feelingColors[productColors[0]]).alpha(1).css()}` : '';
  const gradient2 = productColors?.length >= 2 ? `${chroma(feelingColors[productColors[1]]).alpha(1).css()}` : '';
  const gradient3 = productColors?.length >= 3 ? `${chroma(feelingColors[productColors[2]]).alpha(1).css()}` : '';
  const gradientColors = productColors?.length >= 3 ? `${gradient1} 0%, ${gradient2} 35%, ${gradient3} 100%` : `${gradient1} 0%, ${gradient2} 100%`;
  
  return (
    <div className="c-card-gradient__placeholder"
         style={{background: `linear-gradient(45deg, ${gradientColors})`}}></div>
  );
};

export { GradientCardLayout1, GradientCardLayout2, GradientCardLayout3, GradientCardLayout4, GradientCardLayout5, DefaultGradient, renderGradientPlaceholder }