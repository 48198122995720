export interface RecommendationFilterProps {
  flags?: any;
  prices?: any;
  formats?: any;
  feelings?: any;
}

interface RecPrefProps {
  feelings: any;
  activities: any;
  occasions: any;
  formats: any;
  price: any;
  profileThcPreference: string | null;
  limit: number | null;
}
interface RecPrevResultsProp {
  recPreferences: RecPrefProps | null;
  recProductIds: any;
}
export interface RecState {
  recFinderOpen: boolean;
  recFinderStep: number;
  recsLoading: boolean;
  recsError: boolean;
  recs: any;
  recsAllFormats: any;
  recCountLoading: boolean;
  recCountError: boolean;
  recCount: number | null;
  recConfigLoading: boolean;
  recConfigError: boolean;
  recConfig: any;
  remainingRecsArray: any;
  remainingRecsObj: any;
  recPreferences: RecPrefProps;
  recPreferencesAdjustments: RecPrefProps;
  recProductIds: any;
  xy: any;
  fillOutFeelings: any;
  adjustmentApplied: boolean;
  stepList: any;
  recsDispensary: any;
  carouselIndex: number;
  page: number;
  recsChanging: boolean;
  searchQuery: string;
  sort: string;
  filter: RecommendationFilterProps;
  recPreviousResultData: RecPrevResultsProp;
  totalRecProducts: number | null;
}

export const SET_REC_FINDER_OPEN = "SET_REC_FINDER_OPEN";
export const SET_REC_FINDER_STEP = "SET_REC_FINDER_STEP";
export const FETCH_RECS = "SET_FETCH_RECS";
export const CUST_FETCH_RECS = "SET_CUST_FETCH_RECS";
export const FETCH_RECS_SUCCESS = "SET_FETCH_RECS_SUCCESS";
export const FETCH_RECS_FAILED = "SET_FETCH_RECS_FAILED";
export const FETCH_REC_COUNT = "FETCH_REC_COUNT";
export const FETCH_REC_COUNT_SUCCESS = "FETCH_REC_COUNT_SUCCESS";
export const FETCH_REC_COUNT_FAILED = "FETCH_REC_COUNT_FAILED";
export const FETCH_REC_CONFIG = "FETCH_REC_CONFIG";
export const FETCH_REC_CONFIG_SUCCESS = "FETCH_REC_CONFIG_SUCCESS";
export const FETCH_REC_CONFIG_FAILED = "FETCH_REC_CONFIG_FAILED";
export const SET_REC_FINDER_PREFERENCE = "SET_REC_FINDER_PREFERENCE";
export const SET_REC_FINDER_PREFERENCE_ADJUSTMENT =
  "SET_REC_FINDER_PREFERENCE_ADJUSTMENT";
export const APPLY_REC_FINDER_PREFERENCE_ADJUSTMENT =
  "APPLY_REC_FINDER_PREFERENCE_ADJUSTMENT";
export const RESET_REC_FINDER_PREFERENCE_ADJUSTMENT =
  "RESET_REC_FINDER_PREFERENCE_ADJUSTMENT";
export const SET_REC_XY = "SET_REC_XY";
export const SET_FILLOUT_FEELINGS = "SET_FILLOUT_FEELINGS";
export const SET_RECS_LOCATION = "SET_RECS_LOCATION";
export const SET_CAROUSEL_INDEX = "SET_CAROUSEL_INDEX";
export const SET_RECS_CHANGING = "SET_RECS_CHANGING";
export const SET_RECOMMENDATION_PAGE = "SET_RECOMMENDATION_PAGE";
export const RESET_RECS = "RESET_RECS";
export const SET_SEARCH = "SET_SEARCH";
export const SET_RECOMMENDATION_SORT = "SET_RECOMMENDATION_SORT";
export const SET_RECOMMENDATION_FILTER = "SET_RECOMMENDATION_FILTER";
export const RESET_RECS_PREVIOUS_RESULT_DATA =
  "RESET_RECS_PREVIOUS_RESULT_DATA";
// For localStorage constant
export const RECS_PREVIOUS_RESULT_DATA = "recs_previous_result_data";

interface SetRecXY {
  type: typeof SET_REC_XY;
  xy: any;
}

interface SetFillOutFeelings {
  type: typeof SET_FILLOUT_FEELINGS;
  fillOutFeelings: any;
}

interface SetRecFinderOpen {
  type: typeof SET_REC_FINDER_OPEN;
  isOpen: boolean;
}

interface SetRecFinderStep {
  type: typeof SET_REC_FINDER_STEP;
  step: number;
}

interface SetRecFinderPreference {
  type: typeof SET_REC_FINDER_PREFERENCE;
  id: string;
  value: any;
}

interface SetRecPreferenceAdjustment {
  type: typeof SET_REC_FINDER_PREFERENCE_ADJUSTMENT;
  id: string;
  value: any;
}

interface ApplyRecPrefernceAdjustment {
  type: typeof APPLY_REC_FINDER_PREFERENCE_ADJUSTMENT;
}

interface ResetRecPreferenceAdjustment {
  type: typeof RESET_REC_FINDER_PREFERENCE_ADJUSTMENT;
}

interface FetchRecCount {
  type: typeof FETCH_REC_COUNT;
  payload: any;
}

interface FetchRecCountSuccess {
  type: typeof FETCH_REC_COUNT_SUCCESS;
  recCount: number;
}

interface FetchRecCountFailed {
  type: typeof FETCH_REC_COUNT_FAILED;
  error: any;
}

interface FetchRecs {
  type: typeof FETCH_RECS;
  payload: any;
}
interface CustFetchRecs {
  type: typeof CUST_FETCH_RECS;
  payload: any;
}

interface FetchRecsSuccess {
  type: typeof FETCH_RECS_SUCCESS;
  recs: any;
  recNoFormat: any;
}

interface FetchRecsFailed {
  type: typeof FETCH_RECS_FAILED;
  recs: any;
}

interface FetchRecConfig {
  type: typeof FETCH_REC_CONFIG;
}

interface FetchRecConfigSuccess {
  type: typeof FETCH_REC_CONFIG_SUCCESS;
  config: any;
}

interface FetchRecConfigFailed {
  type: typeof FETCH_REC_CONFIG_FAILED;
  error: any;
}

interface SetRecsLocation {
  type: typeof SET_RECS_LOCATION;
  activeDispensary: string;
}

interface SetCarouselIndex {
  type: typeof SET_CAROUSEL_INDEX;
  index: number;
}

interface SetRecommendationpage {
  type: typeof SET_RECOMMENDATION_PAGE;
  page: number;
}

interface SetRecsChanging {
  type: typeof SET_RECS_CHANGING;
  recsChanging: boolean;
}

interface ResetRecs {
  type: typeof RESET_RECS;
}

interface SetSearch {
  type: typeof SET_SEARCH;
  searchQuery: string;
}

interface SetRecommendationSort {
  type: typeof SET_RECOMMENDATION_SORT;
  sort: string;
}

interface SetRecommendationFilter {
  type: typeof SET_RECOMMENDATION_FILTER;
  filter: RecommendationFilterProps;
}

interface ResetRecsPreviousData {
  type: typeof RESET_RECS_PREVIOUS_RESULT_DATA;
}

export type RecsActionTypes =
  | SetRecXY
  | SetFillOutFeelings
  | SetRecFinderOpen
  | SetRecFinderStep
  | FetchRecs
  | CustFetchRecs
  | FetchRecsSuccess
  | FetchRecsFailed
  | FetchRecCount
  | FetchRecCountSuccess
  | FetchRecCountFailed
  | FetchRecConfig
  | FetchRecConfigSuccess
  | FetchRecConfigFailed
  | SetRecFinderPreference
  | SetRecPreferenceAdjustment
  | ApplyRecPrefernceAdjustment
  | ResetRecPreferenceAdjustment
  | SetRecsLocation
  | SetCarouselIndex
  | SetRecsChanging
  | SetRecommendationpage
  | SetSearch
  | SetRecommendationSort
  | SetRecommendationFilter
  | ResetRecs
  | ResetRecsPreviousData;
