// Libraries
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Draggable from "react-draggable";
import { throttle } from "throttle-debounce";

// Redux Store
import { RootState } from "store";
import {
  SetRecFinderPreference,
  SetRecXY,
} from "store/recommendations/actions";
import { SetResetKioskTimer } from "store/system/actions";

// Utilities
import { scrollTo } from "utils";
import { calculateXY } from "components/Home/MoodSelector/utils";

// Components
import DownCaretIcon from "components/svgs/DownCaret";
import CanvasGradient from "components/Home/MoodSelector/canvas";
import Knob from "components/Home/MoodSelector/Knob";
import Button from "components/Button";
import { recPreferencesLimit } from "constants/recommendations";
import { ForageEmbedModal } from "components/Home";

type StepProps = {
  onSelectorOpen: any;
  setParentChanged: any;
  nextStep: any;
  adjustment?: boolean;
  orientation?: {
    isLandscape: boolean;
    isPortrait: boolean;
  };
};

const KNOB_POS_X = 50;
const KNOB_POS_Y = 10;

export type Ref = HTMLDivElement;
const Step1 = forwardRef<Ref, StepProps>(
  ({ setParentChanged, nextStep, onSelectorOpen, orientation }, ref) => {
    const dispatch = useDispatch();
    const userLocationString = useSelector(
      (state: RootState) => state.user.userLocationString
    );
    const activeDispensary = useSelector(
      (state: RootState) => state.cart.activeDispensary
    );
    const recCount = useSelector((state: RootState) => state.recs.recCount);
    const recConfig = useSelector((state: RootState) => state.recs.recConfig);
    const recCountLoading = useSelector(
      (state: RootState) => state.recs.recCountLoading
    );
    const xy = useSelector((state: RootState) => state.recs.xy);

    const isDesktop = useSelector((state: RootState) => state.system.isDesktop);
    const isKiosk = useSelector((state: RootState) => state.system.isKiosk);
    // const isTablet = useSelector((state: RootState) => state.system.isTablet);
    const moodSelectorStep = useSelector(
      (state: RootState) => state.system.moodSelectorStep
    );
    const moodSelectorOpen = useSelector(
      (state: RootState) => state.system.moodSelectorOpen
    );

    const embedDispensaries = useSelector((state: RootState) => state.system.embedDispensaries);
    const hasEmbedModal = !!(embedDispensaries?.medical && embedDispensaries?.recreational);

    const dragAreaRef = useRef<HTMLDivElement>(null);
    const [offsets, setOffsets] = useState<any>(false);
    const [noArrows, setNoArrows] = useState(false);
    const [hideArea, setHideArea] = useState(false);
    const [colors, setColors] = useState<any>([]);
    const [colorsGradient, setColorsGradient] = useState<any>(colors || []);
    const [x, setX] = useState<any>(false);
    const [y, setY] = useState<any>(false);
    const [dragging, setDragging] = useState(false);
    const [buttonText, setButtonText] = useState("");
    const [changed, setChanged] = useState(false);
    const [initiallySet, setInitiallySet] = useState(false);
    const [showDrag, setShowDrag] = useState(xy.x ? true : false);
    // const [showFigure, setShowFigure] = useState(xy.x ? true : false);
    const [deviceOffset, setDeviceOffset] = useState(isDesktop ? 116 : 80);
    const [showKioskColorBg, setShowKioskColorBg] = useState<boolean>(false);

    const resetPosition = useCallback(() => {
      if (dragAreaRef.current !== null && deviceOffset > 0) {
        const x = xy.x || xy.x === 0 ? xy.x : KNOB_POS_X;
        const y = xy.y || xy.y === 0 ? xy.y : KNOB_POS_Y;
        setX(x);
        setY(y);
        const startingPositionX =
          ((dragAreaRef.current.offsetWidth - deviceOffset) * x) / 100;
        const startingPositionY =
          ((dragAreaRef.current.offsetHeight - deviceOffset) * y) / 100;
        setOffsets({ x: startingPositionX, y: startingPositionY });
      }
    }, [xy, deviceOffset, dragAreaRef]);

    useEffect(() => {
      setDeviceOffset(isDesktop ? 116 : 80);
      resetPosition();
    }, [isDesktop, resetPosition, orientation]);

    const storeColors = useCallback(
      (colors: Array<any>) => {
        let storeFeelings: any = {};
        colors.forEach((color: any) => {
          color.feeling = color.feeling.replace(/[0-9]/g, "");
          if (
            !storeFeelings[color.feeling] ||
            (storeFeelings[color.feeling] &&
              storeFeelings[color.feeling].value === 0)
          ) {
            storeFeelings[color.feeling] = color.value;
          }
        });
        dispatch(SetRecFinderPreference("feelings", storeFeelings));
        dispatch(SetRecFinderPreference("limit", recPreferencesLimit));
      },
      [dispatch]
    );

    useEffect(() => {
      let newButtonText = "Find The Right Cannabis";
      if (activeDispensary) {
        newButtonText = dragging
          ? "Loading Recommendations..."
          : recCount || changed
          ? "Dial in your recommendations"
          : "Find The Right Cannabis";
      }
      if (newButtonText !== buttonText) {
        setButtonText(newButtonText);
      }
    }, [recCount, dragging, activeDispensary, buttonText, changed]);

    useEffect(() => {
      if (moodSelectorStep !== 1 || !recConfig || x === false || y === false)
        return;

      const values: any = calculateXY(x, y, recConfig);
      let feelingObj: any = [];
      Object.keys(values).forEach((valueKey) => {
        feelingObj.push({
          feeling: valueKey,
          value: values[valueKey],
        });
      });
      feelingObj.sort((a: any, b: any) => (a.value < b.value ? 1 : -1));
      if (!initiallySet) {
        storeColors(feelingObj);
      } else {
        setInitiallySet(true);
      }
      if (isKiosk) {
        if (showKioskColorBg) {
          setColorsGradient(feelingObj);
          setShowKioskColorBg(false);
        }
      } else {
        setColorsGradient(feelingObj);
      }
      setColors(feelingObj);
    }, [
      x,
      y,
      recConfig,
      initiallySet,
      moodSelectorStep,
      storeColors,
      showKioskColorBg,
      isKiosk,
    ]);

    useEffect(() => {
      setHideArea(true);
      setShowDrag(false);
      // setShowFigure(true);
      const hideAreaTimeoutId = setTimeout(() => {
        resetPosition();
        setShowDrag(true);
        setHideArea(false);
      }, 1500);

      return () => {
        clearTimeout(hideAreaTimeoutId);
      };
      // eslint-disable-next-line
    }, [moodSelectorOpen]);

    const dragStart = useCallback(() => {
      setNoArrows(true);
      setDragging(true);
      setChanged(true);
      setParentChanged(true);
      dispatch(SetResetKioskTimer(true));
    }, [dispatch, setParentChanged]);

    const drag = useCallback(
      throttle(500, (e: any, data: any) => {
        if (!dragAreaRef.current) return;
        const x = data.x > 50 ? data.x + deviceOffset : data.x;
        const y = data.y > 50 ? data.y + deviceOffset : data.y;
        const normalizedX = (x / dragAreaRef.current.offsetWidth) * 100;
        const normalizedY = (y / dragAreaRef.current.offsetHeight) * 100;
        setX(normalizedX);
        setY(normalizedY);
      }),
      [x, y]
    );

    const dragStop = useCallback(() => {
      setNoArrows(false);
      setDragging(false);
      dispatch(SetRecXY({ x, y }));
      setShowKioskColorBg(true);
    }, [dispatch, x, y]);

    const openSelector = useCallback(async () => {
      const delay = window.scrollY > 10 ? 750 : 0;
      scrollTo(document.documentElement, 0, 500);
      setTimeout(() => {
        onSelectorOpen(true);
        if (changed) {
          nextStep();
        }
      }, delay);
    }, [changed, nextStep, onSelectorOpen]);

    return (
      <div className="c-mood-selector__step c-mood-selector__step--1">
        {/* Open Title Step1 Start */}

        <div
        className={classNames("c-mood-selector__open-title", {
            "c-mood-selector__open-title--visible":
            moodSelectorOpen && moodSelectorStep >= 1,
        })}
        >
        <header className="c-mood-selector__header">
            <h1>How do you want to feel today?</h1>
            <p className="c-mood-selector__question-progress">
            Question 1 of 5
            </p>
        </header>
        </div>

        {/* Open Title Step1 END */}
        <div className="c-mood-selector__step__inner" ref={ref}>
          <figure
            className={classNames(
              "c-mood-selector__figure c-mood-selector__figure--visible"
              // {
              //   "c-mood-selector__figure--visible": showFigure,
              // }
            )}
          >
            <CanvasGradient colors={colorsGradient} id="home" />
          </figure>
          <div className="c-mood-selector__mood-names">
            <div className="c-mood-selector__mood-names__inner">
              <span className="c-mood-selector__mood-names__top-left">
                Energetic
              </span>
              <span className="c-mood-selector__mood-names__top-right">
                Euphoric
              </span>
              <span className="c-mood-selector__mood-names__bottom-left">
                Relaxed
              </span>
              <span className="c-mood-selector__mood-names__bottom-right">
                Tingly
              </span>
              {
                (!hasEmbedModal) ?
                <>
                    <div className="c-mood-selector__mood-names__text">
                        <span>
                        How do you want
                        <br />
                        to feel today?
                        </span>
                        {/* {(isDesktop || isKiosk || isTablet) && ( */}
                        <Button
                        disabled={
                            (dragging || (recCountLoading && !recCount)) &&
                            !!activeDispensary
                        }
                        type="button"
                        variant="find-cannabis"
                        >
                        {buttonText}
                        </Button>
                        {/* )} */}
                    </div>
                </>
                :
                (embedDispensaries?.medical && embedDispensaries?.recreational &&
                <ForageEmbedModal embedDispensaries={embedDispensaries} />)
               }
            </div>
            <div
                className={classNames("c-mood-selector__drag-area", {
                    "c-mood-selector__drag-area--hidden": hideArea,
                })}
                ref={dragAreaRef}
            >
            {!moodSelectorOpen && (
                <div
                className="c-mood-selector__click-ghost"
                onClick={openSelector}
                ></div>
            )}
            {showDrag && offsets && (
                <Draggable
                    axis="both"
                    bounds="parent"
                    handle=".c-mood-selector__drag-knob"
                    defaultPosition={offsets}
                    grid={[1, 1]}
                    scale={1}
                    onStart={dragStart}
                    onDrag={drag}
                    onStop={dragStop}
                    disabled={hasEmbedModal}
                >
                <div className={classNames("c-mood-selector__drag-knob-wrapper", {
                "c-mood-selector__drag-knob-wrapper--disabled": hasEmbedModal,
              })}>
                    <Knob feelings={colors} noArrows={noArrows} />
                </div>
                </Draggable>
            )}
            </div>
          </div>

          <div className="c-mood-selector__actions">
            {/* {!isDesktop && (
              <Button
                disabled={
                  (dragging || (recCountLoading && !recCount)) &&
                  !!activeDispensary
                }
                type="button"
                onClick={openSelector}
                variant={"find-cannabis"}
              >
                {buttonText}
              </Button>
            )} */}
            <div
              className={classNames("c-mood-selector__actions__browsing-from", {
                "c-mood-selector__actions__browsing-from--located": userLocationString,
              })}
            >
              <span>Browsing from&nbsp;</span>
              <Link aria-label="All Locations" to="/locations">
                <span>{userLocationString}</span>
                <DownCaretIcon variant="white" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Step1;
