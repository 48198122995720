import React, { useEffect, useState }                 from 'react';
import classNames                                     from 'classnames';
import { Form, Field, reduxForm, InjectedFormProps }  from 'redux-form';
import { debounce }                                   from 'throttle-debounce';
import { connect, useDispatch }                       from 'react-redux';
import { RootState }                                        from 'store';
import { SetRecsChanging, SetSearch }                       from 'store/recommendations/actions';
import CloseIcon                                            from 'components/svgs/Close';
import SearchIcon                                           from 'components/svgs/Search';
import { Input }                                            from 'components/Forms/Fields';
import UseRecommendation from '../hooks/useRecommendation';

function RecommendationSearch({
    change
  }:InjectedFormProps ) {

  const dispatch = useDispatch();
  const { searchQuery: searchVal } = UseRecommendation();
  const [currentSearch, setCurrentSearch] = useState(searchVal);

  function searchRecommendation(e:any) {
    dispatch(SetRecsChanging(true));
    setTimeout(() => {
      dispatch(SetSearch(e.target.value));
      setCurrentSearch(e.target.value);
    }, 250);
  }

  function resetSearch() {
    change('search', '');
    setCurrentSearch('');
    dispatch(SetRecsChanging(true));
    setTimeout(() => {
      dispatch(SetSearch(''));
    },250);
  }

  useEffect(() => {
    if(currentSearch !== '' && searchVal === ''){
      change('search', '');
      setCurrentSearch('');
    }
  }, [searchVal, currentSearch, change]);

  const debounceSearch = debounce(1000, false,  searchRecommendation);
  return (
    <Form className="c-inventory-search" onSubmit={(e:any) => {e.preventDefault()}} autoComplete="off">
      <Field
        component={Input}
        Icon={SearchIcon}
        name="search"
        type="text"
        label={"Search Inventory"}
        className="c-inventory-search__input"
        onChange={debounceSearch}
      />
      <div className={classNames("c-inventory-search__reset", {"c-inventory-search__reset--visible":searchVal !== ''})} onClick={resetSearch}>
        <CloseIcon variant="charcoal" />
      </div>
    </Form>
  )
}

export default connect(
  (state: RootState) => ({
    initialValues: {
      search:state.recs.searchQuery
    }
  })
)(reduxForm({
  form: 'recsSearch'
})(RecommendationSearch));