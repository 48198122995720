import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { connect, useDispatch, useSelector } from "react-redux";
import { Form, Field, reduxForm, InjectedFormProps } from "redux-form";
import { Input, Select, MultiCheck } from "components/Forms/Fields";
import { RootState } from "store";
import {
  FetchProfileConfig,
  StoreProfilePreferences,
} from "store/profile/actions";
import Button from "components/Button";
import { OptionsProps } from "components/Forms/Fields/types";
import { ProfileSettingsValidator as validate } from "components/Forms/Validators";
import { NAME_MAX_LENGTH } from "components/Forms/Fields/constants";
import "./EditProfileForm.scss";
function ProfileForm({ invalid, handleSubmit, change }: InjectedFormProps) {
  const dispatch = useDispatch();
  const isDesktop = useSelector((state: RootState) => state.system.isDesktop);
  const profileSaving = useSelector(
    (state: RootState) => state.profile.savingProfile
  );
  const formData: any = useSelector(
    (state: RootState) => state.form.editProfile
  );
  const profileConfig = useSelector((state: RootState) => state.profile.config);
  const profileConfigLoading = useSelector(
    (state: RootState) => state.profile.configLoading
  );

  const [storedValues, setStoredValues] = useState<any>(null);
  const [formatsMap, setFormatsMap] = useState<any>(null);
  const [formatOptions, setFormatOptions] = useState<any>(null);
  const [treatmentMap, setTreatmentMap] = useState<any>(null);
  const [treatmentOptions, setTreatmentOptions] = useState<any>(null);

  const { values } = formData;

  useEffect(() => {
    if (!profileConfig && !profileConfigLoading) {
      dispatch(FetchProfileConfig());
    } else if (profileConfig && !formatsMap && !treatmentMap) {
      let formMap: any = {};
      profileConfig.formats.forEach((format: any) => {
        if (format.slug) {
          formMap[format.slug] = format.name;
        }
      });
      const formOptions: Array<OptionsProps> = Object.keys(formMap).map(
        (key) => {
          return { name: key, label: formMap[key] };
        }
      );

      setFormatsMap(formMap);
      setFormatOptions(formOptions);

      let treatMap: any = {};
      profileConfig.treatments.forEach((treatment: any) => {
        if (treatment.slug) {
          treatMap[treatment.slug] = treatment.name;
        }
      });

      const treatOptions: Array<OptionsProps> = Object.keys(treatMap).map(
        (key) => {
          return { name: key, label: treatMap[key] };
        }
      );

      setTreatmentMap(treatMap);
      setTreatmentOptions(treatOptions);
    }
  }, [profileConfig, profileConfigLoading, formatsMap, treatmentMap, dispatch]);

  useEffect(() => {
    if (storedValues === null && values) {
      setStoredValues(values);
    }
  }, [storedValues, values]);

  useEffect(() => {
    dispatch(StoreProfilePreferences(values, false));
  }, [values, dispatch]);

  function resetPreferences() {
    dispatch(StoreProfilePreferences(storedValues, false));
  }

  return (
    <Form
      className={classNames("c-profile-form", {
        "c-profile-form--loading": !treatmentOptions,
      })}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <h3>About</h3>
      <Field
        component={Input}
        name="firstName"
        type="text"
        label={"First Name"}
        required
        className="firstName__input"
        maxlength={NAME_MAX_LENGTH}
      />
      <Field
        component={Input}
        name="lastName"
        type="text"
        label={"Last Name"}
        required
        className="lastName__input"
        maxlength={NAME_MAX_LENGTH}
      />
      <h3>Cannabis Behaviors</h3>
      <Field
        component={Select}
        name="experienceLevel"
        label={"Experience"}
        required
        isDesktop={isDesktop}
        allowEmpty={false}
        className="idDocumentType__input"
        options={[
          {
            label: "None",
            value: "NONE",
          },
          {
            label: "Beginner",
            value: "BEGINNER",
          },
          {
            label: "Intermediate",
            value: "INTERMEDIATE",
          },
          {
            label: "Experienced",
            value: "EXPERT",
          },
        ]}
        change={change}
      />
      {values.experienceLevel === "NONE" ||
      values.experienceLevel === "BEGINNER" ? (
        <Field
          component={Select}
          name="howToFlower"
          label={"What is flower"}
          required
          isDesktop={isDesktop}
          allowEmpty={false}
          className="idDocumentType__input"
          options={[
            {
              label: "I know",
              value: "YES",
            },
            {
              label: "I don`t know",
              value: "NO",
            },
            {
              label: "I want to learn",
              value: "I_WANT_TO_LEARN",
            },
            {
              label: "What is flower?",
              value: "WHATS_FLOWER",
            },
          ]}
          change={change}
        />
      ) : (
        <Field
          component={Select}
          name="strengthPreference"
          label={"Strength Preference"}
          required
          isDesktop={isDesktop}
          className="idDocumentType__input"
          options={[
            {
              label: "Light",
              value: "LIGHT",
            },
            {
              label: "Medium",
              value: "MEDIUM",
            },
            {
              label: "Strong",
              value: "STRONG",
            },
            {
              label: "Send me to the moon",
              value: "SEND_ME_TO_THE_MOON",
            },
          ]}
          change={change}
        />
      )}
      <Field
        component={MultiCheck}
        name="productFormatsToAvoid"
        label={"Format aversions"}
        isDesktop={isDesktop}
        className="idDocumentType__input"
        options={formatOptions ? formatOptions : []}
        change={change}
      />
      <Field
        component={MultiCheck}
        name="productFormatsToTry"
        label={"Format curiosities"}
        isDesktop={isDesktop}
        className="idDocumentType__input"
        options={formatOptions ? formatOptions : []}
        change={change}
      />
      <h3>Usage Goals</h3>
      <Field
        component={Select}
        name="usePreference"
        label={"Fun or Treatment"}
        required
        isDesktop={isDesktop}
        allowEmpty={false}
        className="idDocumentType__input"
        options={[
          {
            label: "Fun",
            value: "FUN",
          },
          {
            label: "Treatment",
            value: "TREATMENT",
          },
          {
            label: "Both",
            value: "BOTH",
          },
        ]}
        change={change}
      />
      {(values.usePreference === "BOTH" ||
        values.usePreference === "TREATMENT") && (
        <Field
          component={MultiCheck}
          name="treatmentGoals"
          label={"Treatment Goals"}
          isDesktop={isDesktop}
          className="idDocumentType__input"
          options={treatmentOptions ? treatmentOptions : []}
          change={change}
        />
      )}
      <Button disabled={invalid} variant="login" type="submit">
        {profileSaving ? "Saving" : "Save Profile"}
      </Button>
      <Button
        disabled={false}
        variant="outlined"
        onClick={resetPreferences}
        type="Link"
        to="/account"
      >
        Cancel
      </Button>
    </Form>
  );
}

export default connect((state: RootState) => ({
  initialValues: state.profile.preferences ? state.profile.preferences : {},
}))(
  reduxForm({
    form: "editProfile",
    validate,
  })(ProfileForm)
);
