import React, { useEffect, useState, useRef, useCallback } from "react";
import classNames from "classnames";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "store";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SignInForm, SignInSocial } from "components/Account/SignIn";
import CloseIcon from "components/svgs/Close";
import auth0 from "auth0-js";

import {
  CustomerLogin,
  CustomerLoginFailed,
  ResetPasswordPlaceholder,
} from "store/customer/actions";
import { SubmissionError } from "redux-form";
import MenuDrawer from "components/MenuDrawer";
import Button from "components/Button";
import FylloImage from "components/FylloImage";

import "./SignIn.scss";

function SignIn({ history, location }: RouteComponentProps) {
  const { pathname } = location;
  const { webAuth } = window;

  const dispatch = useDispatch();
  const creatingCustomer = useSelector(
    (state: RootState) => state.customer.creatingCustomer
  );
  const authenticating = useSelector(
    (state: RootState) => state.customer.authenticating
  );
  const authenticated = useSelector(
    (state: RootState) => state.customer.authenticated
  );
  const signedInCustomer: any = useSelector(
    (state: RootState) => state.customer.customer
  );
  const signInRedirect: string = useSelector(
    (state: RootState) => state.system.siginRedirect || ""
  );
  const [createdUser, setCreatedUser] = useState(false);
  const [logginIn, setLogginIn] = useState(false);
  const [requiresConfirmation, setRequiresConfirmation] = useState(false);
  const [emailVerified, setEmailVerified] = useState<any>(false);
  const [showEmailVerified, setShowEmailVerified] = useState(false);

  const auth0Ref = useRef<HTMLDivElement>(null);
  const [buildTimeStamp, setBuildTimeStamp] = useState<any>(null);

  const locationState: any = location.state;
  if (locationState && locationState.signInRedirect) {
    sessionStorage.setItem(
      "forage_signin_redirect",
      locationState.signInRedirect
    );
  }
  const redirectTo = sessionStorage.getItem("forage_signin_redirect");

  useEffect(() => {
    return function cleanup() {
      sessionStorage.removeItem("forage_signin_redirect");
    };
  }, [pathname]);

  const auth0LoggedIn = useCallback(
    (err: any, authResult: any) => {
      if (err) {
        setLogginIn(false);
        history.replace("/account/signin");
        dispatch(CustomerLoginFailed(null));
      } else if (authResult && !logginIn) {
        setLogginIn(true);
        authResult.email = authResult.idTokenPayload.email;
        history.replace("/account/signin");
        const {
          accessToken,
          idTokenPayload: { given_name, family_name, name, sub, email },
        } = authResult;
        if (sub.indexOf("auth0|") >= 0) {
          if (authResult.idTokenPayload.email_verified) {
            sessionStorage.setItem("forage_email", email);
            sessionStorage.setItem("forage_token", accessToken);
            dispatch(
              CustomerLogin({
                emailSignin: true,
                accessToken: accessToken,
                firstName: given_name,
                lastName: family_name,
                fullName: name,
                email: email,
              })
            );
          } else {
            setLogginIn(false);
            dispatch(ResetPasswordPlaceholder());
            setRequiresConfirmation(true);
          }
        } else {
          dispatch(
            CustomerLogin({
              accessToken: authResult.accessToken,
              firstName: authResult.idTokenPayload.givenName,
              lastName: authResult.idTokenPayload.familyName,
              email: authResult.idTokenPayload.email,
            })
          );
        }
      }
    },
    [dispatch, history, logginIn]
  );

  useEffect(() => {
    let params = null;
    let verifiedSuccess = false;
    if (window?.location?.search) {
      params = new URLSearchParams(window?.location?.search);
      if (params && params.get("success") === "true") {
        verifiedSuccess = true;
      }
    }

    if (
      window.location.hash &&
      window.location.hash.match("access_token") &&
      webAuth
    ) {
      webAuth.parseHash({ state: "forage" }, auth0LoggedIn);
    } else if (
      window.location.search &&
      window.location.search.match("email_verified")
    ) {
      if (verifiedSuccess) {
        setBuildTimeStamp(new Date().getTime());
        setEmailVerified("Email address verified");
        setShowEmailVerified(true);
      }
      history.replace("/account/signin");
    } else if (
      window.location.search &&
      window.location.search.match("password_changed")
    ) {
      setEmailVerified("Password changed");
      setShowEmailVerified(true);
      history.replace("/account/signin");
    } else if (
      window.location.search &&
      window.location.search.match("password-reset")
    ) {
      setEmailVerified("Password reset link sent");
      setShowEmailVerified(true);
      history.replace("/account/signin");
    }
  }, [webAuth, auth0LoggedIn, history]);

  useEffect(() => {
    if (!webAuth) {
      const webAuthDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";
      const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || "";
      window.webAuth = new auth0.WebAuth({
        domain: webAuthDomain,
        clientID: webAuthClientId,
        audience: process.env.REACT_APP_API_URL,
      });
    }
  }, [webAuth]);

  useEffect(() => {
    if (logginIn && authenticated && signedInCustomer && redirectTo === "") {
      if (signedInCustomer.lastLogon) {
        history.replace("/account");
      } else if (signedInCustomer) {
        history.replace("/account/id-settings");
      }
    } else if (signedInCustomer) {
      if (
        signedInCustomer.lastLogon ||
        redirectTo === "/create-profile/success"
      ) {
        if (redirectTo) {
          sessionStorage.removeItem("forage_signin_redirect");
          history.replace(redirectTo);
        } else {
          history.replace("/account");
        }
      } else if (!signedInCustomer.lastLogon) {
        history.replace("/account/id-settings");
      } else {
        history.replace("/account");
      }
    }
  }, [
    authenticated,
    logginIn,
    signedInCustomer,
    redirectTo,
    signInRedirect,
    history,
  ]);

  useEffect(() => {
    if (!createdUser) {
      setCreatedUser(true);
    }
  }, [createdUser, dispatch]);

  function callSignIn(values: any) {
    const dbConnection = process.env.REACT_APP_AUTH0_USER_DB;
    return new Promise(function (resolve, reject) {
      webAuth.login(
        {
          realm: dbConnection,
          email: values.email,
          password: values.password,
          redirectUri: window.location.origin.toString() + "/account/signin",
          responseType: "token id_token",
          scope: "openid profile email",
          state: "forage",
        },
        function (err: any, auth: any) {
          if (err) {
            reject(err);
          } else {
            resolve(auth);
          }
        }
      );
    });
  }

  function handleSubmit(values: any) {
    return callSignIn(values)
      .then(() => {})
      .catch((err) => {
        const errMsg = err?.description || "Invalid credentials";
        dispatch(CustomerLoginFailed(null));
        throw new SubmissionError({ _error: errMsg });
      });
  }

  function socialLogin(connection: string) {
    webAuth.popup.authorize(
      {
        redirectUri: window.location.origin.toString() + "/social-redirect",
        connection: connection,
        responseType: "token id_token",
        state: "forage",
      },
      auth0LoggedIn
    );
  }

  function goBack() {
    history.push("/");
  }

  function closeEmailVerified() {
    setShowEmailVerified(false);
    setBuildTimeStamp(false);
    setTimeout(() => {
      setEmailVerified(false);
    }, 1000);
  }

  return (
    <>
      {emailVerified === "Email address verified" && buildTimeStamp && (
        <FylloImage
          src={`https://ad.ipredictive.com/d/track/cvt/pixel?acct_id=46887&cache_buster=${buildTimeStamp}`}
        />
      )}
      <section
        className={classNames("l-main c-signin", {
          "c-siginin--signing-in": authenticating || creatingCustomer,
        })}
      >
        <div className="l-maxwidth l-maxwidth--small">
          <button onClick={goBack} className="c-signin__close">
            <CloseIcon variant="charcoal" /> <span>Close</span>
          </button>
          <h1>Sign in to your account</h1>
          {requiresConfirmation && (
            <div className="c-signin__required-confirmation">
              Please check your email to complete verification process.
            </div>
          )}
          <div className="c-signin__options">
            <div id="c-signin-auto0" ref={auth0Ref}></div>
            <SignInForm onSubmit={handleSubmit} />
            <div className="c-signin__signup">
              Don't have an account?{" "}
              <Link
                to={{
                  pathname: "/account/signup",
                  state: {
                    signInRedirect:
                      locationState && locationState.signInRedirect
                        ? locationState.signInRedirect
                        : "/account",
                  },
                }}
              >
                Sign Up
              </Link>
            </div>
            <SignInSocial socialLogin={socialLogin} />
          </div>
        </div>
        {emailVerified && (
          <MenuDrawer
            display={showEmailVerified}
            closeMenu={closeEmailVerified}
            position="toast"
            headerClass="h2"
            menuHeading={emailVerified}
          >
            <Button
              type="button"
              onClick={closeEmailVerified}
              variant="outlined"
            >
              Dismiss
            </Button>
          </MenuDrawer>
        )}
      </section>
    </>
  );
}

export default withRouter(SignIn);
