import {
  KIOSK_PLACE_ORDER,
  KIOSK_RESET_ORDER_PLACED,
  KioskOrdersActionTypes,
} from "./types";
import { CartState } from "../cart/types";

export function KioskPlaceOrder(cart: CartState): KioskOrdersActionTypes {
  return {
    type: KIOSK_PLACE_ORDER,
    cart,
  };
}

export function KioskResetOrderPlaced(): KioskOrdersActionTypes {
  return {
    type: KIOSK_RESET_ORDER_PLACED,
  };
}
