import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Patch: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--patch" version="1.1" x="0px" y="0px" viewBox="0 0 160.1 160.1">
      <g>
        <line className="i-product-type--patch--st0" stroke={color} x1="80" y1="100.6" x2="80" y2="109.1"/>
        <path className="i-product-type--patch--st0" stroke={color} d="M80,51c0,0,4.4,5.5,6,21.8s-6,27.8-6,27.8s25.3-2,29.9-29.7c0,0-17,9.9-25.4,19.2"/>
        <path className="i-product-type--patch--st0" stroke={color} d="M80,51c0,0-4.4,5.5-6,21.8s6,27.8,6,27.8s-25.3-2-29.9-29.7c0,0,17,9.9,25.4,19.2"/>
        <path className="i-product-type--patch--st0" stroke={color} d="M105.9,100.1c0,0-11,9.4-25.9,0.5"/>
        <path className="i-product-type--patch--st0" stroke={color} d="M54.1,100.1c0,0,11,9.4,25.9,0.5"/>
      </g>
      <path className="i-product-type--patch--st0" stroke={color} d="M147.7,158.4H12.3c-5.9,0-10.7-4.8-10.7-10.7V12.3c0-5.9,4.8-10.7,10.7-10.7h135.4c5.9,0,10.7,4.8,10.7,10.7
        v135.4C158.4,153.6,153.6,158.4,147.7,158.4z"/>
      <path className="i-product-type--patch--st0" stroke={color} d="M85.5,35.2H41.3c-3.4,0-6.1,2.7-6.1,6.1v20.8"/>
      <path className="i-product-type--patch--st0" stroke={color} d="M124.9,102.1v16.7c0,3.4-2.7,6.1-6.1,6.1H41.3"/>
      <g>
        <line className="i-product-type--patch--st0" stroke={color} x1="124.9" y1="20.5" x2="124.9" y2="15.5"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="102.4" y1="20.5" x2="102.4" y2="15.5"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="80" y1="20.5" x2="80" y2="15.5"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="57.6" y1="20.5" x2="57.6" y2="15.5"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="35.2" y1="20.5" x2="35.2" y2="15.5"/>
      </g>
      <g>
        <line className="i-product-type--patch--st0" stroke={color} x1="124.9" y1="145.3" x2="124.9" y2="140.2"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="102.4" y1="145.3" x2="102.4" y2="140.2"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="80" y1="145.3" x2="80" y2="140.2"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="57.6" y1="145.3" x2="57.6" y2="140.2"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="35.2" y1="145.3" x2="35.2" y2="140.2"/>
      </g>
      <g>
        <line className="i-product-type--patch--st0" stroke={color} x1="20.2" y1="35.6" x2="15.2" y2="35.6"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="20.2" y1="58" x2="15.2" y2="58"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="20.2" y1="80.4" x2="15.2" y2="80.4"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="20.2" y1="102.8" x2="15.2" y2="102.8"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="20.2" y1="125.2" x2="15.2" y2="125.2"/>
      </g>
      <g>
        <line className="i-product-type--patch--st0" stroke={color} x1="144.9" y1="35.6" x2="139.9" y2="35.6"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="144.9" y1="58" x2="139.9" y2="58"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="144.9" y1="80.4" x2="139.9" y2="80.4"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="144.9" y1="102.8" x2="139.9" y2="102.8"/>
        <line className="i-product-type--patch--st0" stroke={color} x1="144.9" y1="125.2" x2="139.9" y2="125.2"/>
      </g>
    </svg>
  )
}

export default Patch;