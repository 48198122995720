import React, { useState }    from 'react';
import { Link }               from 'react-router-dom';
import { CircleArrowButton }  from 'components/Shared';
import './DispensaryNearYou.scss';

function DispensaryNearYou() {
  const [imageLoaded, setImageLoaded] = useState(false);
  return(
    <section className="c-dispensary-near">
      <Link to="/locations" className="c-dispensary-near__inner">
        <figure className="c-dispensary-near__figure" >
          <img src={process.env.REACT_APP_STATIC_URL + '/home/dispensary.jpg'} alt="Interior of dispensary" onLoad={() => setImageLoaded(true)} style={{opacity: imageLoaded ? 1 : 0}} />
        </figure>
        <div className="c-dispensary-near__content">
          <h2>Find a dispensary<br/> near you</h2>
          <CircleArrowButton ariaLabel="All Locations" to="/locations" type="button" />
        </div>
      </Link>
    </section>
  )
}

export default DispensaryNearYou;
