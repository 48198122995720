import { all } from "redux-saga/effects";
import cartSaga from "./cart/saga";
import customerSaga from "./customer/saga";
import inventorySaga from "./inventory/saga";
import locationSaga from "./locations/saga";
import ordersSaga from "./orders/saga";
import profileSaga from "./profile/saga";
import recsSaga from "./recommendations/saga";
import kioskSaga from "./kiosk/saga";
import kioskOrdersSaga from "./kiosk-orders/saga";
export const sagaURL = process.env.REACT_APP_API_URL || "";

export default function* rootSaga() {
  yield all([
    locationSaga(),
    inventorySaga(),
    cartSaga(),
    customerSaga(),
    ordersSaga(),
    recsSaga(),
    profileSaga(),
    kioskSaga(),
    kioskOrdersSaga(),
  ]);
}
