import React from "react";
import DotPulseLoader from "../PulseLoader";
import "components/Account/OrderHistory/OrderHistoryOrder/OrderHistoryOrder.scss";
import "components/Order/OrderTotals/OrderTotals.scss";

import "../Loading.scss";
interface LoadingInterface {
  showCheckoutLoder?: boolean;
  orderErr?: boolean;
}

function LoadingOrderHistoryOrder({
  showCheckoutLoder = false,
  orderErr = false,
}: LoadingInterface) {
  return (
    <article className="c-order-history-order c-loading--order-history-order">
      <div className="c-order-history-order__status">
        {showCheckoutLoder && (
          <>
            <h3 className="c-loading--order-heading">
              Thank you for your patience. Your order is being processed.
            </h3>
            <DotPulseLoader />
          </>
        )}
        {orderErr && (
          <>
            <p className="slug--red">
              Something went wrong. Please contact{" "}
              <a
                href="mailto:support@forage.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@forage.io
              </a>
            </p>
            <br />
          </>
        )}
        <div className="slug c-loading--loading-type--slug"></div>
        <div className="c-order-history-order__dispensary">
          <strong></strong>
          <span></span>
        </div>
      </div>
      <div className="c-order-history-order__details">
        <div className="slug c-loading--loading-type--slug"></div>
        <div className="c-order-history-order__items">
          <article className="c-order-history-order__item">
            <div className="c-order-history-order__item__details">
              <span className="c-order-history-order__item__name"></span>
              <span className="c-order-history-order__item__vendor"></span>
              <div className="c-order-history-order__item__meta">
                <span className="c-order-history-order__item__meta__quantity"></span>
              </div>
            </div>
            <div className="c-order-history-order__item__price">
              <span className="c-order-history-order__item__name"></span>
            </div>
          </article>
        </div>
        <div className="c-order-totals">
          <div className="c-order-totals__subtotals">
            <div className="c-order-totals__row">
              <span className="slug">SUBTOTAL</span>
              <span className="c-order-totals__row__price"></span>
            </div>
            <div className="c-order-totals__row">
              <span className="slug">TAX</span>
              <span className="c-order-totals__row__price"></span>
            </div>
          </div>
          <div className="c-order-totals__total">
            <div className="c-order-totals__row">
              <span className="slug">Total</span>
              <span className="c-order-totals__row__price"></span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default LoadingOrderHistoryOrder;
