import React, { useEffect, useRef, useState, useCallback } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import "./MoodSelector.scss";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import AdjustDrawer from "./Adjust";

import { scrollTo } from "utils";
import {
  SetMoodSelectorIsOpen,
  SetMoodSelectorStep,
} from "store/system/actions";
import {
  FetchRecConfig,
  // FetchRecCount,
  FetchRecs,
  SetFillOutFeelings,
  SetRecXY,
  // SetRecFinderPreference,
  // ResetRecs
} from "store/recommendations/actions";
import { debounce } from "throttle-debounce";
// import MoodSelectorLocation from "./Location";
import { RouteComponentProps, withRouter } from "react-router-dom";
import PDPDrawer from "../../PDP/PDPDrawer";

const STEP_TRANSITION_TIME = 1000;

type pageTitlesType = {
  [key: number]: string | null;
};

const PREFIX_TITLE = "Cannabis Recommendations | ";
export const MAXIMUM_RECS_COUNT = 10;

interface MoodSelectorProps extends RouteComponentProps {
  onSelectorOpen: any;
  orientation?: {
    isLandscape: boolean;
    isPortrait: boolean;
  };
}

function MoodSelector({
  onSelectorOpen,
  history,
  location,
  orientation,
}: MoodSelectorProps) {
  const dispatch = useDispatch();
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );

  const moodSelectorOpen = useSelector(
    (state: RootState) => state.system.moodSelectorOpen
  );
  const moodSelectorStep = useSelector(
    (state: RootState) => state.system.moodSelectorStep
  );
  const drawerOpen = useSelector((state: RootState) => state.system.drawerOpen);
  const isKiosk = useSelector((state: RootState) => state.system.isKiosk);
  const isTablet = useSelector((state: RootState) => state.system.isTablet);
  const isMobile = useSelector((state: RootState) => state.system.isMobile);
  const isMobileLandscape = isMobile && window.innerWidth > window.innerHeight;

  const recCount = useSelector((state: RootState) => state.recs.recCount);
  const recPreferences = useSelector(
    (state: RootState) => state.recs.recPreferences
  );
  const stepList = useSelector((state: RootState) => state.recs.stepList);
  const recConfig = useSelector((state: RootState) => state.recs.recConfig);

  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const step4Ref = useRef<HTMLDivElement>(null);
  const step5Ref = useRef<HTMLDivElement>(null);
  const step6Ref = useRef<HTMLDivElement>(null);

  const [loadingConfig, setLoadingConfig] = useState(false);
  const [currentStep, setCurrentStep] = useState<any>(false);
  const [nextStep, setNextStep] = useState<any>(2);
  const [prevStep, setPrevStep] = useState<any>(0);
  const [relativeStep, setRelativeStep] = useState(1);
  const [stepChanged, setStepChanged] = useState(false);
  const [currentInstance, setCurrentInstance] = useState<any>(null);
  const [recPreferencesLocal, setRecPreferencesLocal] = useState<any>(
    recPreferences
  );

  const PAGE_TITLES: pageTitlesType = {
    1: null,
    2: `${PREFIX_TITLE}How would you like to feel?`,
    3: `${PREFIX_TITLE}What will you be using it for?`,
    4: `${PREFIX_TITLE}What occasion will you be using it for?`,
    5: `${PREFIX_TITLE}What types of products are you looking for?`,
    6: `${
      recCount! > MAXIMUM_RECS_COUNT ? MAXIMUM_RECS_COUNT : recCount
    } Cannabis Recommendations`,
  };

  useEffect(() => {
    if (currentInstance === null) {
      setCurrentInstance(location.pathname);
    }
  }, [currentInstance, location]);

  useEffect(() => {
    const relativeTimeout = setTimeout(() => {
      setRelativeStep(currentStep);
    }, STEP_TRANSITION_TIME);
    return function __cleanup() {
      clearTimeout(relativeTimeout);
    };
  }, [currentStep]);

  useEffect(() => {
    const unlisten = history.listen((newLocation: any) => {
      // Enable footer display on other pages excluding profile and recommendations steps
      if (
        newLocation?.pathname &&
        newLocation.pathname !== "/" &&
        ["/recommendations", "/create-profile"].indexOf(
          newLocation?.pathname
        ) <= -1
      ) {
        dispatch(SetMoodSelectorIsOpen(false));
      }

      if (
        newLocation.pathname === "/" ||
        newLocation.pathname === "/recommendations"
      ) {
        let { hash } = newLocation;
        hash = hash.replace(/#/g, "");
        setStepChanged(true);

        if (hash === "location") {
          if (!activeDispensary) {
            //Set location info in localStorage
            if (
              newLocation?.pathname &&
              ["/", "/recommendations"].indexOf(newLocation?.pathname) > -1
            ) {
              const resultInfo = { pathname: newLocation?.pathname || "" };
              localStorage.setItem(
                "recresult_info",
                JSON.stringify(resultInfo)
              );
            }
            history.push("/locations");
          }
          dispatch(SetMoodSelectorStep(5.5));
          onAnimationStart(currentStep, 5.5);
        } else {
          const newIndex = Math.min(5, stepList.indexOf(hash));
          dispatch(SetMoodSelectorStep(newIndex + 1));
          onAnimationStart(newIndex, newIndex + 1);
          if (newIndex === 0 && currentStep === 1) {
            // dispatch(SetMoodSelectorIsOpen(false));
          }
        }
        if (currentStep === 6 || currentInstance !== newLocation.pathname) {
          setCurrentInstance(newLocation.pathname);
          dispatch(SetRecXY({ x: null, y: null }));
          dispatch(SetFillOutFeelings(null));
          // dispatch(SetMoodSelectorIsOpen(false));
        }
      }
    });
    return () => {
      unlisten();
    };
  }, [
    currentInstance,
    currentStep,
    dispatch,
    history,
    stepList,
    activeDispensary,
  ]);

  // const debouncedRecommendationCount = useCallback(
  //   debounce(500, false, () => {
  //     dispatch(FetchRecCount(recPreferences));
  //   }),
  //   []
  // );

  const debouncedRecommendationResults = useCallback(
    debounce(500, false, () => {
      dispatch(FetchRecs(recPreferences));
    }),
    []
  );

  const onAnimationStart = (currentStep: number, nextStep: number) => {
    setPrevStep(currentStep);
    setNextStep(nextStep);
  };

  const onAnimationFinish = (currentStep: number, nextStep: number) => {
    setPrevStep(currentStep);
    setNextStep(nextStep);
  };

  const loadResults = useCallback(() => {
    // debouncedRecommendationCount();
    debouncedRecommendationResults();
  }, [debouncedRecommendationResults]);

  useEffect(() => {
    if (activeDispensary && moodSelectorOpen) {
      // loadResults();
      if (
        !recCount ||
        JSON.stringify(recPreferences) !== JSON.stringify(recPreferencesLocal)
      ) {
        setRecPreferencesLocal(recPreferences);
        loadResults();
      }
    }
  }, [
    activeDispensary,
    moodSelectorOpen,
    recPreferences,
    loadResults,
    recPreferencesLocal,
    recCount,
  ]);

  useEffect(() => {
    if (!loadingConfig && !recConfig) {
      setLoadingConfig(true);
      dispatch(FetchRecConfig());
    }
  }, [loadingConfig, recConfig, dispatch]);

  useEffect(() => {
    setCurrentStep(moodSelectorStep);

    if (moodSelectorStep === 1 && !stepChanged && !moodSelectorOpen) {
      setNextStep(2);
      setPrevStep(1);
      onSelectorOpen(false);
      if (location.pathname === "/" && location.hash) {
        history.replace("/");
      } else if (location.pathname === "/recommendations" && location.hash) {
        history.replace("/recommendations");
      }
    } else if (moodSelectorStep === 0) {
      onSelectorOpen(false);
      dispatch(SetMoodSelectorStep(1));
    } else if (moodSelectorStep > 1) {
      // Remove white Header white remove if recommendations page
      if (moodSelectorStep === 6) {
        dispatch(SetMoodSelectorIsOpen(true));
      }

      setTimeout(
        () =>
          onAnimationFinish(
            moodSelectorStep,
            moodSelectorStep === 5 && !activeDispensary
              ? 5.5
              : moodSelectorStep + 1
          ),
        STEP_TRANSITION_TIME
      );
    }
  }, [
    moodSelectorStep,
    activeDispensary,
    dispatch,
    history,
    location.hash,
    location.pathname,
    moodSelectorOpen,
    onSelectorOpen,
    stepChanged,
  ]);

  useEffect(() => {
    if (moodSelectorStep === 5.5 && activeDispensary) {
      history.replace("#results");
    }
  }, [activeDispensary, moodSelectorStep, history]);

  const goToResults = () => {
    // dispatch(ResetRecs());

    const delay = window.scrollY > 10 ? 750 : 0;
    scrollTo(document.documentElement, 0, 500);
    setTimeout(() => {
      if (activeDispensary) {
        history.push("#results");
      } else {
        //Set location info in localStorage
        if (
          location?.pathname &&
          ["/", "/recommendations"].indexOf(location?.pathname) > -1
        ) {
          const resultInfo = { pathname: location?.pathname || "" };
          localStorage.setItem("recresult_info", JSON.stringify(resultInfo));
        }

        // history.push("#location");
        history.push("/locations");
      }
    }, delay);
  };

  const goToRelatedProducts = () => {
    // dispatch(SetRecFinderPreference("limit", null));
    history.push("/related-products");
  };

  const goToNextStep = () => {
    const delay = window.scrollY > 10 ? 750 : 0;
    scrollTo(document.documentElement, 0, 500);
    const stepString = stepList[currentStep];
    setTimeout(() => {
      history.push(`#${stepString}`);
    }, delay);
  };

  function goToPDP(path: string) {
    history.push(path);
  }

  const renderPageTitle = () => {
    return currentStep && PAGE_TITLES[currentStep] ? (
      <Helmet>
        <title>{PAGE_TITLES[currentStep]}</title>
      </Helmet>
    ) : null;
  };

  const shouldRenderStep = (step: number) => {
    return step === currentStep || step === nextStep || step === prevStep;
  };

  return (
    <>
      {renderPageTitle()}
      <section
        className={classNames(
          "c-mood-selector c-mood-selector--step-" +
            String(currentStep).replace(".", "_") +
            " c-mood-selector--relative-step-" +
            relativeStep,
          {
            "c-mood-selector--open": moodSelectorOpen,
            "c-mood-selector--visible": currentStep || relativeStep === 1,
          }
        )}
        style={{
          maxHeight: !isKiosk
            ? moodSelectorStep === 1
              ? isMobileLandscape
                ? "100vh"
                : "100vh"
              : "100%"
            : "calc(100vh - 450px)",
          minHeight:
            isKiosk || isTablet ? "" : isMobileLandscape ? "100vh" : "100vh",
        }}
      >
        {shouldRenderStep(1) && (
          <Step1
            ref={step1Ref}
            setParentChanged={() => {}}
            nextStep={goToNextStep}
            onSelectorOpen={onSelectorOpen}
            orientation={orientation}
          />
        )}
        {shouldRenderStep(2) && (
          <Step2
            ref={step2Ref}
            goToResults={goToResults}
            nextStep={goToNextStep}
          />
        )}
        {shouldRenderStep(3) && (
          <Step3
            ref={step3Ref}
            goToResults={goToResults}
            nextStep={goToNextStep}
          />
        )}
        {shouldRenderStep(4) && (
          <Step4
            ref={step4Ref}
            goToResults={goToResults}
            nextStep={goToNextStep}
          />
        )}
        {shouldRenderStep(5) && (
          <Step5
            ref={step5Ref}
            goToResults={goToResults}
            nextStep={goToNextStep}
          />
        )}
        {/* {shouldRenderStep(5.5) && <MoodSelectorLocation />} */}
        <Step6
          ref={step6Ref}
          goToPDP={goToPDP}
          goToRelatedProducts={goToRelatedProducts}
          onSelectorOpen={onSelectorOpen}
        />
        <div
          className={classNames("c-mood-selector__standard-action", {
            "c-mood-selector__standard-action--kiosk": isKiosk,
            "c-mood-selector__standard-action--visible":
              moodSelectorOpen && currentStep < (isKiosk ? 6 : 2),
            "c-mood-selector__standard-action--visible--1":
              moodSelectorOpen && currentStep === 1,
          })}
        >
          <Button type="button" onClick={goToNextStep} variant="">
            Next
          </Button>
          <Button type="button" onClick={goToResults} variant="outlined">
            View Recommendations
          </Button>
        </div>
      </section>
      <AdjustDrawer adjustOpen={drawerOpen} />
      {moodSelectorStep === 6 && <PDPDrawer />}
    </>
  );
}

export default withRouter(MoodSelector);
