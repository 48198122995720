import React      from 'react';
import classNames from 'classnames';
import { CheckboxProps }  from './types';
import CheckmarkIcon      from 'components/svgs/Checkmark';
import feelingColors      from 'sass/utils/feelingColors.module.scss';
import colors             from 'sass/utils/colors.module.scss';
import './Fields.scss';

// General input to be used by forms
// Placeholders or labels are to be used independent of one another as the desired design would clash otherwise.
const renderField = ({ 
  input, 
  className, 
  options, 
  meta 
}: CheckboxProps ) => {
  const {active, touched, invalid, dirty, submitFailed, initial} = meta;
  const showInvalid = (invalid && touched) || submitFailed;
  let formControlClass = 'form-control form-control--checkbox-card';
  if(className){
    formControlClass += ' ' + className;
  }
  return (
    <div className="i-checkout-cards">
      {
        options.map((option, index) => {
          const { Icon } = option;
          if(Icon){
            formControlClass += ' form-control--has-icon'
          }
          const isChecked = input.value.indexOf(option.name) !== -1;
          return (
            <div style={{backgroundColor: isChecked && feelingColors[option.name] ? feelingColors[option.name] :  isChecked ? colors['gray20'] : '#fff' }} key={option.name} className={classNames(formControlClass, {'form-control--invalid': showInvalid, 'form-control--active': active, 'form-control--dirty': (dirty||initial), 'form-control--checked': isChecked})}>
              <label className="form-control__checkbox-label form-control__checkbox-label--card">
                <CheckmarkIcon variant="white" />
                <input type="checkbox"
                  name={`${input.name}[${index}]`}
                  value={option.name}
                  checked={isChecked}
                  onChange={(event) => {
                    const newValue = [...input.value];
                    if (event.target.checked) {
                        newValue.push(option.name);
                    } else {
                        newValue.splice(newValue.indexOf(option.name), 1);
                    }

                    return input.onChange(newValue);
                  }}
                />
                { Icon ? 
                  <>
                    <div className="form-control__checkbox-label__icon-wrapper">
                      <Icon variant={isChecked ? 'white' : 'charcoal'} />
                    </div>
                    <span>{option.label}</span>
                  </>
                :
                  <div className="h1">{ option.label}</div>
                }
              </label>
            </div>
          )
        })
      }
    </div>
  )
}
export default renderField;