import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import chroma from "chroma-js";
import "./Slider.scss";

interface SliderProps {
  feeling: any;
  onChange: any;
}
function Slider({ feeling, onChange }: SliderProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { minimumAlpha, value, color, label } = feeling;
  const normalizedValue = Math.max(0, Math.min(1, value));
  let adjustedValue = Math.ceil(normalizedValue * 10) / 10;

  const [handleColor, setHandleColor] = useState(color);
  const [maxRight, setMaxRight] = useState(0);
  const [gridX, setGridX] = useState<any>(false);
  const [percentage, setPercentage] = useState(value);

  useEffect(() => {
    window.addEventListener("orientationchange", onWindowResize);
    return function __cleanup() {
      window.removeEventListener("orientationchange", onWindowResize);
    };
  });

  useEffect(() => {
    const availableAlpha = 1 - minimumAlpha;
    const adjustedAlpha = availableAlpha * Math.max(0, percentage);
    const newAlpha = minimumAlpha + adjustedAlpha;
    setHandleColor(chroma(color).alpha(newAlpha).hex());
  }, [percentage, color, minimumAlpha]);

  useEffect(() => {
    if (null !== wrapperRef.current) {
      setMaxRight(wrapperRef.current.offsetWidth);
      setGridX(wrapperRef.current.offsetWidth / 10);
    }
  }, []);

  function onWindowResize() {
    setGridX(false);
    setTimeout(() => {
      if (wrapperRef.current === null) return;
      setMaxRight(wrapperRef.current.offsetWidth);
      setGridX(wrapperRef.current.offsetWidth / 10);
    }, 500);
  }

  function handleStart(e: any, data: any) {
    if (e.cancelable) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  function handleDrag(e: any, data: any) {
    e.stopPropagation();

    let percentage = data.x / maxRight;
    percentage = Math.max(0, percentage);
    percentage = Math.min(1, percentage);
    setPercentage(percentage);
  }

  function handleStop(e: any, data: any) {
    let percentage = data.x / maxRight;
    onChange(feeling, percentage);
  }
  return (
    <article className="c-slider">
      <p>{label}</p>
      <div className="c-slider__wrapper" ref={wrapperRef}>
        {gridX !== false && (
          <Draggable
            axis="x"
            bounds={{ left: 0, right: maxRight }}
            handle=".c-slider__handle"
            defaultPosition={{ x: maxRight * adjustedValue, y: 0 }}
            grid={[gridX, 1]}
            onStart={handleStart}
            onDrag={handleDrag}
            onStop={handleStop}
          >
            <div
              onTouchStart={(e: any) => {
                e.persist();
                if (e.cancelable) {
                  e.preventDefault();
                }
              }}
              className="c-slider__handle"
              // onTouchMove={(e: any) => e.stopPropagation()}
              // onMouseMove={(e: any) => {if (e.cancelable) e.preventDefault();}}
            >
              <div className="c-slider__handle__inner">
                <span
                  className={`c-slider__feeling c-slider__feeling-${label?.toLowerCase()}`}
                  style={{ backgroundColor: handleColor }}
                ></span>
              </div>
            </div>
          </Draggable>
        )}
        <div className="c-slider__steps">
          {[...Array(11)].map((e, i) => (
            <div
              key={label + "-step-" + i}
              className="c-slider__steps__step"
            ></div>
          ))}
        </div>
      </div>
      <div className="c-slider__labels">
        <span>Less</span>
        <span>More</span>
      </div>
    </article>
  );
}

export default Slider;
