import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { CartItem } from "store/cart/types";
import { AddToCart, RemoveFromCart, UpdateCartItem } from "store/cart/actions";
import Button from "components/Button";
import DownCaretIcon from "components/svgs/DownCaret";
import "./AddToCart.scss";
import { calculateFinalPrice } from "../PDP/PDPPrice";

interface AddToCartProps {
  dispensarySlug: string;
  product: any;
  parentOffset?: any;
}
function ATC({
  dispensarySlug,
  parentOffset,
  product,
  product: {
    unitPrice,
    quantityAvailable,
    product: { productId },
    discounts,
  },
}: AddToCartProps) {
  const dispatch = useDispatch();
  const atcRef = useRef<HTMLDivElement>(null);

  const cartItems = useSelector((state: RootState) => state.cart.items);
  const isMobile = useSelector((state: RootState) => state.system.isMobile);
  const existsInCart: any = cartItems.find(
    (item: CartItem) => item.productId === productId
  );
  const dispensaries = useSelector(
    (state: RootState) => state.locations.dispensaries
  );

  const [acceptingPreorders, setAcceptingPreorders] = useState(
    dispensaries &&
      dispensaries[dispensarySlug] &&
      dispensaries[dispensarySlug].acceptingPreorders
  );
  const [currentQuantity, setCurrentQuantity] = useState(
    existsInCart ? existsInCart.quantity : 1
  );
  const [updatedQuantity, setUpdatedQuantity] = useState(currentQuantity);
  const [cartState, setCartState] = useState<any>({});
  const [showQuantity, setShowQuantity] = useState(false);
  const [addPointer, setAddPointer] = useState(false);
  const [quantMaxHeight, setQuantMaxHeight] = useState(0);
  const [shopLink, setShopLink] = useState<any>("");

  const cartStateState = cartState.state;

  useEffect(() => {
    if (dispensaries && dispensarySlug) {
      if (dispensaries && dispensaries[dispensarySlug]) {
        setAcceptingPreorders(dispensaries[dispensarySlug].acceptingPreorders);
        setShopLink(dispensaries[dispensarySlug].shopLink);
      }
    }
  }, [dispensaries, dispensarySlug]);

  useEffect(() => {
    setShowQuantity(false);
    setCurrentQuantity(updatedQuantity);
  }, [updatedQuantity]);

  useEffect(() => {
    const existsInCart: any = cartItems.find(
      (item: CartItem) => item.productId === productId
    );
    if (existsInCart) {
      let buttonText = "Update quantity";

      if (currentQuantity === existsInCart.quantity) {
        buttonText = currentQuantity + " in order";
      }
      if (currentQuantity === 0) {
        buttonText = "Remove from order";
      }
      setCartState({
        state: currentQuantity === 0 ? "remove" : "update",
        buttonText: buttonText,
      });
    } else {
      setCartState({
        state: "add",
        buttonText:
          "Add to order $" + calculateFinalPrice(unitPrice, discounts),
      });
    }
    if (
      cartStateState !== "update" ||
      currentQuantity !== existsInCart?.quantity
    ) {
      setAddPointer(true);
    } else {
      setAddPointer(false);
    }
  }, [
    cartItems,
    currentQuantity,
    productId,
    unitPrice,
    cartStateState,
    discounts,
  ]);

  function handleCartAction() {
    switch (cartState.state) {
      case "add":
        dispatch(AddToCart(product, currentQuantity, dispensarySlug));
        break;

      case "update":
        if (existsInCart.quantity !== currentQuantity) {
          dispatch(UpdateCartItem(productId, currentQuantity));
        }
        break;

      case "remove":
        setCurrentQuantity(1);
        setUpdatedQuantity(1);
        dispatch(RemoveFromCart(productId));
        break;
    }
  }

  function toggleQuantitySelector() {
    if (showQuantity) {
      setQuantMaxHeight(0);
    } else {
      if (atcRef.current !== null) {
        let wrapperOffset: number = 0;
        if (parentOffset) {
          wrapperOffset = parentOffset;
        } else {
          const wrapperEl = document.getElementById("c-pdp__topper-wrapper");
          if (wrapperEl) {
            wrapperOffset = wrapperEl.offsetTop;
          }
        }
        const quantHeight = isMobile
          ? window.innerHeight
          : window.innerHeight -
            atcRef.current.offsetTop -
            wrapperOffset -
            atcRef.current.offsetHeight;
        setQuantMaxHeight(quantHeight <= 50 ? 50 : quantHeight);
      }
    }
    setShowQuantity(!showQuantity);
  }

  function handleQuantityChange(quantity: number) {
    setShowQuantity(false);
    setQuantMaxHeight(0);
    setUpdatedQuantity(quantity);
  }

  return (
    <div
      className={classNames("c-atc", {
        "c-atc--out-of-stock": quantityAvailable === 0,
      })}
      ref={atcRef}
    >
      {acceptingPreorders ? (
        <>
          <button className="c-atc__quantity" onClick={toggleQuantitySelector}>
            {quantityAvailable === 0 ? (
              <span>- -</span>
            ) : (
              <>
                <span>{updatedQuantity}</span>
                <span
                  className={classNames("c-atc__quantity-icon", {
                    "c-atc__quantity-icon--rotated": showQuantity,
                  })}
                >
                  <DownCaretIcon variant="white" />
                </span>
              </>
            )}
          </button>
          <button
            className={classNames("c-atc__add", {
              "c-atc__add--pointer": addPointer,
            })}
            onClick={handleCartAction}
          >
            {quantityAvailable === 0 ? (
              <span>Out of stock</span>
            ) : (
              <span>{cartState.buttonText}</span>
            )}
          </button>
          <div
            className={classNames("c-atc__quantity-selector", {
              "c-atc__quantity-selector--open": showQuantity,
              "c-atc__quantity-selector--show-zero": cartState.state !== "add",
            })}
            style={{ maxHeight: quantMaxHeight }}
          >
            {[...Array(Math.min(10, quantityAvailable + 1))].map((e, i) => (
              <button
                key={"quantity-" + i}
                className={classNames("c-atc__quantity-selector__option", {
                  "c-atc__quantity-selector__option--active":
                    i === currentQuantity,
                })}
                onClick={() => handleQuantityChange(i)}
              >
                {i}
              </button>
            ))}
          </div>
        </>
      ) : (
        <Button type="a" to={shopLink} variant="preorder-disabled">
          Buy at Dispensary
        </Button>
      )}
    </div>
  );
}

export default ATC;
