import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { dispensaryProps } from "./types";
import { getAddress, getOpenDetails } from "utils/maps";
import { RootState } from "store";
import {
  FetchDispensaries,
  SetDispensaryIsOpen,
} from "store/locations/actions";
import { ResetCart, SetPickupLocation } from "store/cart/actions";
import {
  SetRecsLocation,
  FetchRecs,
  ResetRecs,
  ResetRecsPreviousData,
} from "store/recommendations/actions";
import { SetMoodSelectorStep } from "store/system/actions";
import Tag from "components/Tag";
import LocationIcon from "components/svgs/Location";
import PhoneIcon from "components/svgs/Phone";
import Button from "components/Button";
import BackButton from "components/BackButton";
import CartMismatch from "components/CartMismatch";
import NotFound from "components/NotFound";
import { HoursOfOperationKeyType } from "../../constants/dispensary";
import { recPreferencesLimit } from "constants/recommendations";
import "./Dispensary.scss";

function Dispensary({ location, match, history }: RouteComponentProps) {
  const params: any = match.params;
  const { slug } = params;

  const dispatch = useDispatch();
  const mapsReady = useSelector((state: RootState) => state.system.mapsReady);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const pickupLocation = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const dispensary = useSelector(
    (state: RootState) =>
      slug && state.locations.dispensaries && state.locations.dispensaries[slug]
  );
  const allLocations = useSelector(
    (state: RootState) => state.locations.dispensaries
  );
  const recPreferences = useSelector(
    (state: RootState) => state.recs.recPreferences
  );
  const recProductIds = useSelector(
    (state: RootState) => state.recs.recProductIds
  );

  const [dispensaryData, setDispensaryData] = useState<dispensaryProps>(null);
  const [isPickupLocation, setIsPickupLocation] = useState(false);
  const [showFullHours, setShowFullHours] = useState(false);
  const [locationDistance, setLocationDistance] = useState(
    dispensary ? dispensary.distance : ""
  );
  const [openInformation, setOpenInformation] = useState<any>({ isOpen: null });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showMismatch, setShowMismatch] = useState(false);
  const [show404, setShow404] = useState(false);
  const isOpen = dispensaryData ? dispensaryData.isOpen : null;
  const staticImageURL =
    (process?.env?.REACT_APP_STATIC_URL || "https://static.forage.io") +
    "/locations/location-default.jpg";
  const [locationImageSrc, setLocationImageSrc] = useState("");

  useEffect(() => {
    const unlisten = history.listen(() => {
      setShowMismatch(false);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (dispensary && dispensary.distance) {
      setLocationDistance(dispensary.distance);
    }
  }, [dispensary, allLocations]);

  // If user has selected store as their pickup location, set store id on redux store
  useEffect(() => {
    if (!dispensaryData) {
      return;
    }
    setIsPickupLocation(pickupLocation === dispensaryData.slug);
  }, [pickupLocation, dispensaryData]);

  useEffect(() => {
    if (!allLocations) {
      dispatch(FetchDispensaries());
    }
  }, [allLocations, dispatch]);

  useEffect(() => {
    if (!dispensary) {
      return;
    }
    dispensary.normalizedHours = {};
    Object.keys(dispensary.hoursOfOperation).forEach(
      (hoursOfOperationKey: any) => {
        if (
          dispensary.hoursOfOperation[
            hoursOfOperationKey as HoursOfOperationKeyType
          ]
        ) {
          const openClose = dispensary.hoursOfOperation[
            hoursOfOperationKey as HoursOfOperationKeyType
          ].split(" - ");
          const openSplit = openClose[0].split(":");
          const closeSplit = openClose[1].split(":");
          let openHour: string | number = parseInt(openSplit[0], 10);
          const openAMPM = openHour < 12 ? "am" : "pm";
          let closeHour: string | number = parseInt(closeSplit[0], 10);
          const closeAMPM = closeHour < 12 ? "am" : "pm";

          openHour = openHour > 12 ? openHour - 12 : openHour;
          closeHour = closeHour > 12 ? closeHour - 12 : closeHour;
          openHour = openHour === 0 ? 12 : (openHour < 10 ? "0" + openHour : openHour);
          closeHour = closeHour === 0 ? 12 : (closeHour < 10 ? "0" + closeHour : closeHour);

          const normalizedOpen = openHour + ":" + openSplit[1] + openAMPM;
          const normalizedClosed = closeHour + ":" + closeSplit[1] + closeAMPM;
          dispensary.normalizedHours[hoursOfOperationKey] =
            normalizedOpen + " - " + normalizedClosed;
        } else {
          dispensary.normalizedHours[hoursOfOperationKey] = "Closed";
        }
      }
    );
    setLocationImageSrc(dispensary.image);
    setDispensaryData(dispensary);
  }, [dispensary]);

  useEffect(() => {
    if (dispensaryData && mapsReady) {
      dispatch(
        SetDispensaryIsOpen(
          dispensaryData.slug,
          dispensaryData.hoursOfOperation,
          dispensaryData.timezone
        )
      );
    }
  }, [dispensaryData, mapsReady, dispatch]);

  useEffect(() => {
    if (!dispensaryData) {
      return;
    }
    const isOpenDetails: any = getOpenDetails(isOpen, dispensaryData);
    setOpenInformation(isOpenDetails);
  }, [isOpen, dispensaryData]);

  // Set 404 if dispansary location not available
  useEffect(() => {
    if (allLocations && !dispensary) {
      setShow404(true);
    }
  }, [allLocations, dispensary]);

  function setPickupLocation() {
    if (!dispensaryData || !dispensaryData?.slug) {
      return;
    }
    if (!isPickupLocation) {
      if (cartItems.length) {
        setShowMismatch(true);
      } else {
        dispatch(SetPickupLocation(dispensaryData));
        dispatch(SetRecsLocation(dispensaryData.slug));
        if (recProductIds?.length) {
          dispatch(ResetRecs());
          dispatch(ResetRecsPreviousData());
        }
      }

      let recresult_info: any = localStorage.getItem("recresult_info");
      if (recresult_info !== "" && recresult_info !== null) {
        recresult_info = JSON.parse(recresult_info);
      }

      if (
        recresult_info?.pathname &&
        ["/", "/recommendations"].indexOf(recresult_info?.pathname) > -1
      ) {
        dispatch(SetMoodSelectorStep(6));
        localStorage.removeItem("recresult_info");
        recPreferences.limit = recPreferencesLimit;
        dispatch(FetchRecs(recPreferences));
        history.push({
          pathname: recresult_info?.pathname || "/",
          hash: "#results",
        });
      }

      // If User navigation from Account page
      if (typeof window !== "undefined") {
        let locationRedirect = sessionStorage.getItem("locationRedirect");
        if (
          locationRedirect &&
          locationRedirect === "/products" &&
          dispensaryData?.slug
        ) {
          history.push("/dispensary/" + dispensaryData.slug + "/products");
        }
        sessionStorage.removeItem("locationRedirect");
      }
    }
  }

  function goBack() {
    history.goBack();
  }

  function resetCallback() {
    if (!dispensaryData || !dispensaryData?.slug) {
      return;
    }
    setShowMismatch(false);
    dispatch(ResetCart());
    dispatch(SetPickupLocation(dispensaryData));
    dispatch(SetRecsLocation(dispensaryData.slug));
  }

  function dismissCallback() {
    setShowMismatch(false);
  }

  return (
    <main className="c-dispensary l-main">
      {dispensary?.name && (
        <Helmet>
          <title>{dispensary?.name}</title>
        </Helmet>
      )}
      {dispensaryData && (
        <>
          <div className="c-dispensary__inner">
            <BackButton onClick={goBack} />
            <figure>
              {dispensaryData && (
                <img
                  src={locationImageSrc || dispensaryData?.image}
                  alt={dispensaryData.name + "Image"}
                  onLoad={() => setImageLoaded(true)}
                  onError={() => setLocationImageSrc(staticImageURL)}
                  style={{ opacity: imageLoaded ? 1 : 0 }}
                />
              )}
            </figure>
            <section className="c-dispensary__details">
              <h1>{dispensaryData && dispensaryData.name}</h1>
              <div className="c-dispensary__tags">
                {dispensaryData && dispensaryData.isMedical && (
                  <Tag>
                    <span>Medical</span>
                  </Tag>
                )}
                {dispensaryData && dispensaryData.isRecreational && (
                  <Tag>
                    <span>Recreational</span>
                  </Tag>
                )}
              </div>
              <a
                href={dispensaryData?.googlePlace.googlePlaceLink}
                target="_blank"
                className="c-dispensary__map"
                rel="noopener noreferrer"
              >
                <LocationIcon variant="deepblue" />
                <CSSTransition
                  in={false && locationDistance !== ""}
                  className="c-dispensary-distance"
                  timeout={1000}
                  appear
                >
                  <div className="c-dispensary-distance">
                    {locationDistance}mi
                  </div>
                </CSSTransition>
                <span>{dispensaryData && getAddress(dispensaryData)}</span>
              </a>
              <a
                href={"tel:" + dispensaryData?.phoneNumber.phoneNumber}
                target="_blank"
                className="c-dispensary__phone"
                rel="noopener noreferrer"
              >
                <PhoneIcon variant="deepblue" />
                <span>{dispensaryData?.phoneNumber.phoneNumber}</span>
              </a>

              <div className="c-dispensary__hours">
                <span
                  className={classNames("c-dispensary__status", {
                    "c-dispensary__status--open":
                      openInformation && openInformation.isOpen,
                    "c-dispensary__status--closed":
                      openInformation && !openInformation.isOpen,
                  })}
                >
                  {openInformation && openInformation.isOpen !== null && (
                    <>
                      <strong>
                        {openInformation.isOpen ? "Open Now" : "Closed"}
                      </strong>
                      &nbsp;<span>{openInformation.openText}</span>
                    </>
                  )}
                </span>
                {dispensaryData?.hoursOfOperation && (
                  <CSSTransition
                    in={showFullHours}
                    className="c-dispensary__full-hours"
                    timeout={2000}
                    appear
                  >
                    <div className="c-dispensary__full-hours">
                      {Object.keys(dispensaryData.hoursOfOperation).map(
                        (day) => {
                          return (
                            <div
                              key={day}
                              className="c-dispensary__hours-entry"
                            >
                              <div className="c-dispensary__hours-day">
                                {day}
                              </div>
                              <div className="c-dispensary__hours-time">
                                {dispensaryData.normalizedHours[day]}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </CSSTransition>
                )}
              </div>
              <button
                className="c-dispensary__toggle-hours"
                onClick={() => setShowFullHours(!showFullHours)}
              >
                {showFullHours ? "Hide  Opening Hours" : "Show Opening Hours"}
              </button>
              <p className="c-dispensary__order-note">
                Last online order accepted <strong>2 hours</strong> before
                dispensary closing.
              </p>
            </section>
            <section className="c-dispensary__actions">
              <Button
                disabled={false}
                variant={isPickupLocation ? "default--active" : "default"}
                type="button"
                onClick={setPickupLocation}
              >
                {isPickupLocation
                  ? "Your Current Pickup Location"
                  : "Set As Pickup Location"}
              </Button>
              <Button
                variant="outlined"
                type="Link"
                to={"/dispensary/" + dispensaryData?.slug + "/products"}
              >
                View Product Inventory
              </Button>
              <Button
                variant="outlined"
                type="a"
                to={dispensaryData?.stateRegulations}
              >
                State Medical Marijuana Details
              </Button>
            </section>
          </div>
          <CartMismatch
            resetCallback={resetCallback}
            type="dispensary"
            forceShow={showMismatch}
            dismissCallback={dismissCallback}
          />
        </>
      )}
      {!dispensary && show404 && <NotFound hasLocationLink />}
    </main>
  );
}

export default withRouter(Dispensary);
