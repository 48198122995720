import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import './CardGradients.scss';
import Noise from 'images/noise.png';
import {isIOS, isSafari} from 'react-device-detect';
import feelingColors from 'sass/utils/feelingColors.module.scss';
import chroma from 'chroma-js';
import { renderGradientPlaceholder } from "./index";


const BLUR = 20;

function blob1(ctx: any, productColors: any) {

  if (!productColors[1]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[1]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[1]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[1]]).alpha(0.0).css();
  // blob1/Path
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(0, -482.7);
  ctx.bezierCurveTo(259.8, 130.6, 438.8, 377.8, 695.0, 165.8);
  ctx.lineTo(730.2, 482.7);
  ctx.lineTo(730.2, 634.1);
  ctx.lineTo(0.0, 634.1);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();

  gradient = ctx.createRadialGradient(307.7, 112.4, 0.0, 507.7, 312.4, 523.5);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
};

function blob2(ctx: any, productColors: any) {

  if (!productColors[2]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[2]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[2]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[2]]).alpha(0.0).css();

  // blob2/Path
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(500, 500);
  ctx.bezierCurveTo(33.0, 347.2, 333.1, 25.8, 106.0, -135.8);
  ctx.lineTo(-600.0, 734.1);
  ctx.lineTo(530.2, 634.1);
  ctx.lineTo(530.2, 809.6);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  gradient = ctx.createRadialGradient(100.4, 305.4, 30.0, 103.4, 305.4, 457.4);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

interface GCL1Props {
  feelings: Array<string>
  animate: boolean
}

function GradientCardLayout1({feelings, animate}: GCL1Props) {
  const ref1 = useRef<HTMLCanvasElement>(null);
  const [ref1Transform, setRef1Transform] = useState('');
  const [productColors, setProductColors] = useState<any>(feelings);

  useEffect(() => {
    if (feelings.length > 0) {
      setProductColors(feelings);
    }
  }, [feelings]);


  function animateRef1() {
    const scaleInt = 1 + (Math.random() * (100 - 0) + 0) / 100;
    const rotateInt = 10 * (Math.random() * (60 - 0) + 0) / 100;
    setRef1Transform('scale(' + scaleInt + ') rotate(' + rotateInt + 'deg)');
  }
  useEffect(() => {
    let animationId = 0;

    if (ref1.current) {
      var ctx = ref1.current.getContext("2d");
      if (ctx) {
        animationId = requestAnimationFrame(() => {
          blob1(ctx, productColors);
          blob2(ctx, productColors);
          animateRef1();
        });
      }
    }
    return () => cancelAnimationFrame(animationId);

  }, [productColors]);

  return (
    <>
      {renderGradientPlaceholder(productColors)}
      {
        productColors && productColors.length &&
        <div className="c-card-gradient c-card-gradient--layout-1"
             style={{backgroundColor: feelingColors[productColors[0]]}}>
          <div style={{backgroundImage: 'url(' + Noise + ')'}}
               className={classNames('c-card-gradient__noise', {'c-card-gradient__noise--safari': isIOS || isSafari})}></div>
          <canvas style={{transform: animate ? ref1Transform : ''}}
                  className={classNames('', {'c-card-gradient--safari-blur': isIOS || isSafari})} width="631"
                  height="535"
                  ref={ref1}></canvas>
        </div>
      }

    </>
  )
}

export default GradientCardLayout1;