import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { FetchOrderHistory } from 'store/customer/actions'
import OrderHistoryOrder from 'components/Account/OrderHistory/OrderHistoryOrder'
import Pagination from 'react-js-pagination'
import { scrollTo } from 'utils'
import './OrderHistory.scss'
import './orderHistoryPagination.scss'

function OrderHistory() {
  const dispatch = useDispatch()

  const orderHistory = useSelector((state: RootState) => state.customer?.orderHistory)
  const ordersCount = useSelector((state: RootState) => state.customer?.ordersCount) || 0
  const orderHistoryLoading = useSelector((state: RootState) => state.customer?.orderHistoryLoading)

  const [orders, setOrders] = useState<any>(orderHistory || false)
  const [loadingOrders, setLoadingOrders] = useState(false)

  // Added pagination related code here
  const limitRow = 10
  const [startRow, setStartRow] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState<any>(null)
  const [orderFetchCount, setOrderFetchCount] = useState<number>(0)

  useEffect(() => {
    if (ordersCount === null || limitRow === null || startRow === null) return
    const current = startRow === 0 ? 1 : Math.ceil(startRow / limitRow + 1)
    const total = Math.ceil(ordersCount / 16)
    setTotalPages(total)
    setCurrentPage(current)
  }, [ordersCount, startRow, limitRow])

  useEffect(() => {
    if ((!orders && orderHistory) || (orderHistory && orderHistory?.length < ordersCount)) {
      setOrders(orderHistory)
      setLoadingOrders(false)
    } else if (orderHistory && ordersCount && orderHistory?.length >= ordersCount) {
      setOrders(orderHistory)
      setLoadingOrders(false)
    }
  }, [orderHistory, orders, ordersCount])

  useEffect(() => {
    if (
      !orderHistoryLoading &&
      orderFetchCount === 0 &&
      (!orderHistory ||
        (orderHistory && orderHistory?.length < limitRow && orderHistory.length < ordersCount))
    ) {
      setLoadingOrders(true)
      setOrderFetchCount(1)
      dispatch(FetchOrderHistory(limitRow, 0))
    }
  }, [orderHistory, ordersCount, orderHistoryLoading, dispatch, orderFetchCount])

  function changePage(pageNumber: number) {
    scrollTo(document.documentElement, 0, 500)
    scrollTo(document.body, 0, 500)

    const localOffset: any = (pageNumber - 1) * limitRow || null
    if (currentPage !== pageNumber) {
      setStartRow(pageNumber)
      dispatch(FetchOrderHistory(limitRow, localOffset))
    }

    setTimeout(() => {
      setCurrentPage(pageNumber)
    }, 750)
  }

  return (
    <section className="c-order-history l-main">
      <div className="l-maxwidth l-maxwidth--small">
        <h1>All past orders</h1>
        <div className="c-order-history__orders">
          {orders && !loadingOrders ? (
            orders.map((order: any, index: number) => {
              return <OrderHistoryOrder order={order} key={index.toString() + order.orderId} />
            })
          ) : (
            <>
              {[...Array(3)].map((e, i) => (
                <OrderHistoryOrder loading={true} key={i} />
              ))}
            </>
          )}
        </div>
        {totalPages && totalPages > 1 && !!currentPage && !!ordersCount ? (
          <div className="c-order-history-pagination">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={limitRow}
              totalItemsCount={ordersCount}
              pageRangeDisplayed={limitRow}
              onChange={changePage}
              lastPageText="⟩⟩"
              firstPageText="⟨⟨"
              hideFirstLastPages={Math.ceil(ordersCount / limitRow) <= 10}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  )
}

export default OrderHistory
