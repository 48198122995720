import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Talkative: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--talkative" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <path className="i-feeling--talkative--st0" stroke={color} d="M113.5,265.1c-13.1-21.3-19.2-47.2-15.2-73.9c8.9-60.2,64.9-101.7,125.1-92.8c60.2,8.9,101.7,64.9,92.8,125.1
          c-8.9,60.2-64.9,101.7-125.1,92.8c-26.3-3.9-49.1-16.8-65.6-35.1l-25.5,5.4L113.5,265.1z"/>
        <rect x="155.3" y="181.4" className="i-feeling--talkative--st1" stroke={color} fill={color} width="105.5" height="16.2"/>
        <rect x="155.3" y="218.6" className="i-feeling--talkative--st1" stroke={color} fill={color} width="88.8" height="16.2"/>
      </g>
    </svg>
  )
}

export default Talkative;