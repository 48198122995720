import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const DownCaret: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="down-caret-icon" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 5L9 0.999999" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default DownCaret;