import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { AddToCart, ResetCart, ResetMismatched } from "store/cart/actions";
import {
  ResetRecs,
  ResetRecsPreviousData,
} from "store/recommendations/actions";
import { MismatchedProductProps } from "store/cart/types";
import Button from "components/Button";
import MenuDrawer from "components/MenuDrawer";

interface CartMismatchProps {
  type?: string;
  forceShow?: boolean;
  resetCallback?: any;
  dismissCallback?: any;
}

function CartMismatch({
  type = "inventory",
  forceShow = false,
  resetCallback,
  dismissCallback,
}: CartMismatchProps) {
  const dispatch = useDispatch();
  const cartMismatch = useSelector(
    (state: RootState) => state.cart.mismatchedDispensary
  );
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const productToAdd: MismatchedProductProps = useSelector(
    (state: RootState) =>
      state.cart.mismatchedProductToAdd || {
        product: null,
        productId: false,
        quantity: false,
        dispensarySlug: false,
      }
  );

  const [forceShowing, setForceShowing] = useState(false);
  const [displayDrawer, setDisplayDrawer] = useState(false);

  useEffect(() => {
    if (forceShow || cartMismatch) {
      setDisplayDrawer(true);
      setForceShowing(true);
    } else if (type === "dispensary" && forceShowing && !forceShow) {
      setForceShowing(false);
      setTimeout(() => {
        setDisplayDrawer(false);
      }, 1000);
    } else if (type === "inventory" && forceShowing && !cartMismatch) {
      setForceShowing(false);
      setTimeout(() => {
        setDisplayDrawer(false);
      }, 1000);
    }
  }, [forceShow, forceShowing, type, cartMismatch]);

  function resetCartMismatch() {
    if (type === "dispensary") {
      setForceShowing(false);
      dispatch(ResetMismatched());
    } else {
      dispatch(ResetMismatched());
    }
  }

  function resetCart() {
    dispatch(ResetCart());
    dispatch(ResetRecs());
    dispatch(ResetRecsPreviousData());
    if (type === "dispensary") {
      resetCallback();
    } else {
      if (
        productToAdd &&
        productToAdd.product !== null &&
        typeof productToAdd.quantity !== "boolean" &&
        typeof productToAdd.dispensarySlug !== "boolean"
      ) {
        const { product, quantity, dispensarySlug } = productToAdd;
        dispatch(AddToCart(product, quantity, dispensarySlug));
      }
    }
  }

  return (
    <>
      {displayDrawer && (
        <MenuDrawer
          display={forceShowing}
          closeMenu={
            type === "dispensary" ? dismissCallback : resetCartMismatch
          }
          position="toast"
          menuHeading={
            "Looks like you've got an existing order at another dispensary."
          }
        >
          <Button
            type="Link"
            to={"/dispensary/" + activeDispensary}
            onClick={resetCartMismatch}
            variant=""
          >
            Go To My Active Dispensary
          </Button>
          <Button type="button" onClick={resetCart} variant="outlined">
            {type === "dispensary"
              ? "Clear order and change dispensary"
              : "Clear previous order and add item"}
          </Button>
        </MenuDrawer>
      )}
    </>
  );
}

export default CartMismatch;
