import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import './CardGradients.scss';
import Noise from 'images/noise.png';
import {isIOS, isSafari} from 'react-device-detect';
import feelingColors from 'sass/utils/feelingColors.module.scss';
import chroma from 'chroma-js';
import { renderGradientPlaceholder } from "./index";

const BLUR = 10;

function blob3(ctx: any, productColors: any) {

  if (!productColors[1]) {
    return
  }
  var gradient;

  const colorStep1 = chroma(feelingColors[productColors[1]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[1]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[1]]).alpha(0.0).css();
  // blob3/Path
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(78, -191);
  ctx.bezierCurveTo(89.2, 128, 202, -58.8, 414, -165);
  ctx.bezierCurveTo(876, 426, 464, -38, 453, 151);
  ctx.bezierCurveTo(442, 313, 288, 335, 261, 481);
  ctx.bezierCurveTo(234, 326, 66, 253, -77, -190);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  gradient = ctx.createRadialGradient(302, -68, 0.0, 302, 3, 334);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.6, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
};

function blob4(ctx: any, productColors: any) {

  if (!productColors[2]) {
    return
  }
  var gradient;

  const colorStep1 = chroma(feelingColors[productColors[2]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[2]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[2]]).alpha(0.0).css();
  // blob3/Path
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(-87, 150);
  ctx.bezierCurveTo(50, 0, 50, 0, 187, 400);
  ctx.lineTo(237, 1096);
  ctx.lineTo(-384, 1096);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  gradient = ctx.createRadialGradient(-25, 400, 0.0, -25, 450, 294);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

function blob5(ctx: any, productColors: any) {

  if (!productColors[1]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[1]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[1]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[1]]).alpha(0.0).css();
  // blob3/Path

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(510, 365);
  ctx.bezierCurveTo(557, 407, 510, 450, 637, 975);
  ctx.bezierCurveTo(464, 500, 422, 564, 367, 520);
  ctx.bezierCurveTo(329, 477, 348, 385, 357, 374);
  ctx.bezierCurveTo(367, 363, 464, 321, 510, 364);
  ctx.closePath();

  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  gradient = ctx.createRadialGradient(530, 500, 0.0, 500, 500, 194);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

function blob6(ctx: any, productColors: any) {

  if (!productColors[2]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[2]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[2]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[2]]).alpha(0.0).css();
  // blob3/Path

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(92, 831);
  ctx.bezierCurveTo(337, 708, 274, 725, 52, 869);
  ctx.bezierCurveTo(76, 413, 59, 506, 115, 429);
  ctx.bezierCurveTo(170, 352, 278, 335, 355, 391);
  ctx.bezierCurveTo(481, 447, 253, 405, 892, 631);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  gradient = ctx.createRadialGradient(254, 600, 0.0, 253, 600, 316);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}


interface GCL5Props {
  feelings: Array<string>
  animate: boolean
}

function GradientCardLayout5({feelings, animate}: GCL5Props) {
  const gradRef = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLCanvasElement>(null);
  const [ref1Transform, setRef1Transform] = useState('');
  const [productColors, setProductColors] = useState<any>([]);

  useEffect(() => {
    if (productColors !== null && productColors.length === 0 && feelings !== null && feelings.length > 0) {
      setProductColors(feelings);
    }
  }, [productColors, feelings]);



  function animateRef1() {
    const scaleInt = 1 + (Math.random() * (100 - 0) + 0) / 100;
    const rotateInt = 10 * (Math.random() * (60 - 0) + 0) / 100;
    setRef1Transform('scale(' + scaleInt + ') rotate(' + rotateInt + 'deg)');
  }

  useEffect(() => {
    let animationId = 0;

    if (ref1.current) {
      var ctx = ref1.current.getContext("2d");
      if (ctx) {
        animationId = requestAnimationFrame(() => {
          blob3(ctx, productColors);
          blob4(ctx, productColors);
          blob5(ctx, productColors);
          blob6(ctx, productColors);
          animateRef1();
        });
      }
    }
    return () => cancelAnimationFrame(animationId);
  }, [productColors]);
  
  return (
    <>
      {renderGradientPlaceholder(productColors)}
      <div className="c-card-gradient c-card-gradient--layout-5"
           style={{backgroundColor: productColors ? feelingColors[productColors[0]] : ''}} ref={gradRef}>
        <div style={{backgroundImage: 'url(' + Noise + ')'}}
             className={classNames('c-card-gradient__noise', {'c-card-gradient__noise--safari': isIOS || isSafari})}></div>

        <canvas style={{transform: animate ? ref1Transform : ''}}
                className={classNames('', {'c-card-gradient--safari-blur': isIOS || isSafari})} width="531"
                height="702" ref={ref1}></canvas>
      </div>
    </>
  )
}

export default GradientCardLayout5;