import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  CustomerSaveSettings,
  FetchAccountSettings,
} from "store/customer/actions";
import IdSettingsForm from "components/Account/SignUp/IdSettingsForm";
import FylloImage from "components/FylloImage";

import "../SignIn/SignIn.scss";
import "./IdSettings.scss";

function IdSettings({ history }: RouteComponentProps) {
  const dispatch = useDispatch();
  const saving = useSelector((state: RootState) => state.customer.saving);
  const customer = useSelector((state: RootState) => state.customer.customer);
  const isNew = useSelector((state: RootState) => state.customer.isNew);

  const [accountSaving, setAccountSaving] = useState(false);
  const [buildTimeStamp, setBuildTimeStamp] = useState<any>(null);

  useEffect(() => {
    if (isNew) {
      setBuildTimeStamp(new Date().getTime());
    }
  }, [isNew]);

  useEffect(() => {
    if (!customer) {
      history.push("/account/signin");
    } else if (customer.readyToLoad) {
      dispatch(FetchAccountSettings({ accessToken: customer?.accessToken }));
    }
  }, [customer, history, dispatch]);

  useEffect(() => {
    if (saving) {
      setAccountSaving(true);
    } else if (accountSaving) {
      setAccountSaving(false);
      history.push("/account");
    }
  }, [saving, accountSaving, history]);

  function handleSubmit(values: any) {
    values.dateOfBirth = values.month + "/" + values.day + "/" + values.year;
    delete values.month;
    delete values.day;
    delete values.year;
    dispatch(CustomerSaveSettings(values));
  }

  return (
    <>
      {buildTimeStamp && (
        <FylloImage
          src={`https://ad.ipredictive.com/d/track/cvt/pixel?acct_id=46887&cache_buster=${buildTimeStamp}`}
        />
      )}
      <section className="l-main c-signin c-id-settings">
        <div className="l-maxwidth l-maxwidth--small">
          <h1>Add pick up details for faster checkout</h1>
          <p>
            We’ll need to verify your identity at the dispensary when you come
            to pick up
          </p>
          <div className="c-signin__options">
            {customer && !customer.readyToLoad && (
              <IdSettingsForm onSubmit={handleSubmit} />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(IdSettings);
