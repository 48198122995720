import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Edibles: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--edibles" version="1.1" x="0px" y="0px" viewBox="0 0 55.1 49.9">
      <g>
        <line className="i-product-type--edibles--st0" stroke={color} x1="19.2" y1="35.6" x2="19.2" y2="38.9"/>
        <path className="i-product-type--edibles--st0" stroke={color} d="M19.2,16.6c0,0,1.7,2.1,2.3,8.4c0.6,6.3-2.3,10.7-2.3,10.7s9.7-0.8,11.5-11.4c0,0-6.5,3.8-9.8,7.4"/>
        <path className="i-product-type--edibles--st0" stroke={color} d="M19.2,16.6c0,0-1.7,2.1-2.3,8.4c-0.6,6.3,2.3,10.7,2.3,10.7S9.4,34.9,7.7,24.2c0,0,6.5,3.8,9.8,7.4"/>
        <path className="i-product-type--edibles--st0" stroke={color} d="M29.1,35.4c0,0-4.2,3.6-9.9,0.2"/>
        <path className="i-product-type--edibles--st0" stroke={color} d="M9.2,35.4c0,0,4.2,3.6,9.9,0.2"/>
      </g>
      <circle className="i-product-type--edibles--st0" stroke={color} cx="40.8" cy="7.6" r="2.9"/>
      <circle className="i-product-type--edibles--st0" stroke={color} cx="52.2" cy="10.5" r="2.4"/>
      <path className="i-product-type--edibles--st0" stroke={color} d="M47.7,31c-4-0.6-7.1-3.9-7.3-8c-0.8,0.3-1.7,0.4-2.7,0.4c-4.7,0-8.5-3.8-8.5-8.5c0-2.2,0.8-4.2,2.2-5.7
        c-2.5-1.5-4.2-4.2-4.2-7.3c0-0.5,0-0.9,0.1-1.4l-0.2,0c-0.7-0.1-1.4-0.1-2.2-0.1C11.5,0.5,0.5,11.5,0.5,25c0,13.5,11,24.5,24.5,24.5
        c11.3,0,20.9-7.7,23.7-18.2L47.7,31z"/>
    </svg>
  )
}

export default Edibles;