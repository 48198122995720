import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Button from "components/Button";
import { Date } from "components/Forms/Fields";
import { TermsOfService } from "./index";

interface ProfileNameProps {
  activeStep: string;
  goNext: any;
}

function DOB({ activeStep, goNext }: ProfileNameProps) {
  const formState = {
    ...useSelector((state: RootState) => state.form.profileCreation),
  };
  const activeStepIndex = useSelector(
    (state: RootState) => state.system.profileStepIndex
  );

  const [disableButton, setDisableButton] = useState(true);
  const [isPrev, setIsPrev] = useState(false);

  const isActive = activeStep === "dob";

  useEffect(() => {
    if (!formState || !formState.values) return;
    const { month, day, year } = formState.values;
    const monthInt = parseInt(String(month), 10);
    const dayInt = parseInt(String(day), 10);
    const yearInt = parseInt(String(year), 10);
    if (
      monthInt > 0 &&
      monthInt <= 12 &&
      dayInt > 0 &&
      dayInt <= 31 &&
      yearInt > 1900 &&
      yearInt <= 2020
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [formState]);

  useEffect(() => {
    let timer: any = null;

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 1);
      }, 1000);
    } else {
      setIsPrev(activeStepIndex > 1);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPrev, activeStepIndex]);

  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? "c-profile__step--prev-" : "c-profile__step-"}
      timeout={1000}
      appear
    >
      <div className={classNames("c-profile__step")}>
        <div className="slug">About You</div>
        <h3>When were you born?</h3>
        <Date tabIndex={isActive ? 0 : -1} />
        <Button
          type="button"
          disabled={disableButton}
          onClick={goNext}
          variant=""
        >
          Next
        </Button>
        <TermsOfService />
      </div>
    </CSSTransition>
  );
}

export default DOB;
