import React from 'react'
import classNames from 'classnames'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import Button from 'components/Button'
import './KioskOptions.scss'
import { ResetInventory, SetInventoryFilter } from 'store/inventory/actions'

function KioskOptions({ history }: RouteComponentProps) {
  const dispatch = useDispatch()
  const moodSelectorOpen = useSelector((state: RootState) => state.system.moodSelectorOpen)
  const isKiosk = useSelector((state: RootState) => state.system.isKiosk)
  const embedDispensaries = useSelector((state: RootState) => state.system.embedDispensaries)
  const hasEmbedModal = !!(embedDispensaries?.medical && embedDispensaries?.recreational)

  const activeDispensary = useSelector((state: RootState) => state.cart.activeDispensary)

  function goToPromotions() {
    dispatch(ResetInventory())
    setTimeout(() => {
      dispatch(SetInventoryFilter({ flags: ['on_sale'] }))
      history.push(
        activeDispensary ? '/dispensary/' + activeDispensary + '/products' : '/locations'
      )
    }, 500)
  }

  function viewAllProducts() {
    dispatch(ResetInventory())
    setTimeout(() => {
      dispatch(SetInventoryFilter({}))
      history.push(
        activeDispensary ? '/dispensary/' + activeDispensary + '/products' : '/locations'
      )
    }, 500)
  }

  return (
    <section
      className={classNames('c-kiosk-options', {
        'c-kiosk-options--selector-open': moodSelectorOpen,
      })}
    >
      <button
        className="c-kiosk-options__big-button"
        onClick={goToPromotions}
        disabled={hasEmbedModal}
      >
        Today's Promotions
      </button>
      <button
        className="c-kiosk-options__big-button"
        onClick={viewAllProducts}
        disabled={hasEmbedModal}
      >
        View All Products
      </button>
      {!isKiosk && (
        <Button type="Link" to="/account/signin" variant="">
          Sign in to access your preferences
        </Button>
      )}
    </section>
  )
}

export default withRouter(KioskOptions)
