import React, { useEffect, useState } from "react";
import classNames from "classnames";
import auth0 from "auth0-js";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { FetchDispensaries } from "store/locations/actions";
import {
  FetchOrderHistory,
  CustomerLogout,
  FetchAccountSettings,
} from "store/customer/actions";
import Button from "components/Button";
import DispensaryLocation from "components/Dispensaries/DispensaryLocation";
import IconButton from "components/IconButton";
import LocationIcon from "components/svgs/Location";
import LockIcon from "components/svgs/Lock";
import OrderHistoryOrder from "components/Account/OrderHistory/OrderHistoryOrder";
import ProfileSetupIcon from "components/svgs/ProfileSetup";
import { LoadingDispensaryLocation } from "components/Loading";
import { GradientCardLayout2 } from "components/Gradients/Cards";
import { PlaceOrder, ResetOrderPlaced } from "store/orders/actions";
import { ResetCart } from "store/cart/actions";
import { ResetRecsPreviousData } from "store/recommendations/actions";
import FylloImage from "components/FylloImage";

import "./AccountHome.scss";

function AccountHome({ history }: RouteComponentProps) {
  const { webAuth } = window;

  const dispatch = useDispatch();
  const dispensarySlug = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const orderHistory = useSelector(
    (state: RootState) => state.customer.orderHistory
  );
  const orderHistoryEmpty = useSelector(
    (state: RootState) => state.customer.orderHistoryEmpty
  );
  const orderHistoryLoading = useSelector(
    (state: RootState) => state.customer.orderHistoryLoading
  );
  const customer: any = useSelector(
    (state: RootState) => state.customer.customer
  );
  const dispensaries = useSelector(
    (state: RootState) => state.locations.dispensaries
  );
  const authenticating = useSelector(
    (state: RootState) => state.customer.authenticating
  );
  const geoLocationDisabled = useSelector(
    (state: RootState) => state.system.geoLocationDisabled
  );
  const geoLocationDismissed = useSelector(
    (state: RootState) => state.system.geoLocationDismissed
  );
  const [janeOrderComplete, setJaneOrderComplete] = useState<boolean>(true);
  const placingOrder = useSelector(
    (state: RootState) => state.orders.placingOrder
  );
  const orderPlaced = useSelector(
    (state: RootState) => state.orders.orderPlaced
  );
  const isNewCustomer = useSelector((state: RootState) => state.customer.isNew);
  const orderError = useSelector((state: RootState) => state.orders.error);
  const ordersCount =
    useSelector((state: RootState) => state.customer?.ordersCount) || 0;

  const [janeOrderErr, setJaneOrderErr] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [activeDispensary, setActiveDispensary] = useState<any>(false);
  const [orders, setOrders] = useState<any>(false);
  const [buildTimeStamp, setBuildTimeStamp] = useState<any>(null);

  useEffect(() => {
    if (isNewCustomer) {
      setBuildTimeStamp(new Date().getTime());
    }
  }, [isNewCustomer]);

  useEffect(() => {
    if (customer?.accessToken) {
      dispatch(FetchAccountSettings({ accessToken: customer.accessToken }));
    }
    // eslint-disable-next-line
  }, []);

  //Place JANE order
  useEffect(() => {
    let forage_cart: any = localStorage.getItem("janeCart");
    if (forage_cart !== "" && forage_cart !== null) {
      forage_cart = JSON.parse(forage_cart);
    }

    forage_cart =
      typeof forage_cart !== "string" && forage_cart !== null
        ? forage_cart
        : null;
    if (
      forage_cart &&
      forage_cart !== [] &&
      !placingOrder &&
      !orderPlaced &&
      !orderError
    ) {
      dispatch(ResetCart());
      dispatch(PlaceOrder(forage_cart));
      setJaneOrderComplete(false);
    }
    if (!placingOrder && orderPlaced && !janeOrderComplete) {
      localStorage.removeItem("janeCart");
      dispatch(ResetOrderPlaced());
      setJaneOrderComplete(true);
      dispatch(FetchOrderHistory(1));
      dispatch(ResetRecsPreviousData());
    }
    if (forage_cart && orderError && orderError === "error") {
      setJaneOrderComplete(true);
      localStorage.removeItem("janeCart");
      setJaneOrderErr(true);
    }
  }, [orderPlaced, placingOrder, janeOrderComplete, orderError, dispatch]);

  useEffect(() => {
    if (
      customer?.accessToken &&
      !orderHistoryLoading &&
      !orders &&
      !orderHistoryEmpty &&
      (ordersCount === 0 || orders?.length < ordersCount)
    ) {
      dispatch(FetchOrderHistory(1));
    }
  }, [
    orders,
    orderHistoryLoading,
    orderHistoryEmpty,
    ordersCount,
    customer,
    dispatch,
  ]);

  useEffect(() => {
    if (customer && customer.readyToLoad && !authenticating) {
      dispatch(FetchAccountSettings({ accessToken: customer.accessToken }));
    }
  }, [customer, authenticating, dispatch]);

  useEffect(() => {
    if (orderHistory && !orderHistoryLoading) {
      setOrders(orderHistory.length ? orderHistory : false);
    }
  }, [orderHistory, orderHistoryLoading]);

  useEffect(() => {
    if (!customer) {
      history.replace("/account/signin");
    }
  }, [customer, history]);

  useEffect(() => {
    if (dispensaries === null && dispensarySlug) {
      dispatch(FetchDispensaries());
    }
    if (dispensaries && dispensarySlug) {
      const location = dispensaries[dispensarySlug];
      if (location) {
        setActiveDispensary(location);
      }
    }
  }, [dispensaries, dispensarySlug, dispatch]);

  useEffect(() => {
    if (!webAuth) {
      const webAuthDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";
      const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || "";
      window.webAuth = new auth0.WebAuth({
        domain: webAuthDomain,
        clientID: webAuthClientId,
      });
    }
  }, [webAuth]);

  function signout() {
    if (webAuth) {
      const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || "";
      setLoggingOut(true);
      dispatch(CustomerLogout());
      webAuth.logout({
        returnTo: window.location.origin.toString() + "/account/signin",
        clientID: webAuthClientId,
      });
    }
  }

  const CanvasIcon = () => {
    const profileFeelings = ["creative", "focused", "talkative"];
    return <GradientCardLayout2 feelings={profileFeelings} animate={false} />;
  };

  const firstOrderHandler = () => {
    if (activeDispensary?.slug) return null;
    sessionStorage.setItem("locationRedirect", "/products");
  };

  return (
    <>
      {buildTimeStamp && (
        <FylloImage
          src={`https://ad.ipredictive.com/d/track/cvt/pixel?acct_id=46887&cache_buster=${buildTimeStamp}`}
        />
      )}
      <section
        className={classNames("l-main c-account-home", {
          "c-account-home--logging-out":
            loggingOut || !customer || (customer && !customer.loaded),
        })}
      >
        {customer && (
          <div className="l-maxwidth l-maxwidth--medium">
            <h1>Hi {customer.firstName}</h1>
            <div className="c-account-home__dispensary">
              <div className="slug c-account-home__slug">
                Your active dispensary
              </div>
              {activeDispensary ? (
                <DispensaryLocation
                  dispensaryFilter="all"
                  location={activeDispensary}
                />
              ) : geoLocationDisabled ||
                geoLocationDismissed ||
                !dispensarySlug ? (
                <IconButton
                  to="/locations"
                  variant="select-dispensary"
                  type="Link"
                  Icon={LocationIcon}
                  iconColor="charcoal"
                  backgroundColor="white"
                  textColor="charcoal"
                  withCaret={true}
                >
                  Select a dispensary
                </IconButton>
              ) : (
                <LoadingDispensaryLocation />
              )}
            </div>
            <div className="c-account-home__last-order">
              {!orderHistoryLoading && janeOrderComplete ? (
                <>
                  {orders ? (
                    <>
                      <h3>Your last order</h3>
                      <OrderHistoryOrder
                        orderErr={janeOrderErr && !!orderError}
                        order={orders[0]}
                      />
                      {ordersCount && ordersCount > 1 && (
                        <Button type="Link" variant="" to="/account/orders">
                          View all past orders
                        </Button>
                      )}
                    </>
                  ) : (
                    <div className="c-account-home__no-history">
                      <h3>You have no past orders.</h3>
                      <Button
                        to={
                          activeDispensary?.slug
                            ? "/dispensary/" +
                              activeDispensary.slug +
                              "/products"
                            : "/locations"
                        }
                        type="Link"
                        variant="outlined"
                        onClick={firstOrderHandler}
                      >
                        Start your first order.
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h3>Your last order</h3>
                  <OrderHistoryOrder
                    loading={true}
                    orderErr={janeOrderErr && !!orderError}
                    showCheckoutLoder={!janeOrderComplete}
                  />
                </>
              )}
            </div>
            <div className="c-account-home__profile-account">
              <div className="c-account-home__profile">
                <h3>Your Profile</h3>
                {customer.hasProfile ? (
                  <IconButton
                    to="/account/profile"
                    variant="profile-link"
                    type="Link"
                    Icon={CanvasIcon}
                    iconColor="charcoal"
                    backgroundColor="white"
                    textColor="charcoal"
                    withCaret={true}
                  >
                    {customer.profileFirst + " " + customer.profileLast}
                  </IconButton>
                ) : (
                  <IconButton
                    to="/create-profile"
                    variant="profile-link"
                    type="Link"
                    Icon={ProfileSetupIcon}
                    iconColor="charcoal"
                    backgroundColor="white"
                    textColor="charcoal"
                    withCaret={true}
                  >
                    Set up your profile
                  </IconButton>
                )}
              </div>
              <div className="c-account-home__account">
                <h3>Account Settings</h3>
                <div className="c-account-home__account-table">
                  <div className="c-account-home__account-row">
                    <div className="c-account-home__account-row__label">
                      Name
                    </div>
                    <div className="c-account-home__account-row__value">
                      <LockIcon />
                      <span>{customer.fullName}</span>
                    </div>
                  </div>
                  <div className="c-account-home__account-row">
                    <div className="c-account-home__account-row__label">
                      Email
                    </div>
                    <div className="c-account-home__account-row__value">
                      <LockIcon />
                      <span>{customer.email}</span>
                    </div>
                  </div>
                  <div className="c-account-home__account-row">
                    <div className="c-account-home__account-row__label">
                      ID Document
                    </div>
                    <div className="c-account-home__account-row__value">
                      <LockIcon />
                      <span>
                        {!customer.documentType || customer.documentType === ""
                          ? "----"
                          : customer.documentType === "PASSPORT"
                          ? "Passport"
                          : "Driver's License"}
                      </span>
                    </div>
                  </div>
                  <div className="c-account-home__account-row">
                    <div className="c-account-home__account-row__label">
                      ID Number
                    </div>
                    <div className="c-account-home__account-row__value">
                      <LockIcon />
                      <span>
                        {!customer.documentType || customer.documentType === ""
                          ? "----"
                          : "********"}
                      </span>
                    </div>
                  </div>
                </div>
                <Link
                  to="/account/settings"
                  className="c-account-home__settings-link"
                >
                  Edit account settings
                </Link>
                <button className="c-account-home__signout" onClick={signout}>
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default withRouter(AccountHome);
