import React from "react";
import { Discount } from "../../../constants/inventory";
import "./PDPPrice.scss";

interface PDPPriceProps {
  unitPrice: any;
  discounts?: Discount[];
}

export const calculateFinalPrice = (
  unitPrice: number,
  discounts: Discount[]
) => {
  if (!discounts) {
    return unitPrice;
  }

  return discounts.reduce(
    (acc, { type, method, amount, minimumItemsRequired }) => {
      let discountAmount =
        type.toLowerCase() === "percent" ? unitPrice * amount : amount;
      if (minimumItemsRequired !== null && minimumItemsRequired > 0) {
        return acc;
      } else if (method && method === "Discount To Amount" && type === "Flat") {
        return amount;
      } else if (
        method &&
        method === "Price To Amount" &&
        type === "Price To Amount"
      ) {
        return amount;
      } else {
        let newDiscAmount: any = Number(acc - discountAmount)
          ? Number(acc - discountAmount).toFixed(2)
          : acc - discountAmount;
        return newDiscAmount;
      }
    },
    unitPrice
  );
};

function PDPPrice({ unitPrice, discounts }: PDPPriceProps) {
  if (
    !discounts ||
    !discounts.length ||
    calculateFinalPrice(unitPrice, discounts) === unitPrice
  ) {
    return <span>${unitPrice}</span>;
  }

  return (
    <>
      <span className="c-pdp-price__old-price">${unitPrice}</span>
      <span className="c-pdp-price__discount">
        ${calculateFinalPrice(unitPrice, discounts)}
      </span>
    </>
  );
}

export default PDPPrice;
