import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Focused: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--focused" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <circle className="i-feeling--focused--st0" stroke={color} cx="207.3" cy="207.3" r="131"/>
        <circle className="i-feeling--focused--st0" stroke={color} cx="207.3" cy="207.3" r="109.7"/>
        <circle fill={color} cx="207.3" cy="207.3" r="29.5"/>
      </g>
    </svg>
  )
}

export default Focused;