import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { RootState } from 'store'
// import { CustomerLogout }     from 'store/customer/actions';
import { SetResetKioskTimer } from 'store/system/actions'
import { ResetCart } from 'store/cart/actions'
import { ResetRecsPreviousData } from 'store/recommendations/actions'
import Button from 'components/Button'
import './KioskTimer.scss'

function KioskTimer() {
  // const { webAuth }           = window;
  const dispatch = useDispatch()

  const resetKioskTimer = useSelector((state: RootState) => state.system.resetKioskTimer)

  const [displayTimeout, setDisplayTimeout] = useState<any>(null)
  const [displayTimer, setDisplayTimer] = useState(false)
  const [renderTimer, setRenderTimer] = useState(false)
  const [timeState, setTimeState] = useState<any>(30)
  const [timerInterval, setTimerInterval] = useState<any>(null)

  const timeRemaining = useRef(30)

  // const logout = useCallback(() => {
  //   const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || '';
  //   dispatch(CustomerLogout());
  //   webAuth.logout({
  //     returnTo: window.location.origin.toString() + '/',
  //     clientID: webAuthClientId
  //   });
  // }, [dispatch, webAuth]);

  // useEffect(() => {
  //   if (location?.pathname === "/order") {
  //     setTimerDuration(60000);
  //   } else {
  //     setTimerDuration(RESET_TIME);
  //   }
  // }, [location, timerDuration]);

  const decreaseTimer = useCallback(() => {
    if (timeRemaining.current === 0) {
      // logout();
      sessionStorage.removeItem('forage_token_timestamp')
      sessionStorage.removeItem('forage_token')
      dispatch(ResetCart())
      dispatch(ResetRecsPreviousData())
      window.location.href = '/'
      // history.push("/");
    } else {
      timeRemaining.current = timeRemaining.current - 1
      setTimeState(timeRemaining.current)
    }
  }, [timeRemaining, dispatch])

  const startTimer = useCallback(() => {
    setDisplayTimeout(
      setTimeout(() => {
        setRenderTimer(true)
        setDisplayTimer(true)
      }, 60000)
    )
  }, [setDisplayTimeout, setDisplayTimer, setRenderTimer])

  const restartTimer = useCallback(() => {
    clearTimeout(displayTimeout)
    clearInterval(timerInterval)
    setDisplayTimer(false)
    setTimerInterval(null)
    startTimer()
    timeRemaining.current = 10
    setTimeout(() => {
      setRenderTimer(false)
      setTimeState(10)
    }, 2000)
  }, [displayTimeout, timerInterval, timeRemaining, startTimer])

  useEffect(() => {
    if (!displayTimeout) {
      startTimer()
    }
  }, [displayTimeout, startTimer])

  // useEffect(() => {
  //   if(!webAuth){
  //     const webAuthDomain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  //     const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || '';
  //     window.webAuth = new auth0.WebAuth({
  //       domain:       webAuthDomain,
  //       clientID:     webAuthClientId
  //     });
  //   }
  // }, [webAuth]);

  useEffect(() => {
    if (resetKioskTimer) {
      restartTimer()
      dispatch(SetResetKioskTimer(false))
    }
  }, [resetKioskTimer, restartTimer, dispatch])

  useEffect(() => {
    if (displayTimer && !timerInterval) {
      setTimerInterval(setInterval(decreaseTimer, 1000))
    }
  }, [displayTimer, timerInterval, decreaseTimer])

  return (
    <>
      {renderTimer && (
        <CSSTransition in={displayTimer} classNames="c-kiosk-timer-" timeout={2000} appear>
          <div className="c-kiosk-timer">
            <div className="c-kiosk-timer__inner">
              <div className="c-kiosk-timer__wrapper">
                <div className="slug">Logging out in...</div>
                <div className="c-kiosk-timer__time-remaining">{timeState}</div>
              </div>

              {/* <Button
                type="Link"
                variant="logout-now"
                to={
                  activeDispensary
                    ? `/dispensary/${activeDispensary}/products`
                    : kioskDomain
                }
              >
                Continue shopping
              </Button> */}
              <Button type="button" variant="outlined" onClick={restartTimer}>
                Continue Foraging
              </Button>
            </div>
          </div>
        </CSSTransition>
      )}
    </>
  )
}

export default KioskTimer
