import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { Input } from "components/Forms/Fields";
import { RootState } from "store";
import CheckmarkIcon from "components/svgs/Checkmark";
import { TermsOfService } from "./index";

interface ProfileTreatmentsProps {
  activeStep: string;
  goNext: any;
  change: any;
  saveStep: boolean;
  config: Array<string>;
}

function Treatments({
  activeStep,
  goNext,
  change,
  saveStep,
  config,
}: ProfileTreatmentsProps) {
  const preferences: any = useSelector(
    (state: RootState) => state.profile.preferences
  );

  const [isPrev, setIsPrev] = useState(false);
  const [saved, setSaved] = useState(false);
  const [selectedTreatments, setSelectedTreatments] = useState<any>(null);
  const [treatmentsArray, setTreatmentsArray] = useState<any>(null);

  const { treatmentGoals } = preferences || {};
  const isActive = activeStep === "treatments";

  useEffect(() => {
    if (config && !selectedTreatments && !treatmentsArray) {
      let treatArray: any = [];
      let selectedTreats: any = {};
      config.forEach((treatment: any) => {
        treatArray.push({
          id: treatment.slug,
          label: treatment.name,
        });
        selectedTreats[treatment.slug] =
          treatmentGoals && treatmentGoals.indexOf(treatment.slug) >= 0;
      });
      setTreatmentsArray(treatArray);
      setSelectedTreatments(selectedTreats);
    }
  }, [config, selectedTreatments, treatmentsArray, treatmentGoals]);

  useEffect(() => {
    if (saveStep && isActive && !saved) {
      setSaved(true);
      let treatmentValue: any = [];
      Object.keys(selectedTreatments).forEach((treatment: any) => {
        if (selectedTreatments[treatment]) {
          treatmentValue.push(treatment);
        }
      });
      change("treatmentGoals", treatmentValue);
      goNext();
    }
  }, [saveStep, isActive, saved, change, goNext, selectedTreatments]);

  const activeStepIndex = useSelector(
    (state: RootState) => state.system.profileStepIndex
  );
  useEffect(() => {
    let timer: any = null;

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 6);
      }, 1000);
    } else {
      setIsPrev(activeStepIndex > 6);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPrev, activeStepIndex]);

  useEffect(() => {
    if (!isActive && saved) {
      setSaved(false);
    }
  }, [saved, isActive]);

  function selectTreatment(value: string) {
    if (!isActive) return;
    let newTreatments: any = { ...selectedTreatments };
    if (newTreatments[value]) {
      newTreatments[value] = false;
    } else {
      newTreatments[value] = true;
    }
    setSelectedTreatments({ ...newTreatments });
  }

  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? "c-profile__step--prev-" : "c-profile__step-"}
      timeout={1000}
      appear
    >
      <div className={classNames("c-profile__step")}>
        <div className="slug">Question 6 of 8</div>
        <h3>What are you looking to treat?</h3>
        <Field
          component={Input}
          name="treatmentGoals"
          type="hidden"
          label={""}
          required
          className="firstName__input"
        />
        <div className="c-mood-selector__occasions">
          {treatmentsArray &&
            selectedTreatments &&
            treatmentsArray.map((treatment: any) => {
              return (  
                <button
                  tabIndex={isActive ? 0 : -1}
                  key={treatment.id}
                  onClick={() => selectTreatment(treatment.id)}
                  className={classNames("c-mood-selector__occasion", {
                    "c-mood-selector__occasion--selected":
                      selectedTreatments[treatment.id],
                  })}
                >
                  <span>{treatment.label}</span>
                  <div className="c-mood-selector__occasion__check">
                    <CheckmarkIcon variant="white" />
                  </div>
                </button>
              );
            })}
            <TermsOfService />
        </div>
      </div>
    </CSSTransition>
  );
}

export default Treatments;
