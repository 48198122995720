import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const Close: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-close-icon" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.36475 10.6352L11 2" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2 2.00014L10.6352 10.6354" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Close;