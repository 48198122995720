import React, { useRef, useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { throttle } from "throttle-debounce";
import { InventoryProps } from "constants/inventory";
import { RootState } from "store";
import {
  SetInventoryChanging,
  SetInventoryFilter,
  SetInventoryPage,
  SetSearch,
} from "store/inventory/actions";
import Button from "components/Button";
import { LoadingProductCard } from "components/Loading";
import ProductCard from "components/ProductCard";
import InventoryPagination from "components/Inventory/InventoryPagination";
import "./InventoryGrid.scss";

interface gridProps {
  dispensarySlug: string;
}

function InventoryGrid({ dispensarySlug }: gridProps) {
  const dispatch = useDispatch();
  const isDesktop = useSelector((state: RootState) => state.system.isDesktop);
  const inventoryState = useSelector((state: RootState) => state.inventory);
  const {
    loadingInventory,
    inventoryIds,
    inventory,
    noResults,
    inventoryChanging,
  } = inventoryState;

  let itemRefArray = useRef<any>([]);
  let itemRefs = useRef<any>([]);
  const gridRef: any = useRef();

  const [activeItem, setActiveItem] = useState<any>(null);
  const [transitioning, setTransitioning] = useState(false);
  const [inventoryLoaded, setInventoryLoaded] = useState(
    inventoryIds && inventoryIds.length > 0
  );
  const [showingNoResults, setShowingNoResults] = useState(noResults);
  const [initialLoad, setInitialLoad] = useState(inventoryIds.length === 0);

  const onInventoryLoad = useCallback(() => {
    setTransitioning(true);

    const loadedTimeout = setTimeout(() => {
      setInitialLoad(false);
      setTransitioning(false);
    }, 250);

    return () => clearTimeout(loadedTimeout);
  }, []);

  useEffect(() => {
    if (initialLoad || inventoryChanging) {
      onInventoryLoad();
    }
  }, [initialLoad, inventoryChanging, onInventoryLoad]);

  useEffect(() => {
    if (loadingInventory) {
      itemRefArray.current = [];
      itemRefs.current = [];
      setInventoryLoaded(false);
      dispatch(SetInventoryChanging(true));
    }
  }, [loadingInventory, dispatch]);

  useEffect(() => {
    if (inventoryIds && inventoryIds.length) {
      setInventoryLoaded(true);
      dispatch(SetInventoryChanging(false));
    }
  }, [inventoryIds, dispatch]);

  useEffect(() => {
    setShowingNoResults(noResults);
  }, [noResults]);

  function closest(array: any, number: number) {
    var num = 0;
    for (var i = array.length - 1; i >= 0; i--) {
      if (
        Math.abs(number - array[i].position) <
        Math.abs(number - array[num].position)
      ) {
        num = i;
      }
    }
    return num;
  }

  const watchScroll = useCallback(() => {
    if (isDesktop || itemRefs.current.length === 0) return;

    const scrollTop = window.scrollY + window.innerHeight / 2 - 200;
    const positions: any = [];

    itemRefs.current.forEach((itemRef: any) => {
      positions.push({ position: itemRef.offsetTop, element: itemRef });
    });

    var getClosest = closest(positions, scrollTop);
    setActiveItem(itemRefArray.current[getClosest]);
  }, [isDesktop]);

  useEffect(() => {
    const scrollFn = throttle(200, false, watchScroll);
    window.addEventListener("scroll", scrollFn);

    return () => {
      window.removeEventListener("scroll", scrollFn);
    };
  }, [watchScroll]);

  const resetEverything = useCallback(() => {
    setTransitioning(true);
    const timeout = setTimeout(() => {
      setInventoryLoaded(false);
      setTransitioning(false);
      dispatch(SetSearch(""));
      dispatch(SetInventoryFilter({}));
      dispatch(SetInventoryPage(1));
      setShowingNoResults(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [dispatch]);

  function addToRef(el: any, productId: string) {
    if (
      el &&
      itemRefArray.current.indexOf(productId) < 0 &&
      !loadingInventory &&
      !transitioning
    ) {
      itemRefArray.current = [...itemRefArray.current, productId];
      itemRefs.current = [...itemRefs.current, el];
    }
  }
  return (
    <section
      className={classNames("c-inventory-grid", {
        "c-inventory-grid--transitioning": transitioning,
        "c-inventory-grid--no-results": showingNoResults,
      })}
      ref={gridRef}
    >
      <div className="c-inventory-grid__inner">
        {showingNoResults && !loadingInventory ? (
          <div className="c-inventory-grid__no-results">
            <h1>No Results Found</h1>
            <Button type="button" onClick={resetEverything} variant="outlined">
              Reset Filters and Search
            </Button>
          </div>
        ) : inventoryLoaded ? (
          inventoryIds.map((inventoryId: number, index: number) => {
            const item: InventoryProps = inventory[inventoryId];
            const { product } = item;

            if (product) {
              return (
                <div
                  key={product?.sku + index}
                  ref={(el) => addToRef(el, product?.productId)}
                >
                  <ProductCard
                    dispensarySlug={dispensarySlug}
                    showSVG={true}
                    isActive={product?.productId === activeItem}
                    index={index}
                    product={product}
                    item={item}
                  />
                </div>
              );
            }

            return null;
          })
        ) : (
          [...Array(16)].map((e, i) => (
            <LoadingProductCard key={"loading" + i} />
          ))
        )}
      </div>
      <InventoryPagination />
    </section>
  );
}

export default InventoryGrid;
