import { RootState } from "store";


const SORT_ALPHA_ASC = 'alpha_ascending';
const SORT_ALPHA_DESC = 'alpha_descending';
const SORT_PRICE_ASC = 'price_ascending';
const SORT_PRICE_DESC = 'price_descending';

export const recPreferences = (state:RootState) => state.recs.recPreferences;
export const top10Recs = (state:RootState) => state.recs.recs;
export const remainingRecs = (state:RootState) => state.recs.remainingRecsObj;
export const recommendationPreferences = (state:RootState) => state.recs.recPreferences;
export const top10RecsArray = (state:RootState) => state.recs.recProductIds;
export const remainingRecsArray = (state:RootState) => state.recs.remainingRecsArray;

export const selectSearchQuery = (state:RootState) => state.recs.searchQuery;
export const selectSort = (state:RootState) => state.recs.sort;
export const selectLoading = (state:RootState) => state.recs.recsLoading;

export const selectRecsCount = (state:RootState) => state.recs.recProductIds.length + state.recs.remainingRecsArray.length;

const isIndexOf = (query = '') => (value = '') => !!(value && value.toLowerCase().indexOf(query.toLowerCase()) !== -1);

const filterBy = (query: any, allRecs: any) => {
  const matchesSearch = isIndexOf(query);
  return allRecs.filter((rec:any) => {
    const { productName, category, brandName, vendorName } = rec.product;
    return (matchesSearch(productName) || matchesSearch(category) || matchesSearch(brandName) || matchesSearch(vendorName));
  });
};

const sortBy= (sort: string) => (productA:any, productB:any) => {
  if (sort === SORT_ALPHA_ASC) {
    return productA.product.productName.localeCompare(productB.product.productName);
  } else if (sort === SORT_ALPHA_DESC) {
    return productB.product.productName.localeCompare(productA.product.productName);
  } else if (sort === SORT_PRICE_ASC) {
    return productA.unitPrice - productB.unitPrice;
  } else if (sort === SORT_PRICE_DESC) {
    return productB.unitPrice - productA.unitPrice
  } else {
    return 0
  }
};

const applyFilters = (products: any, sort: string) => {
  const productKeys = Object.keys(products);
  return productKeys.map(key => products[key])
    .sort(sortBy(sort));
};

const getSlicedResults = (recs: string | any[], page: number) => {
  const resultsIndex = page * 16;
  if (page === 1) {
    return recs.slice(0, 16);
  }

  if (page === Math.ceil(recs.length / 16)) {
    return recs.slice(resultsIndex - 16, resultsIndex);
  }

  return recs.slice(resultsIndex, resultsIndex + 16)
};

export const selectAllRecs = (state:RootState) => {
  const { sort, page, searchQuery: search } = state.recs;

  if (!state.recs.recs) {
    return [];
  }

  const allRecs = applyFilters({
    ...state.recs.recs,
    ...state.recs.remainingRecsObj,
  }, sort);

  if (search.trim() !== '') {
    return filterBy(search, allRecs);
  }

  return getSlicedResults(allRecs, page);
};

export const selectPage = (state: RootState) => state.recs.page;