import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Hungry: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling-hungry" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <path className="i-feeling--hungry--st0" stroke={color} fill={colors[variant]} d="M123.5,136.7c18.1-18.1,63,3.7,69.3,28.9s-6,23.9,29.4,55.7s55.1,49.4,45.2,59.3c-10,10-27.6-9.7-59.3-45.2
          s-30.5-23.1-55.7-29.4S105.4,154.9,123.5,136.7z"/>
        <path className="i-feeling--hungry--st1" stroke={color} fill="none" d="M291.1,138c18.1,18.1-3.7,63-28.9,69.3s-23.9-6-55.7,29.4s-49.4,55.1-59.3,45.2c-10-10,9.7-27.6,45.2-59.3
          s23.1-30.5,29.4-55.7C228.1,141.7,273,119.9,291.1,138z"/>
        <line className="i-feeling--hungry--st1" stroke={color} x1="252.3" y1="157.6" x2="278.1" y2="131.8"/>
        <line className="i-feeling--hungry--st1" stroke={color} x1="261.9" y1="167.2" x2="291.1" y2="138"/>
        <line className="i-feeling--hungry--st1" stroke={color} x1="271.6" y1="176.8" x2="297.4" y2="151"/>
      </g>
    </svg>
  )
}

export default Hungry;