import React, {useCallback, useEffect, useState, useRef} from 'react';
import './PDPGoodFor.scss';

interface GoodForProps {
  goodFor?: any,
  animate: boolean
}

const ANIMATE_TIME = 1250;

const INDICATOR_WIDTH = 34;

function PDPGoodFor({
                      goodFor,
                      animate
                    }: GoodForProps) {

  const barRef = useRef<HTMLDivElement>(null);
  const [actuallyAnimate, setActuallyAnimate] = useState(false);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  const setCounts = [setCount1, setCount2, setCount3];

  const requestId: number[] = [];

  const animateValue = useCallback((setState: any, start: number, end: number, duration: number, index: number) => {

    let startTime = 0;
    let current = start;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / range));


    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;

      const elapsedTime = timestamp - startTime;
      if (elapsedTime >= stepTime) {
        current += increment * Math.min(end - current, elapsedTime / stepTime);
        setState(Math.round(current));
        startTime = timestamp;
      }

      if (current !== end) {
        requestId[index] = requestAnimationFrame(animate);
      }
    };

    requestId[index] = requestAnimationFrame(animate);


    return () => {
      requestId.forEach((id: any, index: number) => {
        cancelAnimationFrame(id);
        requestId[index] = 0;
      });
    };
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (animate && !actuallyAnimate) {
      goodFor.forEach((activity: any, index: number) => {
        if (!requestId[index]) {
          animateValue(setCounts[index], 0, Math.min(1000, Math.round(activity.percentage)), ANIMATE_TIME, index);
          setActuallyAnimate(animate);
        }
      });
    }
  }, [animate, actuallyAnimate, animateValue, goodFor, requestId, setCounts]);


  return (
    <section className="c-pdp-good-for">
      <div className="slug">Good For</div>
      <div className="c-pdp-good-for__measurements" ref={barRef}>
        {
          goodFor?.map((measurement: any, index: number) => {

            const style = {
              opacity: actuallyAnimate ? 1 : 0,
              transform: actuallyAnimate && barRef.current ? `translate(${Math.min(100, measurement.percentage) * (barRef.current.offsetWidth - INDICATOR_WIDTH) / 100}px)` : 0
            };

            return (
              <div className="c-pdp-good-for__measurement" key={index}>
                <div className="c-pdp-good-for__topper">
                  <span className="c-pdp-good-for__mesaurement__title">
                    {measurement.title}
                  </span>
                  <span className="c-pdp-good-for__mesaurement__percentage">
                    {
                      index === 0 ?
                        count1
                        : index === 1 ?
                        count2
                        :
                        count3
                    }%
                  </span>
                </div>
                <div className="c-pdp-good-for__progress">
                  <div className="c-pdp-good-for__progress__inner">
                    <div className="c-pdp-good-for__progress__indicator"
                         style={style as React.CSSProperties}></div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </section>
  )
}

export default PDPGoodFor;