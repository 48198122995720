import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import './CardGradients.scss';
import Noise from 'images/noise.png';
import {isIOS, isSafari} from 'react-device-detect';
import feelingColors from 'sass/utils/feelingColors.module.scss';
import chroma from 'chroma-js';
import { renderGradientPlaceholder } from "./index";

const BLUR = 20;

function blob1(ctx: any, productColors: any) {

  if (!productColors[1]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[1]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[1]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[1]]).alpha(0.0).css();

  // blob1/Path
  ctx.save();
  ctx.beginPath();
  ctx.bezierCurveTo(947.7, 182.3, 883.9, 218.6, 787.0, 300.4);
  ctx.bezierCurveTo(690.0, 382.1, 114.7, 256.3, 33.0, 159.4);
  ctx.bezierCurveTo(-48.8, 62.5, 111.4, 4.1, 356.0, 1.4);
  ctx.bezierCurveTo(533.0, -0.6, 784.2, -11.5, 866.0, 85.4);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  gradient = ctx.createRadialGradient(113.9, 69.1, 0.0, 113.9, 69.1, 727.3);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}


function blob3(ctx: any, productColors: any) {

  if (!productColors[1]) {
    return
  }

  var gradient;

  const colorStep1 = chroma(feelingColors[productColors[1]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[1]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[1]]).alpha(0.0).css();
  // blob3/Path
  ctx.save();
  ctx.beginPath();
  ctx.bezierCurveTo(811.2, 315.5, 620.1, 463.2, 510.0, 400.4);
  ctx.bezierCurveTo(399.8, 337.6, -51.8, 399.5, 11.0, 289.4);
  ctx.bezierCurveTo(73.7, 179.2, 418.8, 133.6, 529.0, 196.4);
  ctx.bezierCurveTo(639.1, 259.2, 936.7, 95.2, 874.0, 205.4);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  ctx.transform(-0.133, -0.991, -0.991, 0.133, 310.9, 8281.9);
  gradient = ctx.createRadialGradient(8007.3, -1222.0, 0.0, 8007.3, -1222.0, 686.1);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

function blob4(ctx: any, productColors: any) {

  if (!productColors[2]) {
    return
  }

  var gradient;

  const colorStep1 = chroma(feelingColors[productColors[2]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[2]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[2]]).alpha(0.0).css();
  // blob3/Path
  ctx.save();
  ctx.beginPath();
  ctx.bezierCurveTo(962.0 / 3, 555.2 / 3, 736.7 / 3, 669.4 / 3, 500.0 / 3, 669.4 / 3);
  ctx.bezierCurveTo(263.2, 669.4 / 3, 250.0, 629.4, 235.0, 551.4);
  ctx.bezierCurveTo(220.0, 473.4 / 3, 294.0, 394.4, 491.0, 365.4);
  ctx.bezierCurveTo(725.2, 330.9 / 3, 962.0 / 3, 175.6 / 3, 962.0 / 3, 365.4 / 3);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  ctx.transform(1.247, 0.000, 0.000, -1.000, -1061.0, 8.5);
  gradient = ctx.createRadialGradient(1176.5, -364.4, 0.0, 1176.5, -364.4, 315.2);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

function blob5(ctx: any, productColors: any) {

  if (!productColors[1]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[1]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[1]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[1]]).alpha(0.0).css();
  // blob3/Path
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(699.8, 310.0);
  ctx.bezierCurveTo(723.5, 185.4, 748.3, 116.8, 872.9, 140.6);
  ctx.bezierCurveTo(997.4, 164.3, 1079.2, 284.5, 1055.4, 409.1);
  ctx.bezierCurveTo(1031.7, 533.6, 682.3, 796.6, 557.7, 772.9);
  ctx.bezierCurveTo(433.2, 749.2, 676.1, 434.5, 699.8, 310.0);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.save();
  ctx.transform(0.446, 0.895, 0.895, -0.446, 6027.2, -1159.2);
  gradient = ctx.createRadialGradient(-740.1, -5457.0, 0.0, -740.1, -5457.0, 527.3);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

interface GCL3Props {
  feelings: Array<string>
  animate: boolean
}

function GradientCardLayout3({feelings, animate}: GCL3Props) {
  const gradRef = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLCanvasElement>(null);
  const [ref1Transform, setRef1Transform] = useState('');
  const [productColors, setProductColors] = useState<any>([]);

  useEffect(() => {
    if (productColors !== null && productColors.length === 0 && feelings !== null && feelings.length > 0) {
      setProductColors(feelings);
    }
  }, [productColors, feelings]);

  function animateRef1() {
    const scaleInt = 1 + (Math.random() * (100 - 0) + 0) / 100;
    const rotateInt = 10 * (Math.random() * (60 - 0) + 0) / 100;
    setRef1Transform('scale(' + scaleInt + ') rotate(' + rotateInt + 'deg)');
  }

  useEffect(() => {
    let animationId = 0;

    if (ref1.current) {
      var ctx = ref1.current.getContext("2d");
      if (ctx) {
        animationId = requestAnimationFrame(() => {
          blob1(ctx, productColors);
          blob3(ctx, productColors);
          blob4(ctx, productColors);
          blob5(ctx, productColors);
          animateRef1();
        });
      }
    }

    return () => cancelAnimationFrame(animationId);

  }, [productColors]);

  return (
    <>
      {renderGradientPlaceholder(productColors)}
      {
        productColors && productColors.length &&
        <div className="c-card-gradient c-card-gradient--layout-3"
             style={{backgroundColor: feelingColors[productColors[0]]}} ref={gradRef}>
          <div style={{backgroundImage: 'url(' + Noise + ')'}}
               className={classNames('c-card-gradient__noise', {'c-card-gradient__noise--safari': isIOS || isSafari})}></div>
          <canvas style={{transform: animate ? ref1Transform : ''}}
                  className={classNames('', {'c-card-gradient--safari-blur': isIOS || isSafari})} width="1060"
                  height="378" ref={ref1}></canvas>
        </div>
      }
    </>
  )
}

export default GradientCardLayout3;