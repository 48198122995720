import React                                          from 'react';
import { connect }                                    from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps }  from 'redux-form';
import Button                                   from 'components/Button';
import { Input }                                from 'components/Forms/Fields';
import { ForgotPasswordValidator as validate }  from 'components/Forms/Validators';
import { EMAIL_MAX_LENGTH } from "components/Forms/Fields/constants";
import './ForgotPasswordForm.scss';

function ForgotPasswordForm ({ 
  handleSubmit, 
  invalid 
}: InjectedFormProps) {
  return (
    <Form className="c-forgot-password-form" autoComplete="off" onSubmit={handleSubmit}>
      <Field 
        component={Input} 
        name="email" 
        type="text"
        label={"Email"}
        required
        className="firstName__input"
        maxlength={EMAIL_MAX_LENGTH}
      />
      <Button disabled={invalid} variant={"login"} type="submit">
        {'Send Password Reset Email'}
      </Button>
    </Form>
  )
}

export default connect()(reduxForm({
  form: 'forgotPasswordForm',
  validate
})(ForgotPasswordForm));
