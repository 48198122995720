import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setKioskDispensary } from '../../store/kiosk/actions'
import { SetForageEmbed, SetForageEmbedDispensaryTypes } from '../../store/system/actions'
import { RootState } from 'store'
import { SetActiveDispensary } from 'store/cart/actions'

interface SetDispensaryProps {
  history: any
  match: {
    params: {
      dispensary?: string
    }
  }
  location: {
    search?: string
  }
}

function SetDispensary({
  history,
  match: {
    params: { dispensary },
  },
  location: { search },
}: SetDispensaryProps) {
  const dispatch = useDispatch()
  const medicalDispensaries = useSelector((state: RootState) => state.cart.medicalDispensaries)
  const recreationalDispensaries = useSelector(
    (state: RootState) => state.cart.recreationalDispensaries
  )
  const loadingSetDispensaries = useSelector(
    (state: RootState) => state.locations.loadingSetDispensaries
  )

  const [showOverlay, setShowOverlay] = useState(false)
  const [loadingSuccess, setLoadingSuccess] = useState(loadingSetDispensaries)

  useEffect(() => {
    if (loadingSetDispensaries) {
      setLoadingSuccess(true)
    }
  }, [loadingSetDispensaries])

  useEffect(() => {
    const setDispAndRedirect = (dispensarySlug: string) => {
      dispatch(setKioskDispensary(dispensarySlug))
      dispatch(SetActiveDispensary(dispensarySlug))
      history.push('/')
    }

    ;(async () => {
      const query = new URLSearchParams(search)
      const paramField = query.get('embed')
      const isEmbed = paramField && paramField === 'true'
      if (isEmbed) {
        await dispatch(SetForageEmbed(true))
      } else {
        await dispatch(SetForageEmbed(false))
      }

      if (loadingSuccess && dispensary) {
        if (isEmbed) {
          let medicalDisp: any = null
          let recreationalDisp: any = null
          setShowOverlay(true)

          medicalDisp =
            medicalDispensaries?.find((element: string) => {
              if (element.includes(dispensary)) {
                return true
              }
            }) || null

          recreationalDisp =
            recreationalDispensaries?.find((element: string) => {
              if (element.includes(dispensary)) {
                return true
              }
            }) || null

          if (medicalDisp === recreationalDisp) {
            setDispAndRedirect(medicalDisp)
          } else if (medicalDisp && recreationalDisp) {
            const embedDispensaries = {
              medical: medicalDisp,
              recreational: recreationalDisp,
            }
            await dispatch(SetForageEmbedDispensaryTypes({ ...embedDispensaries }))
            history.push('/')
          } else if (medicalDisp || recreationalDisp) {
            if (medicalDisp) {
              dispatch(setKioskDispensary(medicalDisp))
              dispatch(SetActiveDispensary(medicalDisp))
            }
            if (recreationalDisp) {
              dispatch(setKioskDispensary(recreationalDisp))
              dispatch(SetActiveDispensary(recreationalDisp))
            }
            dispatch(SetForageEmbedDispensaryTypes({ recreational: null, medical: null }))
            history.push('/')
          }
        } else {
          setDispAndRedirect(dispensary)
          window.location.reload()
        }
      }
    })()
  }, [
    dispensary,
    dispatch,
    history,
    search,
    medicalDispensaries,
    recreationalDispensaries,
    loadingSuccess,
  ])

  return showOverlay ? <h2>Loading...</h2> : null
}

export default withRouter(SetDispensary)
