import React from "react";
import { 
  Aroused, 
  Creative,
  Energetic,
  Euphoric,
  Focused,
  Giggly,
  Happy,
  Hungry,
  Relaxed,
  Sleepy,
  Talkative,
  Tingly,
  Uplifted
} from 'components/svgs/Feelings';
interface FeelingIconProps {
  feelingId: string
}
function FeelingsIcon ({feelingId}:FeelingIconProps) {
  function Icon() {
    switch(feelingId) {
      case 'Aroused':
        return(<Aroused />);
      case 'Creative':
        return(<Creative />);
      case 'Energetic':
        return(<Energetic />);
      case 'Euphoric':
        return(<Euphoric />);
      case 'Focused':
        return(<Focused />);
      case 'Giggly':
        return(<Giggly />);
      case 'Happy':
        return(<Happy />);
      case 'Hungry':
        return(<Hungry />);
      case 'Relaxed':
        return(<Relaxed />);
      case 'Sleepy':
        return(<Sleepy />);
      case 'Talkative':
        return(<Talkative />);
      case 'Tingly':
        return(<Tingly />);
      case 'Uplifted':
        return(<Uplifted />);
      default:
        return(<div></div>);
    }
  }

  return (
    <Icon/>
  )
}

export default FeelingsIcon;