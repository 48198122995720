import React, { useEffect, useState } from 'react';
import { CSSTransition }              from 'react-transition-group';
import { useSelector, useDispatch }   from 'react-redux';
import { RootState }      from 'store';
import { SetDrawerOpen }  from 'store/system/actions';
import SortForm           from './SortForm';
import CloseIcon          from 'components/svgs/Close';
import '../FiltersSort.scss';
import './Sort.scss';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

interface SortProps {
  display:      boolean
  closeSort:    any
  setSort:      any
  sortSetting: string
}
 
function Sort({ 
  display, 
  closeSort, 
  setSort, 
}:SortProps) {
  const dispatch        = useDispatch();
  const sortValues:any  = useSelector(( state: RootState ) => state.form.inventorySort);

  const [showSort, setShowSort]       = useState(false);
  const [currentSort, setCurrentSort] = useState<any>(false);
  const [includeSort, setIncludeSort] = useState(false);

  useEffect(() => {
    const contentElement = document.querySelector('.c-inventory-filters-sort__content');

    if (contentElement) {
      if (showSort) {
        disableBodyScroll(contentElement);
      } else {
        enableBodyScroll(contentElement);
      }
    }

    return () => clearAllBodyScrollLocks();
  }, [showSort]);
  
  useEffect(() => {
    let hideTimeout:any;
    if(display){
      dispatch(SetDrawerOpen(true));
    } else {
      hideTimeout = setTimeout(() => {
        dispatch(SetDrawerOpen(false));
      }, 1000);
    }
    return function __cleanup() {
      clearTimeout(hideTimeout);
    }
  }, [display, dispatch]);

  useEffect(() => {
    if(sortValues && sortValues.initial.sort !== sortValues.values.sort && sortValues.values.sort !== currentSort){
      setCurrentSort(sortValues.values.sort);
      setSort(sortValues.values.sort);
    }
  },[sortValues, setSort, currentSort]);

  useEffect(() => {
    let displayTimeout:any;
    if(display){
      setIncludeSort(true);
      setShowSort(true);
    } else {
      setShowSort(false);
      displayTimeout = setTimeout(() => {
        setIncludeSort(false);
      }, 1000);
    }
    return function __cleanup() {
      clearTimeout(displayTimeout);
    }
  }, [display]);

  return (
    <>
      { includeSort && 
        <>
          <CSSTransition 
            classNames="c-inventory-filters-sort-"
            timeout={1000}
            appear
            in={showSort}
          >
            <section className="c-inventory-filters-sort">
              <div className="c-inventory-filters-sort__backdrop" onClick={closeSort}></div>
              <div className="c-inventory-filters-sort__content">
              <div className="c-inventory-filters-sort__header">
                  <h1>Sort Products</h1>
                  <button className="c-inventory-filters-sort__close" onClick={closeSort}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="c-inventory-filters-sort__options">
                  <SortForm onSubmit={() => {return}} />
                </div>
              </div>
            </section>
          </CSSTransition>
        </>
      }
    </>
  )
}

export default Sort;