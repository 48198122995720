import React, { useState, useEffect } from 'react';
import { useDispatch }                from 'react-redux';
import { SetLocationFilter }  from 'store/locations/actions';
import Pill                   from 'components/Pill';
import './DispensaryFilters.scss';

function DispensaryFilters() {
  const dispatch = useDispatch();
  const [dispensaryFilter, setDispensaryFilter] = useState('all');

  // When filter changed, store it in the redux store.
  useEffect(() => {
    dispatch(SetLocationFilter(dispensaryFilter))
  }, [dispensaryFilter, dispatch]);

  return(
    <div className="c-dispensary-filters l-maxwidth">
      <div className="c-dispensary-filters__column--all">
        <Pill active={dispensaryFilter === 'all'} onClick={() => setDispensaryFilter('all')}>
          <span className="body--xsmall">All</span>
        </Pill>
      </div>
      <div className="c-dispensary-filters__column--recreational">
        <Pill active={dispensaryFilter === 'rec'} onClick={() => setDispensaryFilter('rec')}>
          <span className="body--xsmall">Recreational</span>
        </Pill>
      </div>
      <div className="c-dispensary-filters__column--medical">
        <Pill active={dispensaryFilter === 'med'} onClick={() => setDispensaryFilter('med')}>
          <span className="body--xsmall">Medical</span>
        </Pill>
      </div>
    </div>
  )
}

export default DispensaryFilters;