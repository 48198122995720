import React, { useEffect }                           from 'react';
import { connect, useSelector }                       from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps }  from 'redux-form';
import { RootState }                                from 'store';
import Button                                       from 'components/Button';
import { Input, Select, Date }                      from 'components/Forms/Fields';
import { ReservatoionDetailsValidator as validate } from 'components/Forms/Validators';
import { ID_MAX_LENGTH, NAME_MAX_LENGTH } from "components/Forms/Fields/constants";
import './ReservationDetailsForm.scss';

function ReservationDetailsForm({ 
  handleSubmit, 
  invalid, 
  change 
}: InjectedFormProps) {

  const formState             = useSelector((state:RootState) => state.form.reservationDetails);
  const formDocumentType      = formState && formState.values && formState.values.documentType;
  const formDocumentId        = formState && formState.values && formState.values.documentId;
  const formMedicalId         = formState && formState.values && formState.values.medicalId;
  const loggingIn             = useSelector((state:RootState) => state.customer.creatingCustomer);
  const isDesktop             = useSelector((state: RootState) => state.system.isDesktop);
  const saving                = useSelector(( state : RootState ) => state.customer.saving);
  const activeDispensaryType  = useSelector(( state : RootState ) => state.cart.activeDispensaryType);
  
  const documentTypeOptions = [
    { 
      label:'US Passport',
      value: 'PASSPORT'
    },
    { 
      label:'Drivers License',
      value: 'DRIVERS_LICENSE'
    },
  ];
  
  useEffect(() => {
    change('orderOnly', false);
    // eslint-disable-next-line
  }, []);
  
  const renderMedicalIdField = () => (
    <Field
      component={Input}
      name="medicalId"
      type="text"
      required={!formDocumentType || !formDocumentId}
      label={"Medical Card Number"}
      className="idNumber__input"
      maxlength={ID_MAX_LENGTH}
    />
  );

  const renderDocumentIdFields = () => (
    <>
      <Field
        component={Select}
        name="documentType"
        label={"ID Document Type"}
        isDesktop={isDesktop}
        required={!formDocumentId && !formMedicalId}
        className="idDocumentType__input"
        options={documentTypeOptions}
        change={change}
      />
      <Field
        component={Input}
        name="documentId"
        type="text"
        required={!formDocumentType && !formMedicalId}
        label={"ID Number"}
        className="idNumber__input"
        maxlength={ID_MAX_LENGTH}
      />
    </>
  );

  const renderIDField = () => {
    const isMedical = activeDispensaryType?.indexOf('medical') !== -1;
    const isRecreational = activeDispensaryType?.indexOf('recreational') !== -1;
    
    if (isMedical && isRecreational) {
      return (
        <>
          <p className="body--xsmall c-reservation-details-form__label">
            Document ID or Medical Card Number
          </p>
          {renderDocumentIdFields()}
          {renderMedicalIdField()}
        </>
      );
    } else if (isMedical && !isRecreational) {
      return renderMedicalIdField();
    } else if (!isMedical && isRecreational) {
      return renderDocumentIdFields();
    }
  };
  
  return (
    <div className="c-reservation-details-form">
      <Form className="c-reservation-details-form__form" autoComplete="off" onSubmit={handleSubmit}>
        <Field 
          component={Input} 
          name="firstName" 
          type="text"
          label={"First Name"}
          required
          className="firstName__input"
          maxlength={NAME_MAX_LENGTH}
        /> 
        <Field 
          component={Input} 
          name="lastName" 
          type="text"
          label={"Last Name"}
          required
          className="lastName__input"
          maxlength={NAME_MAX_LENGTH}
        />
        {renderIDField()}
        <Date />
        <Field 
          component={Input} 
          name="orderOnly" 
          type="hidden"
        />
        <Button disabled={saving || invalid || loggingIn} variant="login" type="submit">Save To Account</Button>
        <Button disabled={saving || invalid || loggingIn} variant="outlined" type="submit" onClick={() => change('orderOnly', true)}>Add to this order only</Button>

      </Form>
    </div>
  )
}

export default connect(
  (state: RootState) => ({
    initialValues: state.customer.orderOnlyDetails ? state.customer.orderOnlyDetails : state.customer.customer ? state.customer.customer : {}
  })
)(reduxForm({
  form: 'reservationDetails',
  validate
})(ReservationDetailsForm));