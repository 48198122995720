import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Input } from "components/Forms/Fields";
import IconButton from "components/IconButton";
import { TermsOfService } from "./index";
interface ProfileExperienceProps {
  activeStep: string;
  goNext: any;
  change: any;
}

function Experience({ activeStep, goNext, change }: ProfileExperienceProps) {
  const activeStepIndex = useSelector(
    (state: RootState) => state.system.profileStepIndex
  );

  const [isPrev, setIsPrev] = useState(false);

  const isActive = activeStep === "experience";

  useEffect(() => {
    let timer: any = null;

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 3);
      }, 1000);
    } else {
      setIsPrev(activeStepIndex > 3);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPrev, activeStepIndex]);

  function setExperience(level: string) {
    change("experienceLevel", level);
    goNext();
  }

  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? "c-profile__step--prev-" : "c-profile__step-"}
      timeout={1000}
      appear
    >
      <div className={classNames("c-profile__step")}>
        <div className="slug">Question 3 of 8</div>
        <h3>How much experience do you have with Cannabis?</h3>
        <Field
          component={Input}
          name="experienceLevel"
          type="hidden"
          label={""}
          required
          className="firstName__input"
        />
        <IconButton
          tabIndex={isActive ? 0 : -1}
          onClick={() => setExperience("NONE")}
          variant="select-dispensary"
          type="button"
          iconColor="charcoal"
          backgroundColor="white"
          textColor="charcoal"
          withCaret={true}
        >
          None
        </IconButton>
        <IconButton
          tabIndex={isActive ? 0 : -1}
          onClick={() => setExperience("BEGINNER")}
          variant="select-dispensary"
          type="button"
          iconColor="charcoal"
          backgroundColor="white"
          textColor="charcoal"
          withCaret={true}
        >
          Beginner
        </IconButton>
        <IconButton
          tabIndex={isActive ? 0 : -1}
          onClick={() => setExperience("INTERMEDIATE")}
          variant="select-dispensary"
          type="button"
          iconColor="charcoal"
          backgroundColor="white"
          textColor="charcoal"
          withCaret={true}
        >
          Intermediate
        </IconButton>
        <IconButton
          tabIndex={isActive ? 0 : -1}
          onClick={() => setExperience("EXPERT")}
          variant="select-dispensary"
          type="button"
          iconColor="charcoal"
          backgroundColor="white"
          textColor="charcoal"
          withCaret={true}
        >
          Expert
        </IconButton>
        <TermsOfService />
      </div>
    </CSSTransition>
  );
}

export default Experience;
