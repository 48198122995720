import React, { useRef } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Form, Field, reduxForm, InjectedFormProps } from "redux-form";
import { RootState } from "store";
import { Radio } from "components/Forms/Fields";
import { OptionsProps } from "components/Forms/Fields/types";

interface SortFormProps extends InjectedFormProps {
  onSubmit?: any;
}

function SortForm({ handleSubmit }: InjectedFormProps) {
  const formRef = useRef<HTMLDivElement>(null);

  const sortOptions: Array<OptionsProps> = [
    // {name: 'alpha_ascending', label: 'Name: A-Z'},
    // {name: 'alpha_descending', label: 'Name: Z-A'},
    { name: "price_ascending", label: "Price: Low-High" },
    { name: "price_descending", label: "Price: High-Low" },
  ];

  return (
    <div className={classNames("c-sort-form")} ref={formRef}>
      <Form onSubmit={handleSubmit}>
        <Field component={Radio} name="sort" options={sortOptions} />
      </Form>
    </div>
  );
}

export default connect((state: RootState) => ({
  initialValues: { sort: state.recs.sort },
}))(
  reduxForm({
    form: "recommendationSort",
  })(SortForm)
);
