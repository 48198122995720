import React, { useCallback, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { RootState } from "store";
import PageWrapper from "components/PageWrapper";
import AllLocations from "containers/AllLocations";
import Dispensary from "containers/Dispensary";
import Inventory from "containers/Inventory";
import Home from "containers/Home";
import Profile from "containers/Profile";
import Page from "components/Page";
import {
  AccountHome,
  SignIn,
  SignUp,
  ReservationDetails,
  ForgotPassword,
  AccountSettings,
  IdSettings,
  OrderHistory,
  OrderReceipt,
  Profile as AccountProfile,
} from "containers/Account";
import PDP from "containers/PDP";
import Package from 'containers/Package'
import { OrderView, OrderConfirmation } from "containers/Order";
import SocialRedirect from "containers/Account/SignIn/SocialRedirect";
import { getLocation } from "utils/maps";
import { SetUserLocation } from "store/user/actions";
import AgeGate from "components/AgeGate";
import { useLoadScript } from "@react-google-maps/api";
import {
  SetMapsReady,
  SetGeoLocationDisabled,
  SetGeoLocationDismissed,
  SetGeoLocating,
  SetProfileIsOpen,
  SetProfileStep,
  SetResetKioskTimer,
  SetMoodSelectorIsOpen,
} from "store/system/actions";
import {
  FetchDispensaries,
  SetLocationDistances,
} from "store/locations/actions";
import { checkDistance } from "utils/maps";
import { ResetLastProductAdded, SetPickupLocation } from "store/cart/actions";
import { MoodSelector } from "components/Home";
import NotFound from "components/NotFound";
import { ResetVerificationNeeded } from "store/customer/actions";
import { SetRecsLocation } from "store/recommendations/actions";
import SetDispensary from "containers/SetDispensary";
import RelatedRecommendation from "../RelatedRecommendation";

const libraries = ["places"];

function RouteContainer({ history, location }: RouteComponentProps) {
  const dispatch = useDispatch();
  const dispensaries = useSelector(
    (state: RootState) => state.locations.dispensaries
  );
  const userLocationID = useSelector(
    (state: RootState) => state.user.userLocationID
  );
  const hasDistances = useSelector(
    (state: RootState) => state.locations.hasDistances
  );
  const loadingDistances = useSelector(
    (state: RootState) => state.locations.loadingDistances
  );
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const activeDispensaryType = useSelector(
    (state: RootState) => state.cart.activeDispensaryType
  );
  const geoLocationDisabled = useSelector(
    (state: RootState) => state.system.geoLocationDisabled
  );
  const geoLocationDismissed = useSelector(
    (state: RootState) => state.system.geoLocationDismissed
  );
  const profileOpen = useSelector(
    (state: RootState) => state.system.profileOpen
  );
  const showAgeGate = useSelector(
    (state: RootState) => state.system.showAgeGate
  );
  const isKiosk = useSelector((state: RootState) => state.system.isKiosk);

  const [isGeoLocating, SetIsGeoLocating] = useState(false);
  const [currentLocationId, setCurrentLocationId] = useState(userLocationID);
  const [displayAgegate, setDisplayAgegate] = useState(false);

  const PLACES_API_KEY = process.env.REACT_APP_PLACES_API_KEY;
  const { pathname } = location;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: PLACES_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (!isKiosk && showAgeGate) {
      if (
        [
          "/support",
          "/contact-us",
          "/about-forage",
          "/terms-of-use",
          "/privacy-policy",
          "/cookie-policy",
        ].indexOf(location.pathname) >= 0
      ) {
        setDisplayAgegate(false);
      } else {
        setDisplayAgegate(true);
      }
    } else {
      setDisplayAgegate(false);
    }
  }, [location, showAgeGate, isKiosk]);

  useEffect(() => {
    if (pathname.indexOf("/create-profile") >= 0 && !profileOpen) {
      if (pathname !== "/create-profile/success") {
        dispatch(SetProfileIsOpen(true));
      }
      if (pathname === "/create-profile/success") {
        dispatch(SetProfileStep("success"));
      } else {
        dispatch(SetProfileStep("name"));
      }
    } else if (pathname.indexOf("/create-profile") < 0 && profileOpen) {
      if (pathname !== "/account/signin" && pathname !== "/account/signup") {
        dispatch(SetProfileIsOpen(false));
      }
    }
    const unlisten = history.listen((newLocation: any) => {
      window.scrollTo(0, 0);
      dispatch(ResetLastProductAdded());
      dispatch(ResetVerificationNeeded());
      dispatch(SetResetKioskTimer(true));
      if (
        ["/account/signin", "/account/signup"].indexOf(newLocation.pathname) < 0
      ) {
        sessionStorage.removeItem("forage_signin_redirect");
      }
    });
    return () => {
      unlisten();
    };
  }, [history, pathname, profileOpen, dispatch]);

  // On places sdk loaded, request location access
  // If succesful, store user location on redux store
  // If failed, set geolocation disabled flag on redux store.
  useEffect(() => {
    if (isLoaded && (!showAgeGate || isKiosk)) {
      dispatch(SetMapsReady());
    }
    if (
      isLoaded &&
      (!showAgeGate || isKiosk) &&
      !isGeoLocating &&
      !geoLocationDisabled &&
      !geoLocationDismissed &&
      !activeDispensary
    ) {
      dispatch(SetGeoLocating(true));
      SetIsGeoLocating(true);
      getLocation(
        (place_id: string, address: string, zip: string) => {
          dispatch(SetUserLocation(place_id, address, zip));
          dispatch(SetGeoLocating(false));
          if (!dispensaries) {
            dispatch(FetchDispensaries());
          }
        },
        (error: any) => {
          if (error.status === "denied") {
            dispatch(SetGeoLocationDisabled());
          } else {
            dispatch(SetGeoLocationDismissed(true));
          }
          dispatch(SetGeoLocating(false));
          if (activeDispensary) {
            dispatch(FetchDispensaries());
          }
        }
      );
    }
  }, [
    isLoaded,
    showAgeGate,
    dispensaries,
    isGeoLocating,
    activeDispensary,
    geoLocationDisabled,
    geoLocationDismissed,
    isKiosk,
    dispatch,
  ]);

  useEffect(() => {
    if (
      dispensaries &&
      userLocationID &&
      (!hasDistances || userLocationID !== currentLocationId) &&
      !loadingDistances &&
      !activeDispensary
    ) {
      setCurrentLocationId(userLocationID);
      const locations = Object.keys(dispensaries).map((key: any) => {
        return {
          slug: dispensaries[key]?.slug,
          placeID: dispensaries[key]?.googlePlace?.googlePlaceId || "",
        };
      });
      checkDistance(userLocationID, locations, (distances: any) => {
        dispatch(SetLocationDistances(distances));
      });
    } else if (
      dispensaries &&
      hasDistances &&
      (activeDispensary === "" ||
        activeDispensary === null ||
        !activeDispensaryType)
    ) {
      const sortedDispensaries = Object.keys(dispensaries).sort(
        (a: string, b: string) => {
          const locationA: any = dispensaries[a];
          const locationB: any = dispensaries[b];
          return locationA.distanceMeters > locationB.distanceMeters ? 1 : -1;
        }
      );
      dispatch(SetPickupLocation(dispensaries[sortedDispensaries[0]]));
      dispatch(SetRecsLocation(dispensaries[sortedDispensaries[0]]?.slug));
    } else if (!dispensaries && !loadingDistances) {
      dispatch(FetchDispensaries());
    }
  }, [
    dispensaries,
    userLocationID,
    hasDistances,
    loadingDistances,
    activeDispensary,
    currentLocationId,
    activeDispensaryType,
    dispatch,
  ]);

  const onSelectorOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(SetMoodSelectorIsOpen(isOpen));
    },
    [dispatch]
  );

  return (
    <>
      {displayAgegate && !isKiosk ? (
        <AgeGate />
      ) : (
        <Switch>
          <Route exact path="/">
            <PageWrapper>
              <Home />
            </PageWrapper>
          </Route>
          <Route exact path="/locations">
            <PageWrapper title="Cannabis Dispensary Locations">
              <AllLocations />
            </PageWrapper>
          </Route>
          <Route exact path="/related-products">
            <PageWrapper title="Recommendation: Related Products">
              <RelatedRecommendation />
            </PageWrapper>
          </Route>
          <Route path="/dispensary/:slug/product/:id">
            <PageWrapper>
              <PDP />
            </PageWrapper>
          </Route>
          <Route path="/package/:id">
            <Package />
          </Route>
          <Route path="/dispensary/:slug/products">
            <PageWrapper>
              <Inventory />
            </PageWrapper>
          </Route>
          <Route path="/dispensary/:slug">
            <PageWrapper>
              <Dispensary />
            </PageWrapper>
          </Route>
          <Route exact path="/order">
            <PageWrapper title="Forage | Your Cart">
              <OrderView />
            </PageWrapper>
          </Route>
          <Route exact path="/order-confirmation/:dispensarySlug/:orderId">
            <PageWrapper title="Forage | Order Confirmation">
              <OrderConfirmation />
            </PageWrapper>
          </Route>
          <Route exact path="/account/profile">
            <PageWrapper>
              <AccountProfile />
            </PageWrapper>
          </Route>
          <Route exact path="/account/signin">
            <PageWrapper title="Forage | Sign Into Your Account">
              <SignIn />
            </PageWrapper>
          </Route>
          <Route exact path="/account/signup">
            <PageWrapper title="Forage | Create An Account">
              <SignUp />
            </PageWrapper>
          </Route>
          <Route exact path="/account/forgot-password">
            <PageWrapper>
              <ForgotPassword />
            </PageWrapper>
          </Route>
          <Route exact path="/account/id-settings">
            <PageWrapper>
              <IdSettings />
            </PageWrapper>
          </Route>
          <Route exact path="/account/add-reservation-details">
            <PageWrapper>
              <ReservationDetails />
            </PageWrapper>
          </Route>
          <Route exact path="/account/settings">
            <PageWrapper title="Forage | Edit Account Settings">
              <AccountSettings />
            </PageWrapper>
          </Route>
          <Route exact path="/account/orders">
            <PageWrapper title="Forage | All Past Orders">
              <OrderHistory />
            </PageWrapper>
          </Route>
          <Route exact path="/account/orders/:slug/:orderId">
            <PageWrapper>
              <OrderReceipt />
            </PageWrapper>
          </Route>
          <Route exact path="/account">
            <PageWrapper title="Forage | Your Account">
              <AccountHome />
            </PageWrapper>
          </Route>
          <Route exact path="/recommendations">
            <PageWrapper title="Cannabis Recommendations | Find The Right Cannabis">
              <MoodSelector onSelectorOpen={onSelectorOpen} />
            </PageWrapper>
          </Route>
          <Route path="/create-profile/:step?">
            <PageWrapper title="Profile Creation | What is your name?">
              <Profile />
            </PageWrapper>
          </Route>
          <Route exact path="/about-forage">
            <PageWrapper title="About Forage">
              <Page type="about" />
            </PageWrapper>
          </Route>
          <Route exact path="/privacy-policy">
            <PageWrapper title="Forage Privacy Policy">
              <Page type="privacy" />
            </PageWrapper>
          </Route>
          <Route exact path="/cookie-policy">
            <PageWrapper title="Forage Cookie Policy">
              <Page type="cookies" />
            </PageWrapper>
          </Route>
          <Route exact path="/support">
            <PageWrapper title="Forage Support">
              <Page type="support" />
            </PageWrapper>
          </Route>
          <Route exact path="/contact-us">
            <PageWrapper title="Contact Forage">
              <Page type="contact" />
            </PageWrapper>
          </Route>
          <Route exact path="/terms-of-use">
            <PageWrapper title="Forage Terms of Use">
              <Page type="terms" />
            </PageWrapper>
          </Route>
          <Route exact path="/social-redirect">
            <SocialRedirect />
          </Route>
          <Route exact path="/set-dispensary/:dispensary">
            <SetDispensary />
          </Route>
          <Route>
            <PageWrapper>
              <NotFound />
            </PageWrapper>
          </Route>
        </Switch>
      )}
    </>
  );
}

export default withRouter(RouteContainer);
