import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Energetic: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--energetic" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <polygon className="i-feeling--energetic--st0" stroke={color} fill={color} points="227.1,111.6 166.8,220.3 204.7,220.3 189.6,303 252,193.6 212.4,193.6 	"/>
        <line className="i-feeling--energetic--st1" stroke={color} x1="305.2" y1="147.3" x2="288.8" y2="153.3"/>
        <line className="i-feeling--energetic--st1" stroke={color} x1="111.4" y1="147.3" x2="127.7" y2="153.3"/>
        <line className="i-feeling--energetic--st1" stroke={color} x1="128.2" y1="269.8" x2="111.9" y2="275.8"/>
        <line className="i-feeling--energetic--st1" stroke={color} x1="289.3" y1="269.8" x2="305.6" y2="275.8"/>
        <line className="i-feeling--energetic--st1" stroke={color} x1="112.6" y1="211.7" x2="75.9" y2="211.7"/>
        <line className="i-feeling--energetic--st1" stroke={color} x1="302" y1="211.7" x2="338.7" y2="211.7"/>
      </g>
    </svg>
  )
}

export default Energetic;