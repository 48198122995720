import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { RootState } from "store";
import { ProfileForm } from "components/Profile";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetProfileStep } from "store/system/actions";
// import FylloImage from "components/FylloImage";

function Profile({ history, match }: RouteComponentProps) {
  const dispatch = useDispatch();
  const noReset = useSelector((state: RootState) => state.profile.noReset);
  const customer = useSelector(
    (state: RootState) => state.customer?.customer || null
  );
  const currentProfile = useSelector(
    (state: RootState) => state.profile.current
  );

  const [isLoaded, setIsLoaded] = useState(false);
  // const [buildTimeStamp, setBuildTimeStamp] = useState<any>(null);
  // const isNew = useSelector((state: RootState) => state.customer.isNew);

  const params: any = match.params;

  // useEffect(() => {
  //   if (isNew) {
  //     setBuildTimeStamp(new Date().getTime());
  //   }
  // }, [isNew]);

  useEffect(() => {
    if (!isLoaded) {
      if (
        params &&
        params.step !== "name" &&
        params.step !== "success" &&
        !noReset
      ) {
        history.replace("/create-profile");
        dispatch(SetProfileStep("name"));
        setIsLoaded(true);
      } else if (params.step === "success") {
        dispatch(SetProfileStep("success"));
      }
    }
  }, [isLoaded, params, noReset, history, dispatch]);

  useEffect(() => {
    // if (!customer) {
    //   history.replace({
    //     pathname: "/account/signup",
    //     state: { signInRedirect: "/create-profile" },
    //   });
    // }
    if (
      ((customer && customer.hasProfile) || currentProfile) &&
      params.step !== "success"
    ) {
      history.push("/account/profile");
    }
  }, [customer, currentProfile, params, history]);

  function handleSubmit(values: any) {}

  function goToSignin() {
    history.push({
      pathname: "/account/signin",
      state: { signInRedirect: "/create-profile/success" },
    });
  }

  return (
    <>
      {/* {buildTimeStamp && (
        <FylloImage
          src={`https://ad.ipredictive.com/d/track/cvt/pixel?acct_id=46887&cache_buster=${buildTimeStamp}`}
        />
      )} */}
      <ProfileForm
        onSubmit={handleSubmit}
        goToSignin={goToSignin}
        history={history}
        match={match}
      />
    </>
  );
}

export default withRouter(Profile);
