import React from 'react';
import './PDPDiscounts.scss';

interface PDPDiscountsProps {
  product:any
}

function PDPDiscounts({
  product
}: PDPDiscountsProps) {
  return (
    <section className="c-pdp-discounts">
      { 
        product.discounts.map((discount: {name: string}, key: number) => (
          <div className="c-pdp-discounts__discount" key={key}>
            <div className="c-pdp-discounts__discount__inner">
              {discount.name}
            </div>
          </div>
        ))
      }
    </section>
  )
}

export default PDPDiscounts;
