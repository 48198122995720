// Take mm/dd/yyyy as birthdate and get age as of today.
export function getAge(month: string, day: string, year: string) {
  const birthDateString = month + '/' + day + '/' + year;
  var today = new Date();
  var birthDate = new Date(birthDateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}
const easeInOutQuad = function (t:number, b:number, c:number, d:number) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

export function scrollTo(element:any, to:number, duration:number) {
  var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;
  
  let startTime = 0;


  const animateScroll = (timestamp: number) => {

    if (!startTime) startTime = timestamp;

    const elapsedTime = timestamp - startTime;
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if(val !== to && elapsedTime < duration) {
      requestAnimationFrame(animateScroll);
    }
  };
  requestAnimationFrame(animateScroll);
}

export function checkParent(el:any) {
  const parent:any = el.parentNode;
  const parentClasses:any = parent.classList;
  if(!parentClasses){
    return;
  }
  return parentClasses.contains('enter-active') || parentClasses.contains('enter-done') || parentClasses.contains('c-pdp-topper__content__inner') || parentClasses.contains('c-review-card__topper__figure') || parentClasses.contains('c-card-gradient-wrapper');
}

export function shouldAnimateGradient(el:any) {
  if(el.current !== null  && el.current.parentNode){
    const found = checkParent(el.current);
    if(found){
      return true;
    } else {
      return false;
    }
  } else {
    return false
  }
  
}

export const capitalizeFirstLetter = (string: string) => `${string.charAt(0).toUpperCase()}${string.toLowerCase().slice(1)}`;