import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { scrollTo } from "utils";
import { feelingMap } from "constants/feelings";
import { RootState } from "store";
import {
  SetFillOutFeelings,
  SetRecFinderPreference,
  SetRecPreferenceAdjustment,
} from "store/recommendations/actions";
import Button from "components/Button";
import Slider from "components/FeelingSlider";
import { CSSTransition } from "react-transition-group";
import { recPreferencesLimit } from "constants/recommendations";
import "./Steps.scss";

type StepProps = {
  adjustment?: boolean;
  goToResults?: () => void;
  nextStep?: () => void;
};

export type Ref = HTMLDivElement;
const Step2 = forwardRef<Ref, StepProps>(
  ({ adjustment = false, goToResults, nextStep }, ref) => {
    const allFeelingsRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const feelingsMatch = useSelector((state: RootState) =>
      adjustment
        ? state.recs.recPreferencesAdjustments.feelings
        : state.recs.recPreferences.feelings
    );
    const stateFillOutFeelings = useSelector(
      (state: RootState) => state.recs.fillOutFeelings
    );
    const recPreferences = useSelector(
      (state: RootState) => state.recs.recPreferences
    );
    const xy = useSelector((state: RootState) => state.recs.xy);

    const [feelings, setFeelings] = useState<any>([]);
    const [leftoverFeelings, setLeftoverFeelings] = useState<any>([]);
    const [showLeftovers, setShowLeftovers] = useState<any>(false);
    const [currentXY, setCurrentXY] = useState(xy);
    const [changed, setChanged] = useState(false);
    const [interacted, setInteracted] = useState(false);
    const [feelingInValid, setFeelingInValid] = useState(false);

    const getFillOutFeelings = useCallback(() => {
      let fillOutFeelings: any = [];
      let feelingKeys: any = [];
      Object.keys(feelingsMatch)
        .sort()
        .forEach((feelingKey: any) => {
          const feeling = {
            feeling: feelingKey,
            value: feelingsMatch[feelingKey],
          };
          if (feeling.value === 0 && feelingKeys.indexOf(feelingKey) === -1) {
            feelingKeys.push(feelingKey);
            fillOutFeelings.push({ ...feeling, value: 0 });
          }
        });

      for (let i = fillOutFeelings.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [fillOutFeelings[i], fillOutFeelings[j]] = [
          fillOutFeelings[j],
          fillOutFeelings[i],
        ];
      }

      return fillOutFeelings;
    }, [feelingsMatch]);

    useEffect(() => {
      if (!changed) {
        setCurrentXY(xy);
        const fillOutFeelings = getFillOutFeelings();
        dispatch(SetFillOutFeelings(fillOutFeelings));
        setChanged(true);
      }
    }, [
      xy,
      currentXY,
      stateFillOutFeelings,
      changed,
      dispatch,
      getFillOutFeelings,
    ]);

    const sliderChange = (feeling: any, value: number) => {
      const fs = { ...recPreferences.feelings };
      fs[feeling.feeling] = Math.max(0, Math.min(100, value));
      setInteracted(true);

      const allZeroValues = fs
        ? Object.values(fs).every((x: any) => x === 0 || x < 0.09)
        : false;
      setFeelingInValid(allZeroValues);

      if (adjustment) {
        dispatch(SetRecPreferenceAdjustment("feelings", fs));
      } else {
        dispatch(SetRecFinderPreference("feelings", fs));
        dispatch(SetRecFinderPreference("limit", recPreferencesLimit));
      }
    };

    function toggleLeftovers() {
      if (null !== allFeelingsRef.current && !showLeftovers) {
      } else {
        scrollTo(document.documentElement, 0, 500);
        scrollTo(document.body, 0, 500);
      }
      setShowLeftovers(!showLeftovers);
    }

    useEffect(() => {
      if (!feelingsMatch || !stateFillOutFeelings || !changed || interacted) {
        return;
      }

      let feelingArray: any = [];
      let existingFeelings: any = [];
      let leftoverFeelings: any = [];
      let remainingCount = 0;
      Object.keys(feelingsMatch)
        .sort()
        .forEach((feelingKey: any) => {
          const normalizedFeelingId = feelingKey;
          const feeling = {
            feeling: feelingKey,
            value: feelingsMatch[feelingKey],
          };
          const isFillOut = stateFillOutFeelings.findIndex(
            (sfof: any) => sfof.feeling === feelingKey
          );
          if (
            feeling.value > 0 &&
            existingFeelings.indexOf(normalizedFeelingId) === -1 &&
            isFillOut === -1
          ) {
            existingFeelings.push(normalizedFeelingId);
            feelingArray.push({
              ...feelingMap[normalizedFeelingId],
              value: feeling.value,
              feeling: normalizedFeelingId,
            });
          }
        });
      if (stateFillOutFeelings.length > 0) {
        while (feelingArray.length < 4) {
          const fillOutFeeling = stateFillOutFeelings[remainingCount];
          if (existingFeelings.indexOf(fillOutFeeling.feeling) === -1) {
            existingFeelings.push(fillOutFeeling.feeling);
            feelingArray.push({
              ...feelingMap[fillOutFeeling.feeling],
              value: feelingsMatch[fillOutFeeling.feeling] || 0,
              feeling: fillOutFeeling.feeling,
            });
          }
          remainingCount++;
        }
      }
      stateFillOutFeelings
        .slice(remainingCount)
        .forEach((feeling: any, index: number) => {
          if (feelingsMatch[feeling.feeling] > 0) {
            feelingArray.push({
              ...feelingMap[feeling.feeling],
              value: feelingsMatch[feeling.feeling] || 0,
              feeling: feeling.feeling,
            });
          } else {
            leftoverFeelings.push({
              ...feelingMap[feeling.feeling],
              value: feelingsMatch[feeling.feeling] || 0,
              feeling: feeling.feeling,
            });
          }
        });

      feelingArray = feelingArray.sort((feelingA: any, feelingB: any) =>
        feelingA.value > feelingB.value ? -1 : 1
      );
      setLeftoverFeelings(leftoverFeelings);
      setFeelings([...feelingArray]);
    }, [feelingsMatch, stateFillOutFeelings, changed, interacted]);

    return (
      <div className="c-mood-selector__step c-mood-selector__step--2">
        <div className="c-mood-selector__step__inner" ref={ref}>
          <div className="l-main l-maxwidth l-maxwidth--small">
            <header className="c-mood-selector__header">
              <h1>Dial in that feeling</h1>
              <p className="c-mood-selector__question-progress">
                Question 2 of 5
              </p>
            </header>
            {feelingInValid && (
              <p className="c-mood-selector__question-progress c-mood-selector__feelingInvalid__error">
                Select the feeling value
              </p>
            )}
            <div className="c-mood-selector__sliders">
              {feelings.map((feeling: any, index: number) => {
                return (
                  <Slider
                    key={
                      adjustment
                        ? "adjustment-" + feeling.label + index
                        : feeling.label + index
                    }
                    feeling={feeling}
                    onChange={sliderChange}
                  />
                );
              })}
            </div>
            <div
              className={classNames("c-mood-selector__leftovers", {
                "c-mood-selector__leftovers--open": showLeftovers,
              })}
              ref={allFeelingsRef}
            >
              <Button
                disabled={feelingInValid}
                type="button"
                variant="outlined c-button--leftovers"
                onClick={toggleLeftovers}
              >
                {showLeftovers
                  ? "Hide Additional Feelings"
                  : "Show Additional Feelings"}
              </Button>

              <div className="c-mood-selector__leftovers__sliders">
                {leftoverFeelings &&
                  leftoverFeelings.map((feeling: any, index: number) => {
                    return (
                      <Slider
                        key={
                          adjustment
                            ? "adjustment-" + feeling.label + index
                            : feeling.label + index
                        }
                        feeling={feeling}
                        onChange={sliderChange}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <CSSTransition
          classNames="c-mood-selector__action"
          timeout={500}
          appear
          in
        >
          <div
            className={classNames(
              "c-mood-selector__standard-action c-mood-selector__standard-action__step"
            )}
          >
            <Button
              type="button"
              onClick={nextStep}
              variant=""
              disabled={feelingInValid}
            >
              Next
            </Button>
            <Button
              type="button"
              onClick={goToResults}
              variant="outlined"
              disabled={feelingInValid}
            >
              View Recommendations
            </Button>
          </div>
        </CSSTransition>
      </div>
    );
  }
);

export default Step2;
