import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import {
  Redirect,
  RouteComponentProps,
  withRouter,
  Link,
} from "react-router-dom";

import { RootState } from "store";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { SignInSocial } from "components/Account/SignIn";
import { SignUpForm } from "components/Account/SignUp";
import CloseIcon from "components/svgs/Close";
import auth0 from "auth0-js";
import { SubmissionError } from "redux-form";
import {
  CreateCustomer,
  CreatingCustomer,
  CustomerLogin,
  ResetCustomerError,
} from "store/customer/actions";
import { CreateProfile } from "store/profile/actions";
import "../SignIn/SignIn.scss";

function SignUp({ history, location }: RouteComponentProps) {
  const { webAuth } = window;
  const dispatch = useDispatch();
  const creatingCustomer = useSelector(
    (state: RootState) => state.customer.creatingCustomer
  );
  const customer = useSelector((state: RootState) => state.customer.customer);
  const customerError = useSelector(
    (state: RootState) => state.customer.customerError
  );
  const verificationRequired = useSelector(
    (state: RootState) => state.customer.verificationRequired
  );
  const preferences = useSelector(
    (state: RootState) => state.profile.preferences
  );

  const [createdUser, setCreatedUser] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [signupFailed, setSignupFailed] = useState<any>(false);
  const [creatingEmail, setCreatingEmail] = useState("");
  const [creatingName, setCreatingName] = useState("");
  const [authError, setAuthError] = useState("");
  // const [signupTitle, setSignupTitle] = useState<boolean>(false);

  const auth0Ref = useRef<HTMLDivElement>(null);

  const locationState: any = location.state;

  if (locationState && locationState.signInRedirect) {
    sessionStorage.setItem(
      "forage_signin_redirect",
      locationState.signInRedirect
    );
  }

  const redirectUrl = locationState?.signInRedirect;

  // useEffect(() => {
  //   if (redirectUrl === "/create-profile") {
  //     setSignupTitle(true);
  //   } else {
  //     setSignupTitle(false);
  //   }
  // }, [redirectUrl]);

  useEffect(() => {
    const authID = sessionStorage.getItem("signUpAuth") || null;
    if (
      authID &&
      verificationRequired &&
      // locationState.signInRedirect === "/create-profile/success" &&
      preferences
    ) {
      //Create profile if info required
      dispatch(CreateProfile());
    }
  }, [verificationRequired, locationState, dispatch, preferences]);

  useEffect(() => {
    if (customerError) {
      setAuthError(customerError);
      dispatch(ResetCustomerError());
    }
  }, [customerError, dispatch]);

  useEffect(() => {
    return function cleanup() {
      sessionStorage.removeItem("forage_signin_redirect");
    };
  }, [location]);

  useEffect(() => {
    if (!webAuth) {
      const webAuthDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";
      const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || "";
      window.webAuth = new auth0.WebAuth({
        domain: webAuthDomain,
        clientID: webAuthClientId,
      });
    }
  }, [webAuth]);

  useEffect(() => {
    if (!createdUser) {
      setCreatedUser(true);
    }
  }, [createdUser, dispatch]);

  useEffect(() => {
    if (customer) {
      if (redirectUrl) {
        history.push(redirectUrl);
      } else if (customer.lastLogon) {
        history.push("/account");
      } else {
        history.push("/account/id-settings");
      }
    }
  }, [customer, creatingUser, redirectUrl, history]);

  function callEmailSignup(values: any) {
    const dbConnection = process.env.REACT_APP_AUTH0_USER_DB;
    return new Promise(function (resolve, reject) {
      webAuth.signup(
        {
          connection: dbConnection,
          email: values.email,
          password: values.password,
          name: values.first_name + " " + values.last_name,
          given_name: values.first_name,
          family_name: values.last_name,
        },
        function (err: any, auth: any) {
          if (err) {
            reject(err);
          } else {
            resolve(auth);
          }
        }
      );
    });
  }

  function handleSubmit(values: any) {
    dispatch(CreatingCustomer(true));
    setCreatingEmail(values.email);
    setCreatingName(values.firstName);
    setCreatingUser(true);
    return callEmailSignup(values)
      .then((auth: any) => {
        // console.log("DB AUTH RESULT:", auth);
        dispatch(CreatingCustomer(false));
        const profilePreferences =
          sessionStorage.getItem("forage_profile_preferences") || null;
        const createProfileRedirect = sessionStorage.getItem(
          "forage_signin_redirect"
        );
        if (
          profilePreferences &&
          createProfileRedirect === "/create-profile/success"
        ) {
          const authID = `auth0|${auth.Id}`;
          sessionStorage.setItem("signUpAuth", authID);
          history.push({
            pathname: "/account/signup",
            state: { signInRedirect: "/account/signup" },
          });
        }
        dispatch(
          CreateCustomer({
            auth0Id: "auth0|" + auth.Id,
            firstName: auth.givenName,
            lastName: auth.familyName,
            email: auth.email,
          })
        );
      })
      .catch((error) => {
        console.log("DB SIGNUP ERROR:", error);
        dispatch(CreatingCustomer(false));
        setCreatingUser(false);
        setSignupFailed(() =>
          error.code === "invalid_password" ? (
            <>
              Password is too weak. <br />
              At least 8 characters in length. <br />
              Contain at least 3 of the following 4 types of characters: <br />
              &bull; lower case letters (a-z) <br />
              &bull; upper case letters (A-Z) <br />
              &bull; numbers (i.e. 0-9) <br />
              &bull; special characters (e.g. !@#$%^&*)
            </>
          ) : (
            <>
              There was an error creating your account. <br /> If this issue
              persists, please contact{" "}
              <a
                href="mailto:support@forage.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@forage.io
              </a>
              .
            </>
          )
        );
        throw new SubmissionError({ _error: "User Exists" });
      });
  }

  function socialLogin(connection: string) {
    dispatch(CreatingCustomer(true));
    setCreatingUser(true);
    webAuth.popup.authorize(
      {
        redirectUri: window.location.origin.toString() + "/social-redirect",
        connection: connection,
        responseType: "token id_token",
        state: "forage",
      },
      function (err: any, authResult: any) {
        console.log("SOCIAL SIGNUP ERROR:", err);
        // console.log("SOCIAL AUTH RESULT:", authResult);
        if (err) {
          dispatch(CreatingCustomer(false));
          setCreatingUser(false);
          if (err.original !== "User closed the popup window") {
            setSignupFailed("Something went wrong. Please try again");
          }
        } else {
          sessionStorage.setItem("forage_token", authResult?.accessToken);
          dispatch(
            CustomerLogin({
              accessToken: authResult?.accessToken,
              firstName: authResult.idTokenPayload.givenName,
              lastName: authResult.idTokenPayload.familyName,
              email: authResult.idTokenPayload.email,
            })
          );
        }
      }
    );
  }

  function goBack() {
    history.goBack();
  }

  function backToOrder() {
    sessionStorage.removeItem("forage_signin_redirect");
    history.push(
      redirectUrl === "/account/add-reservation-details" ? "/order" : "/account"
    );
  }

  return (
    <>
      <section
        className={classNames("l-main c-signin", {
          "c-siginin--signing-in": creatingCustomer,
        })}
      >
        <div className="l-maxwidth l-maxwidth--small">
          <button onClick={goBack} className="c-signin__close">
            <CloseIcon variant="charcoal" /> <span>Close</span>
          </button>
          {verificationRequired ? (
            locationState.signInRedirect === "/create-profile/success" ? (
              <Redirect
                to={{
                  pathname: "/create-profile/success",
                  state: { emailToVerify: creatingEmail },
                }}
              />
            ) : locationState.signInRedirect ===
              "/account/add-reservation-details" ? (
              <div className="c-signin__verification-message">
                <h1>Welcome to Forage, {creatingName}</h1>
                <p>
                  We have sent a verification email to{" "}
                  <strong>{creatingEmail}</strong>
                </p>
                <p>You will need to verify your email before signing in.</p>
                <Button type="button" onClick={backToOrder} variant="">
                  Back to order
                </Button>
              </div>
            ) : (
              <div className="c-signin__verification-message">
                <h1>Verify your email address to confirm account</h1>
                <p>
                  We have sent a verification email to{" "}
                  <strong>{creatingEmail}</strong>
                </p>
                <p>You will need to verify your email before signing in.</p>
              </div>
            )
          ) : (
            <>
              {/* {signupTitle ? (
                <h1 className="c-signin__title">
                  You need to be logged in to setup your profile. <br />
                  Please login or signup now
                </h1>
              ) : (
                <h1>Create an account</h1>
              )} */}
              <h1>Create an account</h1>
              {signupFailed ? (
                <p className="c-signin__failure">{signupFailed}</p>
              ) : authError ? (
                <p className="c-signin__failure">
                  An error occurred. If this issue persists, please contact{" "}
                  <a
                    href="mailto:support@forage.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support@forage.io
                  </a>
                  .
                </p>
              ) : null}
              <div className="c-signin__options">
                <div id="c-signin-auto0" ref={auth0Ref}></div>
                <SignUpForm onSubmit={handleSubmit} />
                <div className="c-signin__signup">
                  Already have an account?{" "}
                  <Link to="/account/signin">Sign in</Link>
                  {/* <Link
                    to={{
                      pathname: "/account/signin",
                      state: {
                        signInRedirect:
                          signupTitle &&
                          locationState &&
                          locationState.signInRedirect
                            ? locationState.signInRedirect
                            : "/account",
                      },
                    }}
                  >
                    Sign in
                  </Link> */}
                </div>
                <SignInSocial socialLogin={socialLogin} />
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default withRouter(SignUp);
