import { CREATE_PROFILE_SUCCESS } from "store/profile/types";
import { PLACE_ORDER_SUCCESS } from "store/orders/types";
export interface CustomerType {
  customerId?: number;
  name?: string;
  firstName: string;
  lastName: string;
  middleName: string;
  nameSuffix: string;
  namePrefix: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
  cellPhone: string;
  emailAddress: string;
  status: string;
  mmjidNumber: string;
  mmjidExpirationDate: string;
  lastModifiedDateUTC: string;
  creationDate: string;
  customerType: string;
  gender: string;
  driversLicenseHash: string;
  dateOfBirth: string | null;
  externalCustomerId: string;
  createdByIntegrator: string;
  isAnonymous: boolean;
  referralSource: string;
  otherReferralSource: string;
  discountGroups: Array<string>;
  savingAccountSettings: boolean;
  documentType: string | null;
  documentId: string | null;
  medicalId: string | null;
  month: string | null;
  day: string | null;
  year: string | null;
  hasProfile: boolean;
  profileFirst: string | null;
  profileLast: string | null;
  auth0Id: string;
  usingStored: boolean;
  readyToLoad: boolean;
  loaded: boolean;
  accessToken: string;
  lastLogon: any;
}

export interface ProfileType {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  month: string;
  day: string;
  year: string;
  location?: string;
  usePreference: string;
}

export interface CustomerState {
  creatingCustomer: boolean;
  customerError: any;
  customer: null | CustomerType;
  authenticating: boolean;
  customerEmail: string;
  passwordPlaceholder: null | string;
  accessToken: string | null;
  authenticated: boolean;
  verificationRequired: boolean;
  saving: boolean;
  settingsSaved: boolean;
  orderHistory: Array<any> | null;
  ordersCount: number | null;
  orderHistoryEmpty: boolean;
  orderHistoryLoading: boolean;
  savingAccountSettings: boolean;
  orderOnlyDetails: any;
  productsToReview: any;
  reviewsLoading: boolean;
  reviewsError: boolean;
  reviewingProduct: number | string;
  reviewedProducts: Array<any>;
  reviewError: boolean;
  fullAddress: any;
  lastOrderName: string | null;
  customerLoading: boolean;
  orderHistoryFetchLimit?: number | null;
  offset?: number | null;
  isNew: boolean;
}

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILED = "CREATE_CUSTOMER_FAILED";
export const CUSTOMER_LOGOUT = "CUSTOMER_LOGOUT";
export const CUSTOMER_LOGIN = "CUSTOMER_LOGIN";
export const CUSTOMER_LOGIN_SUCCESS = "CUSTOMER_LOGIN_SUCCESS";
export const CUSTOMER_LOGIN_FAILED = "CUSTOMER_LOGIN_FAILED";
export const CUSTOMER_SAVE_SETTINGS = "CUSTOMER_SAVE_SETTINGS";
export const CUSTOMER_SAVE_SETTINGS_SUCCESS = "CUSTOMER_SAVE_SETTINGS_SUCCESS";
export const CUSTOMER_SAVE_SETTINGS_FAILED = "CUSTOMER_SAVE_SETTINGS_FAILED";
export const CREATING_CUSTOMER = "CREATING_CUSTOMER";
export const FETCH_ORDER_HISTORY = "FETCH_ORDER_HISTORY";
export const FETCH_ORDER_HISTORY_SUCCESS = "FETCH_ORDER_HISTORY_SUCCESS";
export const FETCH_ORDER_HISTORY_FAILED = "FETCH_ORDER_HISTORY_FAILED";
export const FETCH_PRODUCTS_TO_REVIEW = "FETCH_PRODUCTS_TO_REVIEW";
export const FETCH_PRODUCTS_TO_REVIEW_SUCCESS =
  "FETCH_PRODUCTS_TO_REVIEW_SUCCESS";
export const FETCH_PRODUCTS_TO_REVIEW_FAILED =
  "FETCH_PRODUCTS_TO_REVIEW_FAILED";
export const FETCH_ACCOUNT_SETTINGS = "FETCH_ACCOUNT_SETTINGS";
export const FETCH_ACCOUNT_SETTINGS_SUCCESS = "FETCH_ACCOUNT_SETTINGS_SUCCESS";
export const FETCH_ACCOUNT_SETTINGS_FAILED = "FETCH_ACCOUNT_SETTINGS_FAILED";
export const REVIEW_PRODUCT = "REVIEW_PRODUCT";
export const REVIEW_PRODUCT_SUCCESS = "REVIEW_PRODUCT_SUCCESS";
export const REVIEW_PRODUCT_FAILED = "REVIEW_PRODUCT_FAILED";
export const RESET_SETTING_SAVED = "RESET_SETTING_SAVED";
export const RESET_VERIFICATION_NEEDED = "RESET_VERIFICATION_NEEDED";
export const RESET_PASSWORD_PLACEHOLDER = "RESET_PASSWORD_PLACEHOLDER";
export const SET_FULL_ADDRESS = "SET_FULL_ADDRESS";
export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN";
export const RESET_CUSTOMER_ERROR = "RESET_CUSTOMER_ERROR";
export const PROFILE_SUCCESS = CREATE_PROFILE_SUCCESS;
export const ORDER_SUCCESS = PLACE_ORDER_SUCCESS;

interface CreatingCustomer {
  type: typeof CREATING_CUSTOMER;
  isCreating: boolean;
}

interface CreateCustomer {
  type: typeof CREATE_CUSTOMER;
  payload: any;
}

interface CreateCustomerSuccess {
  type: typeof CREATE_CUSTOMER_SUCCESS;
  customer: any;
}

interface CreateCustomerFailed {
  type: typeof CREATE_CUSTOMER_FAILED;
  error: any;
}

interface CustomerLogout {
  type: typeof CUSTOMER_LOGOUT;
}

interface CustomerLogin {
  type: typeof CUSTOMER_LOGIN;
  payload: any;
}

interface CustomerLoginSuccess {
  type: typeof CUSTOMER_LOGIN_SUCCESS;
  customer: any;
}

interface CustomerLoginFailed {
  type: typeof CUSTOMER_LOGIN_FAILED;
  error: any;
}

interface CustomerSaveSettings {
  type: typeof CUSTOMER_SAVE_SETTINGS;
  payload: any;
}

interface CustomerSaveSettingsSuccess {
  type: typeof CUSTOMER_SAVE_SETTINGS_SUCCESS;
  customer: any;
  persistCustomer: boolean;
}

interface CustomerSaveSettingsFailed {
  type: typeof CUSTOMER_SAVE_SETTINGS_FAILED;
  error: any;
}

interface FetchCustomerOrderHistory {
  type: typeof FETCH_ORDER_HISTORY;
  orderHistoryFetchLimit?: number | null;
  offset?: number | null;
}

interface FetchCustomerOrderHistorySuccess {
  type: typeof FETCH_ORDER_HISTORY_SUCCESS;
  orderHistory: Array<any> | any;
}

interface FetchCustomerOrderHistoryFailed {
  type: typeof FETCH_ORDER_HISTORY_FAILED;
  error: any;
}

interface FetchProductsToReview {
  type: typeof FETCH_PRODUCTS_TO_REVIEW;
}

interface FetchProductsToReviewSuccess {
  type: typeof FETCH_PRODUCTS_TO_REVIEW_SUCCESS;
  productsToReview: Array<any>;
}

interface FetchProductsToReviewFailed {
  type: typeof FETCH_PRODUCTS_TO_REVIEW_FAILED;
  error: any;
}

interface FetchAccountSettings {
  type: typeof FETCH_ACCOUNT_SETTINGS;
  payload: any;
}

interface FetchAccountSettingsSuccess {
  type: typeof FETCH_ACCOUNT_SETTINGS_SUCCESS;
  customer: any;
}

interface FetchAccountSettingsFailed {
  type: typeof FETCH_ACCOUNT_SETTINGS_FAILED;
  error: any;
}

interface ReviewProduct {
  type: typeof REVIEW_PRODUCT;
  review: any;
}

interface ReviewProductSuccess {
  type: typeof REVIEW_PRODUCT_SUCCESS;
  review: any;
}

interface ReviewProductFailed {
  type: typeof REVIEW_PRODUCT_FAILED;
  error: any;
}

interface ResetSettingSaved {
  type: typeof RESET_SETTING_SAVED;
}

interface ResetVerificationNeeded {
  type: typeof RESET_VERIFICATION_NEEDED;
}

interface ResetPasswordPlaceholder {
  type: typeof RESET_PASSWORD_PLACEHOLDER;
}

interface SetFullAddress {
  type: typeof SET_FULL_ADDRESS;
  address: any;
}

interface ProfileSuccess {
  type: typeof PROFILE_SUCCESS;
  config: any;
}

interface OrderSuccess {
  type: typeof ORDER_SUCCESS;
}

interface UpdateAccessToken {
  type: typeof UPDATE_ACCESS_TOKEN;
  accessToken: string;
}

interface ResetCustomerError {
  type: typeof RESET_CUSTOMER_ERROR;
}

export type CustomerActionTypes =
  | CreateCustomer
  | CreateCustomerSuccess
  | CreateCustomerFailed
  | CustomerLogout
  | CustomerLogin
  | CustomerLoginSuccess
  | CustomerLoginFailed
  | CreatingCustomer
  | CustomerSaveSettings
  | CustomerSaveSettingsSuccess
  | CustomerSaveSettingsFailed
  | FetchCustomerOrderHistory
  | FetchCustomerOrderHistorySuccess
  | FetchCustomerOrderHistoryFailed
  | ResetSettingSaved
  | FetchProductsToReview
  | FetchProductsToReviewSuccess
  | FetchProductsToReviewFailed
  | ReviewProduct
  | ReviewProductSuccess
  | ReviewProductFailed
  | ResetVerificationNeeded
  | ResetPasswordPlaceholder
  | SetFullAddress
  | ProfileSuccess
  | OrderSuccess
  | UpdateAccessToken
  | ResetCustomerError
  | FetchAccountSettings
  | FetchAccountSettingsSuccess
  | FetchAccountSettingsFailed;
