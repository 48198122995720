import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Form, Field, reduxForm, InjectedFormProps } from "redux-form";
import { getLocation, fetchPlaces } from "utils/maps";
import { RootState } from "store";
import { SetUserLocation } from "store/user/actions";
import {
  SetGeoLocationDisabled,
  SetGeoLocationDismissed,
} from "store/system/actions";
import { SetFullAddress } from "store/customer/actions";
import { Input } from "components/Forms/Fields";
import LocationIcon from "components/svgs/Location";
import NavigatorIcon from "components/svgs/Navigator";
import PlacesSearchResult from "./PlacesSearchResult";
import "./PlacesSearch.scss";
import { ResetHasDistances } from "store/locations/actions";

export interface IUser {
  inputOnly: boolean;
}
export interface IDispatchProps {
  onSubmit: any;
  inputOnly?: boolean;
  tabIndex?: number;
}

function PlacesSearch({
  change,
  handleSubmit,
  onSubmit,
  inputOnly = false,
  tabIndex = 0,
}: IDispatchProps & InjectedFormProps<IUser, IDispatchProps>) {
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => state.form.placesSearch);
  const formValue =
    formState && formState.values && formState.values.userLocationString;
  const userLocationString = useSelector(
    (state: RootState) => state.user.userLocationString
  );
  const geoLocationDisabled = useSelector(
    (state: RootState) => state.system.geoLocationDisabled
  );
  const geoLocationDismissed = useSelector(
    (state: RootState) => state.system.geoLocationDismissed
  );
  const geoLocating = useSelector(
    (state: RootState) => state.system.geoLocating
  );

  const [hasLocator, setHasLocator] = useState(
    navigator.geolocation && !geoLocationDisabled && !geoLocationDismissed
  );
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [placesSearchResults, setPlacesSearchResults] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [searchMatchesSelected, setSearchMatchesSelected] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [gettingGeo, setGettingGeo] = useState(false);
  const [usingGeo, setUsingGeo] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const [markDisabled, setMarkedDisabled] = useState(false);
  const [resultsFixedWidth, setResultsFixedWidth] = useState({});
  const [locationDismissed, setLocationDismissed] = useState(
    geoLocationDismissed
  );

  const searchRef = useRef(searchValue);
  const selectedRef = useRef(selectedAddress);
  const disabledRef = useRef(geoLocationDisabled);
  const searchEl = useRef<HTMLDivElement>(null);
  const searchingPlaces = useRef<any>(false);
  const lastSearch = useRef<any>(false);

  useEffect(() => {
    if (geoLocationDismissed && geoLocationDismissed !== locationDismissed) {
      setLocationDismissed(geoLocationDismissed);
      change("userLocationString", "");
    }
  }, [geoLocationDismissed, locationDismissed, change]);

  useEffect(() => {
    selectedRef.current = selectedAddress;
  }, [selectedAddress]);

  useEffect(() => {
    disabledRef.current = geoLocationDisabled;
  }, [geoLocationDisabled]);

  useEffect(() => {
    function placesScrollWatch() {
      if (null === searchEl.current) {
        return;
      }
      if (window.scrollY >= searchEl.current.offsetTop + 44) {
        if (!isFixed) {
          setIsFixed(true);
          setResultsFixedWidth({ width: searchEl.current.offsetWidth });
        }
      } else {
        isFixed && setIsFixed(false);
      }
    }
    window.addEventListener("scroll", placesScrollWatch, { passive: true });
    return () => window.removeEventListener("scroll", placesScrollWatch);
  });

  useEffect(() => {
    let blurTimeout: any;
    if (blurred && !geoLocating) {
      blurTimeout = setTimeout(() => {
        if (searchRef.current !== selectedRef.current) {
          change("userLocationString", selectedRef.current);
        }
        setHighlightedIndex(-1);
        setShowResults(false);
      }, 125);
    }

    return function __cleanup() {
      clearTimeout(blurTimeout);
    };
  }, [blurred, geoLocating, change]);

  // Use redux store value to determine if currently geolocating.
  // Set the value of the input to placehodler while location is fetched
  useEffect(() => {
    let delayLocate: any;
    if (geoLocationDisabled && !markDisabled && formValue !== "") {
      setMarkedDisabled(true);
      change("userLocationString", "");
      clearTimeout(delayLocate);
    } else if (
      geoLocating &&
      userLocationString &&
      formValue !== userLocationString
    ) {
      setSelectedAddress(userLocationString);
      change("userLocationString", userLocationString);
    }
    if (!geoLocationDisabled && !geoLocationDismissed && userLocationString) {
      setUsingGeo(true);
      setGettingGeo(geoLocating);
    }
    return function __cleanup() {
      clearTimeout(delayLocate);
    };
  }, [
    geoLocating,
    geoLocationDisabled,
    geoLocationDismissed,
    markDisabled,
    userLocationString,
    formValue,
    change,
  ]);

  useEffect(() => {
    if (geoLocationDisabled && usingGeo) {
      setGettingGeo(false);
      setUsingGeo(false);
      change("userLocationString", "");
    }
  }, [geoLocationDisabled, usingGeo, geoLocationDismissed, change]);

  // Use redux store to determine if user has denied access to location
  // If so, set flag that hides the "Use Current Location" option in dropdown
  useEffect(() => {
    setHasLocator(
      navigator.geolocation && !geoLocationDisabled && !geoLocationDismissed
    );
  }, [geoLocationDisabled, geoLocationDismissed]);

  useEffect(() => {
    searchRef.current = searchValue;
    if (searchValue && searchValue !== selectedAddress) {
      setShowResults(true);
    }
  }, [searchValue, selectedAddress]);

  // When search value changes, request place predictions
  useEffect(() => {
    if (searchMatchesSelected || !searchValue || searchingPlaces.current) {
      return;
    }
    searchingPlaces.current = true;
    lastSearch.current = searchValue;
    setTimeout(() => {
      searchingPlaces.current = false;
    }, 1000);
    fetchPlaces(searchValue, (predictions: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setPlacesSearchResults(predictions);
        setHighlightedIndex(-1);
      }
    });
  }, [
    searchMatchesSelected,
    searchValue,
    inputChanged,
    searchingPlaces,
    lastSearch,
  ]);

  useEffect(() => {
    if (
      !searchingPlaces &&
      !(searchMatchesSelected || !searchValue) &&
      searchValue !== lastSearch.current
    ) {
      fetchPlaces(searchValue, (predictions: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setPlacesSearchResults(predictions);
          setHighlightedIndex(-1);
        }
      });
    }
  }, [searchingPlaces, searchMatchesSelected, searchValue]);

  // If searchValue is an empty string, reset the search results
  useEffect(() => {
    if (searchValue === "") setPlacesSearchResults([]);
    if (searchValue === selectedAddress) setSearchMatchesSelected(true);
    else setSearchMatchesSelected(false);
  }, [searchValue, selectedAddress]);

  // Set appropriate flags and values on place suggestion selection
  function selectLocation(address: string, place_id: string, fullAddress: any) {
    setSearchValue(address);
    setSelectedAddress(address);
    setSearchMatchesSelected(true);
    dispatch(SetFullAddress(fullAddress));
    setShowResults(false);
    change("userLocationString", address);
    dispatch(SetUserLocation(place_id, address, ""));
    dispatch(ResetHasDistances());
    onSubmit(address, place_id);
  }

  // If user selects "Use Current Location" option, utilize the getLocation utility to fetch necessary location.
  // Set values and flags as needed based on the response.
  // If permission is denied, set flag on redux store
  function getGeoLocation() {
    setShowResults(false);
    change("userLocationString", "Locating...");
    getLocation(
      (place_id: string, address: string, zip: string) => {
        setSelectedAddress(address);
        setSearchValue(address);
        change("userLocationString", address);
        setUsingGeo(true);
        dispatch(SetUserLocation(place_id, address, zip));
        onSubmit(address);
        dispatch(SetGeoLocationDismissed(false));
      },
      (error: any) => {
        setUsingGeo(false);
        setGettingGeo(false);
        if (error.state === "denied") {
          dispatch(SetGeoLocationDisabled());
        } else {
          change("userLocationString", "");
          dispatch(SetGeoLocationDismissed(true));
        }
      }
    );
  }

  // Watch onKeyDown for selecting results with arrow keys and select result on enter
  function watchKeyDown(e: React.KeyboardEvent) {
    setInputChanged(true);
    setGettingGeo(false);
    setUsingGeo(false);
    const maxHighlightIndex = hasLocator
      ? placesSearchResults.length
      : placesSearchResults.length - 1;
    if (e.keyCode === 40 && highlightedIndex < maxHighlightIndex) {
      setHighlightedIndex(highlightedIndex + 1);
    } else if (e.keyCode === 38 && highlightedIndex >= 0) {
      setHighlightedIndex(highlightedIndex - 1);
    } else if (e.keyCode === 13 && highlightedIndex >= 0) {
      e.preventDefault();
      if (hasLocator && highlightedIndex === 0) {
        getGeoLocation();
      } else {
        const selectedAddress = hasLocator
          ? placesSearchResults[highlightedIndex - 1]
          : placesSearchResults[highlightedIndex];
        if (selectedAddress) {
          selectLocation(
            selectedAddress.description,
            selectedAddress.place_id,
            selectedAddress
          );
        }
      }
    }
  }

  // onMouseOver results list, reset the highlightedIndex
  function resetHighlightedIndex() {
    setHighlightedIndex(-1);
  }

  function onFocus(event: any) {
    event.target.select();
    if (hasLocator) {
      setShowResults(true);
    }
    setBlurred(false);
  }

  return (
    <section
      className={classNames("l-maxwidth c-places-search__wrapper", {
        "c-places-search__wrapper--fixed": isFixed,
      })}
      ref={searchEl}
    >
      <div className="c-places-search">
        <div className="c-places-search__inner">
          <Form
            className="c-places-search__form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Field
              component={Input}
              Icon={gettingGeo || usingGeo ? NavigatorIcon : LocationIcon}
              id="autocomplete-places"
              name="userLocationString"
              type="text"
              label={
                geoLocating && userLocationString === null
                  ? "Locating..."
                  : "Location"
              }
              autocomplete="new_password"
              className="places__input"
              onFocus={onFocus}
              onBlur={() => setBlurred(true)}
              onKeyDown={watchKeyDown}
              tabIndex={tabIndex}
              normalize={(value: string) => {
                value ? setSearchValue(value) : setSearchValue("");
                return value;
              }}
            />
          </Form>
        </div>
      </div>
      {placesSearchResults && showResults && (
        <div
          className={classNames("c-places-search__results", {
            "c-places-search__results--highlighted": highlightedIndex >= 0,
          })}
          onMouseOver={resetHighlightedIndex}
          style={resultsFixedWidth}
        >
          <div className="c-places-search__results__inner">
            {hasLocator && (
              <PlacesSearchResult
                locator={true}
                highlighted={highlightedIndex === 0}
                selectLocation={getGeoLocation}
              />
            )}
            {placesSearchResults.map((place, index) => {
              return (
                <PlacesSearchResult
                  key={place.place_id}
                  highlighted={
                    hasLocator
                      ? index + 1 === highlightedIndex
                      : index === highlightedIndex
                  }
                  address={place}
                  selectLocation={selectLocation}
                />
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
}

export default connect((state: RootState) => ({
  initialValues: state.locations ? state.locations : {},
}))(
  reduxForm<IUser, IDispatchProps>({
    form: "placesSearch",
  })(PlacesSearch)
);
