export interface UserState {
  userLocationID?:      string
  userLocationString?:  string | null
  filter?:              string
  pickupLocation?:      null | string
  userLocationZip?:     string
}

export const SET_USER_LOCATION    = 'SET_USER_LOCATION';
export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';

interface SetUserLocation {
  type:           typeof SET_USER_LOCATION,
  locationID:     string,
  locationString: string,
  locationZip:    string
}

interface SetLocationFilter {
  type:   typeof SET_LOCATION_FILTER,
  filter: string,
}

export type UserActionTypes = 
  SetUserLocation | 
  SetLocationFilter;