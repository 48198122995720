import { call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import moment from "moment-timezone";
import {
  FETCH_DISPENSARIES,
  FETCH_DISPENSARIES_SUCCESS,
  FETCH_DISPENSARIES_FAILED,
  SET_DISPENSARY_IS_OPEN,
  SET_DISPENSARY_IS_OPEN_SUCCESS,
  SET_DISPENSARY_IS_OPEN_FAILED,
} from "store/locations/types";
import { sagaURL } from "store/sagas";
import {
  HoursOfOperationKeyType,
  HoursOfOperationType,
} from "../../constants/dispensary";
import { setAuthorizationHeader } from "../../utils/auth";
import * as customerSelectors from "../customer/selectors";

enum WeekDays {
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
}

function callFetchDispensaries(accessToken = null) {
  setAuthorizationHeader(accessToken);

  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
      },
      query: `{
      dispensaries {
        id
        name
        slug
        storeId
        isMedical
        isRecreational
        stateRegulations
        allowOrders
        dispensaryUrl
        timezone
        hoursOfOperation {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        address {
          id
          streetAddressLine1
          streetAddressLine2
          city
          state
          stateFull
          zipCode
        }
        phoneNumber {
          id
          phoneNumber
          extension
        }
        googlePlace {
          id
          googlePlaceId
          googlePlaceLink
        }
      }
    }`,
    })
    .then(({ data: { data, errors } }) => {
      if (!!errors) {
        throw new Error(errors[0]?.message || null);
      }

      if (!!data && "dispensaries" in data) {
        let locations: any = [];
        data.dispensaries.forEach((location: any) => {
          location.acceptingPreorders = location.allowOrders;
          location.shopLink = location.dispensaryUrl;
          location.isOpen = checkIfDispensaryIsOpen(
            location.hoursOfOperation,
            location.timezone
          );
          locations.push(location);
        });
        return locations;
      }
    })
    .catch((error) => {
      throw error;
    });
}

function checkIfDispensaryIsOpen(
  hoursOfOperation: HoursOfOperationType,
  timezone: string
) {
  if (!timezone) {
    return false;
  }

  const time = moment().tz(timezone);
  const openHours = hoursOfOperation[
    WeekDays[time.day()] as HoursOfOperationKeyType
  ]?.split(" - ");

  if (openHours) {
    const openHour = openHours[0].split(":");
    const closeHour = openHours[1].split(":");
    const openTime = moment(time).set({
      hour:Number(openHour[0]) ===0 ? 24 : Number(openHour[0])   ,
      minute: Number(openHour[1]),
      second: Number(openHour[2]),
    });
    const closeTime = moment(time).set({
      hour: Number(closeHour[0]) ===0 ? 24 : Number(closeHour[0]),
      minute: Number(closeHour[1]),
      second: Number(closeHour[2]),
    });

    return time.isBetween(openTime, closeTime);
  }

  return false;
}

function* fetchDispensaries() {
  try {
    const accessToken = yield select(customerSelectors.accessToken);
    const dispensaries = yield call(callFetchDispensaries, accessToken);

    yield put({ type: FETCH_DISPENSARIES_SUCCESS, dispensaries: dispensaries });
  } catch (e) {
    console.log("FetchDispensary error:", e);
    yield put({ type: FETCH_DISPENSARIES_FAILED, message: e });
  }
}

function* setDispensaryIsOpen(action: any) {
  try {
    const isOpen = yield checkIfDispensaryIsOpen(
      action.hoursOfOperation,
      action.timezone
    );
    yield put({
      type: SET_DISPENSARY_IS_OPEN_SUCCESS,
      isOpen: isOpen,
      slug: action.slug,
    });
  } catch (e) {
    console.log("DispensaryIsOpen error:", e);
    yield put({ type: SET_DISPENSARY_IS_OPEN_FAILED, error: e });
  }
}

function* locationSaga() {
  yield takeLatest(FETCH_DISPENSARIES, fetchDispensaries);
  yield takeLatest(SET_DISPENSARY_IS_OPEN, setDispensaryIsOpen);
}

export default locationSaga;
