import React, { useEffect, useState } from "react";
import auth0 from "auth0-js";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import ForgotPasswordForm from "components/Account/ForgotPasswordForm";
import "./ForgotPassword.scss";

function ForgotPassword({ history }: RouteComponentProps) {
  const { webAuth } = window;
  const [showResetComplete, setShowResetComplete] = useState<boolean>(false);
  const [emailText, setEmailText] = useState("");

  useEffect(() => {
    if (!webAuth) {
      const webAuthDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";
      const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || "";
      window.webAuth = new auth0.WebAuth({
        domain: webAuthDomain,
        clientID: webAuthClientId,
      });
    }
  }, [webAuth]);

  function handleSubmit(values: any) {
    const dbConnection = process.env.REACT_APP_AUTH0_USER_DB;
    webAuth.changePassword(
      {
        connection: dbConnection,
        email: values.email,
      },
      function (err: any, resp: any) {
        if (resp) {
          setShowResetComplete(true);
          setEmailText(values.email);
        }
        // history.push('/account/signin?password-reset=true')
      }
    );
  }

  return (
    <section className={"l-main c-forgot-password"}>
      <div className={`l-maxwidth l-maxwidth--small c-forgot-password__box`}>
        <h1>Reset Password</h1>
        {showResetComplete && emailText ? (
          <>
            <p>
              We have sent a change password link to{" "}
              <strong>{emailText}</strong>
            </p>
            <p>You will need to reset your password before logging in.</p>
          </>
        ) : (
          <>
            <p>
              If we find an account associated with your email address, a link
              will be sent to reset your password
            </p>
            <div className="c-forgot-password__options">
              <ForgotPasswordForm onSubmit={handleSubmit} />
              <div className="c-signin__signup">
                Don't have an account? <Link to="/account/signup">Sign Up</Link>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default withRouter(ForgotPassword);
