import React, { useRef }    from 'react';
import { Field }            from 'redux-form';
import { Input }            from '.';
import { normalizeNumber }  from 'components/Forms/Normalizers';
import './Fields.scss';
import { DAY_MAX_LENGTH, MONTH_MAX_LENGTH, YEAR_MAX_LENGTH } from "./constants";

interface BirthDateProps {
  tabIndex?:number
}
// General input to be used by forms
// Placeholders or labels are to be used independent of one another as the desired design would clash otherwise.
const BirthDateField = ({ 
  tabIndex 
}: BirthDateProps) => {
  const dayInput    = useRef<HTMLInputElement>(null);
  const monthInput  = useRef<HTMLInputElement>(null);
  const yearInput   = useRef<HTMLInputElement>(null);

  function checkKey(e: any) {
    const inputTarget = (e.target as HTMLInputElement);
    if(e.keyCode === 8){
      if(inputTarget.value === ''){
        if(inputTarget.name === 'day' && null !== monthInput.current){
          monthInput.current.getElementsByTagName('input')[0].focus()
        } else if(inputTarget.name === 'year' && null !== dayInput.current){
          dayInput.current.getElementsByTagName('input')[0].focus()
        }
      }
    }
  }

  function keyUp(e:any) {
    if(!((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105))) { 
      return;
    }
    const inputName = e.currentTarget.getAttribute('name');
    const inputValue = e.target.value;
    if(inputName === 'month' && inputValue){
      const onlyNums = inputValue.replace(/[^\d]/g, '');
      const trueValue = parseInt(onlyNums);
      if (null !== dayInput.current && trueValue <= 12 && onlyNums.length === 2) {
        dayInput.current.getElementsByTagName('input')[0].focus()
      }
    }

    if(inputName === 'day' && inputValue){
      const onlyNums = inputValue.replace(/[^\d]/g, '');
      const trueValue = parseInt(onlyNums);
      if (null !== yearInput.current && trueValue <= 31  && onlyNums.length === 2) {
        yearInput.current.getElementsByTagName('input')[0].focus()
      }
    }
  }
  return (
    <>
      <p className="body--xsmall agegate__fieldset__label">
        ID Holder’s Date of Birth
      </p>
      <div className="agegate__fieldset agegate__fieldset--outside-agegate">
        <div ref={monthInput} className="agegate__form-column">
          <Field
            component={Input} 
            name="month" 
            type="number" 
            placeholder="mm"
            normalize={normalizeNumber}
            onKeyUp={keyUp}
            onKeyDown={checkKey}
            maxlength={MONTH_MAX_LENGTH}
            className="agegate__month-input"
            tabIndex={tabIndex}
          />
          <label htmlFor="month" className="agegate__label">Month</label>
        </div>
        <div ref={dayInput} className="agegate__form-column">
          <Field 
            component={Input} 
            name="day" 
            type="number"
            placeholder="dd"
            onKeyUp={keyUp}
            maxlength={DAY_MAX_LENGTH}
            normalize={normalizeNumber}
            onKeyDown={checkKey}
            className="agegate__day-input"
            tabIndex={tabIndex}
          />
          <label htmlFor="day"  className="agegate__label">Day</label>
        </div>
        <div ref={yearInput} className="agegate__form-column agegate__form-column--wide">
          <Field 
            component={Input} 
            name="year"
            type="number"
            placeholder="yyyy"
            onKeyUp={keyUp}
            normalize={normalizeNumber}
            onKeyDown={checkKey}
            maxlength={YEAR_MAX_LENGTH}
            className="agegate__year-input"
            tabIndex={tabIndex}
          />
          <label htmlFor="year"  className="agegate__label">Year</label>
        </div>
      </div>
    </>
  )
}
export default BirthDateField;