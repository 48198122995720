import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import auth0 from "auth0-js";
import { BrowserRouter } from "react-router-dom";
import { withOrientationChange, isSafari } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import { RootState } from "store";
import { Helmet } from "react-helmet";
import {
  FetchAccountSettings,
  UpdateAccessToken,
} from "store/customer/actions";
import initializeGA from "utils/ga";

import {
  SetDeviceSize,
  SetWindowHeight,
  SetGeoLocationDisabled,
  SetGeoLocating,
  SetResetKioskTimer,
} from "store/system/actions";
import RouteContainer from "containers/RouteContainer";
import KioskTimer from "components/KioskTimer";
import packageJson from "../package.json";
import SplashScreen from "./components/SplashScreen";
// import OrientationScreen from "./components/OrientationScreen";
import FylloImage from "components/FylloImage";

import "sass/App.scss";

export const SPLASH_SCREEN_TIMEOUT = 2000;
const appVersion = packageJson.version;

declare global {
  interface Window {
    google: any;
    kiosk: boolean;
    webAuth: any;
  }
}

interface AppProps {
  isPortrait: boolean;
  isLandscape: boolean;
}

function App({ isPortrait, isLandscape }: AppProps) {
  const { webAuth } = window;

  const dispatch = useDispatch();
  const windowHeight = useSelector(
    (state: RootState) => state.system.windowHeight
  );
  const isKiosk = useSelector((state: RootState) => state.system.isKiosk);
  const isForageEmbed = useSelector((state: RootState) => state.system.isForageEmbed || false);
  const resetKioskTimer = useSelector(
    (state: RootState) => state.system.resetKioskTimer
  );
  const customer = useSelector((state: RootState) => state.customer.customer);
  const isMobile = useSelector((state: RootState) => state.system.isMobile);
  const isTablet = useSelector((state: RootState) => state.system.isTablet);
  const cartItems = useSelector((state: RootState) => state?.cart?.items || []);

  const [authInstance, setAuthInstance] = useState<any>(webAuth);
  const [pageSizerRef, setPageSizerRef] = useState<any>(null);
  const [wWidth, setWWidth] = useState(window.innerWidth);
  const [windowWidth, setWindowWidth] = useState(wWidth);
  const [maintainingSession, setMaintainingSession] = useState(false);
  const [maintenanceInteral, setMaintenanceInterval] = useState<any>(null);
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(
    isMobile || isTablet
  );
  const [showApp, setShowApp] = useState<boolean>(!showSplashScreen);
  // const showOrientationScreen = isMobile && isLandscape;
  const [showKioskTimer, setShowKioskTimer] = useState<boolean>(false);
  const [buildTimeStamp, setBuildTimeStamp] = useState<any>(null);

  // const [docReady, setDocReady] = useState("");

  const accessToken = sessionStorage.getItem("forage_token");

  const checkSession = useCallback(() => {
    authInstance.checkSession(
      {
        audience: process.env.REACT_APP_API_URL,
        responseType: "token id_token",
        redirectUri: window.location.origin + "/account/signin",
      },
      function (err: any, authResult: any) {
        // console.log(err, authResult);
        console.log(err);
        if (err) {
          sessionStorage.removeItem("forage_token");
          sessionStorage.removeItem("forage_token_timestamp");
          clearInterval(maintenanceInteral);
        } else if (authResult.accessToken) {
          sessionStorage.setItem(
            "forage_token_timestamp",
            moment().unix().toString()
          );
          sessionStorage.setItem("forage_token", authResult.accessToken);
          dispatch(UpdateAccessToken(authResult.accessToken));
        }
      }
    );
  }, [authInstance, maintenanceInteral, dispatch]);

  useEffect(() => {
    initializeGA();
    setBuildTimeStamp(new Date().getTime());
  }, []);

  useEffect(() => {
    if (showSplashScreen) {
      setTimeout(() => {
        setShowSplashScreen(false);
      }, SPLASH_SCREEN_TIMEOUT);
    }

    if (!showApp) {
      setTimeout(() => {
        setShowApp(true);
      }, SPLASH_SCREEN_TIMEOUT - 500);
    }
  }, [showSplashScreen, showApp]);

  useEffect(() => {
    if (isKiosk) return;
    if (!authInstance) {
      const webAuthDomain = process.env.REACT_APP_AUTH0_DOMAIN || "";
      const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || "";
      window.webAuth = new auth0.WebAuth({
        domain: webAuthDomain,
        clientID: webAuthClientId,
      });
      setAuthInstance(window.webAuth);
    } else if (authInstance && accessToken && !maintainingSession) {
      setMaintainingSession(true);
      setMaintenanceInterval(setInterval(checkSession, 30 * 60 * 1000));
      const tokenTimestamp = sessionStorage.getItem("forage_token_timestamp");
      const lastChecked = tokenTimestamp
        ? moment.unix(parseInt(tokenTimestamp.toString(), 10))
        : false;
      const nowTime = moment();
      const shouldCheck = !lastChecked
        ? true
        : nowTime.diff(lastChecked, "minutes") > 30;
      if (shouldCheck) {
        checkSession();
      }
    } else if (!accessToken) {
      clearInterval(maintenanceInteral);
    }
  }, [
    authInstance,
    webAuth,
    accessToken,
    maintainingSession,
    maintenanceInteral,
    checkSession,
    isKiosk,
  ]);

  useEffect(() => {
    if (customer && customer.readyToLoad) {
      dispatch(FetchAccountSettings({ accessToken: customer.accessToken }));
    }
  }, [customer, dispatch]);

  if (
    isSafari &&
    window.location.protocol.indexOf("https") === -1 &&
    process.env.NODE_ENV === "production"
  ) {
    dispatch(SetGeoLocationDisabled());
    dispatch(SetGeoLocating(false));
  }

  useEffect(() => {
    if (isLandscape) {
      if (windowWidth < 1024 && windowWidth >= 900) {
        dispatch(SetDeviceSize("tablet"));
      } else if (windowWidth < 900) {
        dispatch(SetDeviceSize("mobile"));
      } else {
        dispatch(SetDeviceSize("desktop"));
      }
    } else {
      if (windowWidth < 1024 && windowWidth >= 768) {
        dispatch(SetDeviceSize("tablet"));
      } else if (windowWidth < 768) {
        dispatch(SetDeviceSize("mobile"));
      } else {
        dispatch(SetDeviceSize("desktop"));
      }
    }
  }, [windowWidth, isLandscape, dispatch]);

  useEffect(() => {
    if (isKiosk) {
      if (
        isKiosk &&
        window.location.pathname === "/" &&
        (!cartItems?.length || cartItems === [])
      ) {
        dispatch(SetResetKioskTimer(false));
        setShowKioskTimer(false);
      } else {
        dispatch(SetResetKioskTimer(true));
        setShowKioskTimer(true);
      }
    }
    // eslint-disable-next-line
  }, [cartItems]);

  useEffect(() => {
    function resetKioskTimer() {
      setShowKioskTimer(true);
      dispatch(SetResetKioskTimer(true));
    }

    if (isKiosk) {
      document.addEventListener("mousedown", resetKioskTimer);
      window.addEventListener("scroll", resetKioskTimer);
      document.addEventListener("keydown", resetKioskTimer);
    }
    return () => {
      if (isKiosk) {
        document.removeEventListener("mousedown", resetKioskTimer);
        window.removeEventListener("scroll", resetKioskTimer);
        document.removeEventListener("keydown", resetKioskTimer);
      }
    };
  }, [isKiosk, dispatch, showKioskTimer]);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
  });

  useEffect(() => {
    onWindowResize();
  });

  function onWindowResize() {
    setTimeout(() => {
      if (pageSizerRef !== null && windowHeight !== pageSizerRef.offsetHeight) {
        dispatch(SetWindowHeight(pageSizerRef.offsetHeight));
      }
    }, 500);

    if (window.innerWidth !== windowWidth) {
      setWindowWidth(window.innerWidth);
      setWWidth(window.innerWidth);
    }
  }

  function sizerRefLoaded(element: HTMLDivElement) {
    if (element && pageSizerRef === null) {
      setPageSizerRef(element);
      dispatch(SetWindowHeight(element.offsetHeight));
    }
  }

  useEffect(() => {
    /* <!-- Start of HubSpot Embed Code --> */
    const script = document.createElement("script");
    script.src = "//js.hs-scripts.com/5757798.js"; // URL for the third-party library being loaded.
    script.id = "hs-script-loader"; // e.g., googleMaps or stripe
    script.async = true;
    script.defer = true;
    script.type = "text/javascript";
    document.body.appendChild(script);
    /* <!-- End of HubSpot Embed Code --> */
  }, []);

  return (
    <BrowserRouter>
      <Helmet>
        <meta name="version" content={appVersion} />
        <script async src="/js/alpineiq.js" />
      </Helmet>
      <FylloImage src="https://ad.ipredictive.com/d/rt/pixel?rtsite_id=51292&uuid=8ebd0554-aa4c-4c65-9288-db15df35c14c&rr=CACHE_BUSTER" />
      {buildTimeStamp && (
        <FylloImage
          src={`https://ad.ipredictive.com/d/track/cvt/pixel?acct_id=46877&cache_buster=${buildTimeStamp}`}
        />
      )}
      {/* {showOrientationScreen && <OrientationScreen />} */}
      {showSplashScreen && <SplashScreen />}
      {showApp && <RouteContainer />}
      {!isForageEmbed && isKiosk && (showKioskTimer || resetKioskTimer) && <KioskTimer />}
      <div className="page-sizer" ref={sizerRefLoaded}></div>
    </BrowserRouter>
  );
}

const DATA_DOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_ACCOUNT_ID || "";
const DATA_DOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "";
const REACT_APP_DOMAIN =
  process?.env?.REACT_APP_DOMAIN?.replace(/.io/, "").replace(/\./g, "-") || "";
const kioskHostnames = ["kiosk.forage.io", "kiosk.dev.forage.io"];
let DATA_DOG_ENV = REACT_APP_DOMAIN.indexOf("dev") <= -1 ? "prod" : "dev";
let DATA_DOG_SERVICE = "www-" + REACT_APP_DOMAIN;

if (typeof window !== "undefined") {
  if (
    kioskHostnames.indexOf(window.location.hostname) !== -1 ||
    localStorage?.getItem("forage_kiosk") === "1"
  ) {
    DATA_DOG_SERVICE = DATA_DOG_SERVICE.replace(/www/, "kiosk");
  }
}

datadogRum.init({
  applicationId: DATA_DOG_APPLICATION_ID,
  clientToken: DATA_DOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: DATA_DOG_SERVICE,
  env: DATA_DOG_ENV,
  version: appVersion,
  sampleRate: 100,
  trackInteractions: true,
});

export default withOrientationChange(App);
