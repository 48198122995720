import {
  APPLY_REC_FINDER_PREFERENCE_ADJUSTMENT,
  FETCH_REC_CONFIG,
  FETCH_REC_CONFIG_FAILED,
  FETCH_REC_CONFIG_SUCCESS,
  FETCH_REC_COUNT,
  FETCH_REC_COUNT_FAILED,
  FETCH_REC_COUNT_SUCCESS,
  FETCH_RECS,
  CUST_FETCH_RECS,
  FETCH_RECS_FAILED,
  FETCH_RECS_SUCCESS,
  RecsActionTypes,
  RecState,
  RESET_REC_FINDER_PREFERENCE_ADJUSTMENT,
  RESET_RECS,
  SET_CAROUSEL_INDEX,
  SET_FILLOUT_FEELINGS,
  SET_REC_FINDER_OPEN,
  SET_REC_FINDER_PREFERENCE,
  SET_REC_FINDER_PREFERENCE_ADJUSTMENT,
  SET_REC_FINDER_STEP,
  SET_REC_XY,
  SET_RECOMMENDATION_PAGE,
  SET_RECOMMENDATION_SORT,
  SET_RECS_CHANGING,
  SET_RECS_LOCATION,
  SET_SEARCH,
  SET_RECOMMENDATION_FILTER,
  RESET_RECS_PREVIOUS_RESULT_DATA,
  RECS_PREVIOUS_RESULT_DATA,
} from "./types";

let activeDispensary: any =
  localStorage.getItem("forage_cart_dispensary") || "";

let getRecPreviousResultData: null | string =
  localStorage.getItem(RECS_PREVIOUS_RESULT_DATA) || null;
let initialRecPreviousResultData = null;
if (getRecPreviousResultData !== "" && getRecPreviousResultData !== null) {
  initialRecPreviousResultData = JSON.parse(getRecPreviousResultData);
}

const initialState: RecState = {
  recFinderOpen: false,
  recFinderStep: 1,
  recsLoading: false,
  recsError: false,
  recs: null,
  recsAllFormats: null,
  recCountLoading: false,
  recCountError: false,
  recCount: null,
  recConfigLoading: false,
  remainingRecsArray: [],
  remainingRecsObj: {},
  recConfigError: false,
  adjustmentApplied: false,
  recConfig: null,
  recProductIds: [],
  page: 1,
  stepList: activeDispensary
    ? ["", "adjust", "uses", "occasions", "formats", "results"]
    : ["", "adjust", "uses", "occasions", "formats", "location", "results"],
  recsDispensary: null,
  fillOutFeelings: null,
  carouselIndex: 0,
  recsChanging: false,
  searchQuery: "",
  sort: "alpha_ascending",
  filter: {},
  recPreferences: {
    feelings: {
      aroused: 0,
      creative: 0,
      energetic: 0,
      euphoric: 0,
      focused: 0.5,
      giggly: 0,
      happy: 0,
      hungry: 0,
      relaxed: 0,
      sleepy: 0,
      talkative: 0,
      tingly: 0,
      uplifted: 0.5,
    },
    activities: {
      creating: false,
      focusing: false,
      hookingUp: false,
      managingPain: false,
      medicating: false,
      partying: false,
      recovering: false,
      relaxing: false,
      sleeping: false,
      talking: false,
      working: false,
    },
    occasions: {
      goingToConcert: false,
      houseParty: false,
      havingFunOutdoors: false,
      watchingMovie: false,
      prePostWorkout: false,
      relaxingAtHome: false,
      complimentWork: false,
      helpingMeSleep: false,
      sharingWithFriends: false,
      nothingInParticular: false,
    },
    formats: {
      beverages: false,
      oil: false,
      pills: false,
      topicals: false,
      flower: false,
      vapes: false,
      edibles: false,
      spray: false,
      patch: false,
    },
    price: {
      low: false,
      medium: false,
      high: false,
    },
    profileThcPreference: null,
    limit: null,
  },
  recPreferencesAdjustments: {
    feelings: {
      aroused: 0,
      creative: 30,
      energetic: 0,
      euphoric: 0,
      focused: 30,
      giggly: 0,
      happy: 0,
      hungry: 0,
      relaxed: 0,
      sleepy: 0,
      talkative: 30,
      tingly: 0,
      uplifted: 30,
    },
    activities: {
      creating: false,
      focusing: false,
      hookingUp: false,
      managingPain: false,
      medicating: false,
      partying: false,
      recovering: false,
      relaxing: false,
      sleeping: false,
      talking: false,
      working: false,
    },
    occasions: {
      goingToConcert: false,
      houseParty: false,
      havingFunOutdoors: false,
      watchingMovie: false,
      prePostWorkout: false,
      relaxingAtHome: false,
      complimentWork: false,
      helpingMeSleep: false,
      sharingWithFriends: false,
      nothingInParticular: false,
    },
    formats: {
      beverages: false,
      oil: false,
      pills: false,
      topicals: false,
      flower: false,
      vapes: false,
      edibles: false,
      spray: false,
      patch: false,
    },
    price: {
      low: false,
      medium: false,
      high: false,
    },
    profileThcPreference: null,
    limit: null,
  },
  recPreviousResultData: {
    recProductIds: initialRecPreviousResultData?.recProductIds || null,
    recPreferences: initialRecPreviousResultData?.recPreferences || null,
  },
  totalRecProducts: null,
  xy: {
    x: null,
    y: null,
  },
};

export function recsReducer(
  state = initialState,
  action: RecsActionTypes
): RecState {
  switch (action.type) {
    case SET_FILLOUT_FEELINGS: {
      return {
        ...state,
        fillOutFeelings: action.fillOutFeelings,
      };
    }
    case SET_REC_XY: {
      return {
        ...state,
        xy: action.xy,
      };
    }

    case SET_REC_FINDER_OPEN: {
      return {
        ...state,
        recFinderOpen: true,
      };
    }

    case SET_REC_FINDER_STEP: {
      return {
        ...state,
        recFinderStep: action.step,
      };
    }

    case SET_REC_FINDER_PREFERENCE: {
      let prefs: any = { ...state.recPreferences };
      prefs[action.id] = action.value;
      return {
        ...state,
        recPreferences: { ...prefs },
        recPreferencesAdjustments: { ...prefs },
      };
    }

    case SET_REC_FINDER_PREFERENCE_ADJUSTMENT: {
      let prefs: any = { ...state.recPreferencesAdjustments };
      prefs[action.id] = action.value;
      return {
        ...state,
        recPreferencesAdjustments: { ...prefs },
      };
    }

    case APPLY_REC_FINDER_PREFERENCE_ADJUSTMENT: {
      let prefs: any = { ...state.recPreferencesAdjustments };
      return {
        ...state,
        recPreferences: { ...prefs },
        adjustmentApplied: true,
      };
    }

    case RESET_REC_FINDER_PREFERENCE_ADJUSTMENT: {
      let prefs: any = { ...state.recPreferences };
      return {
        ...state,
        recPreferencesAdjustments: { ...prefs },
      };
    }

    case FETCH_RECS: {
      return {
        ...state,
        recsLoading: true,
      };
    }

    case CUST_FETCH_RECS: {
      let prefs: any = action.payload;
      return {
        ...state,
        recsLoading: true,
        recPreferences: { ...prefs },
        recPreferencesAdjustments: { ...prefs },
      };
    }

    case FETCH_RECS_SUCCESS: {
      let recObj: any = {};
      let recArray: any = [];
      let sortedRecArray: any = [];
      let bestFor: any = null;

      action.recs.data.forEach((item: any) => {
        if (recArray.indexOf(item.product.productId) === -1) {
          recArray.push(item.product.productId);
          if (item.bestForTokens !== null) {
            const bestForTokens = item.bestForTokens.split(",");
            const bestForValues = item.bestForValues.split(",");
            bestFor = bestForTokens.map((token: string, index: number) => {
              return {
                title: token,
                percentage: bestForValues[index],
              };
            });
          }
          // else {
          //   console.log("Recommendation missing `best for`: ", item.product);
          // }
          item.bestFor = bestFor;
          if (item.feelings && item.feelingValues) {
            const allowedFeelings = [
              "energetic",
              "focused",
              "uplifted",
              "euphoric",
              "talkative",
              "creative",
              "happy",
              "hungry",
              "giggly",
              "relaxed",
              "sleepy",
              "aroused",
              "tingly",
            ];
            let feelingData: any = [];
            item.feelings.forEach((feeling: string, index: number) => {
              if (allowedFeelings.indexOf(feeling.toLowerCase()) >= 0) {
                feelingData.push({
                  feeling: feeling,
                  value: item.feelingValues[index],
                });
              }
            });
            feelingData = feelingData.sort((itemA: any, itemB: any) =>
              parseInt(itemA.value, 10) < parseInt(itemB.value, 10) ? 1 : -1
            );
            item.feelingData = feelingData;
          } else {
            item.feelingData = null;
          }
          delete item.feelings;
          delete item.feelingValues;

          recObj[item.product.productId] = item;
        }
      });

      // sortedRecArray = recArray.sort(
      //   (a: any, b: any) =>
      //     recObj[a].recommendationEngineScores.match <
      //     recObj[b].recommendationEngineScores.match
      // );

      sortedRecArray = recArray.slice(0, 10);
      const remainingRecs = recArray.slice(10, recArray.length);

      let top10Recs: any = {};
      let restRecs: any = {};

      sortedRecArray.map((id: number | string) => (top10Recs[id] = recObj[id]));
      remainingRecs.map((id: number | string) => (restRecs[id] = recObj[id]));

      const recPreviousResultData = {
        recProductIds: sortedRecArray,
        recPreferences: state.recPreferences,
      };

      localStorage.setItem(
        RECS_PREVIOUS_RESULT_DATA,
        JSON.stringify(recPreviousResultData)
      );

      return {
        ...state,
        recsLoading: false,
        recsError: false,
        recs: top10Recs,
        recCount: sortedRecArray.length,
        recProductIds: sortedRecArray,
        adjustmentApplied: false,
        recsAllFormats: action.recNoFormat.data,
        remainingRecsArray: remainingRecs,
        remainingRecsObj: restRecs,
        // page: 1,
        recPreviousResultData: recPreviousResultData,
        totalRecProducts: action.recs.totalMatches,
      };
    }

    case FETCH_RECS_FAILED: {
      return {
        ...state,
        recsLoading: false,
        recsError: true,
      };
    }

    case FETCH_REC_COUNT: {
      return {
        ...state,
        recCountLoading: true,
      };
    }

    case FETCH_REC_COUNT_SUCCESS: {
      return {
        ...state,
        recCountLoading: false,
        recCountError: false,
        recCount: action.recCount,
      };
    }

    case FETCH_REC_COUNT_FAILED: {
      return {
        ...state,
        recCountLoading: false,
        recCountError: true,
      };
    }

    case FETCH_REC_CONFIG: {
      return {
        ...state,
        recConfigLoading: true,
      };
    }

    case FETCH_REC_CONFIG_SUCCESS: {
      return {
        ...state,
        recConfigLoading: false,
        recConfigError: false,
        recConfig: action.config,
      };
    }

    case FETCH_REC_CONFIG_FAILED: {
      return {
        ...state,
        recConfigLoading: false,
        recConfigError: true,
      };
    }

    case SET_RECS_LOCATION: {
      return {
        ...state,
        recsDispensary: action.activeDispensary,
        stepList: action.activeDispensary
          ? ["", "adjust", "uses", "occasions", "formats", "results"]
          : [
              "",
              "adjust",
              "uses",
              "occasions",
              "formats",
              "location",
              "results",
            ],
      };
    }
    case SET_CAROUSEL_INDEX: {
      return {
        ...state,
        carouselIndex: action.index,
      };
    }

    case SET_RECOMMENDATION_PAGE: {
      return {
        ...state,
        recsLoading: true,
        page: action.page,
      };
    }

    case SET_RECS_CHANGING: {
      return {
        ...state,
        recsChanging: action.recsChanging,
      };
    }

    case RESET_RECS: {
      return {
        ...state,
        recs: null,
        recProductIds: null,
        remainingRecsArray: null,
        remainingRecsObj: null,
        recCount: null,
        searchQuery: "",
        totalRecProducts: null,
        sort: initialState.sort,
        page: 1,
        xy: {
          x: null,
          y: null,
        },
      };
    }

    case SET_SEARCH: {
      return {
        ...state,
        searchQuery: action.searchQuery,
      };
    }

    case SET_RECOMMENDATION_SORT: {
      return {
        ...state,
        sort: action.sort,
        page: 1,
        searchQuery: "",
        recsLoading: true,
      };
    }

    case SET_RECOMMENDATION_FILTER: {
      return {
        ...state,
        filter: action.filter,
        searchQuery: "",
      };
    }

    case RESET_RECS_PREVIOUS_RESULT_DATA: {
      localStorage.removeItem(RECS_PREVIOUS_RESULT_DATA);

      return {
        ...state,
        recPreviousResultData: {
          recProductIds: null,
          recPreferences: null,
        },
      };
    }

    default:
      return state;
  }
}
