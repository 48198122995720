import { call, put, takeLatest, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import axios from "axios";
import {
  PLACE_ORDER,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILED,
  FETCH_PREORDER,
  FETCH_PREORDER_SUCCESS,
  FETCH_PREORDER_FAILED,
} from "store/orders/types";
import { sagaURL } from "store/sagas";
import { setAuthorizationHeader } from "../../utils/auth";

function callPlaceOrder(
  cart: any,
  customer: any,
  activeDispensary: string,
  orderOnly: any,
  recPreviousResultData: any
) {
  if (recPreviousResultData?.recPreferences) {
    delete recPreviousResultData?.recPreferences?.isReturnInventory;
    delete recPreviousResultData?.recPreferences?.limit;
  }

  const orderData = {
    accessToken: customer.accessToken,
    dateOfOrder: "2020-09-21",
    // productNames : cart.items.map((item:any) => item.productName),
    // vendorNames : cart.items.map((item:any) => item.brandName),
    productIds: cart.items.map((item: any) => parseInt(item.productId, 10)),
    quantities: cart.items.map((item: any) => parseInt(item.quantity, 10)),
    discountAmounts: cart.items.map((item: any) => item.discountsAmounts),
    discountNames: cart.items.map((item: any) => item.discountsNames),
    prices: cart.items.map((item: any) => item.unitPrice),
    subTotal: cart.price.subTotal,
    salesTax: cart.price.taxes,
    discount: cart.price.discount,
    total: cart.price.total,
    isDelivery: false,
    orderSource: "Forage",
    dispensarySlug: activeDispensary,
    uuid: cart.uuid,
    recommendationInput: recPreviousResultData?.recPreferences || null,
    recommendationResult: recPreviousResultData?.recProductIds || null,
  };

  setAuthorizationHeader(customer.accessToken);
  axios.defaults.headers.common["slug"] = activeDispensary;

  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: activeDispensary,
      },
      query: `mutation createPreOrder (
        $data: CreatePreOrderInput
      ) {
      createPreOrder(data: $data){
        orderId
        status
        transaction {
          transactionId
          transactionDate
          voidDate
          isVoid
          subtotal
          totalDiscount
          totalBeforeTax
          tax
          total
          paid
          changeDue
          totalItems
          terminalName
          checkInDate
          invoiceNumber
          transactionType
          loyaltyEarned
          loyaltySpent
          lastModifiedDateUTC
          cashPaid
          debitPaid
          electronicPaid
          electronicPaymentMethod
          checkPaid
          creditPaid
          taxSummary
          orderType
          wasPreOrdered
          orderSource
          invoiceName
          isReturn
        }
        orderDate
        orderType
        orderSource
        rejectedReason
      }
    }`,
      variables: {
        data: orderData,
      },
    })
    .then(({ data }) => {
      const {
        data: { createPreOrder },
      } = data;
      return createPreOrder;
    })
    .catch((error) => {
      throw error;
    });
}

function callGetPreorder(
  orderId: number,
  customer: any,
  activeDispensary: string
) {
  setAuthorizationHeader(customer.accessToken);
  axios.defaults.headers.common["slug"] = activeDispensary;
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: activeDispensary,
      },
      query: `query getPreOrderStatus (
        $preOrderId: ID!
        $accessToken: String!
      ) {
        getPreOrderStatus(preOrderId: $preOrderId, accessToken: $accessToken){
        
        orderId
        status
        productNames
        vendorNames
        productIds
        prices
        quantities
        subTotal
        total
        salesTax
        customer {
          name
        }
        dispensary {
          id
          name
          slug
          googlePlace {
            googlePlaceId
            googlePlaceLink
          }
          phoneNumber {
            phoneNumber
            extension
          }
          hoursOfOperation {
            sunday
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
          }
          address{
            streetAddressLine1
            city
            state
            zipCode
          }
        }
        transaction {
          transactionId
          transactionDate
          voidDate
          isVoid
          subtotal
          totalDiscount
          totalBeforeTax
          tax
          total
          paid
          changeDue
          totalItems
          terminalName
          checkInDate
          invoiceNumber
          transactionType
          loyaltyEarned
          loyaltySpent
          lastModifiedDateUTC
          cashPaid
          debitPaid
          electronicPaid
          electronicPaymentMethod
          checkPaid
          creditPaid
          taxSummary
          orderType
          wasPreOrdered
          orderSource
          invoiceName
          isReturn
        }
        orderDate
        orderType
        orderSource
        rejectedReason
      }
    }`,
      variables: {
        preOrderId: orderId,
        accessToken: customer.accessToken,
      },
    })
    .then(({ data }) => {
      const {
        data: { getPreOrderStatus },
      } = data;
      return getPreOrderStatus;
    })
    .catch((error) => {
      throw error;
    });
}

function* placeOrder(action: any) {
  try {
    const customer: any = yield select(selectors.customer);
    const activeDispensary: any = yield select(selectors.activeDispensary);
    const orderOnly: any = yield select(selectors.orderOnlyDetails);
    const recPreviousResultData: any = yield select(
      selectors.recPreviousResultData
    );

    const order = yield call(
      callPlaceOrder,
      action.cart,
      customer,
      activeDispensary,
      orderOnly,
      recPreviousResultData
    );
    yield put({ type: PLACE_ORDER_SUCCESS, order });
  } catch (e) {
    yield put({ type: PLACE_ORDER_FAILED, error: e });
  }
}

function* getPreorder(action: any) {
  try {
    const { orderId, dispensarySlug } = action;
    const customer: any = yield select(selectors.customer);
    const preorder = yield call(
      callGetPreorder,
      action.orderId,
      customer,
      dispensarySlug
    );
    yield put({ type: FETCH_PREORDER_SUCCESS, preorder, orderId });
  } catch (e) {
    yield put({ type: FETCH_PREORDER_FAILED, error: e });
  }
}

function* ordersSaga() {
  yield takeLatest(PLACE_ORDER, placeOrder);
  yield takeLatest(FETCH_PREORDER, getPreorder);
}

export default ordersSaga;
