import { call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { sagaURL } from "store/sagas";
import * as selectors from "./selectors";
import {
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILED,
  CUSTOMER_LOGIN,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGIN_FAILED,
  CUSTOMER_SAVE_SETTINGS,
  CUSTOMER_SAVE_SETTINGS_SUCCESS,
  CUSTOMER_SAVE_SETTINGS_FAILED,
  FETCH_ORDER_HISTORY,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_ORDER_HISTORY_FAILED,
  FETCH_PRODUCTS_TO_REVIEW,
  FETCH_PRODUCTS_TO_REVIEW_SUCCESS,
  FETCH_PRODUCTS_TO_REVIEW_FAILED,
  REVIEW_PRODUCT,
  REVIEW_PRODUCT_SUCCESS,
  REVIEW_PRODUCT_FAILED,
  FETCH_ACCOUNT_SETTINGS,
  FETCH_ACCOUNT_SETTINGS_SUCCESS,
  FETCH_ACCOUNT_SETTINGS_FAILED,
} from "store/customer/types";
import { setAuthorizationHeader } from "../../utils/auth";

function callCreateCustomer(payload: any) {
  setAuthorizationHeader(payload.accessToken);
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
      },
      query: `mutation signUp (
        $data: SignUpInput
      ) {
      signUp(data: $data){
        id
        auth0Id
        accessToken
        firstName
        lastName
        email
        fullName
        documentType
        documentId
        medicalId
        lastLogon
        dateOfBirth
      }
    }`,
      variables: {
        data: payload,
      },
    })
    .then(
      ({
        data: {
          data: { signUp },
        },
      }) => {
        return signUp;
      }
    )
    .catch((error) => {
      throw error;
    });
}

function callCustomerLogin(payload: any) {
  setAuthorizationHeader(payload.accessToken);
  delete payload.emailSignin;
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
      },
      query: `mutation login (
        $data: LoginInput
      ) {
      login(data: $data){
         id
         accessToken
         firstName
         lastName
         email
         fullName
         documentType
         documentId
         medicalId
         lastLogon
         dateOfBirth
         isNew
      }
    }`,
      variables: {
        data: payload,
      },
    })
    .then(
      ({
        data: {
          data: { login },
        },
      }) => {
        return axios
          .post(sagaURL, {
            headers: {
              "Content-Type": "application/json",
            },
            query: `query profile (
        $accessToken: String!
      ) {
        profile(accessToken: $accessToken){
          id
          firstName,
          lastName
        }
      }`,
            variables: {
              accessToken: payload.accessToken,
            },
          })
          .then(
            ({
              data: {
                data: { profile },
              },
            }) => {
              if (profile && profile.id) {
                login.hasProfile = true;
                login.profileFirst = profile.firstName;
                login.profileLast = profile.lastName;
              } else {
                login.hasProfile = false;
              }
              return login;
            }
          )
          .catch((error) => {
            login.hasProfile = false;
            return login;
          });
      }
    )
    .catch((error) => {
      throw error;
    });
}

function callLoadOrderHistory(
  customer: any,
  activeDispesnary: string,
  orderHistoryFetchLimit: number,
  offset: number
) {
  setAuthorizationHeader(customer?.accessToken);

  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: activeDispesnary,
      },
      query: `query getAllPreOrderStatusesForUser (
        $accessToken: String!,
        $limit: Int
        $offset: Int
      ) {
          getAllPreOrderStatusesForUser(accessToken: $accessToken, limit:$limit, offset:$offset) {
            count
            data {
              orderId
              status
              orderDate
              orderType
              orderSource
              rejectedReason
              transaction {
                transactionId
              }
              productIds
              productNames
              vendorNames
              discountAmounts 
              discountNames
              prices
              quantities
              subTotal
              total
              salesTax
              discount
              transaction {
                transactionId
              }
              customer {
                name
                customerId
              }
              dispensary {
                id
                name
                slug
                googlePlace {
                    googlePlaceId
                    googlePlaceLink
                }
                phoneNumber {
                    phoneNumber
                    extension
                }
                hoursOfOperation {
                    sunday
                    monday
                }
                address {
                    streetAddressLine1
                    city
                    state
                    zipCode
                }
              }  
            }
          }
    }`,
      variables: {
        accessToken: customer?.accessToken,
        limit: orderHistoryFetchLimit,
        offset: offset || null,
      },
    })
    .then(({ data }) => {
      const {
        data: { getAllPreOrderStatusesForUser },
      } = data;
      return (
        (getAllPreOrderStatusesForUser?.data &&
          getAllPreOrderStatusesForUser) ||
        []
      );
    })
    .catch((error) => {
      throw error;
    });
}

function callFetchAccountSettings(payload: any) {
  setAuthorizationHeader(payload.accessToken);
  delete payload.emailSignin;
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
      },
      query: `query getUserSettings (
        $data: LoginInput
      ) {
      getUserSettings(data: $data){
        id
        accessToken
        firstName
        lastName
        email
        fullName
        documentType
        documentId
        medicalId
        lastLogon
        dateOfBirth
      }
    }`,
      variables: {
        data: payload,
      },
    })
    .then(
      ({
        data: {
          data: { getUserSettings },
        },
      }) => {
        return axios
          .post(sagaURL, {
            headers: {
              "Content-Type": "application/json",
            },
            query: `query profile (
        $accessToken: String!
      ) {
        profile(accessToken: $accessToken){
          id
          firstName,
          lastName
        }
      }`,
            variables: {
              accessToken: payload.accessToken,
            },
          })
          .then(
            ({
              data: {
                data: { profile },
              },
            }) => {
              if (profile && profile.id) {
                getUserSettings.hasProfile = true;
                getUserSettings.profileFirst = profile.firstName;
                getUserSettings.profileLast = profile.lastName;
              } else {
                getUserSettings.hasProfile = false;
              }
              return getUserSettings;
            }
          )
          .catch((error) => {
            getUserSettings.hasProfile = false;
            return getUserSettings;
          });
      }
    )
    .catch((error) => {
      throw error;
    });
}

function callSaveSettings(payload: any) {
  delete payload.id;
  delete payload.email;
  delete payload.lastLogon;
  delete payload.leafLogixCustomerId;
  delete payload.month;
  delete payload.day;
  delete payload.year;
  delete payload.hasProfile;
  delete payload.profileFirst;
  delete payload.profileLast;
  delete payload.loaded;
  delete payload.readyToLoad;

  if (!payload?.orderOnly) {
    if (
      (payload.documentId && payload.documentId.indexOf("*") >= 0) ||
      payload.documentId === ""
    ) {
      delete payload.documentId;
    }
    if (payload.documentType === "") {
      delete payload.documentType;
    }

    if (payload.medicalId && payload.medicalId.indexOf("*") >= 0) {
      delete payload.medicalId;
    }
  }

  setAuthorizationHeader(payload.accessToken);
  payload.fullName = payload.firstName + " " + payload.lastName;
  if (payload.orderOnly) {
    return payload;
  } else {
    delete payload.orderOnly;
    return axios
      .post(sagaURL, {
        headers: {
          "Content-Type": "application/json",
        },
        query: `mutation editUserAccountSettings (
          $data: UserAccountSettingsInput
        ) {
          editUserAccountSettings(data: $data){
            id
            firstName
            lastName
            email
            fullName
            documentType
            documentId
            lastLogon
            dateOfBirth
            medicalId
          }
      }`,
        variables: {
          data: payload,
        },
      })
      .then(
        ({
          data: {
            data: { editUserAccountSettings },
          },
        }) => {
          return editUserAccountSettings;
        }
      )
      .catch((error) => {
        throw error;
      });
  }
}

function callLoadProductsToReview(customer: any, activeDispesnary: string) {
  setAuthorizationHeader(customer.accessToken);
  axios.defaults.headers.common["slug"] = activeDispesnary;

  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: activeDispesnary,
      },
      query: `
      query productsToReview (
        $accessToken: String!,
        $sortOrder: productsToReviewSort
      ) {
        productsToReview(accessToken: $accessToken, sortOrder: $sortOrder){
          productId
          sku
          brandName
          productName
          category
          dispensarySlug
          mostRecentDateOfOrder
          feelings {
            feeling
            value
          } 
        }
      }`,
      variables: {
        accessToken: customer.accessToken,
        sortOrder: "ORDER_DATE_DESC",
      },
    })
    .then(
      ({
        data: {
          data: { productsToReview },
        },
      }) => {
        let reviews: any = [];
        let reviewIds: any = [];
        productsToReview.forEach((product: any) => {
          if (
            product.feelings &&
            product.feelings.length &&
            reviewIds.indexOf(product.productId) < 0
          ) {
            reviewIds.push(product.productId);
            reviews.push(product);
          }
        });
        return reviews;
      }
    )
    .catch((error) => {
      throw error;
    });
}

function callReviewProduct(customer: any, review: any) {
  const reviewPayload = {
    accessToken: customer.accessToken,
    productId: review.productId,
    feelings: review.feelings,
    dispensarySlug: review.dispensarySlug,
  };
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: review.dispensarySlug,
      },
      query: `mutation createProductReview (
        $data: ProductReviewCreateInput
      ) {
        createProductReview(data: $data){
          productId
          dispensarySlug
          feelings {
            feeling
            value
          }
        }
    }`,
      variables: {
        data: reviewPayload,
      },
    })
    .then(
      ({
        data: {
          data: { createProductReview },
        },
      }) => {
        return createProductReview;
      }
    )
    .catch((error) => {
      throw error;
    });
}

function* createCustomer(action: any) {
  try {
    const customer = yield call(callCreateCustomer, action.payload);
    yield put({ type: CREATE_CUSTOMER_SUCCESS, customer });
  } catch (error) {
    console.log("CreateCustomer error:", error);
    yield put({ type: CREATE_CUSTOMER_FAILED, error: error });
  }
}

function* customerLogin(action: any) {
  if (!action.payload.accessToken) {
    yield put({ type: CUSTOMER_LOGIN_FAILED, e: "Invalid access token" });
  }
  try {
    let customer = yield call(callCustomerLogin, action.payload);
    customer.accessToken = action.payload.accessToken;
    yield put({ type: CUSTOMER_LOGIN_SUCCESS, customer });
  } catch (error) {
    console.log("CustomerLogin error:", error);
    yield put({ type: CUSTOMER_LOGIN_FAILED, error });
  }
}

function* customerSaveSettings(action: any) {
  try {
    const customer = yield call(callSaveSettings, action.payload);
    yield put({
      type: CUSTOMER_SAVE_SETTINGS_SUCCESS,
      customer,
      persistCustomer: !action.payload.orderOnly,
    });
  } catch (e) {
    console.log("Save Settings error:", e);
    yield put({ type: CUSTOMER_SAVE_SETTINGS_FAILED, e });
  }
}

function* loadOrderHistory(action: any) {
  const customer: any = yield select(selectors.customer);
  const activeDispensary: any = yield select(selectors.activeDispensary);
  const orderHistoryFetchLimit: any = yield select(
    selectors.orderHistoryFetchLimit
  );
  const offset: any = yield select(selectors.offset);
  try {
    const orderHistory = yield call(
      callLoadOrderHistory,
      customer,
      activeDispensary,
      orderHistoryFetchLimit,
      offset
    );
    yield put({ type: FETCH_ORDER_HISTORY_SUCCESS, orderHistory });
  } catch (e) {
    console.log("Order History error:", e);
    yield put({ type: FETCH_ORDER_HISTORY_FAILED, e });
  }
}

function* loadProductsToReview(action: any) {
  let customer: any = yield select(selectors.customer);
  const activeDispensary: any = yield select(selectors.activeDispensary);
  if (customer && activeDispensary) {
    customer.auth0Id = localStorage.getItem("forage_customer");
    try {
      const productsToReview = yield call(
        callLoadProductsToReview,
        customer,
        activeDispensary
      );
      yield put({ type: FETCH_PRODUCTS_TO_REVIEW_SUCCESS, productsToReview });
    } catch (e) {
      console.log("Load Reviews error:", e);
      yield put({ type: FETCH_PRODUCTS_TO_REVIEW_FAILED, e });
    }
  } else {
    yield put({
      type: FETCH_PRODUCTS_TO_REVIEW_FAILED,
      productsToReview: null,
    });
  }
}

function* reviewProduct(action: any) {
  const customer: any = yield select(selectors.customer);
  if (customer) {
    try {
      const review = yield call(callReviewProduct, customer, action.review);
      yield put({ type: REVIEW_PRODUCT_SUCCESS, review });
    } catch (error) {
      console.log("Review Product error:", error);
      yield put({ type: REVIEW_PRODUCT_FAILED, error });
    }
  } else {
    yield put({
      type: FETCH_PRODUCTS_TO_REVIEW_SUCCESS,
      productsToReview: null,
    });
  }
}

function* fetchAccountSettings(action: any) {
  if (!action.payload.accessToken) {
    yield put({
      type: FETCH_ACCOUNT_SETTINGS_FAILED,
      e: "Invalid access token",
    });
  }
  try {
    let customer = yield call(callFetchAccountSettings, action.payload);
    customer.accessToken = action.payload.accessToken;
    yield put({ type: FETCH_ACCOUNT_SETTINGS_SUCCESS, customer });
  } catch (error) {
    console.log("CustomerLogin error:", error);
    yield put({ type: FETCH_ACCOUNT_SETTINGS_FAILED, error });
  }
}

function* customerSaga() {
  yield takeLatest(CREATE_CUSTOMER, createCustomer);
  yield takeLatest(CUSTOMER_LOGIN, customerLogin);
  yield takeLatest(CUSTOMER_SAVE_SETTINGS, customerSaveSettings);
  yield takeLatest(FETCH_ORDER_HISTORY, loadOrderHistory);
  yield takeLatest(FETCH_PRODUCTS_TO_REVIEW, loadProductsToReview);
  yield takeLatest(REVIEW_PRODUCT, reviewProduct);
  yield takeLatest(FETCH_ACCOUNT_SETTINGS, fetchAccountSettings);
}

export default customerSaga;
