import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "store";

import { SetMoodSelectorIsOpen } from "store/system/actions";
import { ResetRecs, CustFetchRecs } from "store/recommendations/actions";

import MoodSelectorLocation from "../MoodSelector/Location";

import customFeeling from "www-links.json";
import { recPreferencesLimit } from "constants/recommendations";

import "./ActivitySelector.scss";

function ActivitySelector({ history, location }: RouteComponentProps) {
  const dispatch = useDispatch();
  const [gotoLocation, setGotoLocation] = useState<Boolean>(false);
  const selectorOpen = useSelector(
    (state: RootState) => state.system.moodSelectorOpen
  );
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );

  const activities = [
    {
      label: "Getting High",
      feelings: ["giggly", "talkative", "uplifted"],
      variables: customFeeling.Getting_High.variables,
    },
    {
      label: "Your First Try",
      feelings: ["happy", "relaxed", "uplifted"],
      variables: customFeeling.Your_First_Try.variables,
    },
    // {
    //   label: "Lockdown Blues",
    //   feelings: ["relaxed", "happy", "hungry"],
    //   variables: customFeeling.Lockdown_Blues.variables,
    // },
    {
      label: "Outdoor Hikes",
      feelings: ["creative", "energetic", "uplifted"],
      variables: customFeeling.Outdoor_Hikes.variables,
    },
    {
      label: "Creative Focus",
      feelings: ["creative", "euphoric", "focused"],
      variables: customFeeling.Creative_Focus.variables,
    },
  ];

  function inventoryActivity(variables: any) {
    dispatch(ResetRecs());
    loadResults(variables);

    // setTimeout(() => {
    if (activeDispensary) {
      history.push("#results");
    } else {
      setGotoLocation(true);
      // history.push("#location");
      //Set location info in localStorage to identify the history pathname
      const resultInfo = { pathname: location?.pathname || "" };
      localStorage.setItem("recresult_info", JSON.stringify(resultInfo));
      history.push("/locations");
    }
    // }, 200);
  }

  const loadResults = useCallback(
    (variables: any) => {
      dispatch(SetMoodSelectorIsOpen(true));
      variables.limit = recPreferencesLimit;
      dispatch(CustFetchRecs({ ...variables }));
    },
    [dispatch]
  );

  return (
    <section
      className={classNames("c-activity-selector", {
        "c-activity-selector--selector-open": selectorOpen,
      })}
    >
      <div className="slug">FIND THE RIGHT CANNABIS FOR</div>
      <ul className="c-activity-selector__list">
        {activities.map((activity: any) => (
          <li
            key={"activity-selector-" + activity.label}
            className="c-activity-selector__list__item"
          >
            <button onClick={() => inventoryActivity(activity.variables)}>
              {activity.label}
            </button>
          </li>
        ))}
      </ul>
      {gotoLocation && <MoodSelectorLocation />}
    </section>
  );
}

export default withRouter(ActivitySelector);
