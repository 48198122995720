
import { 
  SET_USER_LOCATION, 
  SET_LOCATION_FILTER, 
  UserState, 
  UserActionTypes
} from './types';
const initialState: UserState = {
  userLocationID:     '',
  userLocationString: null,
  filter:             'all',
}

export function userReducer(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case SET_USER_LOCATION: {
      return {
        ...state,
        userLocationID:     action.locationID,
        userLocationString: action.locationString,
        userLocationZip:    action.locationZip
      }
    }
    case SET_LOCATION_FILTER: {
      return {
        ...state,
        filter: action.filter
      }
    }
    default:
      return state
  }
}