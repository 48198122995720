import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Flower: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--flower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.37 209.17">
      <path className="i-product-type--flower--st0" stroke={color} d="M5.23,167.4c52.81,29.25,95.07,0,95.07,0S58,138.14,5.23,167.4Z"/>
      <path className="i-product-type--flower--st0" stroke={color} d="M100.3,167.4c52.81,29.25,95.06,0,95.06,0S153.11,138.14,100.3,167.4Z"/>
      <path className="i-product-type--flower--st0" stroke={color} d="M85.1,131.75S49.32,79.21,1.66,69.15c0,0,8.75,51.17,71.71,87.37"/>
      <path className="i-product-type--flower--st0" stroke={color} d="M115.27,131.75S151,79.21,198.71,69.15c0,0-8.75,51.17-71.71,87.37"/>
      <path className="i-product-type--flower--st0" stroke={color} d="M100.3,1.66c-47.59,93.68,0,165.74,0,165.74S147.88,95.34,100.3,1.66Z"/>
      <line className="i-product-type--flower--st0" stroke={color} x1="100.3" y1="167.4" x2="100.3" y2="207.51"/>
      <path className="i-product-type--flower--st0" stroke={color} d="M64.53,190.35s22.18-.41,35.77-22.95"/>
      <path className="i-product-type--flower--st0" stroke={color} d="M100.3,167.4c13.58,22.54,35.77,22.95,35.77,22.95"/>
    </svg>
  )
}

export default Flower;