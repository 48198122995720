import {
  SystemState,
  SystemActionTypes,
  SET_AGE_GATE_PASSED,
  SET_GEOLOCATION_DISABLED,
  SET_GEOLOCATION_DISMISSED,
  SET_GEOLOCATING,
  SET_MAPS_READY,
  SET_SIGNIN_REDIRECT,
  SET_IS_KIOSK,
  SET_DEVICE_SIZE,
  SET_DRAWER_OPEN,
  SET_MOBILE_NAV_OPEN,
  SET_MOOD_SELECTOR_OPEN,
  SET_MOOD_SELECTOR_STEP,
  SET_WINDOW_HEIGHT,
  SET_PROFILE_OPEN,
  SET_PROFILE_STEP,
  SET_RESET_KIOSK_TIMER,
  SET_FORAGE_EMBED,
  FORAGE_EMBED,
  SET_FORAGE_EMBED_DISPENSARY_TYPES,
} from './types'

const kioskHostnames = ['kiosk.forage.io', 'kiosk.dev.forage.io']
const isForageEmbed = new URLSearchParams(window.location?.search).get('embed') === 'true'

const initialState: SystemState = {
  showAgeGate: localStorage.getItem('forage_age_verified') !== '1',
  geoLocationDisabled: null,
  geoLocating: false,
  mapsReady: false,
  isKiosk:
    kioskHostnames.indexOf(window.location.hostname) !== -1 || localStorage.getItem('forage_kiosk')
      ? true
      : false || isForageEmbed,
  isMobile: window.innerWidth < 768,
  isTablet: window.innerWidth < 1024 && window.innerWidth >= 768,
  isDesktop: window.innerWidth >= 1024,
  drawerOpen: false,
  mobileNavOpen: false,
  geoLocationDismissed: false,
  moodSelectorOpen: false,
  moodSelectorStep: 1,
  windowHeight: null,
  profileOpen: false,
  profileStep: null,
  profileStepIndex: 0,
  profileSteps: [
    'name',
    'dob',
    'location',
    'experience',
    'experience-detail',
    'use-preference',
    'treatments',
    'products-to-avoid',
    'products-to-include',
    'signin',
    'success',
  ],
  resetKioskTimer: false,
  isForageEmbed: isForageEmbed || localStorage.getItem(FORAGE_EMBED) === 'true',
  embedDispensaries: {
    medical: null,
    recreational: null,
  },
}

export function systemReducer(state = initialState, action: SystemActionTypes): SystemState {
  switch (action.type) {
    case SET_AGE_GATE_PASSED: {
      localStorage.setItem('forage_age_verified', '1')
      return {
        ...state,
        showAgeGate: false,
      }
    }
    case SET_GEOLOCATION_DISABLED: {
      return {
        ...state,
        geoLocationDisabled: true,
      }
    }
    case SET_GEOLOCATION_DISMISSED: {
      return {
        ...state,
        geoLocationDismissed: action.isDismissed,
      }
    }
    case SET_GEOLOCATING: {
      return {
        ...state,
        geoLocating: action.geoLocating,
      }
    }
    case SET_MAPS_READY: {
      return {
        ...state,
        mapsReady: true,
      }
    }
    case SET_SIGNIN_REDIRECT: {
      return {
        ...state,
        siginRedirect: action.redirect,
      }
    }
    case SET_IS_KIOSK: {
      return {
        ...state,
        isKiosk: action.isKiosk,
      }
    }
    case SET_DEVICE_SIZE: {
      const isMobile = action.deviceSize === 'mobile'
      const isTablet = action.deviceSize === 'tablet'
      const isDesktop = action.deviceSize === 'desktop'
      return {
        ...state,
        isMobile,
        isTablet,
        isDesktop,
      }
    }
    case SET_DRAWER_OPEN: {
      return {
        ...state,
        drawerOpen: action.isOpen,
      }
    }
    case SET_MOBILE_NAV_OPEN: {
      return {
        ...state,
        mobileNavOpen: action.isOpen,
      }
    }
    case SET_MOOD_SELECTOR_OPEN: {
      return {
        ...state,
        moodSelectorOpen: action.isOpen,
      }
    }
    case SET_MOOD_SELECTOR_STEP: {
      return {
        ...state,
        moodSelectorStep: action.step,
      }
    }
    case SET_WINDOW_HEIGHT: {
      return {
        ...state,
        windowHeight: action.height,
      }
    }

    case SET_PROFILE_OPEN: {
      return {
        ...state,
        profileOpen: action.isOpen,
      }
    }

    case SET_PROFILE_STEP: {
      const stepIndex = state.profileSteps.indexOf(action.step)
      return {
        ...state,
        profileOpen: action.step !== 'success',
        profileStep: action.step,
        profileStepIndex: stepIndex,
      }
    }

    case SET_RESET_KIOSK_TIMER: {
      return {
        ...state,
        resetKioskTimer: action.reset,
      }
    }

    case SET_FORAGE_EMBED: {
      if (action.isForageEmbed) {
        localStorage.setItem('forage_kiosk', 'true')
        localStorage.setItem(FORAGE_EMBED, 'true')
      } else {
        localStorage.removeItem('forage_kiosk')
        localStorage.removeItem(FORAGE_EMBED)
      }
      return {
        ...state,
        isForageEmbed: action.isForageEmbed,
      }
    }
    case SET_FORAGE_EMBED_DISPENSARY_TYPES: {
      return {
        ...state,
        embedDispensaries: action?.embedDispensaries || initialState.embedDispensaries,
      }
    }

    default:
      return state
  }
}
