import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Extract: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--extract" version="1.1" x="0px" y="0px" viewBox="0 0 64.1 56.3">
      <g>
        <path className="i-product-type--extract--st0" stroke={color} d="M62.7,39.8H28c-0.5,0-1,0.4-1,1v14.1c0,0.5,0.4,1,1,1h34.6c0.5,0,1-0.4,1-1V40.8
          C63.6,40.2,63.2,39.8,62.7,39.8z"/>
        <path className="i-product-type--extract--st0" stroke={color} d="M59.7,31.3H31c-0.3,0-0.6,0.3-0.6,0.6v7.2c0,0.3,0.3,0.6,0.6,0.6h28.6c0.3,0,0.6-0.3,0.6-0.6V32
          C60.3,31.6,60,31.3,59.7,31.3z"/>
        <path className="i-product-type--extract--st0" stroke={color} d="M23.6,21.3L0.7,47.7c-0.3,0.3-0.3,0.9,0.1,1.2l7.8,6.8c0.3,0.3,0.9,0.3,1.2-0.1l22.9-26.4
          c0.3-0.3,0.3-0.9-0.1-1.2l-7.8-6.8C24.4,20.9,23.9,21,23.6,21.3z"/>
        <line className="i-product-type--extract--st0" stroke={color} x1="30.4" y1="34.1" x2="51.2" y2="34.1"/>
        <line className="i-product-type--extract--st0" stroke={color} x1="39.5" y1="36.8" x2="60.3" y2="36.8"/>
        <path className="i-product-type--extract--st0" stroke={color} d="M48.6,13.9l-4,2.6l0.9,3.8c0,0,4.2,4.1,6.1,4.1c1.9,0,6.1-4.1,6.1-4.1l-0.9-5.1l-6.7-2.4L48.6,13.9z"/>
        <line className="i-product-type--extract--st0" stroke={color} x1="54.4" y1="14.4" x2="49.5" y2="17.8"/>
        <line className="i-product-type--extract--st0" stroke={color} x1="52" y1="21.1" x2="53.9" y2="23.6"/>
        <polygon className="i-product-type--extract--st0" stroke={color} points="33.9,4.7 33.8,9.8 37.7,11.3 42,8.7 41.5,2.8 36.4,0.5 	"/>
        <line className="i-product-type--extract--st0" stroke={color} x1="37.8" y1="5" x2="37.6" y2="1"/>
      </g>
    </svg>
  )
}

export default Extract;