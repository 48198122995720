import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
const Filter: FunctionComponent<IconType> = ({ color = '#102981'}) => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4375 8.82861L15.2659 6.00019L18.0944 8.82861" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.1276 16.1382L9.29913 18.9666L6.4707 16.1382" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.2666 5.99997L15.2666 12.5996" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.29983 18.9668L9.29983 12.3672" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default Filter;