import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "store";

import ProductIcon from "components/ProductIcon";
import { PDPPickup, PDPDiscounts, PDPPrice } from "components/PDP";
import { PDPTopperProps } from "components/PDP/types";
import AddToCart from "components/AddToCart";
import {
  GradientCardLayout1,
  GradientCardLayout2,
  GradientCardLayout3,
  GradientCardLayout4,
  GradientCardLayout5,
} from "components/Gradients/Cards";
import "./PDPTopper.scss";
function PDPTopper({
  productObj,
  gradientOnly,
  noGradient,
  dispensarySlug,
  parentOffset,
  recs,
  productId,
  animateGradient,
}: PDPTopperProps) {
  const isDesktop = useSelector((state: RootState) => state.system.isDesktop);
  const isMobile = useSelector((state: RootState) => state.system.isMobile);
  const isTablet = useSelector((state: RootState) => state.system.isTablet);
  const inventory = useSelector(
    (state: RootState) => state.inventory.inventory
  );
  const recProducts = useSelector((state: RootState) => state.recs.recs);
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );

  const [product, setProduct] = useState<any>(productObj || false);
  const [gradientToUse, setGradientToUse] = useState<any>(null);
  const [gradientFeelings, setGradientFeelings] = useState([]);
  const [catIcon, setCatIcon] = useState<any>(false);
  const [lastOrder, setLastOrder] = useState<string | null>(null);

  const gradientIndex = Math.floor(Math.random() * 5) + 1;

  useEffect(() => {
    if (
      product &&
      gradientFeelings.length === 0 &&
      product.feelingData &&
      product.feelingData.length
    ) {
      const gradFeelings = product.feelingData
        .slice(0, 3)
        .map((feeling: any) => feeling.feeling.toLowerCase());
      setGradientFeelings(gradFeelings);
    }
    if (product?.lastOrder) {
      const lastOrderDate = new Date(product.lastOrder);
      const formatLastOrderDate = `${
        lastOrderDate.getMonth() + 1
      }/${lastOrderDate.getDate()}/${lastOrderDate.getFullYear()}`;
      setLastOrder(formatLastOrderDate);
    }
  }, [product, gradientFeelings]);

  useEffect(() => {
    if (gradientToUse === null) {
      setGradientToUse(gradientIndex);
    }
  }, [gradientIndex, gradientToUse]);

  useEffect(() => {
    if (!catIcon && product && product.category) {
      setCatIcon(<ProductIcon productType={product.category} />);
    }
  }, [catIcon, product]);

  useEffect(() => {
    if (recs) {
      if (
        productId &&
        (!product || product.productId !== productId) &&
        recProducts
      ) {
        setProduct(recProducts[productId]);
      }
    } else {
      if (productId) {
        const inventoryProduct =
          inventory && Object.keys(inventory).length && inventory[productId];
        if (inventoryProduct) {
          setProduct(inventoryProduct);
        } else {
          const recProduct = recProducts && recProducts[productId];
          if (recProduct) {
            setProduct(recProduct);
          }
        }
      }
    }
  }, [productId, product, recs, inventory, recProducts]);

  return (
    <div className="c-pdp-topper">
      {product && (
        <>
          <div
            className={classNames("c-pdp-topper__content", {
              "c-pdp-topper__content--no-gradient": noGradient,
              "c-pdp-topper__content--no-feelings":
                product &&
                (!product.feelingData || product.feelingData.length === 0),
            })}
          >
            <div className="c-pdp-topper__content__inner">
              {(!gradientOnly || recs) && (
                <>
                  {recs && (isMobile || isTablet) ? (
                    <div className="c-pdp-topper__row">
                      <div className="c-pdp-topper__row__icon">
                        <div className="c-pdp-topper__icon">
                          {catIcon && catIcon}
                        </div>
                      </div>
                      <div className="c-pdp-topper__row__price">
                        <PDPPrice
                          unitPrice={product.unitPrice}
                          discounts={product.discounts}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="c-pdp-topper__icon">
                      {catIcon && catIcon}
                    </div>
                  )}
                  <div className="c-pdp-topper__content__details">
                    <div>
                      <h3 className="c-pdp-topper__content__details__name">
                        {product.product.alternateName ||
                          product.product.productName}
                      </h3>
                      <div className="c-pdp-topper__content__details__brand">
                        <h3>{product.product.brandName}</h3>
                      </div>
                      <div className="c-pdp-topper__flags">
                        {product.quantityAvailable < 10 &&
                          product.quantityAvailable > 0 && (
                            <span className="c-pdp-topper__flags__flag">
                              Running Low
                            </span>
                          )}
                        {
                          // product.onSale &&
                          // <span className="c-pdp-topper__flags__flag">On Sale</span>
                        }
                      </div>
                      {!(recs && (isMobile || isTablet)) && (
                        <div className="c-pdp-topper__pricing">
                          <span className="c-pdp-topper__pricing__price">
                            <PDPPrice
                              unitPrice={product.unitPrice}
                              discounts={product.discounts}
                            />
                          </span>
                          {
                            // product.onSale &&
                            // <span className="c-pdp-topper__pricing__price c-pdp-topper__pricing__price--strike">${product.unitPrice + 15}</span>
                          }
                        </div>
                      )}
                    </div>
                    {recs &&
                    activeDispensary &&
                    product?.discounts.length > 0 ? (
                      <section className="c-recs-discounts">
                        {product.discounts.map(
                          (discount: { name: string }, key: number) => (
                            <div className="" key={key}>
                              {discount.name}
                            </div>
                          )
                        )}
                      </section>
                    ) : (
                      ""
                    )}
                    {recs && activeDispensary && isDesktop && (
                      <AddToCart
                        product={product}
                        dispensarySlug={activeDispensary}
                      />
                    )}
                  </div>
                </>
              )}
              {product &&
                (!product.feelingData || product.feelingData.length === 0) && (
                  <div className="c-card-gradient-wrapper c-card-gradient-wrapper--no-feelings" />
                )}
              {!noGradient && (
                // <Gradienter pdp={true} index={1} />
                <>
                  {product &&
                  (!product.feelingData || product.feelingData.length === 0) ? (
                    <></>
                  ) : gradientToUse === 1 ? (
                    <div className="c-card-gradient-wrapper">
                      <GradientCardLayout1
                        feelings={gradientFeelings}
                        animate={animateGradient}
                      />
                    </div>
                  ) : gradientToUse === 2 ? (
                    <div className="c-card-gradient-wrapper">
                      <GradientCardLayout2
                        feelings={gradientFeelings}
                        animate={animateGradient}
                      />
                    </div>
                  ) : gradientToUse === 3 ? (
                    <div className="c-card-gradient-wrapper">
                      <GradientCardLayout3
                        feelings={gradientFeelings}
                        animate={animateGradient}
                      />
                    </div>
                  ) : gradientToUse === 4 ? (
                    <div className="c-card-gradient-wrapper">
                      <GradientCardLayout4
                        feelings={gradientFeelings}
                        animate={animateGradient}
                      />
                    </div>
                  ) : (
                    <div className="c-card-gradient-wrapper">
                      <GradientCardLayout5
                        feelings={gradientFeelings}
                        animate={animateGradient}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {lastOrder && (
            <div className="c-pdp-topper__drawer">
              <span>You last ordered this product on {lastOrder}</span>
            </div>
          )}

          <div className="c-pdp-topper__atc">
            {isDesktop && (
              <PDPPickup product={product} dispensarySlug={dispensarySlug} />
            )}
            {isDesktop && product.discounts && product.discounts.length > 0 && (
              <PDPDiscounts product={product} />
            )}
            {isDesktop && (
              <AddToCart
                product={product}
                dispensarySlug={dispensarySlug}
                parentOffset={parentOffset}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default PDPTopper;
