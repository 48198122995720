import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Candy: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--candy" version="1.1" x="0px" y="0px" viewBox="0 0 238.9 117.5">
      <g>
        <g>
          <line className="i-product-type--candy--st0" stroke={color} x1="119.5" y1="76.3" x2="119.5" y2="84.9"/>
          <path className="i-product-type--candy--st0" stroke={color} d="M119.5,26.7c0,0,4.4,5.5,6,21.8s-6,27.8-6,27.8s25.3-2,29.9-29.7c0,0-17,9.9-25.4,19.2"/>
          <path className="i-product-type--candy--st0" stroke={color} d="M119.5,26.7c0,0-4.4,5.5-6,21.8s6,27.8,6,27.8s-25.3-2-29.9-29.7c0,0,17,9.9,25.4,19.2"/>
          <path className="i-product-type--candy--st0" stroke={color} d="M145.4,75.8c0,0-11,9.4-25.9,0.5"/>
          <path className="i-product-type--candy--st0" stroke={color} d="M93.6,75.8c0,0,11,9.4,25.9,0.5"/>
        </g>
        <circle className="i-product-type--candy--st0" stroke={color} cx="119.5" cy="58.7" r="57.1"/>
        <path className="i-product-type--candy--st0" stroke={color} d="M176.5,70.2c7.6,4.7,15.4,9.2,23.8,12.4c5.5,2.1,12.5,4.5,18.4,2.6c4.8-1.5,7.3-5.3,3.5-9.3
          c-6.6-6.8-18-10.1-26.8-12.8c0,0,41.8,9.6,41.8-4.4s-41.8-4.4-41.8-4.4c8.8-2.7,20.2-6,26.8-12.8c3.8-4,1.3-7.8-3.5-9.3
          c-5.9-1.9-12.9,0.5-18.4,2.6c-8.3,3.2-16.2,7.7-23.8,12.4"/>
        <path className="i-product-type--candy--st0" stroke={color} d="M62.4,70.2C54.8,75,47,79.5,38.6,82.7c-5.5,2.1-12.5,4.5-18.4,2.6c-4.8-1.5-7.3-5.3-3.5-9.3
          c6.6-6.8,18-10.1,26.8-12.8c0,0-41.8,9.6-41.8-4.4s41.8-4.4,41.8-4.4c-8.8-2.7-20.2-6-26.8-12.8c-3.8-4-1.3-7.8,3.5-9.3
          c5.9-1.9,12.9,0.5,18.4,2.6C47,38,54.8,42.5,62.4,47.2"/>
        <path className="i-product-type--candy--st0" stroke={color} d="M167.2,27.2c0,0,6,32.2-10.5,55.9"/>
        <path className="i-product-type--candy--st0" stroke={color} d="M72.2,91.6c0,0-6-32.2,10.5-55.9"/>
      </g>
    </svg>
  )
}

export default Candy;