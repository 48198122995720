import React, { useRef, useState, useEffect, MouseEvent } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { isIOS, isSafari } from "react-device-detect";

import { InventoryProps, ProductProps } from "constants/inventory";
import { RootState } from "store";
import { AddToCart } from "store/cart/actions";
import CardIcon from "components/ProductIcon";
import PlusCircleIcon from "components/svgs/PlusCircle";
import {
  GradientCardLayout1,
  GradientCardLayout2,
  GradientCardLayout3,
  GradientCardLayout4,
  GradientCardLayout5,
} from "components/Gradients/Cards";
import "./ProductCard.scss";
import { PDPPrice } from "../PDP";

interface ProductCardProps {
  product: ProductProps;
  item: InventoryProps;
  active?: boolean;
  index: number;
  showSVG?: boolean;
  dispensarySlug: string;
  isFeatured?: boolean;
  isActive?: boolean;
}
function ProductCard({
  product,
  showSVG,
  dispensarySlug,
  item,
  isActive = false,
}: ProductCardProps) {
  const { productId } = product;

  const dispatch = useDispatch();
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const items = useSelector((state: RootState) => state.cart.items);
  const dispensaries = useSelector(
    (state: RootState) => state.locations.dispensaries
  );

  const itemRef = useRef<HTMLAnchorElement>(null);

  const [acceptingPreorders, setAcceptingPreorders] = useState(
    dispensaries &&
      dispensaries[dispensarySlug] &&
      dispensaries[dispensarySlug].acceptingPreorders
  );
  const [showInCart, setShowInCart] = useState<boolean>(false);
  const [inCartItem, setInCartItem] = useState<any>(false);
  const [gradientToUse, setGradientToUse] = useState<any>(null);
  const [gradientFeelings, setGradientFeelings] = useState<any>(null);
  const [IconToDisplay, setIconToDisplay] = useState<any>(null);
  const [animateGradient, setAnimateGradient] = useState<boolean>(false);

  const gradientIndex = Math.floor(Math.random() * (5 - 1 + 1)) + 1;

  useEffect(() => {
    setAcceptingPreorders(
      dispensaries &&
        dispensaries[dispensarySlug] &&
        dispensaries[dispensarySlug].acceptingPreorders
    );
  }, [dispensaries, dispensarySlug]);

  useEffect(() => {
    if (IconToDisplay !== null) return;
    setIconToDisplay(<CardIcon productType={product.category} />);
  }, [product, IconToDisplay]);

  useEffect(() => {
    if (gradientToUse === null) {
      setGradientToUse(gradientIndex);
    }
  }, [gradientIndex, gradientToUse]);

  useEffect(() => {
    if (
      gradientFeelings === null &&
      item.feelingData &&
      item.feelingData.length
    ) {
      const gradFeelings = item.feelingData
        .slice(0, 3)
        .map((feeling: any) => feeling.feeling.toLowerCase());
      setGradientFeelings(gradFeelings);
    }
  }, [item, gradientFeelings]);

  useEffect(() => {
    const inCartItem = items.find((i: any) => i.productId === productId);
    if (inCartItem && dispensarySlug === activeDispensary) {
      setInCartItem({ ...inCartItem });
    } else {
      setInCartItem(false);
    }
  }, [items, productId, dispensarySlug, activeDispensary]);

  useEffect(() => {
    if (!showInCart && inCartItem && inCartItem.quantity) {
      setShowInCart(true);
    }
  }, [inCartItem, showInCart]);

  function addToCart(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    let { productId }: any = product;
    if (
      inCartItem &&
      (item.quantityAvailable === inCartItem.quantity ||
        inCartItem.quantity === 9)
    ) {
      return;
    }
    const quantity: number =
      inCartItem && inCartItem.quantity ? inCartItem.quantity + 1 : 1;
    if (productId) {
      dispatch(AddToCart(item, quantity, dispensarySlug));
    }
  }

  const onMouseEnter = () => {
    setAnimateGradient(true && !(isIOS || isSafari));
  };

  return (
    <Link
      to={"/dispensary/" + dispensarySlug + "/product/" + product.productId}
      className={classNames("c-product-card", {
        "c-product-card--no-feelings":
          item && (item.feelingData === null || item.feelingData.length === 0),
        "c-product-card--active": isActive,
      })}
      ref={itemRef}
      onMouseEnter={onMouseEnter}
    >
      <div className="c-product-card__content">
        <div className="c-product-card__content__top-section">
          <div className="c-product-card__content__top-section__classification">
            {IconToDisplay}
          </div>
          <div className="c-product-card__content__top-section__price">
            <PDPPrice unitPrice={item.unitPrice} discounts={item.discounts} />
          </div>
          <div className="c-product-card__content__top-section__bubble"></div>
        </div>
        <div className="c-product-card__content__details">
          <div className="c-product-card__content__details__top">
            <h3 className="c-product-card__content__details__name">
              {product.productName}
            </h3>
            <div className="c-product-card__content__details__brand">
              <h3>{product.brandName}</h3>
            </div>
          </div>

          {product?.thcContent || product?.cbdContent ? (
            <h6 className="c-product-card__content__details__name c-product-card__content__details__cbdContent">
              <strong>Average Cannabinoids</strong>
              {product?.thcContent > 0 ? (
                <span>
                  <br />
                  {"THC : " + product.thcContent + product.thcContentUnit}
                </span>
              ) : (
                ""
              )}
              {product?.cbdContent > 0 ? (
                <span>
                  <br />
                  {"CBD : " + product.cbdContent + product.cbdContentUnit}
                </span>
              ) : (
                ""
              )}
            </h6>
          ) : (
            ""
          )}
        </div>
        {item.discounts && item.discounts.length > 0 && (
          <div className="c-product-card__content__discounts">
            {item.discounts.map((discount: { name: string }, key: number) => (
              <div className="body--xsmall" key={key}>
                {discount.name}
              </div>
            ))}
          </div>
        )}
        <div className="c-product-card__actions">
          {acceptingPreorders && (
            <div className="c-product-card__actions__cart">
              {item.quantityAvailable > 0 ? (
                <>
                  <button
                    className={classNames("c-product-card__action", {
                      "c-product-card__action--in-cart": showInCart,
                    })}
                    onClick={addToCart}
                  >
                    <PlusCircleIcon color="charcoal" />
                  </button>
                  {inCartItem && (
                    <CSSTransition
                      in={showInCart}
                      classNames="c-product-card__actions__incart-"
                      timeout={2000}
                      appear
                    >
                      <div className="c-product-card__actions__incart">
                        {inCartItem.quantity} added to order
                      </div>
                    </CSSTransition>
                  )}
                </>
              ) : (
                <div className="c-product-card__actions__out-of-stock">
                  Currently out of stock
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {showSVG && (
        <>
          {item &&
          (item.feelingData === null || item.feelingData.length === 0) ? (
            <div className="c-product-card__default-gradient"></div>
          ) : gradientToUse === 1 ? (
            <GradientCardLayout1
              feelings={gradientFeelings}
              animate={animateGradient}
            />
          ) : gradientToUse === 2 ? (
            <GradientCardLayout2
              feelings={gradientFeelings}
              animate={animateGradient}
            />
          ) : gradientToUse === 3 ? (
            <GradientCardLayout3
              feelings={gradientFeelings}
              animate={animateGradient}
            />
          ) : gradientToUse === 4 ? (
            <GradientCardLayout4
              feelings={gradientFeelings}
              animate={animateGradient}
            />
          ) : (
            <GradientCardLayout5
              feelings={gradientFeelings}
              animate={animateGradient}
            />
          )}
        </>
      )}
    </Link>
  );
}

export default ProductCard;
