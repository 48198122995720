import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Form, Field, reduxForm, InjectedFormProps } from "redux-form";
import { Link } from "react-router-dom";
import { RootState } from "store";
import {
  ResetCustomerError,
  ResetPasswordPlaceholder,
} from "store/customer/actions";
import Button from "components/Button";
import { Input } from "components/Forms/Fields";
import { SignInValidator as validate } from "components/Forms/Validators";
import "./SignInForm.scss";
import { EMAIL_MAX_LENGTH } from "../../../Forms/Fields/constants";

function SignInForm({
  handleSubmit,
  invalid,
  error,
  change,
}: InjectedFormProps) {
  const dispatch = useDispatch();
  const authenticating: any = useSelector(
    (state: RootState) => state.customer.authenticating
  );
  const authenticated: any = useSelector(
    (state: RootState) => state.customer.authenticated
  );
  const passwordPlaceholder: any = useSelector(
    (state: RootState) => state.customer.passwordPlaceholder
  );
  const customerError: any = useSelector(
    (state: RootState) => state.customer.customerError
  );

  const [authError, setAuthError] = useState("");

  useEffect(() => {
    if (customerError) {
      setAuthError(customerError);
      dispatch(ResetCustomerError());
    }
  }, [customerError, dispatch]);

  useEffect(() => {
    if (passwordPlaceholder) {
      change("password", passwordPlaceholder);
    }
  }, [passwordPlaceholder, change]);

  function resetPasswordPlaceholder() {
    if (passwordPlaceholder) {
      dispatch(ResetPasswordPlaceholder());
      change("password", "");
    }
  }

  return (
    <>
      {error ? (
        <p className="c-signin__failure">
          {error || "Invalid Email or Password"}
        </p>
      ) : authError ? (
        <p className="c-signin__failure">
          An error occurred. If this issue persists, please contact{" "}
          <a
            href="mailto:support@forage.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@forage.io
          </a>
          .
        </p>
      ) : null}
      <div className="c-signin-form">
        <Form
          className="c-signin-form__form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Field
            component={Input}
            name="email"
            type="email"
            label={"Email"}
            required
            className="email__input"
            maxlength={EMAIL_MAX_LENGTH}
          />
          <Field
            component={Input}
            name="password"
            type="password"
            label={"Password"}
            required
            onFocus={resetPasswordPlaceholder}
            className="password__input"
          />
          <Link to="/account/forgot-password" className="c-signin-form__forgot">
            Forgot your password?
          </Link>
          <Button
            disabled={invalid || authenticating}
            variant="login"
            type="submit"
          >
            {authenticating || authenticated ? "Signing In" : "Sign In"}
          </Button>
        </Form>
      </div>
    </>
  );
}

export default connect((state: RootState) => ({
  initialValues: {
    email: state.customer.customerEmail,
    password: state.customer.passwordPlaceholder,
  },
}))(
  reduxForm({
    form: "signIn",
    validate,
  })(SignInForm)
);
