import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { SetSearch } from "store/recommendations/actions";
import Button from "components/Button";
import { LoadingProductCard } from "components/Loading";
import ProductCard from "components/ProductCard";
import RecommendationPagination from "./RecommendationPagination";
import UseRecommendation from "./hooks/useRecommendation";

interface gridProps {
  // recs: any;
  dispensarySlug: any;
}

function RecommendationGrid({ dispensarySlug }: gridProps) {
  const dispatch = useDispatch();
  const isDesktop = useSelector((state: RootState) => state.system.isDesktop);
  const { searchQuery, loading: recsLoading } = UseRecommendation();

  let itemRefArray = useRef<any>([]);
  let itemRefs = useRef<any>([]);
  const gridRef: any = useRef();

  const [activeItem, setActiveItem] = useState<any>(null);
  const [transitioning, setTransitioning] = useState(false);
  const [showingNoResults, setShowingNoResults] = useState(false);
  const [showPagination, setShowPagination] = useState<any>(false);

  const getRecs: any = useSelector((state: RootState) => state.recs || {});
  const allRecs = { ...getRecs.recs, ...getRecs.remainingRecsObj } || {};
  const allRecsIds =
    getRecs.recProductIds?.concat(getRecs.remainingRecsArray) || [];

  const recs: any = [];
  allRecsIds?.forEach(function (item: any, index: number) {
    recs[index] = allRecs[item];
  });

  useEffect(() => {
    if (searchQuery.trim() === "") {
      return setShowPagination(true);
    }

    return setShowPagination(false);
  }, [searchQuery]);

  function closest(array: any, number: number) {
    var num = 0;
    for (var i = array.length - 1; i >= 0; i--) {
      if (
        Math.abs(number - array[i].position) <
        Math.abs(number - array[num].position)
      ) {
        num = i;
      }
    }
    return num;
  }

  function watchScroll() {
    if (isDesktop || itemRefs.current.length === 0) return;

    var scrollTop = window.scrollY + window.innerHeight / 2 - 200;
    var positions: any = [];

    itemRefs.current.forEach((itemRef: any) => {
      positions.push({ position: itemRef.offsetTop, element: itemRef });
    });

    var getClosest = closest(positions, scrollTop);
    setActiveItem(itemRefArray.current[getClosest]);
  }

  window.addEventListener("scroll", watchScroll);

  function resetEverything() {
    setTransitioning(true);
    setTimeout(() => {
      setTransitioning(false);
      dispatch(SetSearch(""));
      setShowingNoResults(false);
    }, 500);
  }

  function addToRef(el: any, productId: string) {
    if (el && itemRefArray.current.indexOf(productId) < 0 && !transitioning) {
      itemRefArray.current = [...itemRefArray.current, productId];
      itemRefs.current = [...itemRefs.current, el];
    }
  }

  function renderNoResults(recsLoading: boolean) {
    return !recs?.length && !recsLoading ? (
      <div className="c-inventory-grid__no-results" style={{ opacity: 1 }}>
        <h1>No Results Found</h1>
        <Button type="button" onClick={resetEverything} variant="outlined">
          Reset Filters and Search
        </Button>
      </div>
    ) : null;
  }

  function renderRemaining() {
    // @ts-ignore
    return (
      !recsLoading &&
      recs?.map((rec: any, index: number) => {
        const { product } = rec;
        return (
          <div
            key={product.sku + index}
            ref={(el) => addToRef(el, product.productId)}
          >
            <ProductCard
              dispensarySlug={dispensarySlug}
              showSVG={true}
              isActive={product.productId === activeItem}
              index={index}
              product={product}
              item={rec}
            />
          </div>
        );
      })
    );
  }

  function renderLoading(recsLoading: boolean) {
    return recsLoading
      ? [...Array(12)].map((e, i) => <LoadingProductCard key={"loading" + i} />)
      : null;
  }

  return (
    <section
      className={classNames("c-inventory-grid", {
        "c-inventory-grid--transitioning": transitioning,
        "c-inventory-grid--no-results": showingNoResults,
      })}
      ref={gridRef}
    >
      <div className="c-inventory-grid__inner">
        {renderNoResults(recsLoading)}
        {renderRemaining()}
        {renderLoading(recsLoading)}
      </div>
      {showPagination ? <RecommendationPagination /> : null}
    </section>
  );
}

export default RecommendationGrid;
