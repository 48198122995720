import React                                          from 'react';
import { connect, useSelector }                       from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps }  from 'redux-form';
import { RootState }                        from 'store';
import Button                               from 'components/Button';
import { Input, Select, Date }              from 'components/Forms/Fields';
import { IdSettingsValidator as validate }  from 'components/Forms/Validators';
import { ID_MAX_LENGTH } from "components/Forms/Fields/constants";
import './SignUpForm.scss';

function IdSettingsForm({ 
  handleSubmit, 
  invalid, 
  change 
}: InjectedFormProps) {

  const saving    = useSelector((state: RootState) => state.customer.saving);
  const isDesktop = useSelector((state: RootState) => state.system.isDesktop);

  const documentTypeOptions = [
    { 
      label:'US Passport',
      value: 'PASSPORT'
    },
    { 
      label:'Drivers License',
      value: 'DRIVERS_LICENSE'
    }
  ];
  return (
    <div className="c-signin-form">
      <Form className="c-signin-form__form" autoComplete="off" onSubmit={handleSubmit}> 
        <p className="body--xsmall">Required if purchasing from recreational dispensary</p>
        <Field 
          component={Select} 
          name="documentType"
          label={"Document Type"}
          required
          isDesktop={isDesktop}
          className="idDocumentType__input"
          options={documentTypeOptions}
          change={change}
        />
        <Field 
          component={Input} 
          name="documentId" 
          type="text"
          label={"ID Number"}
          required
          className="email__input"
          maxlength={ID_MAX_LENGTH}
        /> 
        <p className="body--xsmall">Required if purchasing from medical dispensary</p>
        <Field 
          component={Input} 
          name="medicalId" 
          type="text"
          label={"Medical Card Number"}
          required
          className="email__input"
          maxlength={ID_MAX_LENGTH}
        /> 
        <Date />
        <Button disabled={invalid || saving} variant="login" type="submit">{saving ? 'Finishing Account Setup' : 'Finish Account Settings'}</Button>
        <Button variant="outlined" type="Link" to="/account">Skip for now</Button>
      </Form>
    </div>
  )
}

export default connect(
  (state: RootState) => ({
    initialValues: state.customer.customer ? state.customer.customer : {}
  })
)(reduxForm({
  form: 'idSettings',
  validate
})(IdSettingsForm));
