import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { scrollTo } from "utils";
import { RootState } from "store";
import { SetInventoryPage } from "store/inventory/actions";
import "./InventoryPagination.scss";

function InventoryPagination() {
  const dispatch = useDispatch();
  const isMobile = useSelector((state: RootState) => state.system.isMobile);
  const totalInventory = useSelector(
    (state: RootState) => state.inventory.totalInventory
  );
  const startRow = useSelector((state: RootState) => state.inventory.startRow);
  const limitRow = useSelector((state: RootState) => state.inventory.limitRow);

  const [totalPages, setTotalPages] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<any>(null);

  useEffect(() => {
    if (totalInventory === null || limitRow === null || startRow === null)
      return;
    const current = startRow === 0 ? 1 : Math.ceil(startRow / 16 + 1);
    const total = Math.ceil(totalInventory / 16);
    setTotalPages(total);
    setCurrentPage(current);
  }, [totalInventory, startRow, limitRow]);

  function changePage(pageNumber: number) {
    scrollTo(document.documentElement, 0, 500);
    scrollTo(document.body, 0, 500);
    if (pageNumber === currentPage) {
      return false;
    }
    setTimeout(() => {
      setCurrentPage(pageNumber);
      dispatch(SetInventoryPage(pageNumber));
    }, 750);
  }

  return (
    <div className="c-inventory-pagination">
      {totalPages && totalPages > 1 && !!currentPage && !!totalInventory ? (
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={16}
          totalItemsCount={totalInventory}
          pageRangeDisplayed={isMobile ? 5 : 10}
          onChange={changePage}
          lastPageText="⟩⟩"
          firstPageText="⟨⟨"
          hideFirstLastPages={
            isMobile
              ? Math.ceil(totalInventory / 16) <= 5
              : Math.ceil(totalInventory / 16) <= 10
          }
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default InventoryPagination;
