import React, { useEffect, useState, useCallback }  from 'react';
import classNames                                   from 'classnames';
import { connect, useDispatch, useSelector }        from 'react-redux';
import { Form, reduxForm, InjectedFormProps }       from 'redux-form';
import { Helmet }                                   from "react-helmet";
import { RootState }                                                from 'store';
import { SetProfileIsOpen, SetProfileStep }                         from 'store/system/actions';
import { FetchProfileConfig, SetNoReset, StoreProfilePreferences }  from 'store/profile/actions';
import Button                                                       from 'components/Button';
import { Name, DOB, Location, Experience, ExperienceDetail, Use, Treatments, ProductsToAvoid, ProductsToInclude, ProfileSuccess } from 'components/Profile';

export interface IUser {
  goToSignin: any
}

export interface IDispatchProps {
  onSubmit:   any
  goToSignin: any
  history:    any
  match:      any
}

type pageTitlesType = {
  [key: string]: string
}

const PREFIX_TITLE = 'Profile Creation | ';

function ProfileForm({ 
  handleSubmit, 
  change, 
  goToSignin, 
  history, 
  match 
}:IDispatchProps & InjectedFormProps<IUser, IDispatchProps>) {
  const dispatch              = useDispatch();
  const profileStep           = useSelector((state:RootState) => state.system.profileStep);
  const profileFormData       = useSelector((state:RootState) => state.form.profileCreation);
  const customer              = useSelector((state:RootState) => state.customer.customer);
  const profileConfig         = useSelector((state:RootState) => state.profile.config);
  const profileConfigLoading  = useSelector((state:RootState) => state.profile.configLoading);

  const [nextStep, setNextStep]           = useState(false);
  const [active, setActive]               = useState<any>(profileStep);
  const [formUse, setFormUse]             = useState('');
  const [saveStep, setSaveStep]           = useState(false);
  const [stickyVisible, setStickyVisible] = useState( profileStep && ['products-to-avoid', 'products-to-include', 'treatments'].indexOf(profileStep) >= 0);
  const isNewbie = profileFormData?.values?.experienceLevel === 'BEGINNER' || profileFormData?.values?.experienceLevel === 'NONE';
  
  const PAGE_TITLES: pageTitlesType = {
    'name': 'What is your name?',
    'location': 'Where are you located?',
    'experience': 'How much experience do you have with Cannabis?',
    'experience-detail': isNewbie ? 'Do you know how to use flower?' : 'How strong do you like your cannabis?',
    'use-preference': 'How do you want to use Cannabis?',
    'treatments': 'What are you looking to treat?',
    'products-to-avoid': 'Are there any product types you don’t want to use?',
    'products-to-include': 'Are there any product types you’d like to try?',
  };

  useEffect(() => {
    if(!profileConfig && !profileConfigLoading){
      dispatch(FetchProfileConfig());
    }
  }, [profileConfig, profileConfigLoading, dispatch]);
  
  useEffect(() => {
    if(profileFormData && profileFormData.values) {
      dispatch(StoreProfilePreferences(profileFormData.values, true));
    }
  }, [profileFormData, dispatch]);

  useEffect(() => {
    const unlisten = history.listen((newLocation:any) => {
      const { pathname } = newLocation;
      const splits = pathname.split('/');
      if(profileStep && ['products-to-avoid', 'products-to-include', 'treatments'].indexOf(profileStep) >= 0){
        setStickyVisible(true);
      } else {
        setStickyVisible(false);
      }
      if(pathname === '/create-profile'){
        dispatch(SetProfileStep('name'));
      } else if(pathname.indexOf('create-profile') >= 0 && profileStep !== splits[2]){
        dispatch(SetProfileStep(splits[2]));
      }
    });
    return () => {
      unlisten();
    }
  }, [history, profileStep, dispatch]);

  useEffect(() => {
    if(profileStep && ['products-to-avoid', 'products-to-include', 'treatments'].indexOf(profileStep) >= 0){
      setStickyVisible(true);
    } else {
      setStickyVisible(false);
    }
  }, [profileStep]);


  useEffect(() => {
    if(profileStep && profileStep !== active){
      setActive(profileStep);
      if(profileStep === 'success'){
        dispatch(SetProfileIsOpen(false));
      }
    }
  }, [profileStep, active, dispatch]);

  useEffect(() => {
    if(active === ''){
      const matchParmas:any = match.params;
      if(matchParmas.step === 'success'){
        setActive('success');
      } else {
        setActive('name');
      }
    }
  }, [active, match]);

  useEffect(() => {
    if(nextStep){
      setNextStep(false);
      setSaveStep(false);
      switch(active){
        case 'name':
          dispatch(SetProfileStep('location'));
          history.push('/create-profile/location');
          setActive('location');
          setStickyVisible(false);
          break;
        case 'dob':
          dispatch(SetProfileStep('location'));
          history.push('/create-profile/location');
          setActive('location');
          setStickyVisible(false);
          break;
        case 'location':
          dispatch(SetProfileStep('experience'));
          history.push('/create-profile/experience');
          setActive('experience');
          setStickyVisible(false);
          break;
        case 'experience':
          dispatch(SetProfileStep('experience-detail'));
          history.push('/create-profile/experience-detail');
          setActive('experience-detail');
          setStickyVisible(false);
          break;
        case 'experience-detail':
          dispatch(SetProfileStep('use-preference'));
          history.push('/create-profile/use-preference');
          setActive('use-preference');
          setStickyVisible(false);
          break;
        case 'use-preference':
          if(formUse === 'TREATMENT' || formUse === 'BOTH'){
            dispatch(SetProfileStep('treatments'));
            history.push('/create-profile/treatments');
            setActive('treatments');
            setStickyVisible(true);
          } else {
            dispatch(SetProfileStep('products-to-avoid'));
            history.push('/create-profile/products-to-avoid');
            setActive('products-to-avoid');
            setStickyVisible(true);
          }
          break;
        case 'treatments':
          dispatch(SetProfileStep('products-to-avoid'));
          history.push('/create-profile/products-to-avoid');
          setActive('products-to-avoid');
          setStickyVisible(true);
          break;
        case 'products-to-avoid':
          dispatch(SetProfileStep('products-to-include'));
          history.push('/create-profile/products-to-include');
          setActive('products-to-include');
          setStickyVisible(true);
          break;
        case 'products-to-include':
          if(customer){
            dispatch(SetProfileStep('success'));
            setActive('success');
            history.push('/create-profile/success');
          } else {
            dispatch(SetNoReset(true));
            setActive('signin');
            goToSignin();
          }
          break;
      }
    }
  }, [nextStep, formUse, active, goToSignin, history, customer, dispatch])
  const goNext =  useCallback (() => {
    setNextStep(true);
  }, []);

  function setLocation(value:string) {
    change('location', value);
  }

  function saveStepData() {
    setSaveStep(true);
  }

  const renderPageTitle = () => {
    return profileStep && PAGE_TITLES[profileStep] ? <Helmet><title>{`${PREFIX_TITLE}${PAGE_TITLES[profileStep]}`}</title></Helmet> : null;
  };
  
  return (
    <>
      <section className={classNames("c-profile l-main", { 'c-profile--loading': profileConfigLoading })}>
        {renderPageTitle()}
        <div className="l-maxwidth l-maxwidth--small">
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Name activeStep={active} goNext={goNext} />
            <DOB activeStep={active} goNext={goNext} />
            <Location activeStep={active} goNext={goNext} setLocation={setLocation} change={change} />
            <Experience activeStep={active} goNext={goNext} change={change} />
            <ExperienceDetail activeStep={active} goNext={goNext} change={change} />
            <Use activeStep={active} goNext={goNext} change={change} setFormUse={setFormUse} />
            { 
              profileConfig && 
              <>
                <Treatments activeStep={active} goNext={goNext} change={change} saveStep={saveStep}  config={profileConfig.treatments}  />
                <ProductsToAvoid activeStep={active} goNext={goNext} change={change} saveStep={saveStep} config={profileConfig.formats} />
                <ProductsToInclude activeStep={active} goNext={goNext} change={change} saveStep={saveStep} config={profileConfig.formats} />
              </>
            }
            <ProfileSuccess activeStep={active} goNext={goNext} />
          </Form>
        </div>
      </section>
      <div className={classNames("c-profile__sticky-actions", { 'c-profile__sticky-actions--visible': stickyVisible})}>
        <div className="c-profile__sticky-actions__inner">
          <Button type="button" onClick={goNext} variant="">Skip</Button>
          <Button type="button" onClick={saveStepData} variant="">Next</Button>
        </div>
      </div>
    </>
  )
}

export default connect(
  (state: RootState) => ({

  })
)(reduxForm<IUser, IDispatchProps>({
  form: 'profileCreation',
})(ProfileForm));