import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import chroma from "chroma-js";
import feelingColors from "sass/utils/feelingColors.module.scss";
import Granim from "vendor/granim";
import { isIOS, isSafari } from "react-device-detect";

interface canvasProps {
  colors: any;
  id: string;
}

const BLUR = 10;
const granim1OriginalRectDesktop = {
  x: 250,
  y: 1050,
  width: 1600,
  height: 800,
  gradient: [0, 0, 800, 0, 500, 500],
};
const granim1OriginalRectMobile = {
  x: 400,
  y: 1050,
  width: 1600,
  height: 800,
  gradient: [0, 0, 800, 0, 500, 500],
};

const granim2OriginalRectDesktop = {
  x: 1200,
  y: 0,
  width: 1600,
  height: 800,
  gradient: [700, -600, 1500, 700, -600, 0],
};
const granim2OriginalRectMobile = {
  x: 1200,
  y: 0,
  width: 1600,
  height: 800,
  gradient: [0, -100, 800, 100, -100, 0],
};

function Canvas({ colors, id }: canvasProps) {
  const [states, setStates] = useState<any>({});
  const [granim1, setGranim1] = useState<any>(false);
  const [granim2, setGranim2] = useState<any>(false);
  const [prevColor1, setPrevColor1] = useState<any>("");
  const [prevColor2, setPrevColor2] = useState<any>("");
  const [bgColor, setBgColor] = useState("rgb(243, 41, 174)");
  const [granim2Same, setGranim2Same] = useState(false);
  const [granim1Same, setGranim1Same] = useState(false);

  const getGradient = useCallback((fromStart, fromEnd, toStart, toEnd) => {
    return {
      gradients: [
        [
          {
            color: toEnd,
            pos: 0,
          },
          {
            color: toStart,
            pos: 1,
          },
        ],
      ],
      loop: false,
    };
  }, []);

  useEffect(() => {
    if (Object.keys(states).length === 0) {
      let stateObj: any = {};
      Object.keys(feelingColors).forEach((colorKey) => {
        const fromStart = chroma(feelingColors[colorKey]).alpha(1).css();
        const fromEnd = chroma(feelingColors[colorKey]).alpha(0).css();
        const fromStartDark = chroma(feelingColors[colorKey])
          .darken(1.5)
          .alpha(1)
          .css();
        const fromEndDark = chroma(feelingColors[colorKey])
          .darken(1.5)
          .alpha(0)
          .css();
        const fromStartLight = chroma(feelingColors[colorKey])
          .brighten(1.5)
          .alpha(1)
          .css();
        const fromEndLight = chroma(feelingColors[colorKey])
          .brighten(1.5)
          .alpha(0)
          .css();
        Object.keys(feelingColors).forEach((color2Key) => {
          // if(colorKey !== color2Key){
          const toStart = chroma(feelingColors[color2Key]).alpha(1).css();
          const toEnd = chroma(feelingColors[color2Key]).alpha(0).css();
          const toStartDark = chroma(feelingColors[color2Key])
            .darken(1.5)
            .alpha(1)
            .css();
          const toEndDark = chroma(feelingColors[color2Key])
            .darken(1.5)
            .alpha(0)
            .css();
          const toStartLight = chroma(feelingColors[color2Key])
            .brighten(1.5)
            .alpha(1)
            .css();
          const toEndLight = chroma(feelingColors[color2Key])
            .brighten(1.5)
            .alpha(0)
            .css();

          stateObj[colorKey + "-" + color2Key] = getGradient(
            fromStart,
            fromEnd,
            toStart,
            toEnd
          );
          stateObj[colorKey + "Dark-" + color2Key] = getGradient(
            fromStartDark,
            fromEndDark,
            toStart,
            toEnd
          );
          stateObj[colorKey + "Light-" + color2Key] = getGradient(
            fromStartLight,
            fromEndLight,
            toStart,
            toEnd
          );
          stateObj[colorKey + "-" + color2Key + "Dark"] = getGradient(
            fromStart,
            fromEnd,
            toStartDark,
            toEndDark
          );
          stateObj[colorKey + "-" + color2Key + "Light"] = getGradient(
            fromStart,
            fromEnd,
            toStartLight,
            toEndLight
          );
          stateObj[colorKey + "Dark-" + color2Key + "Dark"] = getGradient(
            fromStart,
            fromEnd,
            toStartDark,
            toEndDark
          );
          stateObj[colorKey + "Light-" + color2Key + "Light"] = getGradient(
            fromStart,
            fromEnd,
            toStartLight,
            toEndLight
          );
          stateObj[colorKey + "Dark-" + color2Key + "Light"] = getGradient(
            fromStart,
            fromEnd,
            toStartDark,
            toEndDark
          );
          stateObj[colorKey + "Light-" + color2Key + "Dark"] = getGradient(
            fromStart,
            fromEnd,
            toStartLight,
            toEndLight
          );

          // }
        });
      });
      setStates(stateObj);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const resize = () => {
      const isSmall = window.innerWidth < 1000;

      if (granim1) {
        const originalRect = isSmall
          ? granim1OriginalRectMobile
          : granim1OriginalRectDesktop;
        const newRect = {
          width: window.innerWidth,
          height: window.innerHeight,
        };

        const scaleX = originalRect.width / newRect.width;
        const scaleY = originalRect.height / newRect.height;
        const x = originalRect.x + (newRect.width - originalRect.width) / 2;
        const y = originalRect.y + (newRect.height - originalRect.height) / 2;
        granim1.gradientPosition = [
          x + originalRect.gradient[0] * scaleX,
          y + originalRect.gradient[1] * scaleY,
          originalRect.gradient[2],
          x + originalRect.gradient[3] * scaleX,
          y + originalRect.gradient[4] * scaleY,
          originalRect.gradient[5],
        ];
        granim1.refreshColorsAndPos();
      }
      if (granim2) {
        const originalRect = isSmall
          ? granim2OriginalRectMobile
          : granim2OriginalRectDesktop;
        const newRect = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
        const scaleX = originalRect.width / newRect.width;
        const scaleY = originalRect.height / newRect.height;
        const x = originalRect.x + (newRect.width - originalRect.width) / 2;
        const y = originalRect.y + (newRect.height - originalRect.height) / 2;
        granim2.gradientPosition = [
          x + originalRect.gradient[0] * scaleX,
          y + originalRect.gradient[1] * scaleY,
          originalRect.gradient[2],
          x + originalRect.gradient[3] * scaleX,
          y + originalRect.gradient[4] * scaleY,
          originalRect.gradient[5],
        ];
        granim2.refreshColorsAndPos();
      }
    };

    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
    // eslint-disable-next-line
  }, [granim1, granim2]);

  useEffect(() => {
    if (!colors || colors.length === 0) return;

    const isSmall = window.innerWidth < 1000;
    if (!granim1) {
      const bgFeeling = colors[0].feeling.replace(/[0-9]/g, "");
      const bgColor = chroma(feelingColors[bgFeeling])
        .saturate(colors[0].value)
        .css();
      setBgColor(bgColor);

      const feeling = colors[1].feeling.replace(/[0-9]/g, "");
      setPrevColor1(feeling);

      const originalRect = isSmall
        ? granim1OriginalRectMobile
        : granim1OriginalRectDesktop;
      const newRect = { width: window.innerWidth, height: window.innerHeight };

      const scaleX = originalRect.width / newRect.width;
      const scaleY = originalRect.height / newRect.height;
      const x = originalRect.x + (newRect.width - originalRect.width) / 2;
      const y = originalRect.y + (newRect.height - originalRect.height) / 2;

      const granim = new Granim({
        loop: true,
        element: "#gradient-canvas-" + id,
        name: "gradient-canvas-" + id,
        direction: "radial",
        opacity: [1, 1],
        isPausedWhenNotInView: true,
        defaultStateName: feeling + "-" + feeling,
        stateTransitionSpeed: 1000,
        states: states,
        blur: BLUR,
        gradientPosition: [
          x + originalRect.gradient[0] * scaleX,
          y + originalRect.gradient[1] * scaleY,
          originalRect.gradient[2],
          x + originalRect.gradient[3] * scaleX,
          y + originalRect.gradient[4] * scaleY,
          originalRect.gradient[5],
        ],
      });
      setGranim1(granim);
    }
    if (!granim2) {
      const feeling = colors[2].feeling.replace(/[0-9]/g, "");
      setPrevColor2(feeling);

      const originalRect = isSmall
        ? granim2OriginalRectMobile
        : granim2OriginalRectDesktop;
      const newRect = { width: window.innerWidth, height: window.innerHeight };

      const scaleX = originalRect.width / newRect.width;
      const scaleY = originalRect.height / newRect.height;
      const x = originalRect.x + (newRect.width - originalRect.width) / 2;
      const y = originalRect.y + (newRect.height - originalRect.height) / 2;
      const granim = new Granim({
        loop: true,
        element: "#gradient-canvas-2-" + id,
        name: "gradient-canvas-2-" + id,
        direction: "radial",
        opacity: [1, 1],
        isPausedWhenNotInView: true,
        defaultStateName: feeling + "-" + feeling,
        stateTransitionSpeed: 1000,
        states: states,
        blur: BLUR,
        gradientPosition: [
          x + originalRect.gradient[0] * scaleX,
          y + originalRect.gradient[1] * scaleY,
          originalRect.gradient[2],
          x + originalRect.gradient[3] * scaleX,
          y + originalRect.gradient[4] * scaleY,
          originalRect.gradient[5],
        ],
      });
      setGranim2(granim);
    }

    // eslint-disable-next-line
  }, [granim1, granim2, colors]);

  useEffect(() => {
    if (granim1) {
      let newColor: any;
      if (colors[1].value === 0) {
        newColor = colors[0].feeling.replace(/[0-9]/g, "") + "Light";
        setGranim1Same(true);
      } else {
        newColor = colors[1].feeling.replace(/[0-9]/g, "");
        setGranim1Same(false);
      }
      if (granim1 && prevColor1 !== newColor) {
        const bgFeeling = colors[0].feeling.replace(/[0-9]/g, "");
        const bgColor = chroma(feelingColors[bgFeeling])
          .saturate(colors[0].value)
          .css();
        const newState = prevColor1 + "-" + newColor;
        setBgColor(bgColor);
        granim1.changeState(newState);
        setPrevColor1(newColor);
      }
    }
    if (granim2) {
      let newColor: any;
      if (colors[2].value === 0 && colors[1].value === 0) {
        newColor = colors[0].feeling.replace(/[0-9]/g, "") + "Dark";
        setGranim2Same(true);
      } else {
        newColor = colors[2].feeling.replace(/[0-9]/g, "");
        setGranim2Same(false);
      }
      if (granim2 && prevColor2 !== newColor) {
        const bgFeeling = colors[0].feeling.replace(/[0-9]/g, "");
        const bgColor = chroma(feelingColors[bgFeeling])
          .saturate(colors[0].value)
          .css();
        const newState = prevColor2 + "-" + newColor;
        setBgColor(bgColor);
        granim2.changeState(newState);
        setPrevColor2(newColor);
      }
    }
  }, [colors, prevColor1, prevColor2, granim1, granim2]);

  return (
    <div
      className={classNames("c-mood-selector__canvas-wrapper", {
        "c-mood-selector__canvas-wrapper--safari": isIOS || isSafari,
      })}
      style={{ backgroundColor: bgColor }}
    >
      <div className="c-mood-selector__canvas-inner">
        <canvas
          id={"gradient-canvas-" + id}
          className={classNames("c-mood-selector__canvas-gradient", {
            "c-mood-selector__canvas-gradient--lighten": granim1Same,
          })}
        ></canvas>
        <canvas
          id={"gradient-canvas-2-" + id}
          className={classNames(
            "c-mood-selector__canvas-gradient c-mood-selector__canvas-gradient--2",
            { "c-mood-selector__canvas-gradient--darken": granim2Same }
          )}
        ></canvas>
      </div>
    </div>
  );
}

export default Canvas;
