import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  RemoveFromCart,
  UpdateCartItem,
  ResetCart,
  UpdateCartUuid,
} from "store/cart/actions";
import { KioskPlaceOrder } from "store/kiosk-orders/actions";
import { SetResetKioskTimer } from "store/system/actions";
import { ResetRecsPreviousData } from "store/recommendations/actions";
interface JaneFrameProps {
  data?: any;
  history?: any;
}

const cartData = {
  messageType: "buildCart",
  payload: {
    products: [],
    user: {},
    headlessPartnerName: "Forage",
    headlessCheckoutPartnerId:
      process?.env?.REACT_APP_JANE_HEADLESS_CHECKOUT_PARTNER_ID || "",
  },
};

function JaneFrame({ data = cartData, history }: JaneFrameProps) {
  const dispatch = useDispatch();
  const user: any =
    useSelector((state: RootState) => state?.customer?.customer) || {};
  const activeDispensary: any =
    useSelector((state: RootState) => state.cart?.activeDispensary) || "";
  const cart = useSelector((state: RootState) => state.cart);
  const uuid: any = useSelector((state: RootState) => state.cart.uuid);
  const dispensaries = useSelector(
    (state: RootState) => state?.locations?.dispensaries || {}
  );

  const [storeId, setStoreId] = useState<string>("");
  const [isCheckout, setIsCheckout] = useState<boolean>(false);
  const [isKioskCheckout, setIsKioskCheckout] = useState<boolean>(false);
  const [docReady, setDocReady] = useState("");
  const isKiosk = useSelector(
    (state: RootState) => state?.system?.isKiosk || false
  );
  const isForageEmbed = useSelector(
    (state: RootState) => state?.system?.isForageEmbed || false
  );



  const appURL = process?.env.REACT_APP_DOMAIN
    ? `https://${(isKiosk && !isForageEmbed ? "kiosk." : "") + process?.env.REACT_APP_DOMAIN}`
    : "https://dev.forage.io";
  // const appURL = "http://localhost:3000";

  const [frameRedirectUrl, setFrameRedirectUrl] = useState<string>(
    appURL || ""
  );
  const checkoutUrl = isKiosk ? appURL : "/account";

  const frameFontTheme = {
    font: {
      fontFamily: "Sneak",
      url: `https://${
        isKiosk && !isForageEmbed ? "kiosk.forage.io" : "forage.io"
      }/fonts/Sneak-Regular.woff2`,
    },
    theme: {
      themeColor: "#131311",
      navigationColor: "#131311",
      ctaTextColor: "#fff",
    },
  };

  useEffect(() => {
    if (activeDispensary && dispensaries) {
      if (dispensaries[activeDispensary]?.storeId)
        setStoreId(dispensaries[activeDispensary].storeId);
      if (dispensaries[activeDispensary]?.slug) {
        const url = `${appURL}/dispensary/${dispensaries[activeDispensary].slug}/products`;
        setFrameRedirectUrl(url);
      }
    }
  }, [activeDispensary, dispensaries, appURL, frameRedirectUrl]);

  data.payload.options = {
    redirectUrl: frameRedirectUrl,
    kiosk: isKiosk,
    disableWeightSelection: false,
    disableLoadingSpinner: false,
    disableAuthFeatures: true,
    ...frameFontTheme,
  };

  data.payload.storeId = storeId;

  useEffect(() => {
    if (typeof window !== "undefined" && document?.readyState) {
      setDocReady(document.readyState);
    }
  }, [docReady]);

  //update Frame Userdata
  useEffect(() => {
    const userinfo = {
      firstName: user?.firstName || null,
      lastName: user?.lastName || null,
      birthDate: user?.dateOfBirth
        ? moment(user?.dateOfBirth, "MM/DD/YYYY").format("YYYY-MM-DD")
        : null,
      phone: null,
      email: user?.email || null,
      externalId: user?.id || null,
    };
    data.payload.user = userinfo;
  }, [user, data.payload.user]);

  useEffect(() => {
    if (uuid && isCheckout) {
      if (cart) {
        let janecart = JSON.stringify(cart);
        localStorage.setItem("janeCart", janecart);
      }
      // window.location.href = checkoutUrl;
      history.push(checkoutUrl);
      setIsCheckout(false);
    }
  }, [uuid, isCheckout, cart, checkoutUrl, history]);

  /* For kiosk checkout only */
  useEffect(() => {
    if (isKioskCheckout && cart?.uuid) {
      dispatch(KioskPlaceOrder(cart));
      setIsKioskCheckout(false);
      dispatch(ResetRecsPreviousData());
    }
  }, [isKioskCheckout, cart, dispatch]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      (docReady === "loading" || docReady === "complete")
    ) {
      const scriptEl = document.createElement("script");
      scriptEl.async = true;
      scriptEl.id = "jane-frame-script";
      scriptEl.src = "https://api.iheartjane.com/v1/headless/embed.js";
      document.getElementById("jane-frame")?.appendChild(scriptEl);
    }
  }, [docReady]);

  useEffect(() => {
    let forage_cart: any = localStorage.getItem("forage_cart");
    let newCart: any = [];

    if (forage_cart !== "" && forage_cart !== null) {
      forage_cart = JSON.parse(forage_cart);
    }
    forage_cart =
      typeof forage_cart !== "string" && forage_cart !== null
        ? forage_cart
        : [];

    if (forage_cart) {
      forage_cart.map((product: any) => {
        if (product && product?.janeProductId && product?.priceId) {
          newCart.push({
            productId: product?.janeProductId || null,
            priceId: product?.priceId || null,
            count: product.quantity || null,
          });
        }
        return null;
      });
    }

    data.payload.products = newCart;

    function receiveMessage(event: any) {
      const { payload, messageType = "", pathname = "" } = event?.data || {};

      if (
        !payload ||
        !(
          event.data.messageType === "analyticsEvent" ||
          event.data.messageType === "loadingEvent"
        )
      )
        return;

      if (messageType && payload?.name) {
        const eventType = `${messageType}-${payload.name}`;
        const { productId = null, count = null, uuid = "" } =
          payload?.properties || {};

        switch (eventType) {
          case "loadingEvent-headlessAppLoaded":
            const frame: any = document.getElementById("jane-menu");
            if (frame && data) {
              frame?.contentWindow?.postMessage(data, "*");
            }
            break;

          case "analyticsEvent-cartItemChangeCount":
            if (productId) {
              let cartItemChange = null;
              forage_cart.map((product: any) => {
                if (product?.janeProductId === productId) {
                  cartItemChange = product?.productId || null;
                }
                return null;
              });
              if (cartItemChange) {
                dispatch(UpdateCartItem(cartItemChange, count));
              }
            }

            break;

          case "analyticsEvent-cartItemRemoval":
            if (productId) {
              let cartItemRemoval = null;
              forage_cart.map((product: any) => {
                if (product?.janeProductId === productId) {
                  cartItemRemoval = product?.productId || null;
                }
                return null;
              });
              if (cartItemRemoval) {
                dispatch(RemoveFromCart(cartItemRemoval));
              }
            }
            break;

          case "analyticsEvent-checkout":
            dispatch(UpdateCartUuid(uuid));
            setIsCheckout(true);
            break;

          case "loadingEvent-cartCreateOrUpdate":
            if (
              isKiosk &&
              pathname &&
              pathname === "https://www.iheartjane.com/headless/thankyou"
            ) {
              dispatch(ResetCart());
            }
            break;

          case "analyticsEvent-kioskCheckoutFormChange":
            dispatch(SetResetKioskTimer(true));
            break;

          case "analyticsEvent-kioskCheckout":
            if (uuid) {
              dispatch(UpdateCartUuid(uuid));
              setIsKioskCheckout(true);
            }
            break;
        }
      }
    }
    //Add listerner for Iframe
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, [data, dispatch, isKiosk]);

  return <div id="jane-frame" />;
}

export default JaneFrame;
