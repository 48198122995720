import React      from 'react';
import IconButton from 'components/IconButton';
import './SignInSocial.scss';

interface SignInSocialProps {
  socialLogin: any
}

function SignInSocial({ 
  socialLogin 
}: SignInSocialProps) {
  return (
    <div className="c-signin-social">
      <IconButton variant="google" type="button" onClick={() => socialLogin(process.env.REACT_APP_AUTH0_GOOGLE_OAUTH)} Image={process.env.PUBLIC_URL + '/login-icons/google.png'} iconColor="charcoal" backgroundColor="white" textColor="charcoal" style={{border:0}}>
          Continue with Google
      </IconButton>
      {/* <IconButton variant="facebook" type="button" onClick={() => socialLogin(process.env.REACT_APP_AUTH0_FACEBOOK_OAUTH)} Image={process.env.PUBLIC_URL + '/login-icons/facebook.png'} iconColor="charcoal" backgroundColor="white" textColor="charcoal" style={{border:0}}>
          Continue with Facebook
      </IconButton>
      <IconButton variant="apple" type="button" onClick={() => socialLogin(process.env.REACT_APP_AUTH0_APPLE_OAUTH)} Image={process.env.PUBLIC_URL + '/login-icons/apple.png'} iconColor="charcoal" backgroundColor="white" textColor="charcoal" style={{border:0}}>
          Continue with Apple
      </IconButton> */}
    </div>
  )
}

export default SignInSocial;