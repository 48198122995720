import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Spray: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--spray" version="1.1" x="0px" y="0px" viewBox="0 0 46.7 74.5">
      <g>
        <g>
          <g>
            <path className="i-product-type--spray--st0" stroke={color} d="M7.3,30.8v3.8c-3.9,0.8-6.8,4.3-6.8,8.4v27.8c0,1.7,1.4,3.2,3.2,3.2h24c1.7,0,3.2-1.4,3.2-3.2V43
              c0-4.1-2.9-7.6-6.8-8.4v-3.8"/>
            <path className="i-product-type--spray--st0" stroke={color} d="M25.3,16.2H6.1c-0.7,0-1.2,0.6-1.2,1.2v12.2c0,0.7,0.6,1.2,1.2,1.2h19.2c0.7,0,1.2-0.6,1.2-1.2V17.4
              C26.5,16.7,26,16.2,25.3,16.2z"/>
            <line className="i-product-type--spray--st0" stroke={color} x1="0.5" y1="44.1" x2="13.9" y2="44.1"/>
            <line className="i-product-type--spray--st0" stroke={color} x1="30.6" y1="44.1" x2="17.3" y2="44.1"/>
            <path className="i-product-type--spray--st0" stroke={color} d="M20.2,0.9h-9c-0.6,0-1.1,0.5-1.1,1.1V15c0,0.6,0.5,1.1,1.1,1.1h9c0.6,0,1.1-0.5,1.1-1.1V2
              C21.3,1.4,20.8,0.9,20.2,0.9z"/>
            <circle fill={color} cx="17.6" cy="8.3" r="1.3"/>
          </g>
          <line className="i-product-type--spray--st0" stroke={color} x1="0.5" y1="66.7" x2="30.9" y2="66.7"/>
        </g>
      </g>
      <g>
        <line className="i-product-type--spray--st0" stroke={color} x1="15.6" y1="59.6" x2="15.6" y2="62.9"/>
        <path className="i-product-type--spray--st0" stroke={color} d="M15.6,40.6c0,0,1.7,2.1,2.3,8.4c0.6,6.3-2.3,10.7-2.3,10.7s9.7-0.8,11.5-11.4c0,0-6.5,3.8-9.8,7.4"/>
        <path className="i-product-type--spray--st0" stroke={color} d="M15.6,40.6c0,0-1.7,2.1-2.3,8.4s2.3,10.7,2.3,10.7S5.9,58.8,4.1,48.2c0,0,6.5,3.8,9.8,7.4"/>
        <path className="i-product-type--spray--st0" stroke={color} d="M25.5,59.4c0,0-4.2,3.6-9.9,0.2"/>
        <path className="i-product-type--spray--st0" stroke={color} d="M5.6,59.4c0,0,4.2,3.6,9.9,0.2"/>
      </g>
      <line className="i-product-type--spray--st0" stroke={color} x1="40.2" y1="8.3" x2="46.2" y2="8.3"/>
      <line className="i-product-type--spray--st0" stroke={color} x1="32.9" y1="8.3" x2="36.9" y2="8.3"/>
      <line className="i-product-type--spray--st0" stroke={color} x1="28.3" y1="8.3" x2="29.7" y2="8.3"/>
      <line className="i-product-type--spray--st0" stroke={color} x1="31" y1="13.9" x2="36.4" y2="16.2"/>
      <line className="i-product-type--spray--st0" stroke={color} x1="33.9" y1="1.5" x2="36.4" y2="0.5"/>
      <line className="i-product-type--spray--st0" stroke={color} x1="31" y1="2.8" x2="31.7" y2="2.5"/>
      <line className="i-product-type--spray--st0" stroke={color} x1="10.1" y1="21.8" x2="10.1" y2="25.2"/>
      <line className="i-product-type--spray--st0" stroke={color} x1="15.6" y1="21.8" x2="15.6" y2="25.2"/>
      <line className="i-product-type--spray--st0" stroke={color} x1="21" y1="21.8" x2="21" y2="25.2"/>
    </svg>
  )
}

export default Spray;