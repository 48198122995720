import React, { useEffect, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "store";
import { SetDrawerOpen } from "store/system/actions";
import { ResetRecs } from "store/recommendations/actions";
import {
  SetSigninRedirect,
  SetMobileNavigationOpen,
  SetMoodSelectorStep,
  SetMoodSelectorIsOpen,
} from "store/system/actions";

import BackArrowIcon from "components/svgs/BackArrow";
import Logo from "components/svgs/Logo";
import Avatar from "components/svgs/Avatar";
import Hamburger from "components/svgs/Hamburger";
import LocationIcon from "components/svgs/Location";
import RightCaretIcon from "components/svgs/RightCaret";
import MobileNavigation from "components/MobileNavigation";
import CloseIcon from "components/svgs/Close";

import "./Header.scss";
import { SetInventoryFilter } from "../../store/inventory/actions";
function Header({ history, location, match }: RouteComponentProps) {
  const dispatch = useDispatch();
  const { pathname } = location;
  const params: any = match.params;
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const customer = useSelector((state: RootState) => state.customer.customer);
  const moodSelectorOpen = useSelector(
    (state: RootState) => state.system.moodSelectorOpen
  );
  const profileOpen = useSelector(
    (state: RootState) => state.system.profileOpen
  );
  const drawerOpen = useSelector((state: RootState) => state.system.drawerOpen);
  const geoLocating = useSelector(
    (state: RootState) => state.system.geoLocating
  );
  const isKiosk = useSelector((state: RootState) => state.system.isKiosk);
  const isDesktop = useSelector((state: RootState) => state.system.isDesktop);
  const userLocationZip = useSelector(
    (state: RootState) => state.user.userLocationZip
  );
  const geoLocationDisabled = useSelector(
    (state: RootState) => state.system.geoLocationDisabled
  );
  const geoLocationDismissed = useSelector(
    (state: RootState) => state.system.geoLocationDismissed
  );
  const dispensaries = useSelector(
    (state: RootState) => state.locations.dispensaries
  );

  const drawerActuallyOpen = useRef(false);
  const showHeader = useRef(window.scrollY < 68);
  const showHeaderGhost = useRef(window.scrollY > 68);
  const [acceptingPreorders, setAcceptingPreorders] = useState(
    dispensaries && activeDispensary && dispensaries[activeDispensary]
      ? dispensaries[activeDispensary].acceptingPreorders
      : dispensaries && params.slug
      ? dispensaries[params.slug]?.acceptingPreorders
      : false
  );
  const [action, setAction] = useState("");
  const [cartCount, setCartCount] = useState(0);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [prevYScroll, setPrevYScroll] = useState(window.scrollY);
  const [profileIsOpen, setProfileIsOpen] = useState(false);
  const [locationString, setLocationString] = useState("");

  const closeMenu = useCallback(() => {
    dispatch(SetMobileNavigationOpen(false));
    setMobileNavOpen(false);
    document.body.classList.remove("no-scroll");
  }, [dispatch]);

  function toggleMenu() {
    showHeader.current = true;
    dispatch(SetMobileNavigationOpen(!mobileNavOpen));
    setMobileNavOpen(!mobileNavOpen);
    if (mobileNavOpen) {
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
  }

  function resetHome() {
    dispatch(SetInventoryFilter({}));
    dispatch(SetMoodSelectorIsOpen(false));
    dispatch(SetMoodSelectorStep(1));
    dispatch(ResetRecs());
  }

  const listenToHistory = useCallback(
    (arg: any) => {
      if (drawerActuallyOpen.current) {
        dispatch(SetDrawerOpen(false));
        document.body.classList.remove("no-scroll");
      }
      closeMenu();
    },
    [closeMenu, dispatch]
  );

  useEffect(() => {
    if (drawerOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [drawerOpen]);

  useEffect(() => {
    const unlisten = history.listen(listenToHistory);

    return () => unlisten();
  }, [history, listenToHistory]);

  useEffect(() => {
    drawerActuallyOpen.current = drawerOpen;
  }, [drawerOpen]);

  useEffect(() => {
    setAcceptingPreorders(
      dispensaries && activeDispensary && dispensaries[activeDispensary]
        ? dispensaries[activeDispensary].acceptingPreorders
        : dispensaries && params?.slug
        ? dispensaries[params.slug]?.acceptingPreorders
        : false
    );
  }, [dispensaries, params, activeDispensary]);

  useEffect(() => {
    function watchScroll(e?: any) {
      if (e && e.originalEvent !== undefined) {
        return;
      }
      if (mobileNavOpen) {
        showHeader.current = true;
        return;
      }
      if (window.scrollY < prevYScroll) {
        if (!showHeader.current) {
          showHeader.current = true;
        }
      } else if (window.scrollY > 68) {
        if (showHeader.current) {
          showHeader.current = false;
        }
      }

      if (window.scrollY > 68 && showHeader.current) {
        if (!showHeaderGhost.current) {
          showHeaderGhost.current = true;
        }
      } else {
        if (showHeaderGhost.current) {
          showHeaderGhost.current = false;
        }
      }
      if (window.scrollY !== prevYScroll) {
        setPrevYScroll(window.scrollY);
      }
    }

    window.addEventListener("scroll", watchScroll);
    return function __cleanup() {
      window.removeEventListener("scroll", watchScroll);
    };
  }, [mobileNavOpen, prevYScroll]);

  useEffect(() => {
    if (profileOpen && !profileIsOpen) {
      setProfileIsOpen(true);
    } else if (!profileOpen && profileIsOpen) {
      setProfileIsOpen(false);
    }
  }, [profileIsOpen, profileOpen]);

  useEffect(() => {
    let itemCount = 0;
    cartItems.forEach((item: any) => {
      itemCount += item.quantity;
    });
    setCartCount(itemCount);
  }, [cartItems]);

  useEffect(() => {
    const { path } = match;
    const backPaths = ["/order", "/account/add-reservation-details"];
    if (backPaths.indexOf(path) >= 0 && isKiosk) {
      setAction("back");
    } else {
      setAction("");
    }
  }, [history, match, isKiosk]);

  useEffect(() => {
    let locString: string = "";
    if (geoLocating) {
      locString = "Locating...";
    } else if (
      activeDispensary &&
      dispensaries &&
      dispensaries[activeDispensary]
    ) {
      locString = `${dispensaries[activeDispensary]?.name}`;
    } else if (userLocationZip) {
      locString = "Browsing from: " + userLocationZip;
    } else {
      locString = "Select Location";
    }
    setLocationString(locString);
  }, [
    activeDispensary,
    dispensaries,
    geoLocating,
    geoLocationDisabled,
    geoLocationDismissed,
    userLocationZip,
  ]);

  function goBack() {
    history.goBack();
  }

  return (
    <>
      <header
        id="header"
        className={classNames("c-header", {
          "c-header--mood-selector-open": moodSelectorOpen || profileOpen,
          "c-header--hidden": showHeader.current === false,
          "c-header--ghosted": showHeaderGhost.current,
          "c-header--drawer-open": drawerOpen,
          "c-header--nav-open": mobileNavOpen,
        })}
      >
        <div className="c-header__inner">
          <div className="c-header__inner__nav">
            <div className="c-header__inner__logo-hamburger">
              {!isKiosk && (
                <button aria-label="Toggle Menu" className="c-header__hamburger" onClick={toggleMenu}>
                  <Hamburger variant="charcoal" />
                  <CloseIcon variant="white" />
                </button>
              )}
              {action === "back" ? (
                <button
                  type="button"
                  onClick={goBack}
                  className="c-header__back"
                >
                  <BackArrowIcon variant="charcoal" /> <span>Back</span>
                </button>
              ) : (
                <Link
                  to="/"
                  onClick={resetHome}
                  className="c-header__logo-link"
                  aria-label="FORAGE Homepage"
                >
                  <Logo variant="charcoal" />
                </Link>
              )}
            </div>
            {!isKiosk && isDesktop && (
              <>
                <div className="c-header__location">
                  <Link to="/locations">
                    <div className="c-header__location__button">
                      <LocationIcon variant="charcoal" />
                      <span className="c-header__location__value">
                        {locationString}
                      </span>
                      <RightCaretIcon variant="charcoal" />
                    </div>
                  </Link>
                </div>
                <ul className="c-header__links">
                  <li>
                    <Link to="/recommendations">Recommendations</Link>
                  </li>
                  <li>
                    <Link
                      to={
                        customer && customer.hasProfile
                          ? "/account/profile"
                          : "/create-profile"
                      }
                    >
                      {customer ? "My Profile" : "Create Profile"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        activeDispensary
                          ? "/dispensary/" + activeDispensary + "/products"
                          : "/locations"
                      }
                    >
                      All Products
                    </Link>
                  </li>
                  <li>
                    <Link to="/locations">All Locations</Link>
                  </li>
                </ul>
              </>
            )}
          </div>
          <div
            className={classNames("c-header__controls", {
              "c-header__controls--no-preorders": !acceptingPreorders,
            })}
          >
            {!isKiosk && (
              <Link
                to={customer ? "/account" : "/account/signin"}
                onClick={() =>
                  dispatch(SetSigninRedirect(window.location.pathname))
                }
                aria-label={customer ? "Account" : "Sign in"}
                className={classNames("c-header__controls__account", {
                  "c-header__controls__account--active":
                    pathname.indexOf("account") >= 0,
                })}
              >
                <Avatar
                  variant={
                    pathname.indexOf("account") >= 0 ? "deepblue" : "charcoal"
                  }
                />
              </Link>
            )}
            <Link
              to="/order"
              className={classNames("c-header__controls__cart", {
                "c-header__controls__cart--active": pathname === "/order",
              })}
            >
              {cartCount}
            </Link>
          </div>
        </div>
      </header>
      <MobileNavigation />
    </>
  );
}

export default withRouter(Header);
