import {
  CREATE_CUSTOMER,
  CUSTOMER_LOGOUT,
  CUSTOMER_LOGIN,
  CUSTOMER_LOGIN_FAILED,
  CREATING_CUSTOMER,
  CUSTOMER_SAVE_SETTINGS,
  FETCH_ORDER_HISTORY,
  RESET_SETTING_SAVED,
  FETCH_PRODUCTS_TO_REVIEW,
  CustomerActionTypes,
  REVIEW_PRODUCT,
  RESET_VERIFICATION_NEEDED,
  RESET_PASSWORD_PLACEHOLDER,
  SET_FULL_ADDRESS,
  UPDATE_ACCESS_TOKEN,
  RESET_CUSTOMER_ERROR,
  FETCH_ACCOUNT_SETTINGS_SUCCESS,
  FETCH_ACCOUNT_SETTINGS,
  FETCH_ACCOUNT_SETTINGS_FAILED,
} from "./types";

export function CreateCustomer(payload: any): CustomerActionTypes {
  return {
    type: CREATE_CUSTOMER,
    payload,
  };
}

export function CustomerLogout(): CustomerActionTypes {
  return {
    type: CUSTOMER_LOGOUT,
  };
}

export function CustomerLogin(payload: any): CustomerActionTypes {
  return {
    type: CUSTOMER_LOGIN,
    payload,
  };
}

export function CustomerLoginFailed(error: any): CustomerActionTypes {
  return {
    type: CUSTOMER_LOGIN_FAILED,
    error,
  };
}

export function CreatingCustomer(isCreating: any) {
  return {
    type: CREATING_CUSTOMER,
    isCreating,
  };
}

export function CustomerSaveSettings(payload: any) {
  return {
    type: CUSTOMER_SAVE_SETTINGS,
    payload,
  };
}

export function FetchOrderHistory(
  orderHistoryFetchLimit?: number,
  offset?: number | null
) {
  return {
    type: FETCH_ORDER_HISTORY,
    orderHistoryFetchLimit: orderHistoryFetchLimit,
    offset,
  };
}

export function ResetSettingSaved() {
  return {
    type: RESET_SETTING_SAVED,
  };
}

export function FetchProductsToReview(): CustomerActionTypes {
  return {
    type: FETCH_PRODUCTS_TO_REVIEW,
  };
}

export function ReviewProduct(review: any): CustomerActionTypes {
  return {
    type: REVIEW_PRODUCT,
    review,
  };
}

export function ResetVerificationNeeded(): CustomerActionTypes {
  return {
    type: RESET_VERIFICATION_NEEDED,
  };
}

export function ResetPasswordPlaceholder(): CustomerActionTypes {
  return {
    type: RESET_PASSWORD_PLACEHOLDER,
  };
}

export function SetFullAddress(address: any): CustomerActionTypes {
  return {
    type: SET_FULL_ADDRESS,
    address,
  };
}

export function UpdateAccessToken(accessToken: string): CustomerActionTypes {
  return {
    type: UPDATE_ACCESS_TOKEN,
    accessToken,
  };
}

export function ResetCustomerError(): CustomerActionTypes {
  return {
    type: RESET_CUSTOMER_ERROR,
  };
}

export function FetchAccountSettings(payload: any): CustomerActionTypes {
  return {
    type: FETCH_ACCOUNT_SETTINGS,
    payload,
  };
}

export function FetchAccountSettingsSuccess(
  customer: any
): CustomerActionTypes {
  return {
    type: FETCH_ACCOUNT_SETTINGS_SUCCESS,
    customer,
  };
}

export function FetchAccountSettingsFailed(error: any): CustomerActionTypes {
  return {
    type: FETCH_ACCOUNT_SETTINGS_FAILED,
    error,
  };
}
