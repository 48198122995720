function distance2Points(x1:number, y1:number, x2:number, y2:number) {
  var xx2 = x2 <= 12.5 ? 12.5 : x2;
  if (x2 >= 87.5) {
    xx2 = 87.5
  }
  var yy2 = y2 <= 12.5 ? 12.5 : y2;
  if (y2 >= 87.5) {
    yy2 = 87.5
  }
  var a = x1 - xx2;
  var b = y1 - yy2;
  var c = Math.sqrt( a*a + b*b ); 
  // if (c >= 35.355339 || c < 0) {
  if (c >= 25) {
    return 0; //"too big"
  }
  return (Math.abs(25 - c) * 4);
}

export const calculateXY = (x:number, y:number, recConfig:any) => {
  var aroused     = [distance2Points(recConfig.aroused[0], recConfig.aroused[1], x, y)]
  var creative    = [distance2Points(recConfig.creative[0], recConfig.creative[1], x, y)]
  var energetic   = [distance2Points(recConfig.energized[0], recConfig.energized[1], x, y)]
  var euphoric    = [distance2Points(recConfig.euphoric[0], recConfig.euphoric[1], x, y)]
  var focused1    = [distance2Points(recConfig.focused0[0], recConfig.focused0[1], x, y)]
  var focused2    = [distance2Points(recConfig.focused1[0], recConfig.focused1[1], x, y)]
  var giggly      = [distance2Points(recConfig.giggly[0], recConfig.giggly[1], x, y)]
  var happy       = [distance2Points(recConfig.happy[0], recConfig.happy[1], x, y)]
  var hungry      = [distance2Points(recConfig.hungry[0], recConfig.hungry[1], x, y)]
  var relaxed     = [distance2Points(recConfig.relaxed[0], recConfig.relaxed[1], x, y)]
  var sleepy1     = [distance2Points(recConfig.sleepy0[0], recConfig.sleepy0[1], x, y)]
  var sleepy2     = [distance2Points(recConfig.sleepy1[0], recConfig.sleepy1[1], x, y)]
  var talkative1  = [distance2Points(recConfig.talkative0[0], recConfig.talkative0[1], x, y)]
  var talkative2  = [distance2Points(recConfig.talkative1[0], recConfig.talkative1[1], x, y)]
  var tingly      = [distance2Points(recConfig.tingly[0], recConfig.tingly[1], x, y)]
  var uplifted    = [distance2Points(recConfig.uplifted[0], recConfig.uplifted[1], x, y)]
  let values:any  = [aroused, creative, energetic, euphoric, focused1, focused2, giggly, happy,
  hungry, relaxed, sleepy1, sleepy2, talkative1, talkative2, tingly, uplifted];

  var sum = values.reduce(function(a:any, b:any) {
    return Number(a) + Number(b);
  }, 0);

  var i, len, mappedues;
  for (i = 0, len = values.length, mappedues = []; i < len; i++) {
    mappedues[i] = (values[i]/sum);
  }

  var mappedXY = {
    aroused:    mappedues[0],
    creative:   mappedues[1],
    energetic:  mappedues[2],
    euphoric:   mappedues[3],
    focused:    (mappedues[4] + mappedues[5]),
    giggly:     mappedues[6],
    happy:      mappedues[7],
    hungry:     mappedues[8],
    relaxed:    mappedues[9],
    sleepy:     mappedues[10] + mappedues[11],
    talkative:  mappedues[12] + mappedues[13],
    tingly:     mappedues[14],
    uplifted:   mappedues[15]
  };
  return mappedXY;
}
