import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import Button from "components/Button";
import ReviewCard from "./ReviewCard";
import { RootState } from "store";
import { FetchProductsToReview } from "store/customer/actions";
import "./ProductReviews.scss";
import "swiper/swiper.scss";

function ProductReviews() {
  const dispatch = useDispatch();
  const isMobile = useSelector((state: RootState) => state.system.isMobile);
  const productsToReview = useSelector(
    (state: RootState) => state.customer.productsToReview
  );

  const [ptr, setPTR] = useState<any>(null);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [controlledSwiper, setControlledSwiper] = useState<any>(null);
  const [transitionIn, setTransitionIn] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEnd, setIsEnd] = useState(false);

  const innerRef = useRef<HTMLDivElement>(null);

  const sortPtrByDate = (newestFirst = true) => {
    if (!ptr) {
      return [];
    }

    return ptr.sort((productA: any, productB: any) => {
      // @ts-ignore
      const dateA = new moment(productA.mostRecentDateOfOrder).format(
        "YYYYMMDDHHmm"
      );
      // @ts-ignore
      const dateB = new moment(productB.mostRecentDateOfOrder).format(
        "YYYYMMDDHHmm"
      );

      return newestFirst ? dateB - dateA : dateA - dateB;
    });
  };

  useEffect(() => {
    if (productsToReview === null) {
      dispatch(FetchProductsToReview());
    }
  }, [productsToReview, dispatch]);

  useEffect(() => {
    if (ptr === null && productsToReview && productsToReview.length > 0) {
      const productsToPTR = [...productsToReview];
      setPTR([...productsToPTR.slice(0, 10)]);
      setProductsLoaded(true);
      setTransitionIn(true);
    }
  }, [productsToReview, ptr]);

  function onSubmit(productId: number) {
    const index = ptr.findIndex(
      (product: any) => product.productId === productId
    );
    let existing = [...ptr];
    existing[index].reviewed = true;
    setPTR([...existing]);
    setTimeout(() => {
      existing.splice(index, 1);
      setPTR([...existing]);
    }, 1000);
  }

  function finishReviewing() {
    setTransitionIn(false);
    setTimeout(() => {
      setProductsLoaded(false);
    }, 500);
  }
  return (
    <>
      {productsLoaded ? (
        <CSSTransition
          in={transitionIn}
          classNames="c-product-reviews-"
          timeout={3000}
          appear
        >
          <section className="c-product-reviews">
            <div className="c-product-reviews__inner" ref={innerRef}>
              <div className="slug">Review Your Products</div>
              <Swiper
                slidesPerView={isMobile ? 1 : "auto"}
                spaceBetween={isMobile ? 8 : 44}
                onSwiper={setControlledSwiper}
                allowTouchMove={true}
                noSwipingClass="swiper-no-swiping"
                slidesOffsetAfter={
                  innerRef.current !== null && !isMobile
                    ? innerRef.current.offsetWidth - 356
                    : 0
                }
                onSlideChange={(swiper) => {
                  setActiveIndex(swiper.activeIndex);
                  setIsEnd(swiper.activeIndex === ptr.length);
                }}
              >
                {sortPtrByDate().map((product: any) => {
                  return (
                    <SwiperSlide
                      key={
                        "product-review-" +
                        product.dispensarySlug +
                        "-" +
                        product.productId
                      }
                      className={classNames("c-product-reviews__slide", {
                        "c-product-reviews__slide--reviewed": product.reviewed,
                      })}
                    >
                      <ReviewCard product={product} onSubmit={onSubmit} />
                    </SwiperSlide>
                  );
                })}
                <SwiperSlide
                  key={"reviews-all-done"}
                  className="c-product-reviews__slide"
                  style={{ opacity: ptr.length > 0 ? 0.5 : 1 }}
                >
                  <div className="c-product-reviews__all-done">
                    <h1>
                      That's it for now!
                      <br />
                      <br />
                      Thank you for sharing your experiences
                    </h1>
                    <Button
                      type="button"
                      variant=""
                      disabled={ptr.length > 0}
                      onClick={finishReviewing}
                    >
                      Finish
                    </Button>
                  </div>
                </SwiperSlide>
              </Swiper>
              {controlledSwiper && ptr.length > 0 && (
                <div className="c-product-reviews__nav">
                  {isMobile ? (
                    <div className="c-product-reviews__nav__pages">
                      {ptr.map((product: any, index: number) => (
                        <button
                          className={classNames(
                            "c-product-reviews__nav__pages__button",
                            {
                              "c-product-reviews__nav__pages__button--active":
                                index === activeIndex,
                            }
                          )}
                          key={"nav-" + String(product.productId)}
                          onClick={() => controlledSwiper.slideTo(index, 500)}
                        >
                          {index < 9
                            ? "0" + String(index + 1)
                            : String(index + 1)}
                        </button>
                      ))}
                    </div>
                  ) : (
                    <button
                      className="c-product-reviews__nav__prev"
                      onClick={() => controlledSwiper.slidePrev()}
                    >
                      PREV
                    </button>
                  )}
                  <button
                    className="c-product-reviews__nav__next"
                    disabled={isEnd}
                    onClick={() => controlledSwiper.slideNext()}
                  >
                    NEXT
                  </button>
                </div>
              )}
            </div>
          </section>
        </CSSTransition>
      ) : (
        <></>
      )}
    </>
  );
}

export default ProductReviews;
