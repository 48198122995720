
import { 
  OrdersState, 
  OrdersActionTypes, 
  PLACE_ORDER,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILED,
  RESET_ORDER_PLACED,
  FETCH_PREORDER,
  FETCH_PREORDER_SUCCESS,
  FETCH_PREORDER_FAILED,
} from './types';

const initialState: OrdersState = {
  placingOrder:     false,
  orderPlaced:      false,
  error:            null,
  latestOrder:      null,
  loadingPreorder:  false,
  preorder:         false,
  preorders:        {}
}

export function ordersReducer(
  state = initialState,
  action: OrdersActionTypes
): OrdersState {
  switch (action.type) {
    case PLACE_ORDER: {
      return {
        ...state,
        placingOrder: true
      }
    }

    case PLACE_ORDER_SUCCESS: {
      return {
        ...state,
        placingOrder: false,
        orderPlaced:  true,
        latestOrder:  action.order
      }
    }
    case PLACE_ORDER_FAILED: {
      return {
        ...state,
        placingOrder: false,
        error:        'error'
      }
    }
    case RESET_ORDER_PLACED: {
      return {
        ...state,
        orderPlaced: false,
        latestOrder: false,
      }
    }
    case FETCH_PREORDER : {
      return {
        ...state,
        loadingPreorder: true
      }
    }
    case FETCH_PREORDER_SUCCESS : {
      let existingPreorders = state.preorders;
          existingPreorders[action.orderId] = action.preorder;
      return {
        ...state,
        loadingPreorder:  false,
        preorder:         action.preorder,
        preorders:        {...existingPreorders}
      }
    }
    case FETCH_PREORDER_FAILED : {
      return {
        ...state
      }
    }
    default:
      return state
  }
}