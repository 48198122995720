import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Giggly: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--giggly" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <g>
          <path className="i-feeling--euphoric--st0" stroke={color} fill={color} d="M53.9,188.3c0-35.8,29-64.8,64.8-64.8s64.8,29,64.8,64.8"/>
        </g>
        <g>
          <path className="i-feeling--euphoric--st0" stroke={color} fill={color} d="M231.2,188.3c0-35.8,29-64.8,64.8-64.8s64.8,29,64.8,64.8"/>
        </g>
        <g>
          <path className="i-feeling--giggly--st1" stroke={color} fill={color} d="M143.4,226.2c0,35.8,29,64.8,64.8,64.8s64.8-29,64.8-64.8H143.4z"/>
        </g>
      </g>
    </svg>

  )
}

export default Giggly;