import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Beverages: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--beverages" version="1.1" x="0px" y="0px" viewBox="0 0 41.2 74.1">
      <g>
        <line className="i-product-type--beverages--st0" stroke={color} x1="17.5" y1="51.3" x2="17.5" y2="54.6"/>
        <path className="i-product-type--beverages--st0" stroke={color} d="M17.5,32.3c0,0,1.7,2.1,2.3,8.4c0.6,6.3-2.3,10.7-2.3,10.7s9.7-0.8,11.5-11.4c0,0-6.5,3.8-9.8,7.4"/>
        <path className="i-product-type--beverages--st0" stroke={color} d="M17.5,32.3c0,0-1.7,2.1-2.3,8.4s2.3,10.7,2.3,10.7S7.7,50.6,6,39.9c0,0,6.5,3.8,9.8,7.4"/>
        <path className="i-product-type--beverages--st0" stroke={color} d="M27.4,51.1c0,0-4.2,3.6-9.9,0.2"/>
        <path className="i-product-type--beverages--st0" stroke={color} d="M7.5,51.1c0,0,4.2,3.6,9.9,0.2"/>
      </g>
      <polyline className="i-product-type--beverages--st0" stroke={color} points="0.5,67.7 0.5,21.7 34.4,21.7 "/>
      <polyline className="i-product-type--beverages--st0" stroke={color} points="34.4,21.7 34.4,67.7 0.5,67.7 "/>
      <path className="i-product-type--beverages--st0" stroke={color} d="M34.4,21.7"/>
      <polyline className="i-product-type--beverages--st0" stroke={color} points="34.4,21.7 31.4,15.8 3.5,15.8 0.5,21.7 "/>
      <polyline className="i-product-type--beverages--st0" stroke={color} points="34.4,67.7 31.4,73.6 3.5,73.6 0.5,67.7 "/>
      <polyline className="i-product-type--beverages--st0" stroke={color} points="26.7,15.8 29.6,6.7 40.7,3.5 39.8,0.5 27,4.2 23.4,15.3 "/>
    </svg>


  )
}

export default Beverages;