import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
const Navigator: FunctionComponent<IconType> = ({ color = '#102981'}) => {
  return(
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 6L18.0207 10.0069L11.1199 11.1199L10.0069 18.0207L6 6Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Navigator;