import React, { useState, useEffect, useRef } from 'react';
import classNames                             from 'classnames';
import { useSelector }           from 'react-redux';
import { CSSTransition }                      from 'react-transition-group';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks }                   from 'body-scroll-lock';
import { RootState }      from 'store';
import CloseIcon          from 'components/svgs/Close';
import './MenuDrawer.scss';

interface MenuDrawerProps {
  display:      boolean
  closeMenu:    any
  children:     any
  menuHeading:  string
  className?:   string
  headingButton?: {
    enabled:      boolean
    label:        string
    clickAction:  any
  },
  closeButton?:   boolean
  headerClass?:   string
  position?:      string
  stickyActions?: any
  sizeChange?:    any
}

function MenuDrawer({
  display,
  closeMenu,
  menuHeading,
  headingButton,
  closeButton,
  position,
  children,
  headerClass = 'h1',
  className = '',
  stickyActions,
  sizeChange
}: MenuDrawerProps) {
  const isMobile = useSelector(( state : RootState ) => state.system.isMobile);

  const [menuMaxHeight, setMenuMaxHeight]           = useState(100000);
  const [includeMenuContent, setIncludeMenuContent] = useState(false);
  const [showMenu, setShowMenu]                     = useState(false);
  const [contentHeight, setContentHeight]           = useState<any>(window.innerWidth < 768 || position === 'toast' ? 'auto' : '100%');
  const [behindSticky, setBehindSticky]             = useState(false);

  const sizerRef        = useRef<HTMLDivElement>(null);
  const contentRef      = useRef<HTMLDivElement>(null);
  const contentInnerRef = useRef<HTMLDivElement>(null);
  const childrenRef     = useRef<HTMLDivElement>(null);
  const headerRef       = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(sizerRef.current !== null && isMobile){
      setMenuMaxHeight(window.innerHeight + 16);
    }
  }, [sizerRef, isMobile]);

  useEffect(() => {
    const drawerContentElement = document.querySelector('.c-menu-drawer__content__inner');

    if (drawerContentElement) {
      if (showMenu) {
        disableBodyScroll(drawerContentElement);
      } else {
        enableBodyScroll(drawerContentElement);
      }
    }

    return () => clearAllBodyScrollLocks();
  }, [showMenu]);

  useEffect(() => {
    if(isMobile && menuMaxHeight){
      if(contentInnerRef.current !== null && childrenRef.current !== null){
        if(childrenRef.current.scrollHeight >= menuMaxHeight){
          setContentHeight(menuMaxHeight);
          setBehindSticky(true);
        } else {
          setContentHeight('auto');
          setBehindSticky(false);
        }
      }
    } else {
      if(null !== childrenRef.current && null !== headerRef.current && null !== contentRef.current){
        if(childrenRef.current.clientHeight > contentRef.current.clientHeight - headerRef.current.clientHeight - 100){
          setBehindSticky(true);
        } else {
          setBehindSticky(false);
        }
      }
    }
  }, [sizeChange, isMobile, menuMaxHeight]);

  useEffect(() => {
    let displayTimeout:any;
    if(display){
      setIncludeMenuContent(true);
      setShowMenu(true);
    } else {
      setShowMenu(false);
      displayTimeout = setTimeout(() => {
        setIncludeMenuContent(false);
      }, 1000);
    }

    return function __cleanup() {
      clearTimeout(displayTimeout);
    }
  }, [display]);

  let positionClass = '';
  if(position) {
    positionClass = 'c-menu-drawer--' + position;
  }
  return(
    <>
      { includeMenuContent &&
        <CSSTransition
          classNames="c-menu-drawer-"
          timeout={1000}
          appear
          in={showMenu}
        >
          <section className={classNames("c-menu-drawer " + positionClass + ' ' + className, {'c-menu-drawer--sticky-action': stickyActions})}>
            <div className="c-menu-drawer__backdrop" onClick={closeMenu}></div>
            <div className="c-menu-drawer__content" style={{maxHeight: menuMaxHeight, height: contentHeight}} ref={contentRef}>
              <div className={classNames("c-menu-drawer__content__inner", {'c-menu-drawer__content__inner--sticky': stickyActions})} ref={contentInnerRef}>
                <div className="c-menu-drawer__header" ref={headerRef}>
                  <div className={headerClass}>{menuHeading}</div>
                  {
                    headingButton && headingButton.clickAction && headingButton.label &&
                      <button className="c-menu-drawer__clear" onClick={headingButton.clickAction}>{headingButton.label}</button>
                  }
                  {
                    closeButton &&
                      <button className="c-menu-drawer__close" onClick={closeMenu}>
                        <CloseIcon />
                      </button>
                  }
                </div>
                <div className="c-menu-drawer__children" ref={childrenRef}>
                  {children}
                </div>
              </div>
              {
                stickyActions &&
                <div className={classNames("c-menu-drawer__sticky-actions", {'c-menu-drawer__sticky-actions--behind-sticky': behindSticky})}>
                  {stickyActions}
                </div>
              }
            </div>
          </section>
        </CSSTransition>
      }
      <div id="page-sizer" ref={sizerRef}></div>
    </>
  )
}

export default MenuDrawer;
