import { useSelector } from 'react-redux';
import { selectRecsCount, selectAllRecs, selectSearchQuery, selectSort, selectLoading } from '../../../store/recommendations/selectors';

const UseRecommendation = () => {
  const recsCount = useSelector(selectRecsCount);
  const allRecs = useSelector(selectAllRecs);
  const searchQuery = useSelector(selectSearchQuery);
  const sort = useSelector(selectSort);
  const loading = useSelector(selectLoading);


  return { recsCount, allRecs, searchQuery, sort, loading };
};

export default UseRecommendation;