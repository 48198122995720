import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withOrientationChange } from "react-device-detect";

import {
  ActivitySelector,
  CreateProfile,
  DispensaryNearYou,
  FeaturedProducts,
  KioskOptions,
  MoodSelector,
  ProductReviews
} from "components/Home";
import FylloImage from "components/FylloImage";
import "./Home.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { SetMoodSelectorIsOpen } from "../../store/system/actions";

interface AppProps {
  isPortrait: boolean;
  isLandscape: boolean;
}

function Home({ isPortrait, isLandscape }: AppProps) {
  const dispatch = useDispatch();
  const isKiosk = useSelector((state: RootState) => state.system.isKiosk);
  const customer = useSelector((state: RootState) => state.customer.customer);
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const moodSelectorOpen = useSelector(
    (state: RootState) => state.system.moodSelectorOpen
  );
  const [buildTimeStamp, setBuildTimeStamp] = useState<any>(null);

  const onSelectorOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(SetMoodSelectorIsOpen(isOpen));
    },
    [dispatch]
  );

  useEffect(() => {
    setBuildTimeStamp(new Date().getTime());
  }, []);

  return (
    <>
      {buildTimeStamp && (
        <FylloImage
          src={`https://ad.ipredictive.com/d/track/cvt/pixel?acct_id=46882&cache_buster=${buildTimeStamp}`}
        />
      )}
      <section className="c-home">
        <Helmet>
          <title>Forage | A Cannabis Discovery Tool</title>
        </Helmet>
        <MoodSelector
          orientation={{ isLandscape: isLandscape, isPortrait: isPortrait }}
          onSelectorOpen={onSelectorOpen}
        />
        {
          <>
            {isKiosk ? (
              <KioskOptions />
            ) : (
              !moodSelectorOpen && (
                <>
                  <ActivitySelector />
                  <ProductReviews />
                  {!customer && <CreateProfile />}
                  {activeDispensary ? (
                    <FeaturedProducts />
                  ) : (
                    <div className="c-home__spacer"></div>
                  )}
                  <DispensaryNearYou />
                </>
              )
            )}
          </>
        }
      </section>
    </>
  );
}

export default withOrientationChange(Home);
