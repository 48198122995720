import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Sleepy: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--sleepy" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <path className="i-feeling--sleepy--st0" stroke={color} fill={color} d="M193.4,207.3c0-28.3,15.4-53,38.2-66.2c-11.2-6.5-24.3-10.2-38.2-10.2c-42.2,0-76.5,34.2-76.5,76.5
          c0,42.2,34.2,76.5,76.5,76.5c13.9,0,27-3.7,38.2-10.2C208.8,260.3,193.4,235.6,193.4,207.3z"/>
        <polyline className="i-feeling--sleepy--st1" stroke={color} points="280.2,169.8 297.7,169.8 279,206.2 297.7,206.2 	"/>
        <polyline className="i-feeling--sleepy--st1" stroke={color} points="243.6,188.4 261.1,188.4 242.4,224.8 261.1,224.8 	"/>
      </g>
    </svg>
  )
}

export default Sleepy;