import React, { useEffect, useRef, useState }         from 'react';
import classNames                                     from 'classnames';
import { connect, useSelector }                       from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps }  from 'redux-form';
import { RootState } from 'store';
import { 
  Aroused, 
  Creative,
  Energetic,
  Euphoric,
  Focused,
  Giggly,
  Happy,
  Hungry,
  Relaxed,
  Sleepy,
  Talkative,
  Tingly,
  Uplifted
} from 'components/svgs/Feelings';
import Accordion                    from 'components/Accordion';
import Button                       from 'components/Button';
import { Checkbox, CheckboxCards }  from 'components/Forms/Fields';
import { OptionsProps }             from 'components/Forms/Fields/types';
function FilterForm({ 
  handleSubmit 
}:InjectedFormProps) {
  const filters:any = useSelector((state:RootState) => state.form.inventoryFilter);
  
  const formRef = useRef<HTMLDivElement>(null);
  
  const [displayBorder, setDisplayBorder] = useState(false);
  
  useEffect(() => {
    if(formRef.current !== null){
      formRef.current.addEventListener("scroll", () => {
        if(formRef.current !== null && formRef.current.scrollTop > 0){
          setDisplayBorder(true);
        } else if(formRef.current !== null && formRef.current.scrollTop === 0){
          setDisplayBorder(false);
        }
      })
    }
  }, [formRef])

  
  const flagsMap:any = {
    // 'new'           : 'New',
    'low_inventory' : 'Low Inventory',
    'on_sale'      : 'On Sale'
  };

  const flagOptions:Array<OptionsProps> = Object.keys(flagsMap).map((key) => {
    return {name: key, label: flagsMap[key]}
  })

  const formatsMap:any = {
    'vapes'     : 'Vape',
    'flower'    : 'Flower',
    'edibles'   : 'Edibles',
    'beverages' : 'Drinks',
    'oil'       : 'Dabs',
    'pills'     : 'Pills',
    'topicals'  : 'Topicals',
    'spray'     : 'Sprays',
    'patch'     : 'Patches',
  };

  const formatOptions:Array<OptionsProps> = Object.keys(formatsMap).map((key) => {
    return {name: key, label: formatsMap[key]}
  });

  const pricesMap:any = {
    'LOW'   : '$',
    'MEDIUM'  : '$$',
    'HIGH' : '$$$',
  }

  const priceOptions:Array<OptionsProps> = Object.keys(pricesMap).map((key) => {
    return {name: key, label: pricesMap[key]}
  })

  const feelingsMap:any = {
    'euphoric'  : { label: 'Euphoric', Icon: Euphoric },
    'happy'     : { label: 'Happy', Icon: Happy },
    'energetic' : { label: 'Energetic', Icon: Energetic },
    'creative'  : { label: 'Creative', Icon: Creative },
    'talkative' : { label: 'Talkative', Icon: Talkative },
    'tingly'    : { label: 'Tingly', Icon: Tingly },
    'aroused'   : { label: 'Aroused', Icon: Aroused },
    'giggly'    : { label: 'Giggly', Icon: Giggly },
    'hungry'    : { label: 'Hungry', Icon: Hungry },
    'uplifted'  : { label: 'Uplifted', Icon: Uplifted },
    'sleepy'    : { label: 'Sleepy', Icon: Sleepy },
    'focused'  : { label: 'Focused', Icon: Focused },
    'relaxed'   : { label: 'Relaxed', Icon: Relaxed },
  }

  const feelingsOptions:Array<OptionsProps> = Object.keys(feelingsMap).map((key) => {
    return {name: key, label: feelingsMap[key].label, Icon: feelingsMap[key].Icon}
  });

  function getSelected(type:string) {
    if(!filters || !filters.values){
      return '';
    }
    const values:any = filters.values;
    let selectedString:string = '';
    switch(type){
      case 'prices':
        const { prices } = values;
        if(prices){
          const pricesValues:any = prices.map((key:string) => {
            return pricesMap[key];
          });
          selectedString = pricesValues.join(', ');
        }
        break;
      case 'formats':
        const { formats } = values;
        if(formats){
          const formatsToMap = formats.slice(0, 5);
          const remainingFormats = formats.length - 5;
          const formatValues:any = formatsToMap.map((key:string) => {
            return formatsMap[key];
          });
          selectedString = formatValues.join(', ');
          if(remainingFormats > 0){
            selectedString += ' + ' + remainingFormats + ' others';
          }
        }
        break;
      case 'feelings':
        const { feelings } = values;
        if(feelings){
          const feelingsToMap = feelings.slice(0, 5);
          const remainingFeelings = feelings.length - 5;
          const feelingsValues:any = feelingsToMap.map((key:string) => {
            return feelingsMap[key].label;
          });
          selectedString = feelingsValues.join(', ');
          if(remainingFeelings > 0 ){
            selectedString += ' + ' + remainingFeelings + ' others';
          }
        }
        break;
    }
    return selectedString;

  }

  return (
    <div className={classNames("c-filter-form", { 'c-filter-form--scrolled': displayBorder})} ref={formRef}>
      <Form onSubmit={handleSubmit}>
        <Field 
            component={Checkbox} 
            name="flags" 
            options={flagOptions}
          /> 
          <Accordion label="Format" selectedItems={getSelected('formats')}>
            <Field 
              component={Checkbox} 
              name="formats" 
              options={formatOptions}
              className="i-checkbox-group--two-wide"
            /> 
          </Accordion>
          <Accordion label="Price Range" selectedItems={getSelected('prices')}>
            <Field 
              component={CheckboxCards} 
              name="prices" 
              options={priceOptions}
            /> 
          </Accordion>
          <Accordion label="Feelings" selectedItems={getSelected('feelings')}>
            <Field 
              component={CheckboxCards} 
              name="feelings" 
              options={feelingsOptions}
            /> 
          </Accordion>
          <div className="c-filter-form__submit-wrapper">
            <Button variant="standard" type="submit">
              Show results
            </Button>
          </div>
      </Form>
    </div>
  )
}

export default connect(
  (state: RootState) => ({
    initialValues: state.inventory.filter
  })
)(reduxForm({
  form: 'inventoryFilter',
  
})(FilterForm));