import { 
  SET_INVENTORY_FILTER, 
  SET_INVENTORY_PAGE,
  SET_INVENTORY_SORT, 
  FETCH_INVENTORY, 
  FETCH_PRODUCT, 
  SET_SEARCH,
  SET_SLUG,
  InventoryActionTypes, 
  InventoryFilterProps,
  FETCH_DISCOUNTS,
  SET_INVENTORY_CHANGING,
  RESET_INVENTORY,
  RESET_NO_RESULTS
} from './types';

export function SetInventorySort(sort:string): InventoryActionTypes {
  return {
    type: SET_INVENTORY_SORT,
    sort: sort
  }
}
export function SetInventoryFilter(filter:InventoryFilterProps): InventoryActionTypes {
  return {
    type:   SET_INVENTORY_FILTER,
    filter: filter
  }
}

export function FetchInventory(slug:string): InventoryActionTypes {
  return {
    type: FETCH_INVENTORY,
    slug: slug
  }
}

export function FetchProduct(slug:string, id:number|string): InventoryActionTypes {
  return {
    type: FETCH_PRODUCT,
    slug: slug,
    id:   id
  }
}

export function SetSearch(searchQuery:string): InventoryActionTypes {
  return {
    type: SET_SEARCH,
    searchQuery
  }
}

export function SetSlug(slug:string): InventoryActionTypes {
  return {
    type: SET_SLUG,
    slug
  }
}

export function SetInventoryPage(page:number): InventoryActionTypes {
  return {
    type: SET_INVENTORY_PAGE,
    page
  }
}

export function FetchDiscounts(): InventoryActionTypes {
  return {
    type: FETCH_DISCOUNTS
  }
}

export function SetInventoryChanging(inventoryChanging:boolean): InventoryActionTypes{
  return {
    type: SET_INVENTORY_CHANGING,
    inventoryChanging
  }
}

export function ResetInventory(): InventoryActionTypes {
  return {
    type: RESET_INVENTORY
  }
}

export function ResetNoResults(): InventoryActionTypes {
  return {
    type: RESET_NO_RESULTS
  }
}