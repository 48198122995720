import {
  DispensariesType,
  HoursOfOperationType,
  HoursOfOperationKeyType,
} from "constants/dispensary";
import moment from "moment-timezone";

enum WeekDays {
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
}

export function getAddress(location: DispensariesType) {
  const {
    address: { streetAddressLine1, streetAddressLine2, city, state, zipCode },
  } = location;
  let addressString = streetAddressLine1;
  if (streetAddressLine2) {
    addressString += ", " + streetAddressLine2;
  }
  addressString += ", " + city + ", " + state + " " + zipCode;
  return addressString;
}
export function parseGeoResults(addresses: any, zipOnly: boolean) {
  const address = addresses?.length ? addresses[0] : {};
  let city, state, zip, country;
  address.address_components.forEach((component: any) => {
    if (component.types.indexOf("locality") >= 0) {
      city = component.short_name;
    } else if (component.types.indexOf("sublocality_level_1") >= 0) {
      city = component.long_name;
    } else if (component.types.indexOf("administrative_area_level_1") >= 0) {
      state = component.short_name;
    } else if (component.types.indexOf("postal_code") >= 0) {
      zip = component.long_name;
    } else if (component.types.indexOf("country") >= 0) {
      country = component.short_name === "US" ? "USA" : component.short_name;
    }
  });
  if (zipOnly) {
    return "" + zip;
  } else {
    return city + ", " + state + " " + zip + ", " + country;
  }
}

export function getLocation(callback: any, errorCallback: any) {
  if (!window.navigator) {
    errorCallback();
    return;
  }
  const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
  window.navigator.geolocation.getCurrentPosition(
    function (position: any) {
      const { latitude, longitude } = position.coords;
      geocoder.geocode(
        { location: { lat: latitude, lng: longitude } },
        (addresses) => {
          const parsedGeo: string = parseGeoResults(addresses, false);
          const parsedZip: string = parseGeoResults(addresses, true);
          callback(addresses[0].place_id, parsedGeo, parsedZip);
        }
      );
    },
    function (error) {
      if (navigator && navigator.permissions) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((response) => {
            errorCallback(response);
          });
      }
    }
  );
}

export function checkDistance(
  userPlaceId: string,
  locations: any,
  callback: any
) {
  if (!window.google) {
    return;
  }
  const limit = 25;
  const callsToMake = Math.ceil(locations.length / limit);
  let callCount = 0;
  let locationsMap: any = [];
  let allDistances: any = [];
  for (var i = 0; i < callsToMake; i++) {
    const start = i * limit;
    const slicedLocations = locations.slice(start, start + limit);
    const origins = slicedLocations.map(() => {
      return { placeId: userPlaceId };
    });
    const destinations = slicedLocations.map((location: any) => {
      return { placeId: location.placeID };
    });
    locationsMap.push({
      locations: slicedLocations,
      origins: origins,
      destinations: destinations,
    });
  }
  function getDistances(locationSlice: any, callIndex: number, callback: any) {
    const distanceService: google.maps.DistanceMatrixService = new google.maps.DistanceMatrixService();
    distanceService.getDistanceMatrix(
      {
        origins: [{ placeId: userPlaceId }],
        destinations: locationSlice.destinations,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      },
      (response: google.maps.DistanceMatrixResponse, status: any) => {
        if (response && response.rows && response.rows.length) {
          let locationsWithDistances = locationSlice.locations;
          response.rows[0].elements.forEach((element, index) => {
            locationsWithDistances[index].distance = element.distance;
          });
          callback(locationsWithDistances);
        } else {
          callback(false);
        }
      }
    );
  }

  function callMatrix(callCount: number) {
    if (callCount < locationsMap.length) {
      getDistances(locationsMap[callCount], callCount, (response: any) => {
        if (response) {
          allDistances = [...allDistances, ...response];
        }
        callCount++;
        callMatrix(callCount);
      });
    } else {
      callback(allDistances);
    }
  }
  if (locationsMap.length > 0) {
    callMatrix(callCount);
  }
}

export function fetchPlaceById(placeId: string) {
  const findPlaceService: google.maps.places.PlacesService = new google.maps.places.PlacesService(
    document.createElement("div")
  );
  type detailsType = {
    placeId: string;
    sessionToken: any;
  };
  if (!sessionToken) {
    sessionToken = new google.maps.places.AutocompleteSessionToken();
  }
  const request: detailsType = {
    placeId: placeId,
    sessionToken: sessionToken,
  };
  return new Promise(function (resolve, reject) {
    findPlaceService.getDetails(request, (response: any, error) => {
      if (error !== "OK") {
        reject(error);
      } else {
        let profileAddress: any = {
          street: "",
          streetCont: "",
          city: "",
          stateName: "",
          zip: "",
        };
        response.address_components.forEach((component: any) => {
          if (component.types.indexOf("street_number") >= 0) {
            profileAddress.street = component.short_name + " ";
          } else if (component.types.indexOf("route") >= 0) {
            profileAddress.street += component.short_name;
          } else if (component.types.indexOf("locality") >= 0) {
            profileAddress.city = component.short_name;
          } else if (component.types.indexOf("sublocality_level_1") >= 0) {
            profileAddress.city = component.long_name;
          } else if (
            component.types.indexOf("administrative_area_level_1") >= 0
          ) {
            profileAddress.stateName = component.short_name;
          } else if (component.types.indexOf("postal_code") >= 0) {
            profileAddress.zip = component.long_name;
          }
        });
        resolve(profileAddress);
      }
    });
  });
}

let sessionToken: any;
export function fetchPlaces(searchValue: string, callback: any) {
  const autoCompleteService = new google.maps.places.AutocompleteService();
  if (!sessionToken) {
    sessionToken = new google.maps.places.AutocompleteSessionToken();
  }
  autoCompleteService.getPlacePredictions(
    {
      input: searchValue,
      sessionToken: sessionToken,
      // types: [('regions') ]
    },
    callback
  );
}

export function getClosingTime(dispensaryData: any) {
  const today = moment().format("dddd").toLowerCase();
  const hours = dispensaryData.hoursOfOperation[today];
  let closing = hours.split(" - ")[1];
  closing = moment(closing, "HH:mm").format("hh:mma");
  return closing;
}

export function checkIfDispensaryIsOpen(
  hoursOfOperation: HoursOfOperationType,
  timezone: string,
  no: number
): any {
  if (!timezone) {
    return false;
  }

  let time = moment().tz(timezone);
  if (no) {
    time = moment(time).add(no, "days");
  }
  const openHours = hoursOfOperation[
    WeekDays[time.day()] as HoursOfOperationKeyType
  ]?.split(" - ");
  let openTime: any = 0,
    closeTime: any = 0;
  if (openHours) {
    const openHour = openHours[0].split(":");
    const closeHour = openHours[1].split(":");
    openTime = moment(time).set({
      hour: Number(openHour[0]) ===0 ? 24 : Number(openHour[0]),
      minute: Number(openHour[1]),
      second: Number(openHour[2]),
    });
    closeTime = moment(time).set({
      hour: Number(closeHour[0]) ===0 ? 24 : Number(closeHour[0]),
      minute: Number(closeHour[1]),
      second: Number(closeHour[2]),
    });

    return {
      isOpen: time.isBetween(openTime, closeTime),
      day: time.day(),
      closeTime,
      openTime,
      time,
    };
  }

  return { isOpen: false, day: time.day(), closeTime, openTime, time };
}

type isOpenType = boolean | null;
export function getOpenDetails(isOpen: isOpenType, dispensaryData: any) {
  let openText: string = "";
  if (dispensaryData.isOpen !== null) {
    const store = checkIfDispensaryIsOpen(
      dispensaryData.hoursOfOperation,
      dispensaryData.timezone,
      0
    );
    if (isOpen) {
      if (store.isOpen === true) {
        openText = "Closes at " + moment(store.closeTime).format("hh:mm a");
      }
    } else {
      if (store.isOpen === false) {
        if (moment(store.time).isBefore(store.openTime)) {
          openText =
            "Opens today at " + moment(store.openTime).format("hh:mm a");
        } else if (moment(store.time).isAfter(store.closeTime)) {
          openText = "";
          const days: number[] = [1, 2, 3, 4, 5, 6, 7];
          days.forEach((day) => {
            if (!openText.length && day === 1) {
              const onestore = checkIfDispensaryIsOpen(
                dispensaryData.hoursOfOperation,
                dispensaryData.timezone,
                day
              );
              const getDay = moment(onestore.time).format("dddd").toLowerCase();
              if (onestore.isOpen || dispensaryData.hoursOfOperation[getDay]) {
                openText =
                  "Open tomorrow at " +
                  moment(onestore.openTime).format("hh:mm a");
              }
            } else if (!openText.length && day > 1) {
              const onestore = checkIfDispensaryIsOpen(
                dispensaryData.hoursOfOperation,
                dispensaryData.timezone,
                day
              );
              const getDay = moment(onestore.time).format("dddd").toLowerCase();
              if (onestore.isOpen || dispensaryData.hoursOfOperation[getDay]) {
                openText =
                  "Open " +
                  moment(onestore.openTime).format("dddd") +
                  " at " +
                  moment(onestore.openTime).format("hh:mm a");
              }
            }
          });
        }
      }
    }
    return { isOpen: isOpen, openText: openText };
  }
}
