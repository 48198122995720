import React, { useEffect, useState } from 'react';
import FeelingsIcon                   from 'components/FeelingsIcon';
import './PDPFeelings.scss';

interface PDPFeelingsProps {
  feelings: any
}

function PDPFeelings({
  feelings
}: PDPFeelingsProps) {

  const [feelingsObj, setFeelingsObj] = useState<any>([]);
  
  useEffect(() => {
    const fobj = feelings.slice(0,3).map((feeling:any) => {
      return {
        id: feeling.feeling,
        label: feeling.feeling
      }
    });
    setFeelingsObj(fobj);
  }, [feelings]);

  return(
    <section className="c-pdp-feelings">
      <div className="slug">Feelings</div>
      <div className="c-pdp-feelings__grid">
        { 
          
          feelingsObj && feelingsObj.map((feeling:any) => (
            <div key={feeling.label} className="c-pdp-feelings__grid__feeling">
              <div className="c-pdp-feelings__grid__feeling__icon">
                <FeelingsIcon feelingId={feeling.id} />
              </div>
              <span className="c-pdp-feelings__grid__feeling__label">
                {feeling.label}
              </span>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default PDPFeelings;