import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { DispensariesType } from 'constants/dispensary'
import { getOpenDetails, getAddress } from 'utils/maps'
import { RootState } from 'store'
import { SetDispensaryIsOpen } from 'store/locations/actions'
import LocationIcon from 'components/svgs/Location'
import RightCaretIcon from 'components/svgs/RightCaret'
import './DispensaryLocation.scss'

interface DispensaryLocationType {
  location: DispensariesType
  dispensaryFilter: string
  viewOrder?: boolean
}

function DispensaryLocation({
  location,
  dispensaryFilter,
  viewOrder = false,
}: DispensaryLocationType) {
  const dispatch = useDispatch()
  const mapsReady = useSelector((state: RootState) => state.system.mapsReady)
  const userLocationID = useSelector((state: RootState) => state.user.userLocationID)

  const [locationDistance, setLocationDistance] = useState(
    location?.distance ? location.distance : ''
  )
  const [openInformation, setOpenInformation] = useState<any>({ isOpen: null })
  const [imageLoaded, setImageLoaded] = useState(false)
  const [displayLocation, setDisplayLocation] = useState(
    dispensaryFilter === 'med' && location.isMedical
      ? true
      : dispensaryFilter === 'rec' && location.isRecreational
      ? true
      : dispensaryFilter !== 'rec' && dispensaryFilter !== 'med'
      ? true
      : false
  )

  const isOpen = location?.isOpen || false
  const staticImageURL =
    (process?.env?.REACT_APP_STATIC_URL || 'https://static.forage.io') +
    '/locations/location-default.jpg'
  const [locationImageSrc, setLocationImageSrc] = useState(location?.image || '')

  useEffect(() => {
    switch (dispensaryFilter) {
      case 'med':
        setDisplayLocation(location.isMedical)
        break
      case 'rec':
        setDisplayLocation(location.isRecreational)
        break
      default:
        setDisplayLocation(true)
        break
    }
  }, [dispensaryFilter, location])

  useEffect(() => {
    if (!location || !location.distance) {
      return
    }
    setLocationDistance(location.distance)
  }, [userLocationID, location])

  useEffect(() => {
    if (location && mapsReady) {
      dispatch(SetDispensaryIsOpen(location.slug, location.hoursOfOperation, location.timezone))
    }
  }, [location, mapsReady, dispatch])

  useEffect(() => {
    if (location && isOpen !== null) {
      const openDetails = getOpenDetails(isOpen, location)
      setOpenInformation(openDetails)
    }
  }, [location, isOpen])

  function showImage() {
    setImageLoaded(true)
  }

  if (!location) return null

  return (
    <CSSTransition in={displayLocation} classNames={'c-dispensary-location-'} timeout={1000} appear>
      <Link
        className={classNames('c-dispensary-location', {
          'c-dispensary-location--view-order': viewOrder,
        })}
        to={'/dispensary/' + location?.slug}
      >
        <div className="c-dispensary-location__inner">
          {viewOrder ? (
            <LocationIcon />
          ) : (
            location?.image && (
              <figure className="c-dispensary-location__figure">
                <img
                  src={locationImageSrc || location?.image}
                  alt={location?.name + ' Image'}
                  onLoad={showImage}
                  onError={() => setLocationImageSrc(staticImageURL)}
                  style={{ opacity: imageLoaded ? 1 : 0 }}
                />
              </figure>
            )
          )}
          <div className="c-dispensary-location__details">
            <h3>{location?.name}</h3>
            <div className="c-dispensary-location__details__wrapper">
              <div className="c-dispensary-location__details__left">
                <div className="c-dispensary-location__distance-address body--xsmall">
                  <CSSTransition
                    in={locationDistance !== '' && locationDistance < 500}
                    className="c-dispensary-location__distance"
                    timeout={1000}
                    appear
                  >
                    <span className="c-dispensary-location__distance">{locationDistance}mi</span>
                  </CSSTransition>
                  <span className="c-dispensary-location__address">{getAddress(location)}</span>
                </div>
              </div>
              {!viewOrder && (
                <div className="c-dispensary-location__hours body--xsmall">
                  {openInformation.isOpen !== null && (
                    <>
                      <span
                        className={classNames('c-dispensary-location__status', {
                          'c-dispensary-location__status--closed': !openInformation.isOpen,
                          'c-dispensary-location__status--open': openInformation.isOpen,
                        })}
                      >
                        {openInformation.isOpen ? 'Open' : 'Closed'}
                      </span>
                      &nbsp;
                      <span className="c-dispensary-location__time">
                        {openInformation.openText}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="c-dispensary-location__caret">
            <RightCaretIcon />
          </div>
        </div>
      </Link>
    </CSSTransition>
  )
}

export default DispensaryLocation
