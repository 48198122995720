import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import {
  stateLocationsType,
  activeDispsensaryType,
  dispensaryStateType,
  DispensariesProps,
} from "./types";
import { DispensariesType } from "constants/dispensary";
import { checkDistance } from "utils/maps";
import { scrollTo } from "utils";
import { RootState } from "store";
import {
  SetLocationDistances,
  SetLoadingDistances,
} from "store/locations/actions";
import Button from "components/Button";
import DispensaryLocation from "./DispensaryLocation";
import DispensaryFilters from "./DispensaryFilters";
import "./Dispensaries.scss";

const STORED_SUGGESTED_DISPENSARIES = "forage_suggested_dispensaries";

let allLocationsTimeout: any;
function Dispensaries({ user }: DispensariesProps) {
  const dispatch = useDispatch();
  const pickupLocation = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const dispensaryFilter = useSelector(
    (state: RootState) => state.locations.filter
  );
  const hasDistances = useSelector(
    (state: RootState) => state.locations.hasDistances
  );
  const originID = useSelector((state: RootState) => state.locations.originID);
  const loadingDistances = useSelector(
    (state: RootState) => state.locations.loadingDistances
  );
  const allLocations = useSelector(
    (state: RootState) => state.locations.dispensaries
  );

  const suggestedDispensaries = JSON.parse(
    localStorage.getItem(STORED_SUGGESTED_DISPENSARIES) || JSON.stringify([])
  );

  const { userLocationID } = user;

  const [showSuggested, setShowSuggested] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  // const [suggestedDispensariesCount, setSuggestedDispensariesCount] = useState(
  //   suggestedDispensaries.length || 0
  // );
  const [currentLocationID, setCurrentLocationID] = useState(userLocationID);
  const [gettingDistance, setGettingDistance] = useState("");
  const [showAllLocations, setShowAllLocations] = useState(false);
  const [stateLocations, setStateLocations] = useState<stateLocationsType>(
    null
  );
  const [
    activeDispensary,
    setActiveDispensary,
  ] = useState<activeDispsensaryType>(null);

  // useEffect(() => {
  // let count = suggestedDispensaries.length || 0;

  // if (dispensaryFilter === "rec") {
  //   count = suggestedDispensaries.filter(
  //     (loc: any) => allLocations && allLocations[loc]?.isRecreational
  //   ).length;
  // }

  // if (dispensaryFilter === "med") {
  //   count = suggestedDispensaries.filter(
  //     (loc: any) => allLocations && allLocations[loc]?.isMedical
  //   ).length;
  // }

  // setSuggestedDispensariesCount(count);
  // eslint-disable-next-line
  // }, [dispensaryFilter, suggestedDispensaries]);

  // If pickup location specified and dispensary locations loaded, show active dispensary
  useEffect(() => {
    if (!allLocations || !pickupLocation) {
      return;
    }
    setActiveDispensary(allLocations[pickupLocation]);
  }, [pickupLocation, allLocations]);

  useEffect(() => {
    if (suggestions?.length > 0 && suggestedDispensaries.length < 1) {
      const suggestionsSlugs = suggestions.map(
        (suggestion: any) => suggestion.slug
      );
      localStorage.setItem(
        STORED_SUGGESTED_DISPENSARIES,
        JSON.stringify(suggestionsSlugs)
      );
    }

    if (suggestedDispensaries.length > 0 && !showSuggested) {
      setShowSuggested(true);
    }
  }, [suggestions, suggestedDispensaries, showSuggested]);

  useEffect(() => {
    if (hasDistances && allLocations) {
      const locationsArray: any = [];
      Object.keys(allLocations).forEach((location: any) => {
        locationsArray.push(allLocations[location]);
      });
      const sortedLocations: any = locationsArray.sort((a: any, b: any) => {
        return a.distanceMeters > b.distanceMeters ? 1 : -1;
      });
      if (!sortedLocations) {
        return;
      }
      const suggestedLocations: any = {};
      sortedLocations.slice(0, 3).forEach((location: any) => {
        suggestedLocations[location.slug] = location;
      });
      if (sortedLocations.length) {
        setSuggestions(sortedLocations.slice(0, 3));
        setShowSuggested(true);
      } else {
        setSuggestions([]);
        setShowSuggested(false);
      }
    }
  }, [hasDistances, dispensaryFilter, userLocationID, allLocations]);

  useEffect(() => {
    if (currentLocationID !== userLocationID) {
      setCurrentLocationID(userLocationID);
      if (showAllLocations) {
        let timeout = window.scrollY > 0 ? 500 : 0;
        scrollTo(document.documentElement, 0, 500);
        setTimeout(() => setShowAllLocations(false), timeout);
      }
    }
  }, [userLocationID, showAllLocations, currentLocationID]);

  // If location selected, display suggested
  // Will need to be replaced with async call to API
  useEffect(() => {
    if (
      userLocationID &&
      allLocations &&
      gettingDistance !== userLocationID &&
      originID !== userLocationID &&
      !loadingDistances &&
      !hasDistances
    ) {
      setGettingDistance(userLocationID);
      dispatch(SetLoadingDistances(userLocationID));
      const locations = Object.keys(allLocations).map((key: any) => {
        return {
          slug: allLocations[key]?.slug || "",
          placeID: allLocations[key]?.googlePlace?.googlePlaceId,
        };
      });
      checkDistance(userLocationID, locations, (distances: any) => {
        dispatch(SetLocationDistances(distances));
      });
    }
  }, [
    userLocationID,
    allLocations,
    gettingDistance,
    originID,
    loadingDistances,
    hasDistances,
    dispatch,
  ]);

  useEffect(() => {
    return function __cleanup() {
      clearTimeout(allLocationsTimeout);
    };
  });

  // Separate dispensaries into state arrays for display.
  // May be unnecessary once final api for dispensaries is in place
  useEffect(() => {
    let sl: any = {};
    if (!allLocations || null !== stateLocations) {
      return;
    }
    Object.keys(allLocations).forEach((locKey: string) => {
      const slug = allLocations[locKey].slug;
      const location: any =
        typeof allLocations[slug] !== "undefined" ? allLocations[slug] : null;
      let stateObject: dispensaryStateType = sl[location.address.stateFull] || {
        hasMed: false,
        hasRec: false,
        locations: [],
      };
      stateObject.locations.push({ ...location });
      if (location.isMedical) {
        stateObject.hasMed = true;
      }
      if (location.isRecreational) {
        stateObject.hasRec = true;
      }
      sl[location.address.stateFull] = stateObject;
    });
    setStateLocations({ ...sl });
  }, [allLocations, stateLocations]);

  // Show/Hide All Locations when Suggested locations displayed
  function toggleAllLocations() {
    let timeout = window.scrollY > 0 ? 500 : 0;
    if (showAllLocations) {
      scrollTo(document.documentElement, 0, 500);
    }
    allLocationsTimeout = setTimeout(
      () => setShowAllLocations(!showAllLocations),
      showAllLocations ? timeout : 0
    );
  }

  const renderSuggestedDispensariesCount = () => {
    if (suggestions?.length > 0) {
      return suggestions.length;
    } else if (suggestedDispensaries?.length > 0) {
      const newArr = suggestedDispensaries?.filter((item: any) => {
        const location = allLocations![item];
        return !!location;
      });

      return newArr?.length || 0;
    }
  };

  const renderSuggestedDispensaries = () => {
    if (suggestions?.length > 0) {
      return suggestions.map((location: DispensariesType) => (
        <DispensaryLocation
          key={`${location?.googlePlace?.googlePlaceId || ""}-${
            location?.slug || ""
          }`}
          dispensaryFilter={dispensaryFilter}
          location={location}
        />
      ));
    } else if (suggestedDispensaries?.length > 0) {
      return suggestedDispensaries.map(
        (dispensarySlug: string, index: number) => {
          const location = allLocations![dispensarySlug];
          if (!location) return null;
          return (
            <DispensaryLocation
              key={`${location?.googlePlace?.googlePlaceId || ""}-${
                location?.slug || ""
              }`}
              dispensaryFilter={dispensaryFilter}
              location={location}
            />
          );
        }
      );
    }

    return null;
  };

  return (
    <section className="c-dispensaries">
      <DispensaryFilters />
      {activeDispensary && (
        <div className="c-dispensaries__group c-dispensaries__group--active">
          <div className="c-dispensaries__group__inner l-maxwidth ">
            <div className="slug">Active Dispensary</div>
            <div className="c-dispensaries__group__list">
              <DispensaryLocation
                dispensaryFilter="all"
                location={activeDispensary}
              />
            </div>
          </div>
        </div>
      )}
      <CSSTransition
        in={showSuggested}
        classNames="c-dispensaries__suggested__wrapper-"
        timeout={2000}
        appear
      >
        <div className="c-dispensaries__suggested__wrapper">
          <div className="c-dispensaries__group__inner l-maxwidth slug">
            Suggested Dispensaries
            <DispensaryFilters />
          </div>
          {showSuggested &&
            (suggestions.length > 0 ||
              (suggestedDispensaries.length > 0 && allLocations)) && (
              <section className="c-dispensaries__group">
                <div className="c-dispensaries__group__inner l-maxwidth">
                  <h3 className="c-dispensaries__group-label c-dispensaries__group-label--search">
                    {/* {suggestedDispensariesCount} Results */}
                    {renderSuggestedDispensariesCount()} Results
                  </h3>
                  <div className="c-dispensaries__group__list">
                    {renderSuggestedDispensaries()}
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={toggleAllLocations}
                    >
                      <span className="cta--primary">
                        {showAllLocations
                          ? "View Suggested Only"
                          : "View All Locations"}
                      </span>
                    </Button>
                  </div>
                </div>
              </section>
            )}
        </div>
      </CSSTransition>
      {(!showSuggested || showAllLocations) &&
        stateLocations &&
        Object.keys(stateLocations)
          .sort()
          .map((stateKey: string) => (
            <section className="c-dispensaries__group" key={stateKey}>
              <div className="c-dispensaries__group__inner l-maxwidth">
                <h3 className="c-dispensaries__group-label">{stateKey}</h3>
                <div className="c-dispensaries__group__list">
                  <CSSTransition
                    in={
                      dispensaryFilter === "rec" &&
                      !stateLocations[stateKey].hasRec
                    }
                    className="c-dispensaries__no-locations"
                    timeout={2000}
                    appear
                  >
                    <p>No recreational locations found in {stateKey}</p>
                  </CSSTransition>
                  <CSSTransition
                    in={
                      dispensaryFilter === "med" &&
                      !stateLocations[stateKey].hasMed
                    }
                    className="c-dispensaries__no-locations"
                    timeout={1000}
                    appear
                  >
                    <p>No medical locations found in {stateKey}</p>
                  </CSSTransition>
                  {stateLocations[stateKey].locations.map(
                    (location: DispensariesType) => (
                      <DispensaryLocation
                        key={`${location?.googlePlace?.googlePlaceId || ""}-${
                          location?.slug || ""
                        }`}
                        dispensaryFilter={dispensaryFilter}
                        location={location}
                      />
                    )
                  )}
                </div>
              </div>
            </section>
          ))}
    </section>
  );
}

export default Dispensaries;
