import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ReviewProduct } from "store/customer/actions";
import Button from "components/Button";
import FeelingSlider from "components/FeelingSlider";
import ProductIcon from "components/ProductIcon";
import { feelingMap } from "constants/feelings";
import {
  GradientCardLayout1,
  GradientCardLayout2,
  GradientCardLayout3,
  GradientCardLayout4,
  GradientCardLayout5,
} from "components/Gradients/Cards";
interface ProductReviews {
  product: any;
  onSubmit: any;
}

function ReviewCard({ product, onSubmit }: ProductReviews) {
  const { productId } = product;
  const dispatch = useDispatch();
  const [reviewed, setReviewed] = useState(false);
  const [feelingValues, setFeelingValues] = useState<any>(null);
  const reviewingId = useSelector(
    (state: RootState) => state.customer.reviewingProduct
  );
  const reviewedIds = useSelector(
    (state: RootState) => state.customer.reviewedProducts
  );

  const [reviewing, setReviewing] = useState(false);
  product.orderDate = "08/27";

  const [gradientToUse, setGradientToUse] = useState<any>(null);
  const [gradientFeelings, setGradientFeelings] = useState<any>(null);

  const gradientIndex = Math.floor(Math.random() * (5 - 1 + 1)) + 1;

  useEffect(() => {
    if (gradientToUse === null) {
      setGradientToUse(gradientIndex);
    }
  }, [gradientIndex, gradientToUse]);

  useEffect(() => {
    const gradFeelings = product.feelingData
      .slice(0, 3)
      .map((feeling: any) => feeling.feeling.toLowerCase());
    setGradientFeelings(gradFeelings);
  }, [product]);

  useEffect(() => {
    if (reviewingId === productId) {
      setReviewing(true);
    }
  }, [reviewingId, productId]);

  useEffect(() => {
    if (reviewedIds.indexOf(productId) >= 0 && !reviewed) {
      setReviewed(true);
      onSubmit(productId);
    }
  }, [reviewedIds, productId, reviewed, onSubmit]);

  useEffect(() => {
    if (null === feelingValues) {
      const fv: any = {};
      product.feelingData.forEach((feeling: any) => {
        return (fv[feeling.feeling.toLowerCase()] = 0);
      });
      setFeelingValues(fv);
    }
  }, [product, feelingValues]);

  function sliderChange(feeling: any, value: any) {
    const updatedValues = { ...feelingValues };
    updatedValues[feeling.id] = value;
    setFeelingValues({ ...updatedValues });
  }

  function submitReview(productId: number, dispensarySlug: string) {
    const reviewFeelings = Object.keys(feelingValues).map(
      (feelingKey: string) => {
        return {
          feeling: feelingKey,
          value: Math.max(0, Math.min(100, feelingValues[feelingKey] * 100)),
        };
      }
    );
    dispatch(
      ReviewProduct({
        productId: productId,
        feelings: reviewFeelings,
        dispensarySlug: dispensarySlug,
      })
    );
  }

  function getOrderDate(date: any) {
    const formattedDate = moment(date).format("M/D/YYYY");
    return formattedDate;
  }

  return (
    <article
      className={classNames("c-review-card", {
        "c-review-card--reviewed": reviewed,
        "c-review-card--reviewing": reviewing,
      })}
    >
      <div className="c-review-card__topper">
        <figure className="c-review-card__topper__figure">
          {gradientToUse === 1 ? (
            <GradientCardLayout1 feelings={gradientFeelings} animate={false} />
          ) : gradientToUse === 2 ? (
            <GradientCardLayout2 feelings={gradientFeelings} animate={false} />
          ) : gradientToUse === 3 ? (
            <GradientCardLayout3 feelings={gradientFeelings} animate={false} />
          ) : gradientToUse === 4 ? (
            <GradientCardLayout4 feelings={gradientFeelings} animate={false} />
          ) : (
            <GradientCardLayout5 feelings={gradientFeelings} animate={false} />
          )}
        </figure>
        <div className="c-review-card__topper__details">
          <div className="c-review-card__topper__details__row">
            <div className="c-review-card__topper__details__row__icon">
              <ProductIcon productType={product.category} />
            </div>
            <div className="c-review-card__topper__details__row__title">
              Ordered {getOrderDate(product.mostRecentDateOfOrder)}
            </div>
          </div>
          <div className="c-review-card__topper__details__name-vendor">
            <h3>{product.productName}</h3>
            <h3 className="c-review-card__topper__details__name-vendor__vendor">
              {product.brandName}
            </h3>
          </div>
        </div>
      </div>
      <div className="c-review-card__sliders">
        <div className="slug">HOW DID IT MAKE YOU FEEL?</div>
        <div className="swiper-no-swiping">
          {product.feelingData.slice(0, 3).map((feeling: any) => {
            const feelingValue =
              feeling &&
              feelingValues &&
              feelingValues[feeling.feeling.toLowerCase()];
            return (
              <FeelingSlider
                key={String(product.productId) + feeling.feeling}
                feeling={{
                  ...feelingMap[feeling.feeling.toLowerCase()],
                  value: feelingValue || 0,
                }}
                onChange={sliderChange}
              />
            );
          })}
          <Button
            type="button"
            variant=""
            onClick={() =>
              submitReview(product.productId, product.dispensarySlug)
            }
          >
            {reviewing ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </div>
    </article>
  );
}

export default ReviewCard;
