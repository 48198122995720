import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import classNames from "classnames";

import useCart from "./hooks/useCart";
import useDispensary from "./hooks/useDispensary";
// import UseRecommendation from "./hooks/useRecommendation";
import { FetchRecs } from "store/recommendations/actions";
// import { SetRecFinderPreference } from "store/recommendations/actions";
import RecommendationGrid from "../../components/RecommendationGrid";
import DispensaryLocation from "../../components/Dispensaries/DispensaryLocation";
import { LoadingDispensaryLocation } from "../../components/Loading";
import RecommendationSearch from "../../components/RecommendationGrid/RecommendationSearch";
import RecommendationSort from "../../components/RecommendationGrid/RecommendationSort";
import { recPreferences } from "store/recommendations/selectors";
import "./RelatedRecommendation.scss";

const RelatedRecommendation = () => {
  const dispatch = useDispatch();
  const [activeDispensary, setActiveDispensary]: any = useState(null);
  const recProductIds: any = useSelector(
    (state: RootState) => state.recs.recProductIds || {}
  );
  const [loaded, setLoad] = useState(recProductIds?.length || false);

  // const [recs, fetchRecommendation] = UseRecommendation();

  const dispensarySlug: any = useCart();
  const dispensaries: any = useDispensary();

  useEffect(() => {
    if (dispensaries) {
      setActiveDispensary(dispensaries[dispensarySlug]);
    }
  }, [dispensaries, setActiveDispensary, dispensarySlug]);

  useEffect(() => {
    if (!loaded) {
      dispatch(FetchRecs(recPreferences));
      setLoad(true);
    }
  }, [dispatch, loaded]);

  // useEffect(() => {
  //   // dispatch(SetRecFinderPreference("limit", null));

  //   (async () => {
  //     try {
  //       if (!loaded) {
  //         console.log("useEffect SetRecFinderPreference")
  //         // @ts-ignore
  //         await fetchRecommendation();
  //         setLoad(true);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //       setLoad(false);
  //     }
  //   })();
  //   // eslint-disable-next-line
  // }, [
  //   // fetchRecommendation,
  //   loaded,
  // ]);

  return (
    <main className="c-inventory l-main">
      {activeDispensary?.name && (
        <Helmet>
          <title>{`${activeDispensary?.name} Related Products`}</title>
        </Helmet>
      )}
      <div className="l-maxwidth">
        <h1 className="t-page-title">Related Products</h1>
        <div
          className={classNames("c-inventory__pickup-location", {
            "c-inventory__pickup-location--transitioning": !dispensaries,
          })}
        >
          {activeDispensary ? (
            <DispensaryLocation
              location={activeDispensary}
              dispensaryFilter="all"
            />
          ) : (
            <LoadingDispensaryLocation />
          )}
        </div>
        <RecommendationSearch />
        <RecommendationSort />
      </div>
      {/* {loaded ? ( */}
      <RecommendationGrid
        // recs={recs}
        dispensarySlug={dispensarySlug}
      />
      {/* ): null}  */}
    </main>
  );
};

export default withRouter(RelatedRecommendation);
