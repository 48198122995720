import React                from 'react';
import { useDispatch }      from 'react-redux';
import { SubmissionError }  from 'redux-form';
import { getAge }           from 'utils';
import { SetAgeGatePassed } from 'store/system/actions';
import AgeGateConstants     from 'constants/agegate';
import AgeGateForm          from 'components/AgeGate/AgeGateForm'
import Logo                 from 'components/svgs/Logo';
import Footer               from 'components/Footer';
import './AgeGate.scss';

function AgeGate() {
  const dispatch = useDispatch();

  function handleSubmit(values: any) {
    const { month, day, year } = values;
    delete values.cookies;
    const storedAge = JSON.stringify(values);
    const age = getAge(month, day, year);
    if(age >= 21){
      dispatch(SetAgeGatePassed(storedAge));
    } else {
      const ageDifference = (21 - age);
      throw new SubmissionError({
        _error: 'We\'re sorry, come back in ' + ageDifference + ' year' + (ageDifference > 1 ? 's.' : '.')
      })
    }
  }

  return (
    <>
      <main className="l-main agegate">
        <div className="agegate__header">
          <Logo variant="deepblue" />
          <h3>{AgeGateConstants.logo_tagline}</h3>
        </div>
        <div className="agegate__form-wrapper">
          <p className="agegate__instruction">{AgeGateConstants.form_instruction}</p>
          <AgeGateForm onSubmit={handleSubmit} />
          {/* <p className="agegate__cookies">
            By using this site you consent to the Forage <a href="https://siberia.io" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="https://siberia.io" target="_blank" rel="noopener noreferrer">Cookies Policies</a>
          </p> */}
        </div>

      </main>
      <Footer />
    </>
  )
}

export default AgeGate;
