import {
  SET_PICKUP_LOCATION,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEM,
  RESET_CART,
  UPDATE_CART_UUID,
  RESET_MISMATCHED,
  FETCH_CART_PRICE,
  RESET_LAST_PRODUCT_ADDED,
  CartActionTypes,
  CartState,
  SET_ACTIVE_DISPENSARY,
} from './types'
export function AddToCart(product: any, quantity: number, dispensarySlug: string): CartActionTypes {
  return {
    type: ADD_TO_CART,
    product,
    quantity,
    dispensarySlug,
  }
}
export function RemoveFromCart(productId: string | number): CartActionTypes {
  return {
    type: REMOVE_FROM_CART,
    productId,
  }
}

export function ResetCart(): CartActionTypes {
  return {
    type: RESET_CART,
  }
}

export function UpdateCartUuid(uuid: any = null): CartActionTypes {
  return {
    type: UPDATE_CART_UUID,
    uuid,
  }
}
export function ResetMismatched(): CartActionTypes {
  return {
    type: RESET_MISMATCHED,
  }
}

export function UpdateCartItem(productId: string | number, quantity: number): CartActionTypes {
  return {
    type: UPDATE_CART_ITEM,
    quantity,
    productId,
  }
}

export function FetchCartPrice(cart: CartState): CartActionTypes {
  return {
    type: FETCH_CART_PRICE,
    cart: cart,
  }
}

export function SetPickupLocation(pickupLocation: any): CartActionTypes {
  return {
    type: SET_PICKUP_LOCATION,
    pickupLocation: pickupLocation,
  }
}

export function ResetLastProductAdded(): CartActionTypes {
  return {
    type: RESET_LAST_PRODUCT_ADDED,
  }
}

export function SetActiveDispensary(
  dispensary: string,
  activeDispensaryType: string = ''
): CartActionTypes {
  return {
    type: SET_ACTIVE_DISPENSARY,
    activeDispensary: dispensary,
    activeDispensaryType: activeDispensaryType,
  }
}
