import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Topicals: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--topicals" version="1.1" x="0px" y="0px" viewBox="0 0 33.4 75.7">
      <g>
        <line className="i-product-type--topicals--st0" stroke={color} x1="17.2" y1="39.1" x2="17.2" y2="42.4"/>
        <path className="i-product-type--topicals--st0" stroke={color} d="M17.2,20.1c0,0,1.7,2.1,2.3,8.4c0.6,6.3-2.3,10.7-2.3,10.7s9.7-0.8,11.5-11.4c0,0-6.5,3.8-9.8,7.4"/>
        <path className="i-product-type--topicals--st0" stroke={color} d="M17.2,20.1c0,0-1.7,2.1-2.3,8.4s2.3,10.7,2.3,10.7S7.5,38.4,5.7,27.7c0,0,6.5,3.8,9.8,7.4"/>
        <path className="i-product-type--topicals--st0" stroke={color} d="M27.1,39c0,0-4.2,3.6-9.9,0.2"/>
        <path className="i-product-type--topicals--st0" stroke={color} d="M7.3,39c0,0,4.2,3.6,9.9,0.2"/>
      </g>
      <g>
        <path className="i-product-type--topicals--st0" stroke={color} d="M0.5,10.9h32.4l-3.6,47.2c-0.2,3-2.7,5.3-5.8,5.3H9.9c-3,0-5.5-2.3-5.8-5.3L0.5,10.9z"/>
        <path className="i-product-type--topicals--st0" stroke={color} d="M20.3,63.4L19.5,74c-0.1,0.7-0.6,1.2-1.3,1.2h-3.1c-0.7,0-1.2-0.5-1.3-1.2l-0.8-10.6"/>
        <path className="i-product-type--topicals--st0" stroke={color} d="M1.8,0.5h29.8c0.7,0,1.3,0.6,1.3,1.3v9H0.5v-9C0.5,1.1,1.1,0.5,1.8,0.5z"/>
        <g>
          <line className="i-product-type--topicals--st0" stroke={color} x1="27.7" y1="7.4" x2="27.7" y2="4"/>
          <line className="i-product-type--topicals--st0" stroke={color} x1="22.2" y1="7.4" x2="22.2" y2="4"/>
          <line className="i-product-type--topicals--st0" stroke={color} x1="16.7" y1="7.4" x2="16.7" y2="4"/>
          <line className="i-product-type--topicals--st0" stroke={color} x1="11.2" y1="7.4" x2="11.2" y2="4"/>
          <line className="i-product-type--topicals--st0" stroke={color} x1="5.8" y1="7.4" x2="5.8" y2="4"/>
        </g>
        <line className="i-product-type--topicals--st0" stroke={color} x1="20" y1="68.1" x2="13.4" y2="68.1"/>
      </g>
    </svg>

  )
}

export default Topicals;