import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { Field } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { getLocation, fetchPlaces, fetchPlaceById } from 'utils/maps'
import { SetGeoLocationDisabled, SetGeoLocationDismissed } from 'store/system/actions'

import Button from 'components/Button'
import { Input } from 'components/Forms/Fields'
import LocationIcon from 'components/svgs/Location'
import NavigatorIcon from 'components/svgs/Navigator'
import PlacesSearchResult from 'components/PlacesSearch/PlacesSearchResult'

import { TermsOfService } from "./index";

interface ProfileLocationProps {
  activeStep: string
  goNext: any
  setLocation: any
  change: any
}

function Location({ activeStep, goNext, setLocation, change }: ProfileLocationProps) {
  const dispatch = useDispatch()
  const activeStepIndex = useSelector((state: RootState) => state.system.profileStepIndex)
  const formState = useSelector((state: RootState) => state.form.profileCreation)
  const preferences = useSelector((state: RootState) => state.profile.preferences)
  const userLocationString = useSelector((state: RootState) => state.user.userLocationString)
  const geoLocationDisabled = useSelector((state: RootState) => state.system.geoLocationDisabled)
  const geoLocationDismissed = useSelector((state: RootState) => state.system.geoLocationDismissed)
  const geoLocating = useSelector((state: RootState) => state.system.geoLocating)

  const [hasLocator, setHasLocator] = useState(
    navigator.geolocation && !geoLocationDisabled && !geoLocationDismissed
  )
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const [placesSearchResults, setPlacesSearchResults] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])
  const [searchValue, setSearchValue] = useState('')
  const [searchMatchesSelected, setSearchMatchesSelected] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [gettingGeo, setGettingGeo] = useState(false)
  const [usingGeo, setUsingGeo] = useState(false)
  const [isPrev, setIsPrev] = useState(false)
  const [disableButton, setDisableButton] = useState(true)

  const searchingPlaces = useRef<any>(false)
  const lastSearch = useRef<any>(false)

  const isActive = activeStep === 'location'

  useEffect(() => {
    if (geoLocationDisabled || geoLocationDismissed) {
      setHasLocator(false)
    }
  }, [geoLocationDisabled, geoLocationDismissed])

  useEffect(() => {
    if (!formState || !formState.values) return
    if (
      formState.values.street ||
      formState.values.city ||
      formState.values.state ||
      formState.values.zip
    ) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [formState, preferences])

  useEffect(() => {
    let timer: any = null

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 2)
      }, 1000)
    } else {
      setIsPrev(activeStepIndex > 2)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isPrev, activeStepIndex])

  function getGeoLocation() {
    setShowResults(false)
    change('location', 'Locating...')
    getLocation(
      (place_id: string, address: string, zip: string) => {
        fetchPlaceById(place_id)
          .then((result: any) => {
            change('street', result.street)
            change('city', result.city)
            change('state', result.stateName)
            change('zip', result.zip)
            change('location', address)
          })
          .catch((e: any) => {
            console.error(e)
          })
        setUsingGeo(true)
        dispatch(SetGeoLocationDismissed(false))
      },
      (error: any) => {
        setUsingGeo(false)
        setGettingGeo(false)
        if (error.state === 'denied') {
          dispatch(SetGeoLocationDisabled())
        } else {
          change('userLocationString', '')
          dispatch(SetGeoLocationDismissed(true))
        }
      }
    )
  }

  useEffect(() => {
    let timer: any = null

    if (searchMatchesSelected || !searchValue || searchingPlaces.current) {
      return
    }
    searchingPlaces.current = true
    lastSearch.current = searchValue
    timer = setTimeout(() => {
      searchingPlaces.current = false
    }, 1000)

    fetchPlaces(searchValue, (predictions: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setPlacesSearchResults(predictions)
        setHighlightedIndex(-1)
      }
    })

    return () => {
      clearTimeout(timer)
    }
  }, [searchMatchesSelected, searchValue])

  // Watch onKeyDown for selecting results with arrow keys and select result on enter
  function watchKeyDown(e: React.KeyboardEvent) {
    setGettingGeo(false)
    setUsingGeo(false)
    const maxHighlightIndex = hasLocator
      ? placesSearchResults.length
      : placesSearchResults.length - 1
    if (e.keyCode === 40 && highlightedIndex < maxHighlightIndex) {
      setHighlightedIndex(highlightedIndex + 1)
    } else if (e.keyCode === 38 && highlightedIndex >= 0) {
      setHighlightedIndex(highlightedIndex - 1)
    } else if (e.keyCode === 13 && highlightedIndex >= 0) {
      e.preventDefault()
      if (hasLocator && highlightedIndex === 0) {
        getGeoLocation()
      } else {
        const selectedAddress = hasLocator
          ? placesSearchResults[highlightedIndex - 1]
          : placesSearchResults[highlightedIndex]
        selectLocation(selectedAddress.description, selectedAddress.place_id, selectedAddress)
      }
    }
  }

  function selectLocation(address: string, place_id: string, fullAddress: any) {
    change('location', address)
    fetchPlaceById(place_id)
      .then((result: any) => {
        change('street', result.street)
        change('city', result.city)
        change('state', result.stateName)
        change('zip', result.zip)
        change('location', address)
      })
      .catch((e: any) => {
        console.error(e)
      })
    setSearchValue(address)
    setSearchMatchesSelected(true)
    setShowResults(false)
  }

  // onMouseOver results list, reset the highlightedIndex
  function resetHighlightedIndex() {
    setHighlightedIndex(-1)
  }

  function onFocus(event: any) {
    event.target.select()
    setShowResults(true)
  }
  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? 'c-profile__step--prev-' : 'c-profile__step-'}
      timeout={1000}
      appear
    >
      <div className={classNames('c-profile__step')}>
        <div className="slug">Question 2 of 8</div>
        <h3>Where are you located?</h3>
        <div className="c-profile__step__location-input">
          <Field
            component={Input}
            Icon={gettingGeo || usingGeo ? NavigatorIcon : LocationIcon}
            id="autocomplete-places"
            name="location"
            type="text"
            label={geoLocating && userLocationString === null ? 'Locating...' : 'Location'}
            autocomplete="new_password"
            className="places__input"
            onFocus={onFocus}
            onKeyDown={watchKeyDown}
            onBlur={() => {
              setTimeout(() => setShowResults(false), 500)
            }}
            tabIndex={isActive ? 0 : -1}
            normalize={(value: string) => {
              value ? setSearchValue(value) : setSearchValue('')
              return value
            }}
          />
          <>
            {placesSearchResults && showResults && (
              <div
                className={classNames('c-places-search__results', {
                  'c-places-search__results--highlighted': highlightedIndex >= 0,
                })}
                onMouseOver={resetHighlightedIndex}
              >
                <div className="c-places-search__results__inner">
                  {hasLocator && (
                    <PlacesSearchResult
                      locator={true}
                      highlighted={highlightedIndex === 0}
                      selectLocation={getGeoLocation}
                    />
                  )}
                  {placesSearchResults.map((place, index) => {
                    return (
                      <PlacesSearchResult
                        key={place.place_id}
                        highlighted={
                          hasLocator ? index + 1 === highlightedIndex : index === highlightedIndex
                        }
                        address={place}
                        selectLocation={selectLocation}
                      />
                    )
                  })}
                </div>
              </div>
            )}
          </>
        </div>
        <Field component={Input} name="state" type="hidden" />

        <Field component={Input} name="city" type="hidden" />
        <Field component={Input} name="state" type="hidden" />
        <Field component={Input} name="zip" type="hidden" />
        <Button type="button" disabled={disableButton} onClick={goNext} variant="">
          Next
        </Button>
        <TermsOfService />
      </div>
    </CSSTransition>
  )
}

export default Location
