import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const RightCaret: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
const color = colors[variant];
  return(
    <svg className="i-right-caret" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.60191 8.10122L7.27747 5L4.60191 1.89878" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default RightCaret;