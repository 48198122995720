import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "store";
import {
  FetchInventory,
  ResetInventory,
  ResetNoResults,
  SetInventoryFilter,
  SetSlug,
} from "store/inventory/actions";
import { InventoryProps } from "constants/inventory";
import InventorySearch from "components/Inventory/InventorySearch";
import InventoryFilterSort from "components/Inventory/InventoryFilterSort";
import InventoryGrid from "components/Inventory/InventoryGrid";
import CartMismatch from "components/CartMismatch";
import { LoadingDispensaryLocation } from "components/Loading";
import DispensaryLocation from "components/Dispensaries/DispensaryLocation";
import NotFound from "components/NotFound";
import "./Inventory.scss";

type inventoryProps = Array<InventoryProps>;
interface inventoryParamsProps {
  slug?: string;
}
function Inventory({ match }: RouteComponentProps) {
  const params: inventoryParamsProps = match.params;
  const slug = params.slug || "";

  const dispatch = useDispatch();
  const pickupDispensary = useSelector(
    (state: RootState) =>
      state.locations.dispensaries && state.locations.dispensaries[slug]
  );
  const currentSlug = useSelector((state: RootState) => state.inventory.slug);
  const inventoryIds = useSelector(
    (state: RootState) => state.inventory.inventoryIds
  );
  const inventoryDispensary = useSelector(
    (state: RootState) => state.inventory.inventoryDispensary
  );
  const storeInventory = useSelector((state: RootState) =>
    slug === inventoryDispensary ? state.inventory.inventory : null
  );

  const [transitioningPickup, setTransitioningPickup] = useState(false);
  const [pickupLoaded, setPickupLoaded] = useState(
    pickupDispensary ? true : false
  );
  const [initialLoad, setInitialLoad] = useState(true);
  const allLocations = useSelector(
    (state: RootState) => state.locations.dispensaries
  );
  const [show404, setShow404] = useState(false);

  useEffect(() => {
    if (pickupDispensary) {
      setTransitioningPickup(true);
      setTimeout(() => {
        setPickupLoaded(true);
        setTransitioningPickup(false);
      }, 300);
    }
  }, [pickupDispensary]);

  useEffect(() => {
    if (slug !== currentSlug && currentSlug && initialLoad) {
      dispatch(SetSlug(slug));
      dispatch(ResetNoResults());
      dispatch(ResetInventory());
      dispatch(SetInventoryFilter({}));
    }
    if (slug !== currentSlug && currentSlug && !initialLoad) {
      dispatch(SetSlug(slug));
      dispatch(ResetNoResults());
      dispatch(SetInventoryFilter({}));
    }
    setTimeout(() => {
      if (slug !== currentSlug && currentSlug && slug !== "" && !initialLoad) {
        setInitialLoad(false);
        dispatch(SetSlug(slug));
        dispatch(FetchInventory(slug));
      } else if (inventoryIds.length === 0 && slug !== "" && initialLoad) {
        setInitialLoad(false);
        dispatch(SetSlug(slug));
        dispatch(FetchInventory(slug));
      }
    }, 250);
  }, [slug, inventoryIds, initialLoad, currentSlug, dispatch]);

  // Set 404 if dispansary location not available
  useEffect(() => {
    if (allLocations && !pickupDispensary) {
      setShow404(true);
    }
  }, [allLocations, pickupDispensary]);

  return (
    <main className="c-inventory l-main">
      {pickupDispensary?.name && (
        <Helmet>
          <title>{`${pickupDispensary?.name} Products`}</title>
        </Helmet>
      )}
      {pickupDispensary && (
        <>
          <div className="l-maxwidth">
            <h1 className="t-page-title">All Products</h1>
            <div
              className={classNames("c-inventory__pickup-location", {
                "c-inventory__pickup-location--transitioning": transitioningPickup,
              })}
            >
              {pickupLoaded && pickupDispensary ? (
                <DispensaryLocation
                  location={pickupDispensary}
                  dispensaryFilter="all"
                />
              ) : (
                <LoadingDispensaryLocation />
              )}
            </div>
            <InventorySearch />
            <InventoryFilterSort />
          </div>
          {storeInventory && (
            <>
              <InventoryGrid dispensarySlug={slug} />
            </>
          )}
          <CartMismatch />
        </>
      )}
      {!pickupDispensary && show404 && <NotFound hasLocationLink />}
    </main>
  );
}

export default withRouter(Inventory);
