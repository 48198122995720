import React, { FunctionComponent } from 'react';
import classNames                   from 'classnames';
import './Tag.scss';

type TagProps = {
  onClick?: any
}

const Tag: FunctionComponent<TagProps> = ({
  onClick, 
  children
}) => {
  return (
    <button className={classNames('c-tag tag', {})} onClick={onClick}>
      {children}
    </button>
  )
}

export default Tag;