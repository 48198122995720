import React  from 'react';
import Button from 'components/Button';
import './CreateProfile.scss';

function CreateProfile(){
  return(
    <section className="c-create-profile">
      <div className="c-create-profile__inner">
        <figure className="c-create-profile__figure">
          <img src={process.env.REACT_APP_STATIC_URL + '/home/profilegirl.jpg'} alt="Hippie girl wearing star glasses" />
        </figure>

        <div className="c-create-profile__content">
          <div className="c-create-profile__content__inner">
            <div className="slug">GET MORE OUT OF YOUR CANNABIS</div>
            <h2>Create your profile to perfect your feels.</h2>
            <p>Forage helps pair you with your perfect cannabis. Whether you’re a first time user, self-professed expert, looking to solve back pain or party through the weekend. </p>
            <p>We guide you through the process, providing options as you refine your fit.</p>
            <Button type="Link" to="/create-profile" variant="get-started"> Get Started</Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CreateProfile;