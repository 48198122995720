import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const PlusStars: FunctionComponent<IconType> = ({ variant = 'gray30'}) => {
  const color = colors[variant];
  return(
    <svg className="plus-stars-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 314 141">
    <path fill={color} d="M69.6,13.4h-3.8v-4h-1.6v4h-3.9v1.4h3.9V19h1.6v-4.2h3.8V13.4z"/>
    <path fill={color} d="M99.6,20.4h-3.8v-4h-1.6v4h-3.9v1.4h3.9V26h1.6v-4.2h3.8V20.4z"/>
    <path fill={color} d="M257.6,18.4h-3.8v-4h-1.6v4h-3.9v1.4h3.9V24h1.6v-4.2h3.8V18.4z"/>
    <path fill={color} d="M245.6,49.4h-3.8v-4h-1.6v4h-3.9v1.4h3.9V55h1.6v-4.2h3.8V49.4z"/>
    <path fill={color} d="M257.6,110.4h-3.8v-4h-1.6v4h-3.9v1.4h3.9v4.2h1.6v-4.2h3.8V110.4z"/>
    <path fill={color} d="M69.6,89.4h-3.8v-4h-1.6v4h-3.9v1.4h3.9V95h1.6v-4.2h3.8V89.4z"/>
    </svg>
  )
}

export default PlusStars;