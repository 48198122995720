import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Sleepy: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--uplifted" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <circle fill={color} cx="207.3" cy="95.6" r="39.5"/>
        <line className="i-feeling--uplifted--st1" stroke={color} x1="43.3" y1="215.1" x2="371.3" y2="215.1"/>
      </g>
    </svg>
  )
}

export default Sleepy;