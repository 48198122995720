import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootState } from 'store';
import { AccountSettingsForm } from 'components/Account';
import { CustomerSaveSettings, FetchAccountSettings, ResetSettingSaved } from 'store/customer/actions';
import './Settings.scss';

function AccountSettings({ 
  history 
}: RouteComponentProps) {
  const dispatch        = useDispatch();
  const saving          = useSelector(( state : RootState ) => state.customer.saving);
  const saved           = useSelector(( state : RootState ) => state.customer.settingsSaved);
  const customer        = useSelector((state: RootState) => state.customer.customer);
  const authenticating  = useSelector((state: RootState) => state.customer.authenticating);
  const authenticated   = useSelector((state: RootState) => state.customer.authenticated);
  
  const [customerLoading, setCustomerLoading] = useState<any>(false);
  const [customerLoaded, setCustomerLoaded] = useState(false);
  
  const accessToken = sessionStorage.getItem('forage_token');
  
  useEffect(() => {
    if(!customerLoaded && !authenticating && accessToken){
      dispatch(FetchAccountSettings({accessToken: accessToken}))
    } else if(!customer){
      history.push('/account/signin');
    }
    if(authenticating){
      setCustomerLoading(true);
    }

    if(customerLoading && authenticated){
      setCustomerLoaded(true);
    }
  }, [customer, accessToken, authenticating, customerLoading, customerLoaded, authenticated, history, dispatch]);

  useEffect(() => {
    if(saved){
      history.push('/account');
      dispatch(ResetSettingSaved());
    }
  }, [saved, dispatch, history]);

  function handleSubmit(values:any) {
    values.dateOfBirth = values.month + '/' + values.day + '/' + values.year;
    delete values.month;
    delete values.day;
    delete values.year;
    dispatch(CustomerSaveSettings(values));
  }

  return(
    <section className={classNames("l-main c-account-settings", {'c-account-settings--saving': saving, 'c-account-settings--loading': authenticating})}>
      <div className="l-maxwidth l-maxwidth--small">
        <h1>Edit Account Settings</h1>
        { 
          customerLoaded &&
            <AccountSettingsForm onSubmit={handleSubmit} />
        }
      </div>
    </section>
  )
}

export default withRouter(AccountSettings);