import React from "react";
import "components/ProductCard/ProductCard.scss";
import "./index.scss";
function DotPulseLoader() {
  return (
    <div className="dot-pulse-wrapper">
      <div className="dot-pulse" />
    </div>
  );
}

export default DotPulseLoader;
