// import React, { useState, useRef, useEffect } from 'react';
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Field } from "redux-form";
import { SelectProps } from "./types";
import Button from "components/Button";
import DownCaretIcon from "components/svgs/DownCaret";
import MenuDrawer from "components/MenuDrawer";
import { Checkbox } from "components/Forms/Fields";
import "./Fields.scss";

function FieldComponent({
  input,
  label,
  className,
  meta,
  options,
}: SelectProps) {
  const { active, touched, invalid, submitFailed } = meta;
  const showInvalid = (invalid && touched) || submitFailed;
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [showCheckValues, setshowCheckValues] = useState<any>(input || "");

  const optionToDisplay: any =
    input.value &&
    input.value.length > 0 &&
    options.find((option: any) => {
      return input.value[0] === option.name;
    });

  const [optionToDisp, setOptionToDisp] = useState<any>(
    optionToDisplay || false
  );

  useEffect(() => {
    if (options.length) {
      const optionToDisplay: any =
        showCheckValues.value &&
        showCheckValues.value.length > 0 &&
        options.find((option: any) => {
          return showCheckValues.value[0] === option.name;
        });
      setOptionToDisp(optionToDisplay);
    }
  }, [options, showCheckValues]);

  const handleCheckApply = () => {
    setshowCheckValues(input);
    const optionToDisplay: any =
      input.value &&
      input.value.length > 0 &&
      options.find((option: any) => {
        return input.value[0] === option.name;
      });
    setOptionToDisp(optionToDisplay);
    setOptionsOpen(false);
  };

  return (
    <>
      <div
        className={classNames(
          "form-control form-control--text form-control--multi-check form-control--select " +
            className,
          {
            "form-control--invalid": showInvalid,
            "form-control--active": active,
            "form-control--dirty": input.value.length > 0,
          }
        )}
      >
        <div
          className="form-control__content"
          onClick={() => setOptionsOpen(true)}
        >
          <input type="hidden" {...showCheckValues} />
          <div className=">h3 form-control__input"></div>
          {label && (
            <label className="form-control__label" htmlFor={input.name}>
              {label}
            </label>
          )}
          <DownCaretIcon variant="charcoal" />
          {optionToDisp && (
            <div className="form-control__multi-values">
              <div className="form-control__multi-values__value">
                {optionToDisp?.label}
              </div>
              {showCheckValues?.value && showCheckValues?.value.length > 1 && (
                <div className="form-control__multi-values__value">
                  +{showCheckValues?.value.length - 1}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <MenuDrawer
        display={optionsOpen}
        closeMenu={() => {
          setOptionsOpen(false);
          input?.onChange(showCheckValues.value);
        }}
        position="toast"
        menuHeading={label}
      >
        <div>
          <Field
            component={Checkbox}
            name={input.name}
            options={options}
            className="i-checkbox-group--two-wide"
          />
          <Button type="button" variant="saveMulti" onClick={handleCheckApply}>
            Apply
          </Button>
        </div>
      </MenuDrawer>
    </>
  );
}

const renderField = (props: SelectProps) => {
  return <FieldComponent {...props} />;
};
export default renderField;
