import React, {FunctionComponent} from 'react';
import colors from 'sass/utils/colors.module.scss';
import { IconType } from 'components/svgs/types';
const BackArrow: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.69238 12H16.6924" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0962 7.90381L8 12L12.0962 16.0962" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default BackArrow;