import moment from "moment";
import {
  CustomerState,
  CustomerActionTypes,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILED,
  CUSTOMER_LOGOUT,
  CUSTOMER_LOGIN,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGIN_FAILED,
  CREATING_CUSTOMER,
  CUSTOMER_SAVE_SETTINGS,
  CUSTOMER_SAVE_SETTINGS_SUCCESS,
  CUSTOMER_SAVE_SETTINGS_FAILED,
  FETCH_ORDER_HISTORY_SUCCESS,
  RESET_SETTING_SAVED,
  FETCH_PRODUCTS_TO_REVIEW_SUCCESS,
  FETCH_PRODUCTS_TO_REVIEW_FAILED,
  FETCH_PRODUCTS_TO_REVIEW,
  REVIEW_PRODUCT_SUCCESS,
  REVIEW_PRODUCT_FAILED,
  REVIEW_PRODUCT,
  RESET_VERIFICATION_NEEDED,
  RESET_PASSWORD_PLACEHOLDER,
  SET_FULL_ADDRESS,
  PROFILE_SUCCESS,
  ORDER_SUCCESS,
  FETCH_ORDER_HISTORY_FAILED,
  FETCH_ORDER_HISTORY,
  UPDATE_ACCESS_TOKEN,
  RESET_CUSTOMER_ERROR,
  FETCH_ACCOUNT_SETTINGS_FAILED,
  FETCH_ACCOUNT_SETTINGS,
  FETCH_ACCOUNT_SETTINGS_SUCCESS,
} from "./types";

const storedToken = sessionStorage.getItem("forage_token");
const storeID = localStorage.getItem("forage_customer");
let startingCustomer: any = null;
if (storedToken) {
  startingCustomer = {
    accessToken: storedToken,
    auth0Id: storeID,
    readyToLoad: true,
  };
}

const authenticating =
  window.location.hash && window.location.hash.match("access_token")
    ? true
    : false;
const storedEmail = sessionStorage.getItem("forage_email") || "";
sessionStorage.removeItem("forage_email");

const initialState: CustomerState = {
  creatingCustomer: false,
  customerError: null,
  customer: startingCustomer,
  authenticating: authenticating,
  authenticated: storedToken ? true : false,
  accessToken: storedToken,
  customerEmail: storedEmail,
  verificationRequired: false,
  saving: false,
  settingsSaved: false,
  orderHistory: null,
  ordersCount: null,
  orderHistoryEmpty: false,
  orderHistoryLoading: false,
  savingAccountSettings: false,
  orderOnlyDetails: null,
  productsToReview: null,
  reviewsLoading: false,
  reviewsError: false,
  reviewingProduct: "",
  reviewedProducts: [],
  reviewError: false,
  passwordPlaceholder: storedEmail ? "*********" : null,
  fullAddress: null,
  lastOrderName: null,
  customerLoading: false,
  isNew: false,
};

function storeCustomer(customer: any) {
  if (customer?.accessToken) {
    sessionStorage.setItem("forage_token", customer.accessToken);
  }
}

export function customerReducer(
  state = initialState,
  action: CustomerActionTypes
): CustomerState {
  switch (action.type) {
    case CREATING_CUSTOMER: {
      return {
        ...state,
        creatingCustomer: action.isCreating,
      };
    }
    case CREATE_CUSTOMER: {
      return {
        ...state,
        creatingCustomer: true,
      };
    }

    case CUSTOMER_LOGOUT: {
      sessionStorage.removeItem("forage_token");
      sessionStorage.removeItem("forage_token_timestamp");
      return {
        ...state,
      };
    }

    case CREATE_CUSTOMER_SUCCESS: {
      let { customer } = action;
      customer.accessToken = sessionStorage.getItem("forage_token");
      customer.loaded = true;
      customer.readyToLoad = false;
      if (customer.auth0Id.indexOf("auth0|") >= 0) {
        return {
          ...state,
          creatingCustomer: false,
          verificationRequired: true,
        };
      } else {
        storeCustomer(customer);
        return {
          ...state,
          creatingCustomer: false,
          customer: customer,
          authenticated: true,
        };
      }
    }

    case CREATE_CUSTOMER_FAILED: {
      const { error } = action;
      return {
        ...state,
        creatingCustomer: false,
        customerError: error,
        authenticated: false,
      };
    }

    case CUSTOMER_LOGIN: {
      return {
        ...state,
        authenticating: true,
        authenticated: false,
        customerEmail: action.payload
          ? action.payload.email
          : state.customerEmail,
        passwordPlaceholder: action.payload.emailSignin ? "**************" : "",
      };
    }

    case CUSTOMER_LOGIN_SUCCESS: {
      let customer = action.customer;
      sessionStorage.setItem("forage_token", customer?.accessToken);
      sessionStorage.setItem(
        "forage_token_timestamp",
        moment().unix().toString()
      );
      storeCustomer(customer);
      let dob = customer.dateOfBirth
        ? customer.dateOfBirth.split("/")
        : [null, null, null];
      customer.month = dob[0];
      customer.day = dob[1];
      customer.year = dob[2];
      customer.loaded = true;
      customer.readyToLoad = false;
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        accessToken: customer?.accessToken,
        customer: customer,
        customerEmail: "",
        isNew: customer?.isNew || false,
      };
    }

    case CUSTOMER_LOGIN_FAILED: {
      sessionStorage.removeItem("forage_email");
      sessionStorage.removeItem("forage_token");
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        accessToken: null,
        customer: null,
        customerEmail: "",
        customerError: action.error,
      };
    }

    case CUSTOMER_SAVE_SETTINGS: {
      return {
        ...state,
        saving: true,
      };
    }

    case PROFILE_SUCCESS: {
      let customer: any = { ...state.customer };
      customer.hasProfile = true;
      customer.profileFirst = action.config.firstName;
      customer.profileLast = action.config.lastName;
      return {
        ...state,
        customer: { ...customer },
      };
    }

    case CUSTOMER_SAVE_SETTINGS_SUCCESS: {
      let customer: any = { ...state.customer };
      let actionCustomer = action.customer;
      actionCustomer.documentId = actionCustomer.documentId
        ? "******" +
          actionCustomer.documentId.substring(
            actionCustomer.documentId.length - 4,
            actionCustomer.documentId.length
          )
        : "";
      actionCustomer.medicalId = actionCustomer.medicalId
        ? "******" +
          actionCustomer.medicalId.substring(
            actionCustomer.medicalId.length - 4,
            actionCustomer.medicalId.length
          )
        : "";
      let dob = actionCustomer.dateOfBirth;
      if (dob) {
        dob = dob.split("/");
        actionCustomer.month = dob[0];
        actionCustomer.day = dob[1];
        actionCustomer.year = dob[2];
      }
      customer = { ...customer, ...actionCustomer };
      if (action.persistCustomer) {
        storeCustomer(customer);
        return {
          ...state,
          customer: customer,
          saving: false,
          settingsSaved: true,
          orderOnlyDetails: customer,
        };
      } else {
        return {
          ...state,
          saving: false,
          settingsSaved: true,
          orderOnlyDetails: customer,
        };
      }
    }

    case CUSTOMER_SAVE_SETTINGS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case FETCH_ORDER_HISTORY: {
      let { orderHistoryFetchLimit = null, offset = null } = action;

      return {
        ...state,
        orderHistory: null,
        orderHistoryEmpty: false,
        orderHistoryLoading: true,
        orderHistoryFetchLimit,
        offset,
      };
    }

    case FETCH_ORDER_HISTORY_SUCCESS: {
      let { orderHistory } = action;
      const { data, count } = orderHistory;
      let orderHistoryData: Array<any> =
        data?.sort(
          (a: any, b: any) => +new Date(b.orderDate) - +new Date(a.ororderDate)
        ) || [];
      return {
        ...state,
        orderHistory: orderHistoryData,
        ordersCount: count,
        orderHistoryEmpty: !orderHistory || orderHistory.length === 0,
        orderHistoryLoading: false,
      };
    }

    case FETCH_ORDER_HISTORY_FAILED: {
      return {
        ...state,
        orderHistoryLoading: false,
        orderHistoryEmpty: true,
      };
    }

    case FETCH_PRODUCTS_TO_REVIEW: {
      return {
        ...state,
        reviewsError: false,
        reviewsLoading: true,
      };
    }

    case FETCH_PRODUCTS_TO_REVIEW_SUCCESS: {
      let ptr = action.productsToReview.map((product: any) => {
        if (product.feelings) {
          const allowedFeelings = [
            "energetic",
            "focused",
            "uplifted",
            "euphoric",
            "talkative",
            "creative",
            "happy",
            "hungry",
            "giggly",
            "relaxed",
            "sleepy",
            "aroused",
            "tingly",
          ];
          let feelingData: any = [];
          product.feelings.forEach((feeling: any) => {
            if (allowedFeelings.indexOf(feeling.feeling.toLowerCase()) >= 0) {
              feelingData.push({
                feeling: feeling.feeling,
                value: feeling.value,
              });
            }
          });
          feelingData = feelingData.sort((itemA: any, itemB: any) =>
            parseInt(itemA.value, 10) < parseInt(itemB.value, 10) ? 1 : -1
          );
          product.feelingData = feelingData;
        } else {
          product.feelingData = null;
        }
        delete product.feelings;
        delete product.feelingValues;
        return product;
      });
      return {
        ...state,
        reviewsError: false,
        reviewsLoading: false,
        productsToReview: ptr.length > 0 ? [...ptr] : null,
      };
    }

    case FETCH_PRODUCTS_TO_REVIEW_FAILED: {
      return {
        ...state,
        reviewsError: true,
        reviewsLoading: false,
      };
    }

    case RESET_SETTING_SAVED: {
      return {
        ...state,
        settingsSaved: false,
      };
    }

    case REVIEW_PRODUCT: {
      return {
        ...state,
        reviewingProduct: action.review.productID,
        reviewError: false,
      };
    }

    case REVIEW_PRODUCT_SUCCESS: {
      let newReviewed = [...state.reviewedProducts];
      newReviewed.push(action.review.productId);
      return {
        ...state,
        reviewingProduct: "",
        reviewedProducts: [...newReviewed],
      };
    }

    case REVIEW_PRODUCT_FAILED: {
      return {
        ...state,
        reviewError: true,
      };
    }

    case RESET_PASSWORD_PLACEHOLDER: {
      sessionStorage.removeItem("forage_email");
      return {
        ...state,
        passwordPlaceholder: "",
        authenticating: false,
      };
    }

    case RESET_VERIFICATION_NEEDED: {
      return {
        ...state,
        verificationRequired: false,
      };
    }

    case SET_FULL_ADDRESS: {
      return {
        ...state,
        fullAddress: action.address,
      };
    }

    case ORDER_SUCCESS: {
      const lastOrderName = state.orderOnlyDetails
        ? state.orderOnlyDetails.firstName +
          " " +
          state.orderOnlyDetails.lastName
        : state.customer
        ? state.customer.firstName + " " + state.customer.lastName
        : null;
      return {
        ...state,
        orderOnlyDetails: null,
        lastOrderName,
      };
    }

    case UPDATE_ACCESS_TOKEN: {
      const { accessToken } = action;
      return {
        ...state,
        customer: state.customer ? { ...state.customer, accessToken } : null,
      };
    }

    case RESET_CUSTOMER_ERROR: {
      return {
        ...state,
        customerError: null,
      };
    }

    case FETCH_ACCOUNT_SETTINGS: {
      return {
        ...state,
        authenticating: true,
        authenticated: false,
        customerLoading: true,
      };
    }

    case FETCH_ACCOUNT_SETTINGS_SUCCESS: {
      let customer = action.customer;
      storeCustomer(customer);
      let dob = customer.dateOfBirth
        ? customer.dateOfBirth.split("/")
        : [null, null, null];
      customer.month = dob[0];
      customer.day = dob[1];
      customer.year = dob[2];
      customer.loaded = true;
      customer.readyToLoad = false;
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        customer: customer,
      };
    }

    case FETCH_ACCOUNT_SETTINGS_FAILED: {
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        customerLoading: false,
        customerError: true,
      };
    }

    default:
      return state;
  }
}
