import { combineReducers } from 'redux';
import { cartReducer } from './cart/reducer';
import { customerReducer } from './customer/reducer';
import { inventoryReducer } from './inventory/reducer';
import { locationsReducer } from './locations/reducer';
import { ordersReducer } from './orders/reducer';
import { profileReducer } from './profile/reducer';
import { recsReducer } from './recommendations/reducer';
import { systemReducer } from './system/reducer';
import { userReducer } from './user/reducer';
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  cart:       cartReducer,
  customer:   customerReducer,
  form:       formReducer,
  inventory:  inventoryReducer,
  locations:  locationsReducer,
  orders:     ordersReducer,
  profile:    profileReducer,
  recs:       recsReducer,
  system:     systemReducer,
  user:       userReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;