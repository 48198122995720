import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { InventoryProps } from "constants/inventory";
import "./PDPDescriptionImage.scss";

interface PDPDescriptionImageProps {
  product: InventoryProps;
}

function PDPDescriptionImage({ product }: PDPDescriptionImageProps) {
  const [expanded, setExpanded] = useState(false);
  const [isLongDesc, setIsLongDesc] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const el: any = document.getElementById("pdp-description");
      // Get total height of the content
      const divHeight = el?.offsetHeight || 0;
      const descLines = Math.round(divHeight / 27.5);
      if (divHeight && descLines <= 3) {
        setIsLongDesc(false);
      }
    }
  }, []);

  return (
    <>
      {(product.category === "FLW-Flower" || product?.product?.description) && (
        <section className="c-pdp-description-image">
          {product?.product?.description && (
            <>
              <div className="slug">Description</div>
              <div
                className={classNames("c-pdp-description-image__description", {
                  "c-pdp-description-image__description--expanded": expanded,
                })}
              >
                <div
                  id="pdp-description"
                  className="c-pdp-description-image__description--container"
                  dangerouslySetInnerHTML={{
                    __html: product?.product?.description,
                  }}
                />
                {/* <p>
                    High potency distillate vapes; each cartridge features a
                    perfectly balanced cannabinoid and terpene content that is
                    sure to provide an enjoyable experience.
                </p>  */}
              </div>
              {isLongDesc && (
                <button
                  className="c-pdp-description__toggle-expanded"
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? "Read Less" : "Read More"}
                </button>
              )}
            </>
          )}
          {/* {product.imageUrl && (
            <figure className="c-pdp-description-image__figure">
              <img
                src={product.imageUrl}
                alt={product.product.productName || ""}
              />
            </figure>
          )} */}
        </section>
      )}
    </>
  );
}

export default PDPDescriptionImage;
