import React from "react";
import "./NotFound.scss";
import { Link } from "react-router-dom";

interface NotFoundProp {
  hasLocationLink?: boolean;
}

function NotFound({ hasLocationLink }: NotFoundProp) {
  return (
    <section className="l-main l-maxwidth l-max-width-small c-not-found">
      <h1 className="c-not-found-text c-not-found-text--slim">404</h1>
      <h1 className="c-not-found-text">
        Sorry, we couldn't find what
        <br />
        you're looking for.
      </h1>
      {hasLocationLink ? (
        <Link to="/locations" className="c-not-found-recommendation">
          Select a Different Location
        </Link>
      ) : (
        <Link to="/" className="c-not-found-recommendation">
          Get Recommendations
        </Link>
      )}
    </section>
  );
}

export default NotFound;
