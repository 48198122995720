import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import { FetchDispensaries } from "store/locations/actions";
import LocationIcon from "components/svgs/Location";
import { InventoryProps } from "constants/inventory";
import "./PDPPickup.scss";

interface PDPPickupProps {
  product: InventoryProps;
  dispensarySlug: string;
}

function PDPPickup({ product, dispensarySlug }: PDPPickupProps) {
  const dispatch = useDispatch();
  const allLocations = useSelector(
    (state: RootState) => state.locations.dispensaries
  );

  const [activeDispensary, setActiveDispensary] = useState<any>(false);

  useEffect(() => {
    if (allLocations === null && dispensarySlug) {
      dispatch(FetchDispensaries());
    }
    if (allLocations && dispensarySlug) {
      const location = allLocations[dispensarySlug];
      if (location) {
        setActiveDispensary(location);
      }
    }
  }, [allLocations, dispensarySlug, dispatch]);

  return (
    <div
      className={classNames("c-pdp-pickup", {
        "c-pdp-pickup--out-of-stock": product.quantityAvailable === 0,
      })}
    >
      {activeDispensary && (
        <>
          <div className="c-pdp-pickup__availability">
            {product.quantityAvailable > 0 ? (
              <>Available for pickup today</>
            ) : (
              <>Currently out of stock</>
            )}
          </div>
          <Link
            to={"/dispensary/" + activeDispensary?.slug}
            className="c-pdp-pickup__location"
          >
            <LocationIcon />
            <span>{activeDispensary?.name}</span>
          </Link>
        </>
      )}
    </div>
  );
}

export default PDPPickup;
