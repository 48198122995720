import { 
  SET_USER_LOCATION, 
  SET_LOCATION_FILTER, 
  UserActionTypes 
} from './types';
export function SetUserLocation(locationID:string, locationString:string, locationZip:string): UserActionTypes {
  return {
    type:           SET_USER_LOCATION,
    locationID:     locationID,
    locationString: locationString,
    locationZip:    locationZip
  }
}
export function SetLocationFilter(filter:string): UserActionTypes {
  return {
    type:   SET_LOCATION_FILTER,
    filter: filter
  }
}
