import React          from 'react';
import classNames     from 'classnames';
import { InputProps } from './types';
import './Fields.scss';

// General input to be used by forms
// Placeholders or labels are to be used independent of one another as the desired design would clash otherwise.
const renderField = ({
  input,
  label,
  placeholder,
  type,
  normalize,
  className,
  onKeyDown,
  onKeyUp,
  Icon,
  meta,
  disabled,
  maxlength=9999,
  tabIndex = 0
}: InputProps ) => {
  const {active, touched, invalid, dirty, submitFailed, initial} = meta;
  const { value } = input;
  const showInvalid = (invalid && touched) || (submitFailed && !touched);
  const usePlaceholder = placeholder && !label;

  return (
    <>
    {
      type === 'hidden' ?
        <input type={type} {...input} />
      :
        <div className={classNames('form-control form-control--text ' + className, {'form-control--placeholder': usePlaceholder, 'form-control--disabled': disabled, 'form-control--invalid': showInvalid, 'form-control--active': active, 'form-control--dirty': ((dirty||initial) && value), 'form-control--has-icon': Icon})}>
          <div className="form-control__content">
            {
              Icon &&
                <div className="form-control__icon">
                  <Icon />
                </div>
            }
            {
              usePlaceholder ?
                <input className="h3 form-control__input" tabIndex={tabIndex} maxLength={maxlength} disabled={disabled} placeholder={placeholder} {...input} type={type} normalize={normalize} onKeyUp={onKeyUp} onKeyDown={onKeyDown} id={input.name}/>
              :
                <input className="h3 form-control__input" tabIndex={tabIndex} maxLength={maxlength} disabled={disabled} {...input} type={type} normalize={normalize} onKeyUp={onKeyUp} onKeyDown={onKeyDown} id={input.name}
                />
            }
            {
              label &&
                <label className="form-control__label" htmlFor={input.name}>{label}</label>
            }
          </div>
        </div>
    }
    </>
  )
}
export default renderField;
