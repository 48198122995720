export interface ProfilePreferenceState {
  firstName: null | string;
  lastName: null | string;
  dateOfBirth: null | string;
  month: null | string;
  day: null | string;
  year: null | string;
  location: null | string;
  street: null | string;
  streetCont: null | string;
  city: null | string;
  state: null | string;
  zip: null | string;
  dispensarySlug: null | string;
  experienceLevel: null | string;
  howToFlower: null | string;
  strengthPreference: null | string;
  usePreference: null | any;
  treatmentGoals?: null | Array<string>;
  productFormatsToTry: null | Array<string>;
  productFormatsToAvoid: null | Array<string>;
}

export interface ProfileState {
  savingProfile: boolean;
  profileSaved: boolean;
  profileLoading: boolean;
  noReset: boolean;
  current: ProfilePreferenceState | null;
  preferences: ProfilePreferenceState;
  config: any;
  configLoading: boolean;
  profileError: null | string;
  hasProfile: null | boolean;
}

export const SET_NO_RESET = "SET_NO_RESET";
export const STORE_PREFERENCES = "STORE_PREFERENCES";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_SUCCESS_ONSIGNUP =
  "CREATE_PROFILE_SUCCESS_ONSIGNUP";
export const CREATE_PROFILE_FAILED = "CREATE_PROFILE_FAILED";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const FETCH_PROFILE_CONFIG = "FETCH_PROFILE_CONFIG";
export const FETCH_PROFILE_CONFIG_SUCCESS = "FETCH_PROFILE_CONFIG_SUCCESS";
export const FETCH_PROFILE_CONFIG_FAILED = "FETCH_PROFILE_CONFIG_FAILED";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILED = "FETCH_PROFILE_FAILED";

interface StorePreferences {
  type: typeof STORE_PREFERENCES;
  preferences: ProfilePreferenceState;
  persist: boolean;
}

interface SetNoReset {
  type: typeof SET_NO_RESET;
  noReset: boolean;
}

interface CreateProfile {
  type: typeof CREATE_PROFILE;
}

interface CreateProfileSuccess {
  type: typeof CREATE_PROFILE_SUCCESS;
  profile: any;
}
interface CreateProfileSuccessOnSignUp {
  type: typeof CREATE_PROFILE_SUCCESS_ONSIGNUP;
  profile: any;
}

interface CreateProfileFailed {
  type: typeof CREATE_PROFILE_FAILED;
  error: any;
}

interface UpdateProfile {
  type: typeof UPDATE_PROFILE;
}

interface UpdateProfileSuccess {
  type: typeof UPDATE_PROFILE_SUCCESS;
  profile: any;
}

interface UpdateProfileFailed {
  type: typeof UPDATE_PROFILE_FAILED;
  error: any;
}

interface FetchProfileConfig {
  type: typeof FETCH_PROFILE_CONFIG;
}

interface FetchProfileConfigSuccess {
  type: typeof FETCH_PROFILE_CONFIG_SUCCESS;
  config: any;
}

interface FetchProfileConfigFailed {
  type: typeof FETCH_PROFILE_CONFIG_FAILED;
  error: any;
}

interface FetchProfile {
  type: typeof FETCH_PROFILE;
}

interface FetchProfileSuccess {
  type: typeof FETCH_PROFILE_SUCCESS;
  profile: any;
}

interface FetchProfileFailed {
  type: typeof FETCH_PROFILE_FAILED;
  error: any;
}

export type ProfileActionTypes =
  | CreateProfile
  | CreateProfileSuccess
  | CreateProfileSuccessOnSignUp
  | CreateProfileFailed
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFailed
  | StorePreferences
  | SetNoReset
  | FetchProfileConfig
  | FetchProfileConfigSuccess
  | FetchProfileConfigFailed
  | FetchProfile
  | FetchProfileSuccess
  | FetchProfileFailed;
