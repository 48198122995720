import React, { FunctionComponent } from 'react';
import classNames                   from 'classnames';
import colors                       from 'sass/utils/colors.module.scss';

import './Pill.scss';

type PillProps = {
  onClick?: any
  active:   boolean
  Icon?:    React.ReactType
}

const Pill: FunctionComponent<PillProps> = ({
  onClick, 
  active, 
  Icon, 
  children
}) => {
  return (
    <button className={classNames('c-pill', {'c-pill--active': active})} onClick={onClick}>
      { 
        Icon &&
          <Icon color={active ? colors.white : colors.deepblue} />
      }
      {children}
    </button>
  )
}

export default Pill;