import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { scrollTo } from "scroll-js";

import { isIOS, isSafari } from "react-device-detect";

import { RootState } from "store";
import { FetchProduct } from "store/inventory/actions";
import Button from "components/Button";
import CartMismatch from "components/CartMismatch";
import PDPDrawer from "components/PDP/PDPDrawer";
import AddToCart from "components/AddToCart";
import {
  PDPTopper,
  PDPPickup,
  PDPGoodFor,
  PDPProfileMatch,
  PDPBudtender,
  PDPFeelings,
  // PDPDetails,
  PDPPairsWith,
  PDPDescriptionImage,
  PDPTable,
  PDPDiscounts,
  PDPImage,
} from "components/PDP";
import "./PDP.scss";
import NotFound from "../../components/NotFound";

type TParams = { id?: string; slug?: string };

function PDP({ match: { params } }: RouteComponentProps<TParams>) {
  const productId = Number(String(params.id))
    ? parseInt(String(params.id), 10)
    : NaN;
  const dispensarySlug = params.slug || "";

  const dispatch = useDispatch();
  const isMobile = useSelector((state: RootState) => state.system.isMobile);
  const isTablet = useSelector((state: RootState) => state.system.isTablet);
  const customer = useSelector((state: RootState) => state.customer.customer);
  const recProd = useSelector(
    (state: RootState) => state.recs.recs && state.recs.recs[productId]
  );
  const inventory: any = useSelector(
    (state: RootState) => state.inventory.inventory
  );
  const inventoryErr: boolean = useSelector(
    (state: RootState) => state.inventory.inventoryError
  );
  // const inventoryLoading: boolean = useSelector(
  //   (state: RootState) => state.inventory.loadingInventory
  // );
  // const noResults: any = useSelector(
  //   (state: RootState) => state.inventory.noResults
  // );

  const recProducts: any = useSelector((state: RootState) => state.recs.recs);

  const [product, setProduct] = useState<any>(false);
  const [showShadow, setShowShadow] = useState(false);
  const [shadowStyle, setShadowStyle] = useState({});
  const [hasfeelings, setHasFeelings] = useState(false);
  const [pdpVisible, setPDPVisible] = useState(false);
  const [animateGradient] = useState(!(isIOS || isSafari));

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!pdpVisible && product) {
      setPDPVisible(true);
    }
  }, [pdpVisible, product]);

  useEffect(() => {
    let prod: any = false;
    if (inventory && Object.keys(inventory).length) {
      prod = inventory[productId];
      if (
        !prod &&
        recProducts &&
        Object.keys(recProducts).length &&
        productId
      ) {
        prod = recProducts[productId];
      }
    } else if (recProducts && Object.keys(recProducts).length && productId) {
      prod = recProducts[productId];
    }
    if (
      recProd &&
      recProd.recommendationEngineScores &&
      recProd.recommendationEngineScores.match
    ) {
      prod.recommendationEngineScores = recProd.recommendationEngineScores;
    }
    if (prod && prod.feelingData && prod.feelingData.length) {
      setHasFeelings(true);
    }
    if (!prod) {
      dispatch(FetchProduct(dispensarySlug, productId));
    }
    setProduct(prod);
  }, [inventory, recProducts, recProd, productId, dispatch, dispensarySlug]);

  useEffect(() => {
    window.addEventListener("scroll", watchScroll);
    return function __cleanup() {
      window.removeEventListener("scroll", watchScroll);
    };
  });

  function watchScroll() {
    if (!(isMobile && isTablet) && null !== cardRef.current) {
      var rect = cardRef.current.getBoundingClientRect();
      if (rect.y < 68) {
        setShowShadow(true);
        setShadowStyle({ left: rect.x - 10, width: rect.width + 20 });
      } else {
        setShowShadow(false);
      }
    }
  }

  function backToTop() {
    void scrollTo(document.documentElement, {
      top: 0,
      easing: "ease-in-out",
      duration: 750,
    });
  }

  function render404() {
    if (!product && inventoryErr) {
      return <NotFound hasLocationLink />;
    }
  }

  function renderContent() {
    return product ? (
      <>
        <main
          className={classNames("c-pdp l-main", {
            "c-pdp--shadowed": showShadow,
            "c-pdp--visible": pdpVisible,
          })}
        >
          <div className="shadow" style={shadowStyle}></div>
          <div className="l-maxwidth">
            {product && (
              <>
                {!isMobile && (
                  <div
                    className="c-pdp__topper-wrapper"
                    id="c-pdp__topper-wrapper"
                  >
                    <PDPTopper
                      gradientOnly={isTablet}
                      productId={productId}
                      dispensarySlug={dispensarySlug}
                      animateGradient={animateGradient}
                    />
                  </div>
                )}
                {isTablet && (
                  <>
                    <PDPTopper
                      noGradient={true}
                      productId={productId}
                      dispensarySlug={dispensarySlug}
                      animateGradient={animateGradient}
                    />
                  </>
                )}
                {false && isTablet && customer && (
                  <div className="c-pdp-topper__drawer c-pdp-topper__drawer--tablet-only ">
                    <span>You last ordered this product on 07/29/20</span>
                  </div>
                )}
                <div className={classNames("c-pdp__card", {})} ref={cardRef}>
                  {product?.product?.photos?.url && (
                    <PDPImage
                      productName={product?.product?.product_name}
                      photos={product?.product?.photos}
                    />
                  )}
                  {isMobile && (
                    <>
                      <PDPTopper
                        productId={productId}
                        dispensarySlug={dispensarySlug}
                        animateGradient={animateGradient}
                      />
                    </>
                  )}
                  {isMobile && (
                    <>
                      <PDPPickup
                        product={product}
                        dispensarySlug={dispensarySlug}
                      />
                      {product.discounts && product.discounts.length > 0 && (
                        <PDPDiscounts product={product} />
                      )}
                      <AddToCart
                        product={product}
                        dispensarySlug={dispensarySlug}
                      />
                    </>
                  )}
                  {product.bestFor && (
                    <PDPGoodFor goodFor={product.bestFor} animate={true} />
                  )}
                  {isTablet && (
                    <>
                      <PDPPickup
                        product={product}
                        dispensarySlug={dispensarySlug}
                      />
                      {product.discounts && product.discounts.length > 0 && (
                        <PDPDiscounts product={product} />
                      )}
                      <AddToCart
                        product={product}
                        dispensarySlug={dispensarySlug}
                      />
                    </>
                  )}
                  {product?.recommendationEngineScores?.match ? (
                    <PDPProfileMatch
                      profileMatch={
                        product.recommendationEngineScores &&
                        product.recommendationEngineScores.match
                          ? product.recommendationEngineScores.match
                          : null
                      }
                    />
                  ) : (
                    ""
                  )}

                  {false && <PDPBudtender />}
                  {hasfeelings && (
                    <PDPFeelings feelings={product.feelingData} />
                  )}
                  {/* <PDPDetails product={product} /> */}
                  {false && <PDPPairsWith />}
                  <PDPDescriptionImage product={product} />
                  <PDPTable product={product} />
                </div>
                <div className="c-pdp__back-to-top">
                  <Button variant="outlined" type="button" onClick={backToTop}>
                    Back To Top
                  </Button>
                </div>
              </>
            )}
          </div>
          <CartMismatch />
        </main>
        <PDPDrawer product={product} />
      </>
    ) : null;
  }

  return (
    <div className="c-pdp-wrapper">
      {!product && inventoryErr && render404()}
      {product?.product?.productName && (
        <Helmet>
          <title>{product?.product?.productName}</title>
        </Helmet>
      )}
      {renderContent()}
    </div>
  );
}

export default withRouter(PDP);
