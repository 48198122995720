import React, { useEffect, useRef, useState, useCallback } from 'react'
import classNames from 'classnames'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  useSelector,
  // useDispatch
} from 'react-redux'
import { Helmet } from 'react-helmet'
import { scrollTo } from 'scroll-js'

import { isIOS, isSafari } from 'react-device-detect'

import { RootState } from 'store'
import Button from 'components/Button'
// import AddToCart from 'components/AddToCart'
import {
  PDPTopper,
//   PDPPickup,
  PDPGoodFor,
  PDPProfileMatch,
  PDPBudtender,
  //   PDPFeelings,
  // PDPDetails,
  PDPPairsWith,
  PDPDescriptionImage,
  PDPTable,
//   PDPDiscounts,
  PDPImage,
} from 'components/PDP'
import './PDP.scss'
import NotFound from '../../components/NotFound'
import { setHeader } from '../../utils/auth'
import { sagaURL } from 'store/sagas'

type TParams = { id?: string; slug?: string }

function PDP({ match: { params } }: RouteComponentProps<TParams>) {
  const productId = params.id || ''
  const dispensarySlug = params.slug || ''

  //   const dispatch = useDispatch();
  const isMobile = useSelector((state: RootState) => state.system.isMobile)
  const isTablet = useSelector((state: RootState) => state.system.isTablet)
  const activeDispensary:string|null  = useSelector(( state : RootState ) => state.cart.activeDispensary);
  const isKiosk:boolean  = useSelector(( state : RootState ) => state.system?.isKiosk || false);
  const inventoryErr: boolean = useSelector((state: RootState) => state.inventory.inventoryError)
  const [product, setProduct] = useState<any>(false)
  const [showShadow, setShowShadow] = useState(false)
  const [shadowStyle, setShadowStyle] = useState({})
  //   const [hasfeelings, setHasFeelings] = useState(false);
  const [pdpVisible, setPDPVisible] = useState(false)
  const [animateGradient] = useState(!(isIOS || isSafari))
  const [packageLoading, setPackageLoading] = useState<any>(true)
  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!pdpVisible && product) {
      setPDPVisible(true)
    }
  }, [pdpVisible, product])

  const fetchResults = useCallback(async () => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', setHeader(null))
    myHeaders.append("slug", (isKiosk && activeDispensary ? activeDispensary : ''));
    myHeaders.append('Content-Type', 'application/json')
    const graphql = JSON.stringify({
      query: `query getPackageId (
            $packageId: String!
          ) {
          getPackageId(packageId: $packageId){
            data{
              labResults{
                id
                labTest
                labResultUnit
                value
              }
              description
              category
              quantityAvailable
              quantityUnits unitPrice
              packagedDate
              vendor
              alternateName
              size
              strainType
              strain
              productId
              sku
              bestForTokens
              bestForValues
              feelings
              feelingValues
              terpenes
              discounts {
                name
                type
                method
                amount
                minimumItemsRequired
                maximumUsageCount
                stackOnOtherDiscounts
              }
              product {
                janeProductId
                priceId
                photos{
                  url
                }
                description
                productId
                category
                productName
                price
                vendorName
                sku
                thcContent
                thcContentUnit
                cbdContent
                cbdContentUnit
                brandName
                flavor
                alternateName
              }
            }
          }
        }`,
      variables: { packageId: productId },
    })
    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow',
    }
    let response: any = await fetch(sagaURL, requestOptions)
    response = await response.json();
    if(response){
        setPackageLoading(false);
    }
    setProduct(response?.data?.getPackageId?.data || null)
  }, [productId, activeDispensary, isKiosk])

  useEffect(() => {
    fetchResults()
  }, [fetchResults])

  useEffect(() => {
    window.addEventListener('scroll', watchScroll)
    return function __cleanup() {
      window.removeEventListener('scroll', watchScroll)
    }
  })

  function watchScroll() {
    if (!(isMobile && isTablet) && null !== cardRef.current) {
      var rect = cardRef.current.getBoundingClientRect()
      if (rect.y < 68) {
        setShowShadow(true)
        setShadowStyle({ left: rect.x - 10, width: rect.width + 20 })
      } else {
        setShowShadow(false)
      }
    }
  }

  function backToTop() {
    void scrollTo(document.documentElement, {
      top: 0,
      easing: 'ease-in-out',
      duration: 750,
    })
  }

  function renderContent() {
    return product ? (
      <>
        <main
          className={classNames('l-main', {
            "c-pdp--shadowed": showShadow,
            // "c-pdp--visible": pdpVisible,
          })}
        >
          <div className="shadow" style={shadowStyle}></div>
          <div className="l-maxwidth c-package">
            {product && (
              <>
                {product.labResults?.length && (
                  <div className="c-package__lab-results">
                    <h2>Lab Results</h2> <br />
                    {product.labResults?.map((lt: any, index: number) => (
                      <div key={`labRes-${index}`}>
                        <p>
                          <strong>{lt.labTest} :</strong>  {lt.value} {lt?.labResultUnit === "Percentage" ? '%' : lt?.labResultUnit}
                        </p>
                        <br />
                      </div>
                    ))}
                  </div>
                )}

                <div className="c-package__topper-wrapper" id="c-pdp__topper-wrapper">
                  {!isMobile && (
                    <PDPTopper
                      //  productObj={product}
                      gradientOnly={isTablet}
                      productId={product?.productId}
                      dispensarySlug={dispensarySlug}
                      animateGradient={animateGradient}
                    />
                  )}
                  {isTablet && (
                    <>
                      <PDPTopper
                        // productObj={product}
                        noGradient={true}
                        productId={product?.productId}
                        dispensarySlug={dispensarySlug}
                        animateGradient={animateGradient}
                      />
                    </>
                  )}
                  {/* {false && isTablet && customer && (
                  <div className="c-pdp-topper__drawer c-pdp-topper__drawer--tablet-only ">
                    <span>You last ordered this product on 07/29/20</span>
                  </div>
                )} */}
                  <div className={classNames('c-package__card', {})} ref={cardRef}>
                    {product?.product?.photos?.url && (
                      <PDPImage
                        productName={product?.product?.product_name}
                        photos={product?.product?.photos}
                      />
                    )}
                    {isMobile && (
                      <>
                        <PDPTopper
                          productId={product?.productId}
                          dispensarySlug={dispensarySlug}
                          animateGradient={animateGradient}
                        />
                      </>
                    )}
                    {isMobile && (
                      <>
                        {/* <PDPPickup
                        product={product}
                        dispensarySlug={dispensarySlug}
                      />
                      {product.discounts && product.discounts.length > 0 && (
                        <PDPDiscounts product={product} />
                      )} */}
                        {/* <AddToCart product={product} dispensarySlug={dispensarySlug} /> */}
                      </>
                    )}
                    {product.bestFor && <PDPGoodFor goodFor={product.bestFor} animate={true} />}
                    {isTablet && (
                      <>
                        {/* <PDPPickup
                        product={product}
                        dispensarySlug={dispensarySlug}
                      /> */}
                        {/* {product.discounts && product.discounts.length > 0 && (
                        <PDPDiscounts product={product} />
                      )} */}
                        {/* <AddToCart product={product} dispensarySlug={dispensarySlug} /> */}
                      </>
                    )}
                    {product?.recommendationEngineScores?.match ? (
                      <PDPProfileMatch
                        profileMatch={
                          product.recommendationEngineScores &&
                          product.recommendationEngineScores.match
                            ? product.recommendationEngineScores.match
                            : null
                        }
                      />
                    ) : (
                      ''
                    )}

                    {false && <PDPBudtender />}
                    {/* {hasfeelings && (
                    <PDPFeelings feelings={product.feelingData} />
                  )} */}
                    {/* <PDPDetails product={product} /> */}
                    {false && <PDPPairsWith />}
                    <PDPDescriptionImage product={product} />
                    <PDPTable product={product} />
                  </div>
                  <div className="c-pdp__back-to-top">
                    <Button variant="outlined" type="button" onClick={backToTop}>
                      Back To Top
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* <CartMismatch /> */}
        </main>
        {/* <PDPDrawer product={product} /> */}
      </>
    ) : null
  }

  return (
    <div className="c-pdp-wrapper">
      {!product && (!packageLoading || inventoryErr) && <NotFound hasLocationLink />}
      {product?.product?.productName && (
        <Helmet>
          <title>{product?.product?.productName}</title>
        </Helmet>
      )}
      {renderContent()}
    </div>
  )
}

export default withRouter(PDP)
