import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Input } from "components/Forms/Fields";
import { NAME_MAX_LENGTH } from "components/Forms/Fields/constants";
import Button from "components/Button";
import { TermsOfService } from "./index";

interface ProfileNameProps {
  activeStep: string;
  goNext: any;
}

function ProfileName({ activeStep, goNext }: ProfileNameProps) {
  const formState = {
    ...useSelector((state: RootState) => state.form.profileCreation),
  };
  const activeStepIndex = useSelector(
    (state: RootState) => state.system.profileStepIndex
  );

  const [isPrev, setIsPrev] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const isActive = activeStep === "name";

  useEffect(() => {
    if (!formState || !formState.values) return;
    if (formState.values.firstName && formState.values.lastName) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [formState]);

  useEffect(() => {
    let timer: any = null;

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 0);
      }, 1000);
    } else {
      setIsPrev(activeStepIndex > 0);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPrev, activeStepIndex]);

  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? "c-profile__step--prev-" : "c-profile__step-"}
      timeout={1000}
      appear
    >
      <div className={classNames("c-profile__step")}>
        <div className="slug">Question 1 of 8</div>
        <h3>What is your name?</h3>
        <Field
          component={Input}
          name="firstName"
          type="text"
          label={"First Name"}
          required
          className="firstName__input"
          tabIndex={isActive ? 0 : -1}
          maxlength={NAME_MAX_LENGTH}
        />
        <Field
          component={Input}
          name="lastName"
          type="text"
          label={"Last Name"}
          required
          className="firstName__input"
          tabIndex={isActive ? 0 : -1}
          maxlength={NAME_MAX_LENGTH}
        />
        <Button
          type="button"
          disabled={disableButton}
          onClick={goNext}
          variant=""
        >
          Next
        </Button>
        <TermsOfService />
      </div>
    </CSSTransition>
  );
}

export default ProfileName;
