import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Creative: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--creative" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <g>
          <path className="i-feeling--creative--st0" stroke={color} d="M196.7,260.8c15.1-13.2,32.5-29.6,50.7-47.8c49.3-49.3,85.3-93.4,80.3-98.3c-5-5-49,31-98.3,80.3
            c-18.2,18.2-34.6,35.7-47.8,50.7L196.7,260.8z"/>
          <line className="i-feeling--creative--st0" stroke={color} x1="211.4" y1="213.4" x2="229.3" y2="231.2"/>
          <path className="i-feeling--creative--st0" stroke={color} d="M196.7,260.8c-2.5,45.8-77,49.4-94,23.5c0,0,21.6-0.3,33.5-11.6c11.9-11.3,28.2-30,45.5-27"/>
          <path fill={color} d="M142,267.1c-2,2-4,3.9-5.8,5.6c-11.9,11.3-33.5,11.6-33.5,11.6c10.6,16.1,43.6,20.8,67.5,10.9L142,267.1z"/>
        </g>
        <circle fill={color} cx="102.7" cy="245.4" r="16.3"/>
        <circle fill={color} cx="129.6" cy="207.2" r="9.4"/>
      </g>
    </svg>
  )
}

export default Creative;