import React from 'react';
import './UnsupportedBrowser.scss';

function UnsupportedBrowser() {
  return(
    <section className="c-unsupported">
      <h1 className="c-unsupported-text">Please upgrade your browser to view Forage.</h1>
    </section>
  )
}

export default UnsupportedBrowser;