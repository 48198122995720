import { 
  CREATE_PROFILE, 
  UPDATE_PROFILE, 
  STORE_PREFERENCES, 
  SET_NO_RESET, 
  FETCH_PROFILE_CONFIG, 
  FETCH_PROFILE,
  ProfileActionTypes
} from './types';

export function CreateProfile(): ProfileActionTypes {
  return {
    type: CREATE_PROFILE
  }
}

export function UpdateProfile(): ProfileActionTypes {
  return {
    type: UPDATE_PROFILE
  }
}

export function StoreProfilePreferences(preferences:any, persist:any): ProfileActionTypes {
  return {
    type: STORE_PREFERENCES,
    preferences,
    persist
  }
}

export function SetNoReset(noReset:any): ProfileActionTypes {
  return {
    type: SET_NO_RESET,
    noReset
  }
}

export function FetchProfileConfig(): ProfileActionTypes {
  return {
    type: FETCH_PROFILE_CONFIG
  }
}

export function FetchProfile(): ProfileActionTypes {
  return {
    type: FETCH_PROFILE
  }
}