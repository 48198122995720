import React          from 'react';
import classNames     from 'classnames';
import LocationIcon   from 'components/svgs/Location';
import NavigatorIcon  from 'components/svgs/Navigator';

type ResultType = {
  locator?:       boolean,
  highlighted:    boolean,
  address?:       google.maps.places.AutocompletePrediction,
  selectLocation: any
}

function PlacesSearchResult(props:ResultType) {
  const { address, highlighted,  selectLocation, locator } = props;
  function handleClick() {
    if(locator){
      selectLocation()
    } else if(address) {
      selectLocation( address.description, address.place_id, address )
    }
  }
  return (
    <>
      {
        (address || locator) &&
          <article 
            className={classNames("c-places-result", { 'c-places-result--highlighted': highlighted })} 
            onClick={handleClick}
          >
            <div className="c-places-result__content">
              <div className="c-places-result__icon">
                {
                  locator ?
                    <NavigatorIcon />
                  :
                    <LocationIcon />    
                }
              </div>
              <div className="c-places-result__address">  
                {
                  locator ?
                  <span className="body--small">Use Current Location</span>
                  :
                  <span className="body--small">{address && address.description}</span>
                }
              </div>
            </div>
          </article>
      }
    </>
    
  )

}

export default PlacesSearchResult;