import moment from 'moment';
export function AgegateValidator (values: any) {
  type Errors = {
    month:    string
    day:      string
    year:     string
    cookies:  string
  }
  let errors : Errors = { month: '', day: '', year: '', cookies: ''};
  const { month, day, year, cookies } = values;
  // Check if month is within acceptable range
  if(!cookies || cookies.length === 0){
    errors.cookies = 'invalid';
  } else {
    errors.cookies = ''
  }
  if(!month || month > 12 || month < 0){
    errors.month = 'invalid';
  } else {
    errors.month = ''
  }
  // Check if day is within acceptable range
  const month31days = [1, 3, 5, 7, 8, 10, 12];
  const month30days = [4, 6, 9, 11];
  if(
    !day || 
    day <= 0 ||
    (month31days.indexOf(parseInt(month,10)) >= 0 && day > 31) ||
    (month30days.indexOf(parseInt(month,10)) >= 0 && day > 30) ||
    (month === 2 && day > 28)
  ){
    errors.day = 'invalid';
  } else {
    errors.day = ''
  }
  // Check if year is within acceptable range
  if(!year || year > 2020 || year < 1900){
    errors.year = 'invalid';
  } else {
    errors.year = ''
  }
  return errors;
}

export function SignInValidator (values: any) {
  type Errors = {
    email:    string
    password: string
  }
  let errors : Errors = { email: '', password: '' };
  const { email, password } = values;
  const validEmail = email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  if(!validEmail){
    errors.email = 'invalid';
  }
  if(!password){
    errors.password = 'invalid';
  }
  return errors;
}

export function SignUpValidator (values: any) {
  type Errors = {
    first_name:   string
    last_name:    string
    email:        string
    password:     string
    terms_agreed: string
  }
  let errors : Errors = { email: '', password: '', first_name: '', last_name: '', terms_agreed: '' };
  const { email, password, first_name, last_name, terms_agreed } = values;
  const validEmail = email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  if(!validEmail){
    errors.email = 'invalid';
  }
  if(!password){
    errors.password = 'invalid';
  }
  if(!first_name){
    errors.first_name = 'invalid';
  }
  if(!last_name){
    errors.last_name = 'invalid';
  }
  if(!terms_agreed || terms_agreed.length === 0){
    errors.terms_agreed = 'invalid';
  }
  return errors;
}

export function ForgotPasswordValidator (values: any) {
  type Errors = {
    email: string
  }
  let errors : Errors = { email: ''};
  const { email } = values;
  const validEmail = email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  if(!validEmail){
    errors.email = 'invalid';
  }
  return errors;
}

export function ReservatoionDetailsValidator (values: any) {
  type Errors = {
    firstName:    string
    lastName:     string
    documentType: string
    documentId:   string
    medicalId:    string
    month:        string
    day:          string
    year:         string
  }
  let errors : Errors = { firstName: '', lastName: '', documentType: '', documentId: '', medicalId: '', month: '', day: '', year: '' };
  const { firstName, lastName, documentType, documentId, medicalId, month, day, year } = values;
  if(!medicalId){
    
    if(!documentType || !documentId) {
      errors.medicalId = 'invalid';
    }
  }
  if(!firstName){
    errors.firstName = 'invalid';
  }
  if(!lastName){
    errors.lastName = 'invalid';
  }
  if(!documentType){

    if((!documentId && !medicalId) || documentId) {
      errors.documentType = 'invalid';
    }
  }
  
  if(!documentId){
    if(documentType || !medicalId) {
      errors.documentId = 'invalid';
    }
  }

  if(!month || month > 12 || month < 0){
    errors.month = 'invalid';
  } else {
    errors.month = ''
  }
  // Check if day is within acceptable range
  const month31days = [1, 3, 5, 7, 8, 10, 12];
  const month30days = [4, 6, 9, 11];
  if(
    !day || 
    day <= 0 ||
    (month31days.indexOf(parseInt(month,10)) >= 0 && day > 31) ||
    (month30days.indexOf(parseInt(month,10)) >= 0 && day > 30) ||
    (month === 2 && day > 28)
  ){
    errors.day = 'invalid';
  } else {
    errors.day = ''
  }
  // Check if year is within acceptable range
  if(!year || year > 2020 || year < 1900){
    errors.year = 'invalid';
  } else {
    errors.year = ''
  }


  var yearsDiff = moment().diff(year + '-' + month + '-' + day, 'years');
  if(yearsDiff < 21 && (month && month.length > 0) && (day && day.length > 0) && (year && year.length === 4)){
    errors.day = 'invalid';
    errors.month = 'invalid';
    errors.year = 'invalid';
  }
  
  return errors;
}

export function IdSettingsValidator (values: any) {
  type Errors = {
    documentType: string
    documentId:   string
    medicalId:    string
    month:        string
    day:          string
    year:         string
  }
  let errors : Errors = { documentType: '', documentId: '', medicalId: '', month:'', day:'', year:'' };
  const { month, day, year, documentType, documentId, medicalId } = values;

  // if(!medicalId){
  //   errors.medicalId = 'invalid';
  // }
  if(!documentType && !medicalId){
    errors.documentType = 'invalid';
  }
  if(
    (!documentId && !medicalId) ||
    (documentType && !documentId)
  ){
    errors.documentId = 'invalid';
  }
  if(!month || month > 12 || month < 0){
    errors.month = 'invalid';
  } else {
    errors.month = ''
  }
  // Check if day is within acceptable range
  const month31days = [1, 3, 5, 7, 8, 10, 12];
  const month30days = [4, 6, 9, 11];
  if(
    !day || 
    day <= 0 ||
    (month31days.indexOf(parseInt(month,10)) >= 0 && day > 31) ||
    (month30days.indexOf(parseInt(month,10)) >= 0 && day > 30) ||
    (month === 2 && day > 28)
  ){
    errors.day = 'invalid';
  } else {
    errors.day = ''
  }
  // Check if year is within acceptable range
  if(!year || year > 2020 || year < 1900){
    errors.year = 'invalid';
  } else {
    errors.year = ''
  }


  var yearsDiff = moment().diff(year + '-' + month + '-' + day, 'years');
  if(yearsDiff < 21 && (month && month.length > 0) && (day && day.length > 0) && (year && year.length === 4)){
    errors.day = 'invalid';
    errors.month = 'invalid';
    errors.year = 'invalid';
  }

  return errors;
}

export function AccountSettingsValidator (values: any) {
  type Errors = {
    firstName:    string
    lastName:     string
    documentType: string
    documentId:   string
    medicalId:    string
    month:        string
    day:          string
    year:         string
  }
  let errors : Errors = { firstName: '', lastName: '', documentType: '', documentId: '', medicalId:'', month:'', day:'', year:'' };
  const { firstName, lastName, documentType, documentId, medicalId, month, day, year } = values;
  if(!firstName){
    errors.firstName = 'invalid';
  }
  if(!lastName){
    errors.lastName = 'invalid';
  }
  if(!documentType && !medicalId){
    errors.documentType = 'invalid';
  }
  if(
    (!documentId && !medicalId) ||
    (documentType && !documentId)
  ){
    errors.documentId = 'invalid';
  }
  
  if(!medicalId && (!documentType && !documentId)){
    errors.medicalId = 'invalid';
  }
  if(!month){
    errors.month = 'invalid';
  }
  const month31days = [1, 3, 5, 7, 8, 10, 12];
  const month30days = [4, 6, 9, 11];
  if(
    !day || 
    day <= 0 ||
    (month31days.indexOf(parseInt(month,10)) >= 0 && day > 31) ||
    (month30days.indexOf(parseInt(month,10)) >= 0 && day > 30) ||
    (month === 2 && day > 28)
  ){
    errors.day = 'invalid';
  }
  if(!year){
    errors.year = 'invalid';
  }

  
  var yearsDiff = moment().diff(year + '-' + month + '-' + day, 'years');
  if(yearsDiff < 21 && (month && month.length > 0) && (day && day.length > 0) && (year && year.length === 4)){
    errors.day = 'invalid';
    errors.month = 'invalid';
    errors.year = 'invalid';
  }

  return errors;
}


export function ProfileSettingsValidator (values: any) {
  type Errors = {
    firstName:    string
    lastName:     string
    dateOfBirth:  string
  }
  let errors : Errors = { firstName: '', lastName: '', dateOfBirth: ''};
  const { firstName, lastName, dateOfBirth } = values;
  if(!firstName){
    errors.firstName = 'invalid';
  }
  if(!lastName){
    errors.lastName = 'invalid';
  }

  if(!dateOfBirth || !dateOfBirth.match(/^(0[1-9]|1[012]|[1-9])[- /.](0[1-9]|[12][0-9]|3[01]|[1-9])[- /.](19|20)\d\d$/g)){
    errors.dateOfBirth = 'invalid';
  }
  
  return errors;
}