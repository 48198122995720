import React            from 'react';
import classNames       from 'classnames';
import { useSelector }  from 'react-redux';
import { RootState }  from 'store';
import Button         from 'components/Button';
import PlusStars      from 'components/svgs/PlusStars';
import './PDPProfileMatch.scss';

interface MatchProps {
  profileMatch: number | null
}

function PDPProfileMatch({ 
  profileMatch 
}: MatchProps) {
  const customer = useSelector((state: RootState) => state.customer.customer);
  
  return (
    <section className="c-pdp-match">
      <div className="c-pdp-match__inner">
        <div className="c-pdp-match__percentage">
          <div className="c-pdp-match__percentage__number">
            <span className={classNames('', {"c-pdp-match__percentage__number--grayed":!customer && !profileMatch})}>
              {
                profileMatch ? profileMatch.toFixed(0) : customer ? '73' : '??'
              }
            </span>%
          </div>
        </div>
        <div className="c-pdp-match__label">
          Recommendation Match
        </div>
        <PlusStars />
      </div>
      {
        (!customer && !profileMatch) &&
        <Button variant="default" disabled={false} type="Link" to ="/create-profile">
          Create a profile to see your match
        </Button>
      }
    </section>
  )
}

export default PDPProfileMatch;