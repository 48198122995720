import UAParser from 'ua-parser-js';

const UNSUPPORTED_BROWSERS_NAMES = ['IE'];

export class UnsupportedBrowserDetection {
  parser = new UAParser();

  get browser() {
    return this.parser.getBrowser();
  }

  isSupported() {
    let isSupported = true;

    if (!Array.prototype.some) {
      isSupported = false;
    } else {
      isSupported = !UNSUPPORTED_BROWSERS_NAMES.some(browser => browser === this.browser.name);
    }
    
    return isSupported;
  }
}
