import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Relaxed: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--relaxed" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <circle fill={color} cx="207.3" cy="206.1" r="53.6"/>
        <path className="i-feeling--relaxed--st0" stroke={color} fill="none" d="M63.2,185.3c31.2,46.3,84.1,76.8,144.1,76.8s113-30.5,144.1-76.8"/>
      </g>
    </svg>
  )
}

export default Relaxed;