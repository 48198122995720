import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootState } from 'store';
import { FetchOrderHistory } from 'store/customer/actions';
import OrderHistoryOrder from 'components/Account/OrderHistory/OrderHistoryOrder';

function OrderReceipt({
  match, 
  history
}: RouteComponentProps){
  const dispatch      = useDispatch();
  const historyOrder  = useSelector(( state : RootState ) => state.customer.orderHistory);
  const customer      = useSelector(( state : RootState ) => state.customer.customer);

  const [preorder, setPreorder] = useState<any>(false);

  const params:any        = match.params;
  const { orderId, slug } = params;

  useEffect(() => {
    if(!customer){
      history.replace({pathname:'/account/signin', state: { signInRedirect: '/account/orders/' + slug + '/' + orderId }})
    }
  }, [customer, orderId, slug, history])

  useEffect(() => {
    if(customer && !historyOrder && orderId){
      dispatch(FetchOrderHistory());
    }
    if(customer && historyOrder && !preorder){
      const receiptOrder = historyOrder.find((order: any) => order.dispensary.slug === slug && order.orderId === orderId);
      if(receiptOrder){
        setPreorder(receiptOrder);
      } else {
        history.replace('/account');
      }
    }
  }, [historyOrder, orderId, preorder, customer, slug, history, dispatch]);
  
  return (
    <main className=" l-main">
      <section className="c-order-receipt l-maxwidth l-maxwidth--small">
        <h1>Order Number: {orderId}</h1>
        <br/>
        <OrderHistoryOrder order={preorder} loading={!preorder} />
      </section>
    </main>
  )
}

export default withRouter(OrderReceipt);