import { FETCH_DISPENSARIES_SUCCESS } from 'store/locations/types'

export interface MismatchedProductProps {
  quantity: number | boolean
  dispensarySlug: string | boolean
  product: any
}

export interface CartItem {
  productId?: string | number
  quantity?: number
  discountsNames?: Array<string>
  discountsAmounts?: Array<number>
  janeProductId: any
  priceId: any
}

export interface CartPrice {
  cartItemPrices?: any
  subTotal?: number
  salesTax?: number
  taxes?: number
  discount?: number
  total?: number
}

export interface CartState {
  items: Array<CartItem>
  price: CartPrice
  activeDispensary: string | null
  activeDispensaryType: string | null
  mismatchedDispensary: boolean
  mismatchedProductToAdd?: MismatchedProductProps
  lastProductAdded: number | null
  lastProductUpdated: number | null
  lastProductRemoved: number | null
  acceptingPreorders: boolean
  medicalDispensaries: any
  recreationalDispensaries: any
  cartPriceError: boolean
  uuid: string | null
}

export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const FETCH_CART_PRICE = 'FETCH_CART_PRICE'
export const FETCH_CART_PRICE_SUCCESS = 'FETCH_CART_PRICE_SUCCESS'
export const FETCH_CART_PRICE_FAILED = 'FETCH_CART_PRICE_FAILED'
export const RESET_CART = 'RESET_CART'
export const UPDATE_CART_UUID = 'UPDATE_CART_UUID'
export const RESET_MISMATCHED = 'RESET_MISMATCHED'
export const SET_PICKUP_LOCATION = 'SET_PICKUP_LOCATION'
export const RESET_LAST_PRODUCT_ADDED = 'RESET_LAST_PRODUCT_ADDED'
export const DISPENSARIES_LOADED = FETCH_DISPENSARIES_SUCCESS
export const SET_ACTIVE_DISPENSARY = 'SET_ACTIVE_DISPENSARY'

interface AddToCart {
  type: typeof ADD_TO_CART
  product: any
  quantity: number
  dispensarySlug: string
}

interface RemoveFromCart {
  type: typeof REMOVE_FROM_CART
  productId: string | number
}

interface UpdateCartItem {
  type: typeof UPDATE_CART_ITEM
  productId: string | number
  quantity: number
}

interface ResetCart {
  type: typeof RESET_CART
}
interface UpdateCartUuid {
  type: typeof UPDATE_CART_UUID
  uuid: any
}

interface ResetMismatched {
  type: typeof RESET_MISMATCHED
}

interface FetchCartPrice {
  type: typeof FETCH_CART_PRICE
  cart: CartState
}

interface FetchCartPriceSuccess {
  type: typeof FETCH_CART_PRICE_SUCCESS
  cartPrice: any
}

interface FetchCartPriceFailed {
  type: typeof FETCH_CART_PRICE_FAILED
  error: string
}

interface SetPickupLocation {
  type: typeof SET_PICKUP_LOCATION
  pickupLocation: any
}

interface ResetLastProductAdded {
  type: typeof RESET_LAST_PRODUCT_ADDED
}

interface DispensariesLoaded {
  type: typeof DISPENSARIES_LOADED
  dispensaries: any
}

interface SetActiveDispensary {
  type: typeof SET_ACTIVE_DISPENSARY
  activeDispensaryType: string
  activeDispensary: string
}

export type CartActionTypes =
  | AddToCart
  | RemoveFromCart
  | UpdateCartItem
  | ResetCart
  | UpdateCartUuid
  | FetchCartPrice
  | FetchCartPriceSuccess
  | FetchCartPriceFailed
  | ResetMismatched
  | SetPickupLocation
  | ResetLastProductAdded
  | DispensariesLoaded
  | SetActiveDispensary
