import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Happy: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--happy" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6" >
      <g>
        <path className="i-feeling--happy--st0" stroke={color} d="M345.1,163.8c0,76.1-61.7,137.8-137.8,137.8S69.5,239.9,69.5,163.8"/>
        <circle fill={color} cx="165.6" cy="165.4" r="26.5"/>
        <circle fill={color} cx="249.5" cy="165.4" r="26.5"/>
      </g>
    </svg>

  )
}

export default Happy;