import React, { useState }  from 'react';
import classNames           from 'classnames';
import DownCaretIcon        from 'components/svgs/DownCaret';
import './Accordion.scss';
interface AccordionProps {
  label:              string
  selectedItems?:     string
  children:           any
  accordionChanged?:  any
}

function Accordion({
  label, 
  selectedItems,
  accordionChanged, 
  children
}: AccordionProps) {
  const [ isOpen, setIsOpen ] = useState(false);
  function toggleAccordion() {
    setIsOpen(!isOpen);
    if(accordionChanged){
      setTimeout(() => {
        accordionChanged();
      }, 500);
    }
  } 
  return(
    <div className={classNames("c-accordion", {'c-accordion--open': isOpen})}>
      <div className={classNames("c-accordion__toggle",{'c-accordion__toggle--hasSelected': selectedItems && !isOpen})} onClick={toggleAccordion}>
        <div className="c-accordion__toggle__inner">
          <h3 className="c-accordion__toggle__label">{label}</h3>
          <DownCaretIcon />
          {
            selectedItems &&
            <div className={classNames("c-accordion__selected", {'c-accordion__selected--display': !isOpen})}>{selectedItems}</div>
          }
        </div>
      </div>
      <div className="c-accordion__content">
        {children}
      </div>
    </div>
  )
}

export default Accordion