import {
  CartState,
  CartActionTypes,
  CartItem,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEM,
  RESET_CART,
  UPDATE_CART_UUID,
  RESET_MISMATCHED,
  FETCH_CART_PRICE,
  FETCH_CART_PRICE_SUCCESS,
  FETCH_CART_PRICE_FAILED,
  RESET_LAST_PRODUCT_ADDED,
  SET_PICKUP_LOCATION,
  DISPENSARIES_LOADED,
  SET_ACTIVE_DISPENSARY,
} from './types'

let storedItems: string | null | Array<CartItem> = localStorage.getItem('forage_cart')
if (storedItems !== '' && storedItems !== null) {
  storedItems = JSON.parse(storedItems)
}
storedItems = typeof storedItems !== 'string' && storedItems !== null ? storedItems : []
let acceptingPreorders = localStorage.getItem('forage_accepting_preorders')
let activeDispensary = localStorage.getItem('forage_cart_dispensary') || ''

let activeDispensaryType = localStorage.getItem('forage_cart_dispensary_type') || null
const initialState: CartState = {
  items: storedItems,
  price: {},
  activeDispensary: activeDispensary,
  activeDispensaryType: activeDispensaryType,
  acceptingPreorders: acceptingPreorders ? true : false,
  mismatchedDispensary: false,
  lastProductAdded: null,
  lastProductUpdated: null,
  lastProductRemoved: null,
  medicalDispensaries: null,
  recreationalDispensaries: null,
  cartPriceError: false,
  mismatchedProductToAdd: {
    quantity: false,
    dispensarySlug: false,
    product: null,
  },
  uuid: null,
}

export function cartReducer(state = initialState, action: CartActionTypes): CartState {
  switch (action.type) {
    case ADD_TO_CART: {
      const { product, quantity, dispensarySlug } = action
      const existingDispensarySlug = localStorage.getItem('forage_cart_dispensary')
      let addedItems: any
      if (
        existingDispensarySlug !== null &&
        existingDispensarySlug !== dispensarySlug &&
        state.items.length > 0
      ) {
        return {
          ...state,
          mismatchedDispensary: true,
          mismatchedProductToAdd: { product, quantity, dispensarySlug },
        }
      } else {
        if (typeof product.product.productId === 'undefined') {
          return { ...state }
        } else {
          const janeData = {
            priceId: product?.product?.priceId,
            janeProductId: product?.product?.janeProductId,
            // count :currentQuantity || null,
          }

          const itemsIndex = state.items.findIndex(
            (item) => item.productId === product.product.productId
          )
          if (itemsIndex > -1) {
            addedItems = [...state.items]
            addedItems[itemsIndex].quantity = quantity
            // JANE data added
            addedItems[itemsIndex].priceId = janeData.priceId
            addedItems[itemsIndex].janeProductId = janeData.janeProductId
          } else {
            addedItems = [
              ...state.items,
              {
                ...janeData,
                productId: product?.product?.productId,
                productName: product?.product?.productName,
                brandName: product?.product?.brandName,
                unitPrice: product.unitPrice,
                quantity: quantity,
                category: product.category,
                availableQuantity: product.quantityAvailable,
              },
            ]
          }

          localStorage.setItem('forage_cart', JSON.stringify(addedItems))
          localStorage.setItem('forage_cart_dispensary', dispensarySlug)
          const { medicalDispensaries, recreationalDispensaries } = state
          let activeDispensaryTypeArray: string[] = []

          if (medicalDispensaries.indexOf(dispensarySlug) >= 0) {
            activeDispensaryTypeArray.push('medical')
          }

          if (recreationalDispensaries.indexOf(dispensarySlug) >= 0) {
            activeDispensaryTypeArray.push('recreational')
          }

          const activeDispensaryType = activeDispensaryTypeArray.join(', ')

          localStorage.setItem('forage_cart_dispensary_type', activeDispensaryType)
          localStorage.setItem('forage_cart', JSON.stringify(addedItems))
          localStorage.setItem('forage_cart_dispensary', dispensarySlug)

          return {
            ...state,
            items: [...addedItems],
            lastProductAdded: product.product.productId,
            activeDispensary: dispensarySlug,
            mismatchedDispensary: false,
            activeDispensaryType,
          }
        }
      }
    }
    case REMOVE_FROM_CART: {
      const { productId } = action
      let items = [...state.items]
      const removeIndex = items.findIndex((item) => item.productId === productId)
      items.splice(removeIndex, 1)
      localStorage.setItem('forage_cart', JSON.stringify(items))
      return {
        ...state,
        items: [...items],
        lastProductRemoved: parseInt(String(productId), 10),
      }
    }
    case UPDATE_CART_ITEM: {
      let items = state.items
      const { productId, quantity } = action

      const existingIndex = items.findIndex((item) => item.productId === productId)

      if (items && items[existingIndex]) {
        items[existingIndex].quantity = quantity
      }

      localStorage.setItem('forage_cart', JSON.stringify(items))
      return {
        ...state,
        items: [...items],
        lastProductUpdated: parseInt(String(productId), 10),
      }
    }

    case RESET_CART: {
      localStorage.removeItem('forage_cart')
      return {
        ...state,
        uuid: null,
        mismatchedDispensary: false,
        price: {},
        items: [],
      }
    }

    case UPDATE_CART_UUID: {
      const { uuid = null } = action
      return {
        ...state,
        uuid: uuid,
      }
    }

    case RESET_LAST_PRODUCT_ADDED: {
      return {
        ...state,
        lastProductAdded: null,
        lastProductUpdated: null,
        lastProductRemoved: null,
      }
    }

    case RESET_MISMATCHED: {
      return {
        ...state,
        mismatchedDispensary: false,
      }
    }
    case FETCH_CART_PRICE: {
      return {
        ...state,
        cartPriceError: false,
      }
    }
    case FETCH_CART_PRICE_SUCCESS: {
      const { cartPrice } = action
      let updatedItems = [...state.items]
      cartPrice.cartItemPrices.forEach((itemPrice: any) => {
        const itemIndex = updatedItems.findIndex(
          (item: any) => item.productId === itemPrice.productId
        )
        const discountNames = itemPrice.discounts.map((discount: any) => discount.discountName)
        const discountAmounts = itemPrice.discounts.map((discount: any) => discount.amount)
        updatedItems[itemIndex].discountsNames = discountNames
        updatedItems[itemIndex].discountsAmounts = discountAmounts
      })
      return {
        ...state,
        price: cartPrice,
        cartPriceError: false,
      }
    }

    case FETCH_CART_PRICE_FAILED: {
      return {
        ...state,
        cartPriceError: true,
      }
    }

    case SET_PICKUP_LOCATION: {
      if (action.pickupLocation.slug) {
        let cartDispensaryTypeArray: string[] = []

        if (action.pickupLocation?.isMedical) {
          cartDispensaryTypeArray.push('medical')
        }

        if (action.pickupLocation?.isRecreational) {
          cartDispensaryTypeArray.push('recreational')
        }

        const cartDispensaryType = cartDispensaryTypeArray.join(', ')

        localStorage.setItem('forage_accepting_preorders', action.pickupLocation.acceptingPreorders)
        localStorage.setItem('forage_cart_dispensary', action.pickupLocation.slug)
        localStorage.setItem('forage_cart_dispensary_type', cartDispensaryType)
        return {
          ...state,
          activeDispensary: action.pickupLocation.slug,
          activeDispensaryType: cartDispensaryType,
          acceptingPreorders: action.pickupLocation.acceptingPreorders,
        }
      } else {
        localStorage.removeItem('forage_cart_dispensary')
        localStorage.removeItem('forage_cart_dispensary_type')
        return {
          ...state,
          activeDispensary: null,
          activeDispensaryType: null,
        }
      }
    }
    case DISPENSARIES_LOADED: {
      const { dispensaries } = action
      let medicalDispensaries: any = []
      let recreationalDispensaries: any = []
      !!dispensaries &&
        dispensaries.forEach((dispensary: any) => {
          if (dispensary?.isMedical) {
            medicalDispensaries.push(dispensary.slug)
          }
          if (dispensary?.isRecreational) {
            recreationalDispensaries.push(dispensary.slug)
          }
        })
      return {
        ...state,
        medicalDispensaries,
        recreationalDispensaries,
      }
    }

    case SET_ACTIVE_DISPENSARY: {
      const { activeDispensary } = action

      return {
        ...state,
        activeDispensary,
        activeDispensaryType: localStorage.getItem('forage_cart_dispensary_type') || null,
      }
    }

    default:
      return state
  }
}
