import { call, put, takeLatest, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import * as cartSelectors from "store/cart/selectors";

import axios from "axios";
import {
  FETCH_RECS,
  FETCH_RECS_SUCCESS,
  FETCH_RECS_FAILED,
  // FETCH_REC_COUNT,
  // FETCH_REC_COUNT_SUCCESS,
  // FETCH_REC_COUNT_FAILED,
  FETCH_REC_CONFIG,
  FETCH_REC_CONFIG_SUCCESS,
  FETCH_REC_CONFIG_FAILED,
  SET_RECOMMENDATION_PAGE,
  SET_RECOMMENDATION_SORT,
  // CUST_FETCH_RECS,
} from "./types";
import { sagaURL } from "store/sagas";
import { setAuthorizationHeader } from "../../utils/auth";
import * as customerSelectors from "../customer/selectors";

function callFetchRecs(
  preferences: any,
  slug: any,
  includeFormat: boolean,
  accessToken = null,
  page: number | null = null,
  recLimit: number | null = null,
  sort?: any
) {
  let recPreferences = { ...preferences };
  setAuthorizationHeader(accessToken);
  axios.defaults.headers.common["slug"] = slug;
  let normalizedFeelings: any = {};
  Object.keys(recPreferences.feelings).forEach((feelingId) => {
    normalizedFeelings[feelingId] = Math.round(
      recPreferences.feelings[feelingId] * 100
    );
  });
  recPreferences.feelings = normalizedFeelings;
  if (!includeFormat) {
    recPreferences.formats = {
      beverages: true,
      oil: true,
      pills: true,
      topicals: true,
      flower: true,
      vapes: true,
      edibles: true,
      spray: true,
      patch: true,
    };
  }

  const limitRow =
    includeFormat && (recPreferences?.limit || recLimit)
      ? recLimit
        ? recLimit
        : recPreferences?.limit
      : null;
  const startRow = recLimit && page ? (page === 1 ? 0 : (page - 1) * 16) : null;

  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: slug,
      },
      query:
        `query getRecommendations (
      $feelings:          FeelingsInput
      $activities:        ActivitiesInput
      $occasions:         OccasionsInput
      $formats:           FormatsInput
      $price:             PriceInput
      $isReturnInventory: Boolean
      $sortByPriceCondition: PriceSort
      $limit: Int
      $start: Int
    ) {
    getRecommendations(
      feelings:           $feelings, 
      activities:         $activities, 
      occasions:          $occasions, 
      formats:            $formats, 
      price:              $price, 
      isReturnInventory:  $isReturnInventory
      sortByPriceCondition: $sortByPriceCondition
      limit:              $limit,
      start:              $start,
    ){
      totalMatches,
      data { 
        ` +
        (includeFormat
          ? `description 
        category 
        quantityAvailable 
        quantityUnits 
        unitPrice 
        packagedDate 
        vendor 
        alternateName  
        size 
        strainType 
        strain 
        bestForTokens
        bestForValues
        feelings
        feelingValues
        terpenes
        recommendationEngineScores {
          vector
          match
        }
        discounts {
          name
          type
          method
          amount
          minimumItemsRequired
          maximumUsageCount
          stackOnOtherDiscounts
        }
        product { 
          productId
          janeProductId
          priceId 
          category 
          productName 
          vendorName 
          sku 
          thcContent 
          thcContentUnit
          cbdContent
          cbdContentUnit
          flavor
          brandName
        }`
          : `category`) +
        `}
    }
  }`,
      variables: {
        feelings: recPreferences.feelings,
        activities: recPreferences.activities,
        occasions: recPreferences.occasions,
        formats: recPreferences.formats,
        price: recPreferences.price,
        profileThcPreference: recPreferences.profileThcPreference,
        isReturnInventory: true,
        limit: limitRow,
        start: startRow,
        sortByPriceCondition:
          sort === "price_ascending"
            ? "LOW_TO_HIGH"
            : sort === "price_descending"
            ? "HIGH_TO_LOW"
            : null,
        // limit:10,
      },
    })
    .then(
      ({
        data: {
          data: {
            getRecommendations: { totalMatches, data },
          },
        },
      }) => {
        return {
          totalMatches,
          data,
        };
      }
    )
    .catch((error) => {
      throw error;
    });
}

function callFetchRecsCategory(
  preferences: any,
  slug: any,
  includeFormat: boolean,
  accessToken = null
) {
  let recPreferences = { ...preferences };
  setAuthorizationHeader(accessToken);
  axios.defaults.headers.common["slug"] = slug;
  let normalizedFeelings: any = {};
  Object.keys(recPreferences.feelings).forEach((feelingId) => {
    normalizedFeelings[feelingId] = Math.round(
      recPreferences.feelings[feelingId] * 100
    );
  });
  recPreferences.feelings = normalizedFeelings;
  if (!includeFormat) {
    recPreferences.formats = {
      beverages: true,
      oil: true,
      pills: true,
      topicals: true,
      flower: true,
      vapes: true,
      edibles: true,
      spray: true,
      patch: true,
    };
  }
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: slug,
      },
      query: `query getCategories (
      $feelings:          FeelingsInput
      $activities:        ActivitiesInput
      $occasions:         OccasionsInput
      $formats:           FormatsInput
      $price:             PriceInput
      $isReturnInventory: Boolean
      $limit: Int
    ) {
      getCategories(
      feelings:           $feelings, 
      activities:         $activities, 
      occasions:          $occasions, 
      formats:            $formats, 
      price:              $price, 
      isReturnInventory:  $isReturnInventory
      limit:              $limit,
    ){
      totalMatches,
      data { 
        category
      }
    }
  }`,
      variables: {
        feelings: recPreferences.feelings,
        activities: recPreferences.activities,
        occasions: recPreferences.occasions,
        formats: recPreferences.formats,
        price: recPreferences.price,
        profileThcPreference: recPreferences.profileThcPreference,
        isReturnInventory: true,
        limit: null,
      },
    })
    .then(
      ({
        data: {
          data: {
            getCategories: { totalMatches, data },
          },
        },
      }) => {
        return {
          totalMatches,
          data,
        };
      }
    )
    .catch((error) => {
      throw error;
    });
}

// function callFetchRecCount(preferences: any, slug: any, accessToken = null) {
//   let recPreferences = { ...preferences };
//   setAuthorizationHeader(accessToken);
//   axios.defaults.headers.common["slug"] = slug;

//   let normalizedFeelings: any = {};
//   Object.keys(recPreferences.feelings).forEach((feelingId) => {
//     normalizedFeelings[feelingId] = Math.round(
//       recPreferences.feelings[feelingId] * 100
//     );
//   });
//   recPreferences.feelings = normalizedFeelings;
//   return axios
//     .post(sagaURL, {
//       headers: {
//         "Content-Type": "application/json",
//         slug: slug,
//       },
//       query: `query getRecommendations (
//       $feelings:          FeelingsInput
//       $activities:        ActivitiesInput
//       $occasions:         OccasionsInput
//       $formats:           FormatsInput
//       $isReturnInventory: Boolean
//       $limit : Int
//     ) {
//     getRecommendations(
//       feelings:           $feelings,
//       activities:         $activities,
//       occasions:          $occasions,
//       formats:            $formats,
//       isReturnInventory:  $isReturnInventory
//       limit:              $limit,
//     ){
//       totalMatches
//     }
//   }`,
//       variables: {
//         feelings: recPreferences.feelings,
//         activities: recPreferences.activities,
//         occasions: recPreferences.occasions,
//         formats: recPreferences.formats,
//         isReturnInventory: false,
//         limit: null,
//       },
//     })
//     .then(
//       ({
//         data: {
//           data: {
//             getRecommendations: { totalMatches },
//           },
//         },
//       }) => {
//         return totalMatches;
//       }
//     )
//     .catch((error) => {
//       throw error;
//     });
// }

function callFetchRecConfig(accessToken = null) {
  setAuthorizationHeader(accessToken);
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
      },
      query: `{
      getGradientsConfig {
        aroused
        creative
        energized
        euphoric
        focused0
        focused1
        giggly
        happy
        hungry
        relaxed
        sleepy0
        sleepy1
        talkative0
        talkative1
        tingly
        uplifted
      }
    }`,
    })
    .then(
      ({
        data: {
          data: { getGradientsConfig },
        },
      }) => {
        return getGradientsConfig;
      }
    )
    .catch((error: any) => {
      throw error;
    });
}

function* fetchRecs(action: any) {
  const recPreferences: any = yield select(selectors.recPreferences);
  const activeDispensary: any = yield select(cartSelectors.activeDispensary);
  const accessToken = yield select(customerSelectors.accessToken);
  const page = null;
  const sort = yield select(selectors.selectSort) || null;
  const recLimit = 16;

  try {
    const recs = yield call(
      callFetchRecs,
      recPreferences,
      activeDispensary,
      true,
      accessToken,
      page,
      recLimit,
      sort
    );

    const recNoFormat = yield call(
      callFetchRecsCategory,
      recPreferences,
      activeDispensary,
      false,
      accessToken
    );

    yield put({ type: FETCH_RECS_SUCCESS, recs, recNoFormat });
  } catch (e) {
    console.log("FetchRecs error:", e);
    yield put({ type: FETCH_RECS_FAILED, error: e });
  }
}

function* fetchRecsPage(action: any) {
  const recPreferences: any = yield select(selectors.recPreferences);
  const activeDispensary: any = yield select(cartSelectors.activeDispensary);
  const accessToken = yield select(customerSelectors.accessToken);
  const page = yield select(selectors.selectPage) || null;
  const recLimit = 16;
  const sort = yield select(selectors.selectSort) || null;

  try {
    const recs = yield call(
      callFetchRecs,
      recPreferences,
      activeDispensary,
      true,
      accessToken,
      page,
      recLimit,
      sort
    );

    const recNoFormat = { data: [] };
    yield put({ type: FETCH_RECS_SUCCESS, recs, recNoFormat });
  } catch (e) {
    console.log("FetchRecs error:", e);
    yield put({ type: FETCH_RECS_FAILED, error: e });
  }
}

function* fetchRecConfig(action: any) {
  try {
    const accessToken = yield select(customerSelectors.accessToken);
    const recConfig = yield call(callFetchRecConfig, accessToken);
    yield put({ type: FETCH_REC_CONFIG_SUCCESS, config: recConfig });
  } catch (e) {
    console.log("FetchRecConfig error:", e);
    yield put({ type: FETCH_REC_CONFIG_FAILED, error: e });
  }
}

function* recSaga() {
  yield takeLatest(FETCH_RECS, fetchRecs);
  yield takeLatest(SET_RECOMMENDATION_PAGE, fetchRecsPage);
  yield takeLatest(SET_RECOMMENDATION_SORT, fetchRecsPage);
  // yield takeLatest(FETCH_REC_COUNT, fetchRecCount);
  yield takeLatest(FETCH_REC_CONFIG, fetchRecConfig);
}

export default recSaga;
