import React        from 'react';
import PlacesSearch from 'components/PlacesSearch';

function MoodSelectorLocation() {
  function handleSubmit(values:any) {

  }
  return(
    <section className="c-mood-selector__step c-mood-selector__step--5_5">
      <div className="c-mood-selector__step__inner">
        <div className="l-main l-maxwidth l-maxwidth--small">
          <h3>Confirm your location</h3>
          <p className="body--xsmall">Enter your address to help us find your closest dispensary</p>
          <PlacesSearch onSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  )
}

export default MoodSelectorLocation;