import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Location: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="location-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.7992 10.8365C16.7992 14.9092 11.9992 18.4001 11.9992 18.4001C11.9992 18.4001 7.19922 14.9092 7.19922 10.8365C7.19922 9.44769 7.70493 8.1158 8.60511 7.13379C9.50528 6.15178 10.7262 5.6001 11.9992 5.6001C13.2723 5.6001 14.4932 6.15178 15.3933 7.13379C16.2935 8.1158 16.7992 9.44769 16.7992 10.8365Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.9984 12.0008C12.8821 12.0008 13.5984 11.2844 13.5984 10.4008C13.5984 9.51713 12.8821 8.80078 11.9984 8.80078C11.1148 8.80078 10.3984 9.51713 10.3984 10.4008C10.3984 11.2844 11.1148 12.0008 11.9984 12.0008Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Location;