import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Euphoric: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--euphoric" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <g>
          <g>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="266.5" y1="135.1" x2="266.5" y2="178.3"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="266.5" y1="316.9" x2="266.5" y2="273.7"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="175.6" y1="226" x2="218.9" y2="226"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="357.4" y1="226" x2="314.2" y2="226"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="317.1" y1="175.6" x2="306.3" y2="186.4"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="316.9" y1="276.6" x2="306.1" y2="265.7"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="216" y1="276.4" x2="226.8" y2="265.6"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="216.1" y1="175.4" x2="226.9" y2="186.2"/>
          </g>
          <circle fill={color} cx="266.5" cy="226" r="26.8"/>
        </g>
        <g>
          <g>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="103.5" y1="96.7" x2="103.5" y2="118.8"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="103.5" y1="189.4" x2="103.5" y2="167.4"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="57.1" y1="143.1" x2="79.2" y2="143.1"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="149.9" y1="143.1" x2="127.8" y2="143.1"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="129.3" y1="117.3" x2="123.8" y2="122.9"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="129.2" y1="168.8" x2="123.7" y2="163.3"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="77.7" y1="168.8" x2="83.3" y2="163.3"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="77.8" y1="117.3" x2="83.3" y2="122.8"/>
          </g>
          <circle fill={color} cx="103.5" cy="143.1" r="13.7"/>
        </g>
        <g>
          <g>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="123.1" y1="266.5" x2="123.1" y2="278.7"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="123.1" y1="317.9" x2="123.1" y2="305.7"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="97.4" y1="292.2" x2="109.6" y2="292.2"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="148.8" y1="292.2" x2="136.6" y2="292.2"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="137.4" y1="277.9" x2="134.3" y2="281"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="137.4" y1="306.5" x2="134.3" y2="303.4"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="108.8" y1="306.5" x2="111.9" y2="303.4"/>
            <line className="i-feeling--euphoric--st0" stroke={color} x1="108.8" y1="277.9" x2="111.9" y2="281"/>
          </g>
          <circle fill={color} cx="123.1" cy="292.2" r="7.6"/>
        </g>
      </g>
    </svg>

  )
}

export default Euphoric;