import React                                          from 'react';
import { InjectedFormProps, Form, Field, reduxForm }  from 'redux-form';
import { connect, useSelector }                       from 'react-redux';
import { Link }                                       from 'react-router-dom';
import { RootState }                            from 'store';
import Button                                   from 'components/Button';
import { Input, Select, Date }                  from 'components/Forms/Fields';
import { AccountSettingsValidator as validate } from 'components/Forms/Validators';
import { ID_MAX_LENGTH, NAME_MAX_LENGTH } from "components/Forms/Fields/constants";
import './SettingsForm.scss';

function AccountSettingsForm({ 
  handleSubmit, 
  invalid, 
  change 
}: InjectedFormProps) {
  
  const saving    = useSelector(( state : RootState ) => state.customer.saving);
  const isDesktop = useSelector(( state : RootState ) => state.system.isDesktop);
  const documentTypeOptions = [
    { 
      label:'US Passport',
      value: 'PASSPORT'
    },
    { 
      label:'Drivers License',
      value: 'DRIVERS_LICENSE'
    }
  ];
  
  return(
    <Form onSubmit={handleSubmit} className="c-settings-form">
      <Field
        component={Input} 
        name="firstName" 
        type="text"
        label={"First Name"}
        required
        className="name__input"
        maxlength={NAME_MAX_LENGTH}
      />
      <Field
        component={Input} 
        name="lastName" 
        type="text"
        label={"Last Name"}
        required
        className="name__input"
        maxlength={NAME_MAX_LENGTH}
      />
      <p className="body--xsmall">Required if purchasing from recreational dispensary</p>
      <Field 
        component={Select} 
        name="documentType"
        label={"ID Document"}
        required
        isDesktop={isDesktop}
        className="idDocumentType__input"
        change={change}
        options={documentTypeOptions}
      />
      <Field
        component={Input} 
        name="documentId" 
        type="text"
        label={"ID Number"}
        required
        className="name__input"
        maxlength={ID_MAX_LENGTH}
      />
      <p className="body--xsmall">Required if purchasing from medical dispensary</p>
      <Field
        component={Input} 
        name="medicalId" 
        type="text"
        label={"Medical Card Number"}
        required
        className="name__input"
        maxlength={ID_MAX_LENGTH}
      />
      <Date />
      <Link to="/account/forgot-password" className="c-settings-form__reset-password">Reset Password</Link>
      <Button disabled={saving || invalid} type="submit" variant="settings-submit">{saving ? 'Saving' : 'Save Settings'}</Button>
      <Button disabled={saving} type="Link" to="/account" variant="outlined">Cancel</Button>
    </Form>
  )
}

export default connect(
  (state: RootState) => ({
    initialValues: state.customer.customer ? state.customer.customer : {}
  })
)(reduxForm({
  form: 'accountSettings',
  validate
})(AccountSettingsForm));;