import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector }   from 'react-redux';
import { RootState }              from 'store';
import { ResetLastProductAdded }  from 'store/cart/actions';
import Button                     from 'components/Button';
import MenuDrawer                 from 'components/MenuDrawer';

interface PDPDrawerProps { product?: any }
function PDPDrawer({
  product
}: PDPDrawerProps) {
  const dispatch            = useDispatch();
  const lastProductAdded    = useSelector((state: RootState) => state.cart.lastProductAdded ? parseInt(String(state.cart.lastProductAdded)) : null);
  const lastProductUpdated  = useSelector((state: RootState) => state.cart.lastProductUpdated ? parseInt(String(state.cart.lastProductUpdated)) : null);
  const lastProductRemoved  = useSelector((state: RootState) => state.cart.lastProductRemoved ? parseInt(String(state.cart.lastProductRemoved)) : null);

  const [displayMenu, setDisplayMenu] = useState(false);
  const [menuHeading, setMenuHeading] = useState('');

  useEffect(() => {
    if(!product) {
      if(lastProductAdded){
        setDisplayMenu(true);
        setMenuHeading('Item added to order');
        dispatch(ResetLastProductAdded());
      } else if(lastProductUpdated){
        setMenuHeading('Item quantity updated');
        setDisplayMenu(true);
        dispatch(ResetLastProductAdded());
      } else if(lastProductRemoved){
        setMenuHeading('Item removed from order');
        setDisplayMenu(true);
        dispatch(ResetLastProductAdded());
      }
    } else {
      let {product: {productId}} = product;
      productId = parseInt(String(productId), 10);
      if (productId === lastProductAdded) {
        setDisplayMenu(true);
        setMenuHeading('Item added to order');
        dispatch(ResetLastProductAdded());
      } else if (productId === lastProductUpdated) {
        setMenuHeading('Item quantity updated');
        setDisplayMenu(true);
        dispatch(ResetLastProductAdded());
      } else if (productId === lastProductRemoved) {
        setMenuHeading('Item removed from order');
        setDisplayMenu(true);
        dispatch(ResetLastProductAdded());
      }
    }
  }, [product, lastProductAdded, lastProductUpdated, lastProductRemoved, dispatch])

  function closeDrawer() {
    setDisplayMenu(false);
  }
  return (
    <MenuDrawer display={displayMenu} closeMenu={closeDrawer} position="toast" headerClass="h3" menuHeading={menuHeading}>
      <Button type="button"  onClick={closeDrawer} variant="">Keep Shopping</Button>
      <Button type="Link" to="/order" variant="outlined">View Order</Button>
    </MenuDrawer>
  )
}

export default PDPDrawer;