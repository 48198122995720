import { CartState } from "../cart/types";

export interface KioskOrdersState {
  placingOrder: boolean;
  orderPlaced: boolean;
  latestOrder: any;
  error: any;
  loadingPreorder: boolean;
  preorder: any;
  preorders: any;
}

export const KIOSK_PLACE_ORDER = "KIOSK_PLACE_ORDER";
export const KIOSK_PLACE_ORDER_SUCCESS = "KIOSK_PLACE_ORDER_SUCCESS";
export const KIOSK_PLACE_ORDER_FAILED = "KIOSK_PLACE_ORDER_FAILED";
export const KIOSK_RESET_ORDER_PLACED = "KIOSK_RESET_ORDER_PLACED";

interface KioskPlaceOrder {
  type: typeof KIOSK_PLACE_ORDER;
  cart: CartState;
}

interface KioskPlaceOrderSuccess {
  type: typeof KIOSK_PLACE_ORDER_SUCCESS;
  order: any;
}

interface KioskPlaceOrderFailed {
  type: typeof KIOSK_PLACE_ORDER_FAILED;
  error: any;
}

interface ResetOrderPlaced {
  type: typeof KIOSK_RESET_ORDER_PLACED;
}

export type KioskOrdersActionTypes =
  | KioskPlaceOrder
  | KioskPlaceOrderSuccess
  | KioskPlaceOrderFailed
  | ResetOrderPlaced;
