import React from "react";
import { Link } from "react-router-dom";

import "./Steps.scss";

export const TermsOfService = () => {
  return (
    <span className="c-terms-of-service">
      <br/>
      By creating this profile you agree to the <Link to="/terms-of-use">terms of service</Link>, <Link to={{ pathname: "https://columbia.care/privacy-policy" }} target="_blank">privacy policy</Link> and for California residents the <Link to={{ pathname: "https://columbia.care/privacy-policy#california-residents"}} target="_blank">California section</Link> of our privacy policy.
    </span>
  )
};