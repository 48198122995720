import colors from 'sass/utils/colors.module.scss';

export const feelingMap:any = {
  'energetic':  { id:'energetic', label: 'Energetic', minimumAlpha: 0.25, color: colors['energetic'] },
  'focused':    { id:'focused', label: 'Focused', minimumAlpha: 0.25, color: colors['focused'] },
  'uplifted':   { id:'uplifted', label: 'Uplifted', minimumAlpha: 0.25, color: colors['uplifted'] },
  'euphoric':   { id:'euphoric', label: 'Euphoric', minimumAlpha: 0.5, color: colors['euphoric'] },
  'talkative':  { id:'talkative', label: 'Talkative', minimumAlpha: 0.25, color: colors['talkative'] },
  'creative':   { id:'creative', label: 'Creative', minimumAlpha: 0.25, color: colors['creative'] },
  'happy':      { id:'happy', label: 'Happy', minimumAlpha: 0.25, color: colors['happy'] },
  'hungry':     { id:'hungry', label: 'Hungry', minimumAlpha: 0.5, color: colors['hungry'] },
  'giggly':     { id:'giggly', label: 'Giggly', minimumAlpha: 0.25, color: colors['giggly'] },
  'relaxed':    { id:'relaxed', label: 'Relaxed', minimumAlpha: 0.25, color: colors['relaxed'] },
  'sleepy':     { id:'sleepy', label: 'Sleepy', minimumAlpha: 0.15, color: colors['sleepy'] },
  'aroused':    { id:'aroused', label: 'Aroused', minimumAlpha: 0.25, color: colors['aroused'] },
  'tingly':     { id:'tingly', label: 'Tingly', minimumAlpha: 0.4, color: colors['tingly'] },
}