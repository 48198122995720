import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch }   from 'react-redux';
import { RootState }                                              from 'store';
import { ResetInventory, SetInventoryChanging, SetInventorySort } from 'store/inventory/actions';
import { InventoryFilterProps }                                   from 'store/inventory/types';
import { SetInventoryFilter }                                     from 'store/inventory/actions';
import Sort                                                       from './Sort';
import Filter                                                     from './Filter';
import Pill                                                       from 'components/Pill';
import FilterIcon                                                 from 'components/svgs/Filter';
import SortIcon                                                   from 'components/svgs/Sort';
import './InventoryFilterSort.scss';

function InventoryFilterSort() {
  const dispatch        = useDispatch();
  const sortSetting     = useSelector(( state : RootState ) => state.inventory.sort );
  const filterSettings  = useSelector(( state : RootState ) => state.inventory.filter);

  const [activePill, setActivePill]         = useState('');
  const [previousScroll, setPreviousScroll] = useState(0);
  const [filterCount, setFilterCount]       = useState(0);

  useEffect(() => {    
    if(activePill !== '' && previousScroll === 0){
      setPreviousScroll(window.scrollY);
      document.body.classList.add('no-scroll');
    } else if(activePill === '') {
      setPreviousScroll(0);
      document.body.classList.remove('no-scroll');
      window.scrollTo(0, previousScroll);
    }
  }, [activePill, previousScroll]);

  useEffect(() => {
    let filterCount = 0;
    const fs:any = filterSettings;
    if(fs){
      Object.keys(fs).forEach((key:string) => {
        filterCount += fs[key].length;
      });
      setFilterCount(filterCount);
    }
  }, [filterSettings]);

  function setSort(sort:string) {
    setActivePill('');
    dispatch(SetInventoryChanging(true));
    setTimeout(() => {
      dispatch(ResetInventory());
      dispatch(SetInventorySort(sort));
    }, 250);
  }

  function setFilters(filters:InventoryFilterProps){
    setActivePill('');
    dispatch(SetInventoryChanging(true));
    setTimeout(() => {
      dispatch(ResetInventory());
      dispatch(SetInventoryFilter(filters));
    }, 250);
  }


  const FilterCount = () => {
    
    return (
      <div className="c-inventory-filter-sort__filter-count">
        <span>{filterCount}</span>
      </div>
    )
  }

  return(
    <>
      <section className="c-inventory-filter-sort">
        <Pill Icon={filterCount > 0 ? FilterCount : FilterIcon} active={activePill === 'filter'} onClick={() => activePill === 'filter' ? setActivePill('') : setActivePill('filter')}>
          <span className="body--xsmall">Filter{filterCount > 1 ? 's' : ''}</span>
        </Pill>
        <Pill Icon={SortIcon} active={activePill === 'sort'} onClick={() => activePill === 'sort' ? setActivePill('') : setActivePill('sort')}>
          <span className="body--xsmall">Sort</span>
        </Pill>
      </section>
      <Sort sortSetting={sortSetting} display={activePill === 'sort'} closeSort={() => setActivePill('')} setSort={setSort} />
      <Filter filterSettings={filterSettings} display={activePill === 'filter'} closeFilters={() => setActivePill('')} setFilters={setFilters} />
    </>
  )
}

export default InventoryFilterSort;