import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Input } from "components/Forms/Fields";
import IconButton from "components/IconButton";
import { TermsOfService } from "./index";

interface ProfileUseProps {
  activeStep: string;
  goNext: any;
  change: any;
  setFormUse: any;
}

function Use({ activeStep, goNext, change, setFormUse }: ProfileUseProps) {
  const activeStepIndex = useSelector(
    (state: RootState) => state.system.profileStepIndex
  );

  const [isPrev, setIsPrev] = useState(false);

  const isActive = activeStep === "use-preference";

  useEffect(() => {
    let timer: any = null;

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 5);
      }, 1000);
    } else {
      setIsPrev(activeStepIndex > 5);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPrev, activeStepIndex]);

  function setUse(use: string) {
    change("usePreference", use);
    setFormUse(use);
    goNext();
  }

  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? "c-profile__step--prev-" : "c-profile__step-"}
      timeout={1000}
      appear
    >
      <div className={classNames("c-profile__step")}>
        <div className="slug">Question 5 of 8</div>
        <h3>How do you want to use Cannabis?</h3>
        <Field
          component={Input}
          name="usePreference"
          type="hidden"
          label={""}
          required
          className="firstName__input"
        />
        <IconButton
          tabIndex={isActive ? 0 : -1}
          onClick={() => setUse("FUN")}
          variant="select-dispensary"
          type="button"
          iconColor="charcoal"
          backgroundColor="white"
          textColor="charcoal"
          withCaret={true}
        >
          Fun
        </IconButton>
        <IconButton
          tabIndex={isActive ? 0 : -1}
          onClick={() => setUse("TREATMENT")}
          variant="select-dispensary"
          type="button"
          iconColor="charcoal"
          backgroundColor="white"
          textColor="charcoal"
          withCaret={true}
        >
          Treatment
        </IconButton>
        <IconButton
          tabIndex={isActive ? 0 : -1}
          onClick={() => setUse("BOTH")}
          variant="select-dispensary"
          type="button"
          iconColor="charcoal"
          backgroundColor="white"
          textColor="charcoal"
          withCaret={true}
        >
          Both
        </IconButton>
        <TermsOfService />
      </div>
    </CSSTransition>
  );
}

export default Use;
