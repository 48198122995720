import { call, put, takeLatest, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import axios from "axios";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEM,
  FETCH_CART_PRICE,
  FETCH_CART_PRICE_SUCCESS,
  FETCH_CART_PRICE_FAILED,
} from "store/cart/types";
import { sagaURL } from "store/sagas";
import { setAuthorizationHeader } from "../../utils/auth";
import * as customerSelectors from "../customer/selectors";

function callFetchPrice(
  cart: any,
  accessToken = null,
  activeDispensary = null
) {
  if (cart.items.length === 0) {
    return {
      total: 0,
      subTotal: 0,
      discount: 0,
      taxes: 0,
      cartItemPrices: [],
    };
  }
  const productIds = cart.items.map((item: any) =>
    parseInt(item.productId, 10)
  );

  const quantities = cart.items.map((item: any) => parseInt(item.quantity, 10));
  setAuthorizationHeader(accessToken);
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: activeDispensary,
      },
      query: `query getPriceCart($productId: [Int!]!, $quantity: [Int!]!, $customerGroups: [String!]!, $dispensarySlug: String) {
      getPriceCart(productId: $productId, quantity: $quantity, customerGroups: $customerGroups, dispensarySlug: $dispensarySlug){
        cartItemPrices{
          productId
          productName
          quantity
          subtotal
          discounts {
            id
            discountName
            discountReason
            amount
            transaction {
              transactionId
            }
          }
          tax
        }
        subTotal
        taxes
        discount
        total
      }
    }`,
      variables: {
        productId: productIds,
        quantity: quantities,
        customerGroups: ["0"],
        dispensarySlug: activeDispensary,
      },
    })
    .then(
      ({
        data: {
          data: { getPriceCart },
        },
      }) => {
        return getPriceCart;
      }
    )
    .catch((error) => {
      throw error;
    });
}

function* fetchPrice(action: any) {
  const cart = yield select(selectors.cart);
  try {
    const accessToken = yield select(customerSelectors.accessToken);
    const activeDispensary = yield select(customerSelectors.activeDispensary);

    const cartPrice = yield call(
      callFetchPrice,
      cart,
      accessToken,
      activeDispensary
    );
    yield put({ type: FETCH_CART_PRICE_SUCCESS, cartPrice });
  } catch (e) {
    yield put({ type: FETCH_CART_PRICE_FAILED, error: e });
  }
}

function* inventorySaga() {
  yield takeLatest(REMOVE_FROM_CART, fetchPrice);
  yield takeLatest(UPDATE_CART_ITEM, fetchPrice);
  yield takeLatest(ADD_TO_CART, fetchPrice);
  yield takeLatest(FETCH_CART_PRICE, fetchPrice);
}

export default inventorySaga;
