import { call, put, takeLatest, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import * as cartSelectors from "store/cart/selectors";
import * as customerSelectors from "store/customer/selectors";
import axios from "axios";
import {
  FETCH_INVENTORY,
  FETCH_INVENTORY_SUCCESS,
  FETCH_INVENTORY_FAILED,
  FETCH_PRODUCT,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILED,
  SET_SEARCH,
  SET_INVENTORY_SORT,
  SET_INVENTORY_FILTER,
  SET_INVENTORY_PAGE,
  FETCH_DISCOUNTS,
  FETCH_DISCOUNTS_SUCCESS,
  FETCH_DISCOUNTS_FAILED,
} from "store/inventory/types";
import { sagaURL } from "store/sagas";
import { setAuthorizationHeader } from "../../utils/auth";

function callFetchInventory(slug: string, searchData: any, accessToken = null) {
  let formatsMap: any = {
    vapes: false,
    flower: false,
    edibles: false,
    beverages: false,
    oil: false,
    pills: false,
    topicals: false,
    spray: false,
    patch: false,
  };

  if (searchData.filter.formats) {
    searchData.filter.formats.forEach((formatKey: string) => {
      formatsMap[formatKey] = true;
    });
  }

  setAuthorizationHeader(accessToken);
  axios.defaults.headers.common["slug"] = slug;
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: slug,
      },
      query: `
      query search (
        $data: SearchParamsInput
      ) {
        search(data: $data){
          totalMatches
          startRow
          limitRow
          data{
            id,
            description
            category
            quantityAvailable
            quantityUnits unitPrice
            packagedDate
            vendor
            alternateName
            size
            strainType
            strain
            productId
            sku
            bestForTokens
            bestForValues
            feelings
            feelingValues
            terpenes

            discounts {
              name
              type
              method
              amount
              minimumItemsRequired
              maximumUsageCount
              stackOnOtherDiscounts
            }
            product {
              janeProductId
              priceId
              photos{
                url
              }
              description
              productId
              category
              productName
              vendorName
              sku
              price
              thcContent
              thcContentUnit
              cbdContent
              cbdContentUnit
              brandName
              flavor
              alternateName
            }
          }
        }
      }`,
      variables: {
        data: {
          searchTerm: searchData.searchQuery,
          isFilterByLowInventory:
            searchData.filter.flags &&
            searchData.filter.flags.indexOf("low_inventory") >= 0
              ? true
              : false,
          isFilterByOnSale:
            searchData.filter.flags &&
            searchData.filter.flags.indexOf("on_sale") >= 0
              ? true
              : false,
          isFilterByFormat:
            searchData.filter.flags &&
            searchData.filter.flags.indexOf("new") >= 0
              ? true
              : false,
          formats: searchData.filter.formats?.length ? formatsMap : null,
          filterByPriceRangeCondition:
            searchData.filter.prices && searchData.filter.prices.length > 0
              ? searchData.filter.prices
              : null,
          filterByFeelingName:
            searchData.filter.feelings && searchData.filter.feelings.length > 0
              ? searchData.filter.feelings
              : null,
          sortByProductName:
            searchData.sort === "alpha_ascending"
              ? "ASC"
              : searchData.sort === "alpha_descending"
              ? "DESC"
              : null,
          sortByPriceCondition:
            searchData.sort === "price_ascending"
              ? "LOW_TO_HIGH"
              : searchData.sort === "price_descending"
              ? "HIGH_TO_LOW"
              : null,
          start: searchData.page === 1 ? 0 : (searchData.page - 1) * 16,
          limit: 16,
        },
      },
    })
    .then(({ data: { data } }) => {
      return {
        startRow: data.search.startRow,
        totalMatches: data.search.totalMatches,
        inventory: data.search.data,
        limitRow: data.search.limitRow,
      };
    })
    .catch((error) => {
      throw error;
    });
}

function callFetchProduct(
  slug: string,
  id: number | string,
  accessToken = null
) {
  setAuthorizationHeader(accessToken);
  axios.defaults.headers.common["slug"] = slug;
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: slug,
      },
      query: `query getProduct (
        $productId: [Int!]
      ) {
      getProduct(productId: $productId){
        lastOrder
        data{
          description
          category
          quantityAvailable
          quantityUnits unitPrice
          packagedDate
          vendor
          alternateName
          size
          strainType
          strain
          productId
          sku
          bestForTokens
          bestForValues
          feelings
          feelingValues
          terpenes
          discounts {
            name
            type
            method
            amount
            minimumItemsRequired
            maximumUsageCount
            stackOnOtherDiscounts
          }
          product {
            janeProductId
            priceId
            photos{
              url
            }
            description
            productId
            category
            productName
            price
            vendorName
            sku
            thcContent
            thcContentUnit
            cbdContent
            cbdContentUnit
            brandName
            flavor
            alternateName
          }
        }
      }
    }`,
      variables: {
        productId: [id],
      },
    })
    .then(
      ({
        data: {
          data: {
            getProduct: { data, lastOrder },
          },
        },
      }) => {
        data[0].lastOrder = lastOrder;
        return data;
      }
    )
    .catch((error) => {
      throw error;
    });
}

function callFetchDiscounts(slug: string, accessToken = null) {
  setAuthorizationHeader(accessToken);
  axios.defaults.headers.common["slug"] = slug;
  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: slug,
      },
      query: `{
      getDiscountedProducts {
        description
        category
        quantityAvailable
        quantityUnits unitPrice
        packagedDate
        vendor
        alternateName
        size
        strainType
        strain
        productId
        sku
        bestForTokens
        bestForValues
        feelings
        feelingValues
        terpenes
        discounts {
          name
          type
          method
          amount
        }
        product {
          janeProductId
          priceId
          description
          productId
          category
          productName
          vendorName
          sku
          thcContent
          thcContentUnit
          cbdContent
          cbdContentUnit
          brandName
          flavor
          alternateName
        }
      }
    }`,
    })
    .then(({ data: { data, errors } }) => {
      if (!!errors) {
        throw new Error(errors[0]?.message || null);
      }

      if (!!data && "getDiscountedProducts" in data) {
        return data.getDiscountedProducts;
      }
    })
    .catch((error) => {
      throw error;
    });
}

function* fetchInventory(action: any) {
  try {
    const slug = yield select(selectors.slug);
    if (!slug) return null;
    const accessToken = yield select(customerSelectors.accessToken);
    const searchData = {
      searchQuery: yield select(selectors.searchQuery),
      sort: yield select(selectors.sort),
      filter: yield select(selectors.filter),
      page: yield select(selectors.page),
    };
    const inventoryData = yield call(
      callFetchInventory,
      slug,
      searchData,
      accessToken
    );
    yield put({ type: FETCH_INVENTORY_SUCCESS, inventory: inventoryData });
  } catch (e) {
    console.log("FetchInventory error:", e);
    yield put({ type: FETCH_INVENTORY_FAILED, error: e });
  }
}

function* fetchProduct(action: any) {
  try {
    const accessToken = yield select(customerSelectors.accessToken);
    const product = yield call(
      callFetchProduct,
      action.slug,
      action.id,
      accessToken
    );
    yield put({ type: FETCH_PRODUCT_SUCCESS, product });
  } catch (e) {
    console.log("FetchProduct error:", e);
    yield put({ type: FETCH_PRODUCT_FAILED, error: e });
  }
}

function* fetchDiscounts(action: any) {
  try {
    const activeDispensary = yield select(cartSelectors.activeDispensary);
    const accessToken = yield select(customerSelectors.accessToken);
    let discountProducts = yield call(
      callFetchDiscounts,
      activeDispensary,
      accessToken
    );
    yield put({ type: FETCH_DISCOUNTS_SUCCESS, discountProducts });
  } catch (e) {
    console.log("Discounts error:", e);
    yield put({ type: FETCH_DISCOUNTS_FAILED, error: e });
  }
}

function* inventorySaga() {
  yield takeLatest(FETCH_INVENTORY, fetchInventory);
  yield takeLatest(FETCH_PRODUCT, fetchProduct);
  yield takeLatest(SET_SEARCH, fetchInventory);
  yield takeLatest(SET_INVENTORY_SORT, fetchInventory);
  yield takeLatest(SET_INVENTORY_FILTER, fetchInventory);
  yield takeLatest(SET_INVENTORY_PAGE, fetchInventory);
  yield takeLatest(FETCH_DISCOUNTS, fetchDiscounts);
}

export default inventorySaga;
