import {
  ProfileState,
  ProfileActionTypes,
  CREATE_PROFILE,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_SUCCESS_ONSIGNUP,
  CREATE_PROFILE_FAILED,
  STORE_PREFERENCES,
  SET_NO_RESET,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  FETCH_PROFILE_CONFIG,
  FETCH_PROFILE_CONFIG_SUCCESS,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILED,
} from "./types";

let storedProfile: any = sessionStorage.getItem("forage_profile_preferences");
let sessionProfile: any = null;
if (storedProfile) {
  storedProfile = JSON.parse(storedProfile);
  sessionProfile = {
    ...storedProfile,
  };
}

const startingProfile = sessionProfile || {
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  month: null,
  day: null,
  year: null,
  location: null,
  street: null,
  streetCont: null,
  city: null,
  state: null,
  zip: null,
  experienceLevel: null,
  howToFlower: null,
  strengthPreference: null,
  usePreference: null,
  treatmentGoals: null,
  productFormatsToTry: null,
  productFormatsToAvoid: null,
};

const initialState: ProfileState = {
  savingProfile: false,
  profileLoading: false,
  profileError: null,
  current: null,
  preferences: startingProfile,
  noReset: false,
  profileSaved: false,
  config: null,
  configLoading: false,
  hasProfile: null,
};

export function profileReducer(
  state = initialState,
  action: ProfileActionTypes
): ProfileState {
  switch (action.type) {
    case STORE_PREFERENCES: {
      if (action.persist) {
        sessionStorage.setItem(
          "forage_profile_preferences",
          JSON.stringify(action.preferences)
        );
      }
      return {
        ...state,
        preferences: action.preferences,
      };
    }

    case SET_NO_RESET: {
      return {
        ...state,
        noReset: action.noReset,
      };
    }

    case CREATE_PROFILE: {
      return {
        ...state,
        savingProfile: true,
        profileSaved: false,
        profileError: null,
      };
    }

    case CREATE_PROFILE_SUCCESS: {
      sessionStorage.removeItem("forage_profile_preferences");
      sessionStorage.removeItem("forage_profile_address");
      return {
        ...state,
        savingProfile: false,
        current: state.preferences,
        profileSaved: true,
        profileError: null,
      };
    }

    case CREATE_PROFILE_SUCCESS_ONSIGNUP: {
      sessionStorage.removeItem("forage_profile_preferences");
      sessionStorage.removeItem("forage_profile_address");
      sessionStorage.removeItem("signUpAuth");
      sessionStorage.removeItem("forage_signin_redirect");
      return {
        ...state,
        savingProfile: false,
        current: null,
        profileError: null,
        preferences: startingProfile,
      };
    }

    case CREATE_PROFILE_FAILED: {
      sessionStorage.removeItem("forage_profile_preferences");
      sessionStorage.removeItem("forage_profile_address");
      return {
        ...state,
        savingProfile: false,
        profileError: "Failed to create",
      };
    }

    case UPDATE_PROFILE: {
      return {
        ...state,
        savingProfile: true,
        profileSaved: false,
      };
    }

    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        savingProfile: false,
        profileSaved: true,
        current: state.preferences,
      };
    }

    case FETCH_PROFILE_CONFIG: {
      return {
        ...state,
        configLoading: true,
      };
    }

    case FETCH_PROFILE_CONFIG_SUCCESS: {
      return {
        ...state,
        configLoading: false,
        config: action.config,
      };
    }

    case FETCH_PROFILE: {
      return {
        ...state,
        profileLoading: true,
        profileError: null,
      };
    }

    case FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        profileLoading: false,
        current: action.profile,
        preferences: action.profile,
        profileError: null,
        hasProfile: action.profile ? true : false,
      };
    }

    case FETCH_PROFILE_FAILED: {
      return {
        ...state,
        profileLoading: false,
        profileError: action.error,
      };
    }

    default:
      return state;
  }
}
