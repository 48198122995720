import React, { useEffect } from 'react';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { CustomerSaveSettings, FetchAccountSettings, ResetSettingSaved } from 'store/customer/actions';
import  { ReservationDetailsForm } from 'components/Account';
import './ReservationDetails.scss';
function ReservationDetails({
  history, 
}: RouteComponentProps) {
  const dispatch        = useDispatch();
  const saved           = useSelector(( state : RootState ) => state.customer.settingsSaved);
  const customer        = useSelector((state: RootState) => state.customer.customer);
  const authenticated  = useSelector((state: RootState) => state.customer.authenticated);
  
  useEffect(() => {
    if(customer && !authenticated){
      dispatch(FetchAccountSettings({accessToken: customer.accessToken}))
    } else if(!customer){
      history.push('/account/signin');
    }
  }, [customer, authenticated, history, dispatch]);

  useEffect(() => {
    if(saved){
      history.push('/order');
      dispatch(ResetSettingSaved());
    }
  }, [saved, dispatch, history]);
  
  function handleSubmit(values:any) {
    values.dateOfBirth = values.month + '/' + values.day + '/' + values.year;
    dispatch(CustomerSaveSettings(values));
  }
  
  return (
    <section className={classNames("l-main c-reservation-details", {'c-siginin--signing-in': false })}>
      <div className="l-maxwidth l-maxwidth--small">
        <h1>Add pick up details to continue checkout</h1>
        <p>We’ll need to verify your identity at the dispensary when you come to pick up</p>
        <div className="c-reservation-details__form">
          {
            (customer && !customer.readyToLoad) &&
              <ReservationDetailsForm onSubmit={handleSubmit} />
          }
        </div>
      </div>
    </section>
  )
}

export default withRouter(ReservationDetails);