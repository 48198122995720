import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { CartPrice } from "store/cart/types";
import "./OrderTotals.scss";

interface NoramlizedPrice {
  cartItemPrices?: any;
  subTotal?: string | number | null;
  salesTax?: string | number | null;
  taxes?: string | number | null;
  discount?: string | number | null;
  total?: string | number | null;
}

interface OrderTotalsProps {
  totals?: any;
}
function OrderTotals({ totals }: OrderTotalsProps) {
  const cartPrices: CartPrice = useSelector(
    (state: RootState) => state.cart.price
  );

  const [normalizedPrices, setNormalizedPrices] = useState<NoramlizedPrice>({});

  useEffect(() => {
    const pricesNormalized = {
      cartItemPrices: cartPrices.cartItemPrices,
      discount: cartPrices.discount ? cartPrices.discount.toFixed(2) : null,
      subTotal: cartPrices.subTotal ? cartPrices.subTotal.toFixed(2) : null,
      total: cartPrices.total ? cartPrices.total.toFixed(2) : null,
      salesTax: cartPrices.salesTax
        ? cartPrices.salesTax.toFixed(2)
        : cartPrices.taxes
        ? cartPrices.taxes.toFixed(2)
        : null,
    };
    setNormalizedPrices(pricesNormalized);
  }, [cartPrices]);

  useEffect(() => {
    if (!totals) {
      return;
    }
    const pricesNormalized = {
      discount: totals.discount ? totals.discount.toFixed(2) : null,
      subTotal: totals.subTotal ? totals.subTotal.toFixed(2) : null,
      total: totals.total ? totals.total.toFixed(2) : null,
      salesTax: totals.salesTax
        ? totals.salesTax.toFixed(2)
        : totals.taxes
        ? totals.taxes.toFixed(2)
        : null,
    };
    setNormalizedPrices(pricesNormalized);
  }, [totals]);

  return (
    <div className="c-order-totals">
      <div className="c-order-totals__subtotals">
        <div className="c-order-totals__row">
          <span className="slug">SUBTOTAL</span>
          <span className="c-order-totals__row__price">
            ${normalizedPrices.subTotal || 0}
          </span>
        </div>
        {normalizedPrices.discount && (
          <div className="c-order-totals__row">
            <span className="slug">Discount</span>
            <span className="c-order-totals__row__price">
              -${normalizedPrices.discount}
            </span>
          </div>
        )}
        <div className="c-order-totals__row">
          <span className="slug">TAX</span>
          <span className="c-order-totals__row__price">
            ${normalizedPrices.salesTax || 0}
          </span>
        </div>
      </div>
      <div className="c-order-totals__total">
        <div className="c-order-totals__row">
          <span className="slug">Total</span>
          <span className="c-order-totals__row__price">
            ${normalizedPrices.total || 0}
          </span>
        </div>
      </div>
    </div>
  );
}

export default OrderTotals;
