import React, { useEffect, useState } from 'react';
import classNames                     from 'classnames';
import BackArrowIcon                  from 'components/svgs/BackArrow';

interface KnobProps {
  noArrows: boolean,
  feelings: any
}
function Knob({
  noArrows, 
  feelings
}: KnobProps) {

  const [currentLabel, setCurrentLabel]   = useState('');
  
  useEffect(() => {
    if(feelings.length === 0 ) return;
    const topFeeling = feelings[0].feeling.replace(/[0-9]/g, '');
    setCurrentLabel(topFeeling);
  }, [feelings]);

  return(
    <div 
      className={
        classNames("c-mood-selector__drag-knob", {
          'c-mood-selector__drag-knob--no-arrows' : noArrows, 
          'c-mood-selector__drag-knob--visible': true
        })
      } 
    >
      <svg version="1.1" x="0px" y="0px" viewBox="-0.5 -0.5 118 118" className={classNames("c-mood-selector__drag-knob__labels")}>
        <path fill="#FFF" d="M89.44,13.28L89.44,13.28c0-0.01-27.65-18.87-60.51-0.6C28.1,13,27.32,13.44,26.64,14c0,0,0,0,0,0l0,0
          c-1.81,1.49-2.97,3.74-2.97,6.27c0,4.48,3.63,8.11,8.11,8.11c1.34,0,2.61-0.33,3.73-0.91l0,0c0,0,0.01-0.01,0.02-0.01
          c0.36-0.19,0.71-0.41,1.03-0.65c4.57-2.71,23.54-12.31,44.7,0.66l0.09-0.16c1.18,0.67,2.55,1.07,4.01,1.07
          c4.48,0,8.11-3.63,8.11-8.11C93.46,17.29,91.84,14.69,89.44,13.28z"/>
        <path fill="none" id="SVGID_x5F_1_x5F_" d="M12.35,63.77c0-25.49,20.66-46.15,46.15-46.15s46.15,20.66,46.15,46.15"/>
        <text textAnchor="middle" className="c-mood-selector__drag-knob__labels__current">
          <textPath  xlinkHref="#SVGID_x5F_1_x5F_" startOffset="50%">
            <tspan  style={{fontSize:'10.5px'}}>{currentLabel}</tspan>
          </textPath>
        </text>
        <circle fill="none" stroke="#fff" cx="58.5" cy="58.5" r="58" className="c-mood-selector__drag-knob__circle" />
      </svg>
      <div className="c-mood-selector__drag-knob__arrows">
        <span className="c-mood-selector__drag-knob__arrows--top"><BackArrowIcon variant="white" /></span>
        <span className="c-mood-selector__drag-knob__arrows--right"><BackArrowIcon variant="white" /></span>
        <span className="c-mood-selector__drag-knob__arrows--bottom"><BackArrowIcon variant="white" /></span>
        <span className="c-mood-selector__drag-knob__arrows--left"><BackArrowIcon variant="white" /></span>
      </div>
    </div>
  )
}

export default Knob;