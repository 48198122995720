import React from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FooterConstants, FooterLinkType } from "constants/footer";
import { RootState } from "store";
import { ResetRecs } from "store/recommendations/actions";
import Logo from "components/svgs/Logo";
import "./Footer.scss";

function Footer() {
  const moodSelectorOpen = useSelector(
    (state: RootState) => state.system.moodSelectorOpen
  );
  const profileOpen = useSelector(
    (state: RootState) => state.system.profileOpen
  );
  const dispatch = useDispatch();

  const { footerLinks } = FooterConstants;

  function resetHome() {
    dispatch(ResetRecs());
  }

  return (
    <footer
      className={classNames("c-footer", {
        "c-footer--hidden": moodSelectorOpen || profileOpen,
      })}
    >
      <div className="c-footer__inner">
        <Link aria-label="FORAGE Homepage" to="/" onClick={resetHome}>
          <Logo variant="charcoal" />
        </Link>
        <div className="c-footer__links">
          <div className="c-footer__links__column">
            {footerLinks.slice(0, 3).map((link: FooterLinkType) => {
              switch (link.type) {
                case 'external': return (
                  <Link to={{ pathname: link.path }} target="_blank">
                    {link.label}
                  </Link>
                )
                default: return (
                  <Link key={link.path} to={link.path}>
                    {link.label}
                  </Link>
                )
            }})}
          </div>
          <div className="c-footer__links__column">
            {footerLinks.slice(3, 6).map((link: FooterLinkType) => {
              switch (link.type) {
                case 'external': return (
                  <Link to={{ pathname: link.path }} target="_blank">
                    {link.label}
                  </Link>
                )                
                default: return (
                  <Link key={link.path} to={link.path}>
                    {link.label}
                  </Link>
                )
            }})}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
