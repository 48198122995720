import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import './OrderConfirmation.scss';
import moment from 'moment';
import CloseIcon from 'components/svgs/Close';
import CheckCircleIcon from 'components/svgs/CheckCircle';
import DetailsTable from 'components/DetailsTable';
import Button from 'components/Button';
import { CustomerLogout } from 'store/customer/actions';
import { ResetOrderPlaced } from 'store/orders/actions';
import { ResetCart } from 'store/cart/actions';
import { getAddress, getClosingTime } from 'utils/maps';
import { capitalizeFirstLetter } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { FetchPreorder } from 'store/orders/actions';

interface OrderConfirmationMatchProps {
  orderId?:         number | string
  dispensarySlug?:  string
}

function OrderConfirmation({
  match, 
  history
}: RouteComponentProps) {
  const dispatch = useDispatch();
  
  const dispensaries              = useSelector((state:RootState) => state.locations.dispensaries);
  const customer:any              = useSelector((state:RootState) => state.customer.customer);
  const lastOrderName:string|null = useSelector((state:RootState) => state.customer.lastOrderName);
  const loadingPreorder           = useSelector((state: RootState) => state.orders.loadingPreorder);
  const isKiosk                   = useSelector((state:RootState) => state.system.isKiosk);
  const orderPlaced               = useSelector((state:RootState) => state.orders.orderPlaced);
  let statePreorder               = useSelector((state:RootState) => state.orders.preorder);

  const [pickupLocation, setPickupLocation] = useState<any>(false);
  const [pickupTime, setPickupTime]         = useState<any>(false);
  const [preorder, setPreorder]             = useState<any>(false);
  const [closingTime, setClosingTime]       = useState('--:--');
  const [timeToLogout, setTimeToLogout]     = useState(60);
  const [orderReset, setOrderReset]         = useState(false);

  const params:OrderConfirmationMatchProps  = match.params;
  const orderId:any                         = params.orderId;
  const slug:any                            = params.dispensarySlug;

  if(!customer){
    history.replace('/');
  }
  
  const reasonDetailsRow = {
      label:'REASON',
      value: !!preorder?.rejectedReason && capitalizeFirstLetter(preorder?.rejectedReason)
  };

  const detailsRows:any = [
    {
      label:'PICKUP NAME',
      value: lastOrderName ? lastOrderName : (preorder) ? preorder.customer.name : null
    },
    {
      label:'ORDER NUMBER',
      value: orderId
    },
    {
      label:'ORDER TOTAL',
      value: preorder ? '$' +  preorder.total : ''
    }
  ];

  useEffect(() => {
    if(orderPlaced){
      dispatch(ResetCart());
    }
  }, [orderPlaced, dispatch]);

  useEffect(() => {
    if(!orderReset && preorder){
      setOrderReset(true);
      dispatch(ResetOrderPlaced());
    }
  }, [orderReset, dispatch, preorder])

  useEffect(() => {
    let logoutInterval : any;
    let ttl = 60;
    if(isKiosk){
      logoutInterval = setInterval(() => {
        if(ttl === 1){
          dispatch(CustomerLogout());
          history.replace('/');
        }
        ttl--;
        setTimeToLogout(ttl);
      }, 1000);
    }
    return () => clearInterval(logoutInterval);
  }, [isKiosk, dispatch, history])

  useEffect(() => {
    if(orderId && !loadingPreorder && !statePreorder){
      dispatch(FetchPreorder(orderId, slug));
    }
  }, [orderId, preorder, loadingPreorder, statePreorder, slug, dispatch])

  useEffect(() => {
    if(statePreorder && statePreorder.orderId === orderId){
      setPreorder(statePreorder);
    }
  }, [preorder, statePreorder, orderId]);
  
  useEffect(() => {
    if(preorder){
      
      setPickupTime(getPickupTime());
    }
    function getPickupTime() {
      if(dispensaries){
        const location = dispensaries[slug];
        const closingTime = getClosingTime(location);
        const closingHour = parseInt(closingTime.split(':')[0]);
        if(isKiosk){
          const orderPlacedMoment = moment(preorder.orderDate).add(5,'minutes');
          return 'Your order will be ready at ' + orderPlacedMoment.hour() + ':' +  orderPlacedMoment.minute();
        } else {
          const orderPlacedMoment = moment(preorder.orderDate).add(2,'hours');

          let hour = orderPlacedMoment.hour();
          let ampm = hour >= 12 ? 'pm' : 'am';
              hour = hour > 12 ? hour - 12 : hour;
          let minute: string|number = orderPlacedMoment.minute();
              minute = Math.ceil(minute / 15) * 15;
          if(minute === 60){
            hour++;
            minute = '00';
          }
          if(hour >= closingHour){
            return closingTime + ', ' + orderPlacedMoment.format('dddd MMMM D yyyy');
          } else {
            return hour + ':' + minute + ampm + ', ' + orderPlacedMoment.format('dddd MMMM D yyyy')
          }
        }
      }
    }

  }, [preorder, dispensaries, slug, isKiosk])

  useEffect(() => {
    if(preorder && dispensaries){
      const location = dispensaries[slug];
      setPickupLocation(location);
      setClosingTime(getClosingTime(location));
    }
  }, [preorder, dispensaries, slug]);

  function logMeOut() {
    dispatch(CustomerLogout());
    history.replace('/');
  }

  return (
    <section className="l-main c-order-confirmation">
    {
      preorder && 
      <>
        <div className="c-order-confirmation__top">
          <div className="l-maxwidth l-maxwidth--small">
            <Link to="/" className="c-order-confirmation__close">
              <CloseIcon variant="charcoal" /> <span>Close</span>
            </Link>
            <h1>
              <span>
                {
                  preorder.status === 'Filled' ?
                    'Your order is ready for pickup'
                    :
                    'Your order has been'
                }
                {
                  preorder.status === 'Filled'?
                    ''
                  : ['Received','In Progress'].indexOf(preorder.status) !== -1 ?
                    ' placed'
                    : preorder.status === 'Rejected' ?
                    ' cancelled'
                    :
                    ' ' + (preorder.status && preorder.status.toLowerCase())
                }
              </span>
              { 
                preorder.status === 'Rejected' 
                  ? <CheckCircleIcon negation />
                  : <CheckCircleIcon variant="green" />
              }
            </h1>
            <DetailsTable 
              rows={preorder?.status === 'Rejected' && preorder?.rejectedReason ? [reasonDetailsRow, ...detailsRows] : detailsRows}
            />
          </div>
        </div>
        <div className="c-order-confirmation__bottom">
          <div className="l-maxwidth l-maxwidth--small">
            <h3>Please check your email for confirmation before going to pickup.</h3>
            <div className="c-order-confirmation__pickup-details">
              <div className="c-order-confirmation__pickup-details__row">
                <div className="slug">{preorder.status === 'Completed' ? 'Picked Up From' : 'Pickup From' }</div>
                {
                  pickupLocation &&
                    <a href={pickupLocation.googlePlace.googlePlaceLink} rel="noopener noreferrer" className="c-order-confirmation__pickup-details__value" target="_blank">
                      {pickupLocation.name}<br/>
                      {getAddress(pickupLocation)}
                    </a>
                }
              </div>
              <div className="c-order-confirmation__pickup-details__row">
                <div className="slug">Estimated Pickup Time</div>
                <div className="c-order-confirmation__pickup-details__value">
                    {pickupTime}
                </div>
                <div className="c-order-confirmation__pickup-details__note">
                  Orders will be held until close of business today, which is {closingTime} for this dispensary
                </div>
              </div>
              <div className="c-order-confirmation__pickup-details__row">
                <div className="c-order-confirmation__pickup-details__value c-order-confirmation__pickup-details__value--directions">
                  When you arrive at the dispensary, please check-in with the attendant before asking for assistance. You will need valid proof of age and identity and your selected method of payment.
                </div>
              </div>
            </div>
            {
              isKiosk ?
              <Button type="button" onClick={logMeOut} variant="view-account">Logout in {timeToLogout}s</Button>
              : <Button type="Link" to="/account" variant="view-account">View your account</Button>
            }
            <Button type="Link" to="/" variant="outlined">Back To Forage</Button>
          </div>
        </div>
    </>
    }
    </section>
  )
}

export default withRouter(OrderConfirmation);