import React, { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import CheckmarkIcon from "components/svgs/Checkmark";

import { RootState } from "store";
import { SetRecFinderPreference } from "store/recommendations/actions";
import { SetRecPreferenceAdjustment } from "store/recommendations/actions";
import "./Steps.scss";
import Button from "../../../Button";
import { CSSTransition } from "react-transition-group";
import { recPreferencesLimit } from "constants/recommendations";

type StepProps = {
  adjustment?: boolean;
  goToResults?: () => void;
  nextStep?: () => void;
};
export type Ref = HTMLDivElement;
const Step3 = forwardRef<Ref, StepProps>(
  ({ adjustment = false, goToResults, nextStep }, ref) => {
    const dispatch = useDispatch();
    const existingActivities = useSelector((state: RootState) =>
      adjustment
        ? state.recs.recPreferencesAdjustments.activities
        : state.recs.recPreferences.activities
    );

    const [selectedActivities, setSelectedActivities] = useState<any>(
      existingActivities
    );
    const [activityPreferenceMap, setActivityPreferenceMap] = useState(
      existingActivities
    );

    const activities = [
      { id: "partying", label: "Partying" },
      { id: "creating", label: "Creating" },
      { id: "working", label: "Working" },
      { id: "relaxing", label: "Relaxing" },
      { id: "sleeping", label: "Sleeping" },
      { id: "focusing", label: "Focusing" },
      { id: "talking", label: "Talking" },
      { id: "hookingUp", label: "Hooking Up" },
      // {id:'medicating', label: 'Medicating'},
      { id: "managingPain", label: "Managing Pain" },
      { id: "recovering", label: "Recovering" },
    ];

    useEffect(() => {
      setSelectedActivities(existingActivities);
    }, [existingActivities]);

    function selectActivities(acitivity: any) {
      const newActivities: any = { ...activityPreferenceMap };
      newActivities[acitivity.id] = !newActivities[acitivity.id];
      setActivityPreferenceMap({ ...newActivities });
      if (adjustment) {
        dispatch(
          SetRecPreferenceAdjustment("activities", { ...newActivities })
        );
      } else {
        dispatch(SetRecFinderPreference("activities", { ...newActivities }));
        dispatch(SetRecFinderPreference("limit", recPreferencesLimit));
      }
    }

    return (
      <div className="c-mood-selector__step c-mood-selector__step--3">
        <div className="c-mood-selector__step__inner" ref={ref}>
          <div className="l-main l-maxwidth l-maxwidth--small">
            <header className="c-mood-selector__header">
              <h1>What will you be using it for?</h1>
              <p className="c-mood-selector__question-progress">
                Question 3 of 5
              </p>
            </header>
            <div className="c-mood-selector__activities">
              {activities.map((activity: any) => {
                return (
                  <button
                    key={adjustment ? "adjustment-" + activity.id : activity.id}
                    onClick={() => selectActivities(activity)}
                    className={classNames("c-mood-selector__activity", {
                      "c-mood-selector__activity--selected":
                        selectedActivities[activity.id],
                    })}
                  >
                    <span>{activity.label}</span>
                    <div className="c-mood-selector__activity__check">
                      <CheckmarkIcon variant="white" />
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <CSSTransition
          classNames="c-mood-selector__action"
          timeout={500}
          appear
          in
        >
          <div
            className={classNames(
              "c-mood-selector__standard-action c-mood-selector__standard-action__step"
            )}
          >
            <Button type="button" onClick={nextStep} variant="">
              Next
            </Button>
            <Button type="button" onClick={goToResults} variant="outlined">
              View Recommendations
            </Button>
          </div>
        </CSSTransition>
      </div>
    );
  }
);

export default Step3;
