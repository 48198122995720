import React, { useRef, useState }  from 'react';
import classNames                   from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { RootState }      from 'store';
import { SetDrawerOpen }  from 'store/system/actions';
import { ApplyRecPrefernceAdjustment, ResetRecPrefernceAdjustment, ResetRecs, SetRecPreferenceAdjustment, SetRecsChanging } from 'store/recommendations/actions';
import MenuDrawer     from 'components/MenuDrawer';
import Accordion      from 'components/Accordion';
import Step2          from 'components/Home/MoodSelector/Steps/Step2';
import Step3          from 'components/Home/MoodSelector/Steps/Step3';
import Step4          from 'components/Home/MoodSelector/Steps/Step4';
import Step5          from 'components/Home/MoodSelector/Steps/Step5';
import Button         from 'components/Button';
import CheckmarkIcon  from 'components/svgs/Checkmark';
import './Adjust.scss';
interface AdjustMoodSelectorProps {
  adjustOpen:boolean,
}
function AdjustMoodSelector(props: AdjustMoodSelectorProps) {
  const { adjustOpen } = props;
  const dispatch      = useDispatch();
  const step2Ref      = useRef<HTMLDivElement>(null);
  const step3Ref      = useRef<HTMLDivElement>(null);
  const step4Ref      = useRef<HTMLDivElement>(null);
  const step5Ref      = useRef<HTMLDivElement>(null);

  const existingPrices = useSelector((state:RootState) => state.recs.recPreferencesAdjustments.price);

  const [priceSettings, setPriceSettings] = useState(existingPrices);
  const [changeCount, setChangeCount]     = useState(0);
  
  function showResults() {
    dispatch(SetRecsChanging(true));
    dispatch(SetDrawerOpen(false));
    setTimeout(() => {
      dispatch(ResetRecs());
      dispatch(ApplyRecPrefernceAdjustment());
    }, 250);
  }

  function closeAdjust() {
    dispatch(SetDrawerOpen(false));
    setTimeout(() => {
      dispatch(ResetRecPrefernceAdjustment());
    }, 500);
  }

  function accordionChanged() {
    setChangeCount(changeCount+1);
  }

  function selectPrice(priceKey:string) {
    const newPrices = {...priceSettings};
    newPrices[priceKey] = !newPrices[priceKey];
    setPriceSettings(newPrices);
    dispatch(SetRecPreferenceAdjustment('price', {...newPrices}));
  }

  const stickyAction = () => {
    return(
      <Button type="button" onClick={showResults} variant="">Show Results</Button>
    )
  }
  return(
    <MenuDrawer 
      display={adjustOpen} 
      closeMenu={closeAdjust} 
      menuHeading={'Adjust Recommendations'} 
      className="adjust-recommendations" 
      stickyActions={stickyAction()}
      sizeChange={changeCount}
      closeButton={true}
    >
      <Accordion label="Feelings" accordionChanged={accordionChanged}>
        <Step2 adjustment={true} ref={step2Ref} />
      </Accordion>
      <Accordion label="Activity" accordionChanged={accordionChanged}>
        <Step3 adjustment={true} ref={step3Ref} />
      </Accordion>
      <Accordion label="Occasion" accordionChanged={accordionChanged}>
        <Step4 adjustment={true} ref={step4Ref} />
      </Accordion>
      <Accordion label="Format" accordionChanged={accordionChanged}>
        <Step5 adjustment={true} ref={step5Ref} />
      </Accordion>
      <Accordion label="Price Range" accordionChanged={accordionChanged}>
        <div className="c-price-selectors">
            {
              Object.keys(priceSettings).map((priceKey:string) => (
                <div key={'adjust-' + priceKey} className={classNames("c-price-selectors__price c-mood-selector__format", { 'c-price-selectors__price--active': priceSettings[priceKey]})} onClick={() => selectPrice(priceKey)}>
                  <span className="c-price-selectors__price__label">
                      {
                        priceKey === 'low' ?
                        '$'
                        : priceKey === 'medium' ?
                        '$$'
                        : 
                        '$$$'
                      }
                  </span>
                  <CheckmarkIcon variant="white" />
                </div>
              ))
            }
        </div>
      </Accordion>
    </MenuDrawer> 

  )
}

export default AdjustMoodSelector;