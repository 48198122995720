import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Sleepy: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--tingly" version="1.1" x="0px" y="0px" viewBox="0 0 414.6 414.6">
      <g>
        <rect x="67.4" y="218.4" className="i-feeling--tingly--st0" stroke={color} fill={color} width="279.8" height="13.8"/>
        <path className="i-feeling--tingly--st1" stroke={color} d="M67.4,182.4c10,0,10,14,20,14c10,0,10-14,20-14c10,0,10,14,20,14c10,0,10-14,20-14c10,0,10,14,20,14
          c10,0,10-14,20-14c10,0,10,14,20,14c10,0,10-14,20-14c10,0,10,14,20,14c10,0,10-14,20-14c10,0,10,14,20,14c10,0,10-14,20-14
          c10,0,10,14,20,14s10-14,20-14"/>
      </g>
    </svg>
  )
}

export default Sleepy;