import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const Lock: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.71875 5.5H2.71875C2.16647 5.5 1.71875 5.94772 1.71875 6.5V10C1.71875 10.5523 2.16647 11 2.71875 11H9.71875C10.271 11 10.7188 10.5523 10.7188 10V6.5C10.7188 5.94772 10.271 5.5 9.71875 5.5Z" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.71875 5.5V3.5C3.71875 2.83696 3.98214 2.20107 4.45098 1.73223C4.91982 1.26339 5.55571 1 6.21875 1C6.88179 1 7.51768 1.26339 7.98652 1.73223C8.45536 2.20107 8.71875 2.83696 8.71875 3.5V5.5" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Lock;