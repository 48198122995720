import React, { FunctionComponent } from 'react';
import { Link }                     from 'react-router-dom';
import LinkOutIcon                  from 'components/svgs/LinkOut';
import './Button.scss';

interface ButtonProps {
  type:       "submit" | "button" | "Link" | "a"
  variant:    string
  disabled?:  boolean
  onClick?:   any
  to?:        string
  style?:     any
}

const Button: FunctionComponent<ButtonProps> = ({
  type, 
  disabled, 
  variant, 
  onClick, 
  to, 
  children, 
  style
}) => {
  let buttonClass = 'c-button';
  if(variant){
    buttonClass += ' c-button--' + variant;
  }
  return (
    <>
    {
      type === 'a' ?
        <a style={style} className={buttonClass} href={to ? to : '/'} target="_blank" rel="noopener noreferrer">
          <span className="cta--primary">{children}</span>
          <LinkOutIcon  />
        </a>
      : type === "Link" && onClick ?
        <Link style={style} className={buttonClass} to={to ? to : '/'} onClick={onClick}>
          <span className="cta--primary">{children}</span>
        </Link>
      : type === "Link" ?
        <Link style={style} className={buttonClass} to={to ? to : '/'}>
          <span className="cta--primary">{children}</span>
        </Link>
      :
        <button style={style} disabled={disabled} className={buttonClass} type={type} onClick={onClick}>
          <span className="cta--primary">{children}</span>
        </button>
    }
    </>
  )
}

export default Button;