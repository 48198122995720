import React, { FunctionComponent } from 'react';
import { Link }                     from 'react-router-dom';
import LinkOutIcon    from 'components/svgs/LinkOut';
import RightCaretIcon from 'components/svgs/RightCaret';
import colors         from 'sass/utils/colors.module.scss';
import './IconButton.scss';

type IconButtonProps = {
  type:             "submit" | "button" | "Link" | "a"
  Icon?:            any
  Image?:           string
  variant:          string
  disabled?:        boolean
  onClick?:         any
  to?:              any
  style?:           any
  iconColor?:       string
  withCaret?:       boolean
  backgroundColor?: string
  textColor?:       string
  replace?:         boolean
  caretColor?:      string
  tabIndex?:        number
}

const IconButton: FunctionComponent<IconButtonProps> = ({
  type, 
  disabled, 
  variant, 
  onClick, 
  to, 
  Icon, 
  Image, 
  children, 
  replace = false, 
  caretColor="charcoal", 
  style={}, 
  iconColor='charcoal', 
  textColor='white', 
  withCaret = false, 
  backgroundColor = 'charcoal',
  tabIndex = 0
}) => {
  let buttonClass = 'c-icon-button';
  if(variant){
    buttonClass += ' c-icon-button--' + variant;
  }
  const bgColor = colors[backgroundColor];
  const color = colors[textColor];
  style['backgroundColor'] = bgColor;
  style['color'] = color;

  return (
    <>
    {
      type === 'a' ?
        <a style={style} tabIndex={tabIndex} className={buttonClass} href={to ? to : '/'} target="_blank" rel="noopener noreferrer">
          <div className="c-icon-button__icon">
            {
              Icon &&
                <Icon variant={iconColor}/>
            }
            { Image &&
              <img src={Image} alt="icon" />
            }
          </div>
          <span className="cta--primary">{children}</span>
          <LinkOutIcon  />
        </a>
      : type === "Link" ?
        <Link style={style} tabIndex={tabIndex} onClick={onClick ? onClick : () => {}} className={buttonClass} to={to ? to : '/'} replace={replace}>
          <div className="c-icon-button__icon">
            {
              Icon &&
                <Icon variant={iconColor}/>
            }
            { Image &&
              <img src={Image} alt="icon" />
            }
          </div>
          <span className="cta--primary">{children}</span>
          { 
            withCaret &&
            <RightCaretIcon variant={caretColor} />
          }
        </Link>
      :
        <button tabIndex={tabIndex} style={style} disabled={disabled} className={buttonClass} type={type} onClick={onClick}>
          <div className="c-icon-button__icon">
            {
              Icon &&
                <Icon variant={iconColor}/>
            }
            { Image &&
              <img src={Image} alt="icon" />
            }
          </div>
          <span className="cta--primary">{children}</span>
          { 
            withCaret &&
            <RightCaretIcon variant={caretColor} />
          }
        </button>
    }
    </>
  )
}

export default IconButton;