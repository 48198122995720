import {
  SET_AGE_GATE_PASSED,
  SET_GEOLOCATION_DISABLED,
  SET_GEOLOCATION_DISMISSED,
  SET_GEOLOCATING,
  SET_MAPS_READY,
  SET_SIGNIN_REDIRECT,
  SET_IS_KIOSK,
  SET_DEVICE_SIZE,
  SET_DRAWER_OPEN,
  SET_MOBILE_NAV_OPEN,
  SET_MOOD_SELECTOR_OPEN,
  SET_MOOD_SELECTOR_STEP,
  SET_WINDOW_HEIGHT,
  SystemActionTypes,
  SET_PROFILE_OPEN,
  SET_PROFILE_STEP,
  SET_RESET_KIOSK_TIMER,
  SET_FORAGE_EMBED,
  SET_FORAGE_EMBED_DISPENSARY_TYPES,
  EmbedDispensariesProps,
} from './types'

export function SetAgeGatePassed(dob: string): SystemActionTypes {
  return {
    type: SET_AGE_GATE_PASSED,
    dob,
  }
}

export function SetGeoLocationDisabled(): SystemActionTypes {
  return {
    type: SET_GEOLOCATION_DISABLED,
  }
}

export function SetGeoLocating(geoLocating: boolean): SystemActionTypes {
  return {
    type: SET_GEOLOCATING,
    geoLocating: geoLocating,
  }
}

export function SetMapsReady(): SystemActionTypes {
  return {
    type: SET_MAPS_READY,
  }
}

export function SetSigninRedirect(redirect: string): SystemActionTypes {
  return {
    type: SET_SIGNIN_REDIRECT,
    redirect,
  }
}

export function SetIsKiosk(isKiosk: boolean): SystemActionTypes {
  return {
    type: SET_IS_KIOSK,
    isKiosk,
  }
}

export function SetDeviceSize(deviceSize: string): SystemActionTypes {
  return {
    type: SET_DEVICE_SIZE,
    deviceSize,
  }
}

export function SetDrawerOpen(isOpen: boolean): SystemActionTypes {
  return {
    type: SET_DRAWER_OPEN,
    isOpen,
  }
}

export function SetMobileNavigationOpen(isOpen: boolean): SystemActionTypes {
  return {
    type: SET_MOBILE_NAV_OPEN,
    isOpen,
  }
}

export function SetGeoLocationDismissed(isDismissed: boolean): SystemActionTypes {
  return {
    type: SET_GEOLOCATION_DISMISSED,
    isDismissed,
  }
}

export function SetMoodSelectorIsOpen(isOpen: boolean): SystemActionTypes {
  return {
    type: SET_MOOD_SELECTOR_OPEN,
    isOpen,
  }
}

export function SetMoodSelectorStep(step: number): SystemActionTypes {
  return {
    type: SET_MOOD_SELECTOR_STEP,
    step,
  }
}

export function SetProfileIsOpen(isOpen: boolean): SystemActionTypes {
  return {
    type: SET_PROFILE_OPEN,
    isOpen,
  }
}

export function SetProfileStep(step: string): SystemActionTypes {
  return {
    type: SET_PROFILE_STEP,
    step,
  }
}

export function SetWindowHeight(height: number): SystemActionTypes {
  return {
    type: SET_WINDOW_HEIGHT,
    height,
  }
}

export function SetResetKioskTimer(reset: boolean): SystemActionTypes {
  return {
    type: SET_RESET_KIOSK_TIMER,
    reset,
  }
}

export function SetForageEmbed(isForageEmbed: boolean): SystemActionTypes {
  return {
    type: SET_FORAGE_EMBED,
    isForageEmbed,
  }
}

export function SetForageEmbedDispensaryTypes(
  embedDispensaries: EmbedDispensariesProps
): SystemActionTypes {
  return {
    type: SET_FORAGE_EMBED_DISPENSARY_TYPES,
    embedDispensaries,
  }
}
