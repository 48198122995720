import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import Pagination from "react-js-pagination";
import { scrollTo } from "utils";
import { SetRecommendationPage } from "store/recommendations/actions";
// import UseRecommendation from "../hooks/useRecommendation";
import UseSystem from "../hooks/useSystem";

function RecommendationPagination() {
  // const { recsCount } = UseRecommendation();
  const dispatch = useDispatch();
  const isMobile = UseSystem();
  const totalInventory = useSelector(
    (state: RootState) => state.recs.totalRecProducts
  );
  const page = useSelector((state: RootState) => state.recs.page);
  const limit = 16;
  const startRow = page === 1 ? 0 : (page - 1) * 16;
  const [totalPages, setTotalPages] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<any>(1);

  useEffect(() => {
    if (totalInventory === null || startRow === null) return;
    const current = startRow === 0 ? 1 : Math.ceil(startRow / limit + 1);
    const total = Math.ceil(totalInventory / limit);
    setTotalPages(total);
    setCurrentPage(current);
  }, [totalInventory, startRow]);

  function changePage(pageNumber: number) {
    scrollTo(document.documentElement, 0, 500);
    scrollTo(document.body, 0, 500);
    if (pageNumber === currentPage) {
      return false;
    }
    setTimeout(() => {
      setCurrentPage(pageNumber);
      dispatch(SetRecommendationPage(pageNumber));
    }, 750);
  }

  return (
    <div className="c-inventory-pagination">
      {totalPages && totalPages > 1 && currentPage && totalInventory ? (
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={limit}
          totalItemsCount={totalInventory}
          pageRangeDisplayed={isMobile ? 5 : 10}
          onChange={changePage}
          lastPageText="⟩⟩"
          firstPageText="⟨⟨"
          hideFirstLastPages={
            isMobile
              ? Math.ceil(totalInventory / limit) <= 5
              : Math.ceil(totalInventory / limit) <= 10
          }
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default RecommendationPagination;
