import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const CheckCircle: FunctionComponent<IconType> = ({ variant = "charcoal", negation = false }) => {
  const color = colors[variant];
  
  const renderNegativeCheckmark = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="11" fill="#CF3605"/>
      <path d="M7.76388 14.7392L14.7582 7.89434" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.745 14.763L7.8033 7.86477" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const renderPositiveCheckmark = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <circle cx="11" cy="11" r="11" fill={color} />
      <path d="M7 11.5L9.66667 14L15 9" stroke={colors['white']} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  
  return negation ? renderNegativeCheckmark() : renderPositiveCheckmark();
};

export default CheckCircle;