// import React, { useState, useRef, useEffect } from 'react';
import React            from 'react';
import classNames       from 'classnames';
import { SelectProps }  from './types';
import DownCaretIcon    from 'components/svgs/DownCaret';
import './Fields.scss';

const renderField = ({
  input,
  label,
  type,
  className,
  meta,
  isDesktop,
  options,
  allowEmpty= true,
  change
}: SelectProps ) => {
  const {active, touched, invalid, dirty, submitFailed, initial } = meta;
  const showInvalid = (invalid && touched) || submitFailed;
  return (
    <>
    {
      // isDesktop ?
      // <DesktopDropdown options={options} className={className} showInvalid={showInvalid} label={label} input={input} meta={meta} change={change} />
      // :
      <div className={classNames('form-control form-control--text form-control--select ' + className, {'form-control--invalid': showInvalid, 'form-control--active': active, 'form-control--dirty': (dirty||initial)})}>
        <div className="form-control__content">
          <select className="h3 form-control__input" {...input} type={type}
           id={input.name}
           >
            {allowEmpty ? <option value=""></option> : null}
            {
              options.map((option)=> (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))
            }
          </select>
          {
            label &&
              <label className="form-control__label" htmlFor={input.name}>{label}</label>
          }
          <DownCaretIcon variant="charcoal" />
        </div>
      </div>
    }
    </>
  )
}
export default renderField;
