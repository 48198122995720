import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
const Search: FunctionComponent<IconType> = ({ color = '#102981'}) => {
  return(
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.5" cy="11" r="5.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 18.5L15 15.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Search;