import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Pills: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--pills" version="1.1" x="0px" y="0px" viewBox="0 0 51.6 58.5">
      <g>
        <path className="i-product-type--pills--st0" stroke={color} d="M37.2,49V21.3l-4-9.3"/>
        <path className="i-product-type--pills--st0" stroke={color} d="M4.5,12l-4,9.3v29.9c0,3.7,3,6.7,6.7,6.7h26.9"/>
        <polyline className="i-product-type--pills--st0" stroke={color} points="21.8,49 5.1,49 5.1,25.8 20,25.8 	"/>
        <path className="i-product-type--pills--st0" stroke={color} d="M33,12H3.9c-1,0-1.9-0.8-1.9-1.9V2.4c0-1,0.8-1.9,1.9-1.9H33c1,0,1.9,0.8,1.9,1.9v7.8C34.8,11.2,34,12,33,12z"
          />
        <line className="i-product-type--pills--st0" stroke={color} x1="7.5" y1="4.6" x2="7.5" y2="7.9"/>
        <line className="i-product-type--pills--st0" stroke={color} x1="13" y1="4.6" x2="13" y2="7.9"/>
        <line className="i-product-type--pills--st0" stroke={color} x1="18.4" y1="4.6" x2="18.4" y2="7.9"/>
        <line className="i-product-type--pills--st0" stroke={color} x1="23.9" y1="4.6" x2="23.9" y2="7.9"/>
        <line className="i-product-type--pills--st0" stroke={color} x1="29.4" y1="4.6" x2="29.4" y2="7.9"/>
        <line className="i-product-type--pills--st0" stroke={color} x1="23.3" y1="25.8" x2="37.2" y2="25.8"/>
        <g>
          <line className="i-product-type--pills--st0" stroke={color} x1="21.8" y1="41" x2="21.8" y2="44.3"/>
          <path className="i-product-type--pills--st0" stroke={color} d="M21.8,21.9c0,0,1.7,2.1,2.3,8.4c0.6,6.3-2.3,10.7-2.3,10.7s9.7-0.8,11.5-11.4c0,0-6.5,3.8-9.8,7.4"/>
          <path className="i-product-type--pills--st0" stroke={color} d="M21.8,21.9c0,0-1.7,2.1-2.3,8.4C18.8,36.6,21.8,41,21.8,41s-9.7-0.8-11.5-11.4c0,0,6.5,3.8,9.8,7.4"/>
          <path className="i-product-type--pills--st0" stroke={color} d="M25.4,42.3c-1.1-0.2-2.3-0.6-3.6-1.4"/>
          <path className="i-product-type--pills--st0" stroke={color} d="M11.8,40.8c0,0,4.2,3.6,9.9,0.2"/>
        </g>
      </g>
      <g>
        <path className="i-product-type--pills--st0" stroke={color} d="M46.6,49H33.7c-2.5,0-4.5,2-4.5,4.5v0c0,2.5,2,4.5,4.5,4.5h12.9c2.5,0,4.5-2,4.5-4.5v0
          C51.1,51,49.1,49,46.6,49z"/>
        <line className="i-product-type--pills--st0" stroke={color} x1="40.2" y1="49" x2="40.2" y2="57.7"/>
      </g>
      <g>
        <path className="i-product-type--pills--st0" stroke={color} d="M34.6,48.8l1.2-1.2c1.7-1.7,1.7-4.6,0-6.3l0,0c-1.7-1.7-4.6-1.7-6.3,0l-9.1,9.1c-1.7,1.7-1.7,4.6,0,6.3l0,0
          c1.7,1.7,4.6,1.7,6.3,0l2.6-2.6"/>
        <line className="i-product-type--pills--st0" stroke={color} x1="24.9" y1="45.8" x2="30" y2="50.9"/>
      </g>
    </svg>

  )
}

export default Pills;