import React, {FunctionComponent} from 'react';
import colors from 'sass/utils/colors.module.scss';
import { IconType } from 'components/svgs/types';
const RightArrow: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.507628 5.11157L18.8788 5.11157" stroke={color} />
      <path d="M14.3244 8.21279L19 5.11157L14.3244 2.01035" stroke={color} />
    </svg>
  )
}

export default RightArrow;