export const FooterConstants = {
  footerLinks: [
    {
      'label': 'Support',
      'path': '/support',
      'type': 'internal'
    },
    {
      'label': 'Contact Us',
      'path': '/contact-us',
      'type': 'internal'
    },
    {
      'label': 'About Forage',
      'path': '/about-forage',
      'type': 'internal'
    },
    {
      'label': 'Terms of Use',
      'path': '/terms-of-use',
      'type': 'internal'
    },
    {
      'label': 'Privacy Policy',
      'path': 'https://columbia.care/privacy-policy',
      'type': 'external'
    },
    {
      'label': 'Cookie Policy',
      'path': '/cookie-policy',
      'type': 'internal'
    }
  ]
}

export type FooterLinkType = {
  label:  string,
  path:   string,
  type:   string
};