import React, { useEffect, useState } from "react";
import { reset } from "redux-form";
import classNames from "classnames";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { CreateProfile } from "store/profile/actions";
import Button from "components/Button";
import CheckCircleIcon from "components/svgs/CheckCircle";

interface ProfileSuccessProps extends RouteComponentProps {
  activeStep: string;
  goNext: any;
}

function ProfileSuccess({ activeStep, goNext, history }: ProfileSuccessProps) {
  const dispatch = useDispatch();
  const activeStepIndex = useSelector(
    (state: RootState) => state.system.profileStepIndex
  );
  const customer = useSelector((state: RootState) => state.customer.customer);
  const profileData = useSelector(
    (state: RootState) => state.profile.preferences
  );
  const profileCreated = useSelector(
    (state: RootState) => state.profile.profileSaved
  );
  const profileError = useSelector(
    (state: RootState) => state.profile.profileError
  );

  const [isPrev, setIsPrev] = useState(false);
  const [profileSaved, setProfileSaved] = useState(false);

  const isActive = activeStep === "success";

  useEffect(() => {
    if (profileCreated) {
      sessionStorage.removeItem("forage_profile_preferences");
      dispatch(reset("profileCreation"));
    }
  }, [profileCreated, dispatch]);

  useEffect(() => {
    if (isActive && !profileCreated && !profileError && !profileData.street) {
      // history.replace("/");
    }
  }, [profileCreated, profileError, profileData, isActive, history]);

  useEffect(() => {
    let timer: any = null;

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 9);
      }, 1000);
    } else {
      setIsPrev(activeStepIndex > 9);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPrev, activeStepIndex]);

  useEffect(() => {
    if (isActive && !profileSaved && customer) {
      setProfileSaved(true);
      dispatch(CreateProfile());
    }
  }, [isActive, profileSaved, customer, profileData, dispatch]);

  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? "c-profile__step--prev-" : "c-profile__step-"}
      timeout={1000}
      appear
    >
      <div className={classNames("c-profile__step")}>
        {profileCreated ? (
          <>
            <div className="slug">FINISH YOUR PROFILE</div>
            <h2>
              That's your profile{" "}
              <span className="title-message">
                set-up <CheckCircleIcon variant="green" />
              </span>
            </h2>
            <Button
              type="Link"
              to="/account/profile"
              onClick={goNext}
              variant=""
            >
              View Profile
            </Button>
          </>
        ) : profileError ? (
          <>
            <div className="slug">ERROR</div>
            <p className="body--small">
              An error occurred. If this issue persists, please contact{" "}
              <a
                href="mailto:support@forage.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@forage.io
              </a>
              .
            </p>
          </>
        ) : null}
      </div>
    </CSSTransition>
  );
}

export default withRouter(ProfileSuccess);
