import axios from "axios";

const USERNAME = "forage";

const AUTHENTICATION_SCHEME = {
  BASIC: "Basic",
  BEARER: "Bearer",
};

const API_SHARED_SECRET = process.env.REACT_APP_API_SHARED_SECRET || "";

export const getToken = () => btoa(`${USERNAME}:${API_SHARED_SECRET}`);

export const setAuthorizationHeader = (accessToken?: string | null) => {
  const scheme = accessToken
    ? AUTHENTICATION_SCHEME.BEARER
    : AUTHENTICATION_SCHEME.BASIC;
  const token = accessToken ? accessToken : getToken();

  axios.defaults.headers.common["Authorization"] = `${scheme} ${token}`;
};

export const setHeader = (accessToken?: string | null) => {
  const scheme = accessToken
    ? AUTHENTICATION_SCHEME.BEARER
    : AUTHENTICATION_SCHEME.BASIC;
  const token = accessToken ? accessToken : getToken();
  return `${scheme} ${token}`;
};
