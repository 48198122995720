import React, { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import {
  SetRecFinderPreference,
  SetRecPreferenceAdjustment,
} from "store/recommendations/actions";
import CheckmarkIcon from "components/svgs/Checkmark";
import ProductIcon from "components/ProductIcon";
import "./Steps.scss";
import { FetchProfile, FetchProfileConfig } from "store/profile/actions";
import Button from "../../../Button";
import { CSSTransition } from "react-transition-group";
import { recPreferencesLimit } from "constants/recommendations";

type StepProps = {
  adjustment?: boolean;
  goToResults?: () => void;
  nextStep?: () => void;
};

export type Ref = HTMLDivElement;
const Step5 = forwardRef<Ref, StepProps>(
  ({ adjustment, goToResults, nextStep }, ref) => {
    const dispatch = useDispatch();
    const existingFormats = useSelector((state: RootState) =>
      adjustment
        ? state.recs.recPreferencesAdjustments.formats
        : state.recs.recPreferences.formats
    );
    const recs = useSelector((state: RootState) => state.recs.recsAllFormats);
    const moodSelectorStep = useSelector(
      (state: RootState) => state.system.moodSelectorStep
    );
    const currentProfile = useSelector(
      (state: RootState) => state.profile.current
    );
    const customer = useSelector((state: RootState) => state.customer.customer);
    const profileConfig = useSelector(
      (state: RootState) => state.profile.config
    );
    const profileConfigLoading = useSelector(
      (state: RootState) => state.profile.configLoading
    );

    const [formats, setFormats] = useState<any>(false);
    const [selectedFormats, setSelectedFormats] = useState<any>(false);
    const [disabledFormats, setDisabledFormats] = useState<any>({});
    const [determineDisabled, setDetermineDisabled] = useState(true);
    const [preferenceApplied, setPreferenceApplied] = useState<boolean>(false);

    useEffect(() => {
      if (!profileConfig && !profileConfigLoading) {
        dispatch(FetchProfileConfig());
      }
    }, [profileConfig, profileConfigLoading, dispatch]);

    useEffect(() => {
      if (customer && customer.accessToken && !currentProfile) {
        dispatch(FetchProfile());
      } else if (
        currentProfile &&
        !preferenceApplied &&
        formats &&
        !adjustment
      ) {
        setPreferenceApplied(true);
        const { productFormatsToAvoid, productFormatsToTry } = currentProfile;
        if (productFormatsToTry) {
          let selectFormats: any = { ...selectedFormats };
          productFormatsToTry.forEach((format: string) => {
            selectFormats[format] = true;
          });
          setSelectedFormats({ ...selectFormats });
          dispatch(SetRecFinderPreference("formats", { ...selectFormats }));
          dispatch(SetRecFinderPreference("limit", recPreferencesLimit));
        }
        if (productFormatsToAvoid) {
          let existingFormats = [...formats];
          productFormatsToAvoid.forEach((format: string) => {
            const formatToRemoveIndex = existingFormats.findIndex(
              (existingFormat: any) => existingFormat.id === format
            );
            existingFormats.splice(formatToRemoveIndex, 1);
          });
          const cbdIndex = existingFormats.findIndex(
            (existingFormat: any) => existingFormat.id === "cbd"
          );
          existingFormats.splice(cbdIndex, 1);
          setFormats([...existingFormats]);
        } else {
          let existingFormats = [...formats];
          const cbdIndex = existingFormats.findIndex(
            (existingFormat: any) => existingFormat.id === "cbd"
          );
          existingFormats.splice(cbdIndex, 1);
          setFormats([...existingFormats]);
        }
      } else if (formats && !preferenceApplied) {
        setPreferenceApplied(true);
        let existingFormats = [...formats];
        const cbdIndex = existingFormats.findIndex(
          (existingFormat: any) => existingFormat.id === "cbd"
        );
        existingFormats.splice(cbdIndex, 1);
        setFormats([...existingFormats]);
      }
    }, [
      customer,
      currentProfile,
      preferenceApplied,
      formats,
      selectedFormats,
      adjustment,
      dispatch,
    ]);

    useEffect(() => {
      if (profileConfig && profileConfig.formats && !formats) {
        const nameMap: any = {
          beverages: "Drink",
          oil: "Dab",
          topicals: "Topically",
          flower: "Smoke",
          edibles: "Edibly",
          patch: "Wear",
        };
        setFormats(
          profileConfig.formats.map((format: any) => {
            return {
              id: format?.slug || "",
              label: nameMap[format?.slug] ? nameMap[format.slug] : format.name,
            };
          })
        );
      }
    }, [profileConfig, formats]);

    useEffect(() => {
      if (!recs || recs.length === 0 || !determineDisabled) {
        return;
      }
      let disabledMap = {
        beverages: true,
        oil: true,
        pills: true,
        topicals: true,
        flower: true,
        vapes: true,
        edibles: true,
        spray: true,
        patch: true,
      };

      Object.keys(recs).forEach((recKey: any) => {
        const rec = recs[recKey];
        const cat = rec.category;
        if (cat.indexOf("BEV") >= 0) {
          disabledMap.beverages = false;
        } else if (cat.indexOf("EDB") >= 0) {
          disabledMap.edibles = false;
        } else if (cat.indexOf("FLW") >= 0) {
          disabledMap.flower = false;
        } else if (cat.indexOf("CNC") >= 0) {
          disabledMap.oil = false;
        } else if (cat.indexOf("VPR") >= 0) {
          disabledMap.vapes = false;
        } else if (cat.indexOf("TPC") >= 0) {
          if (cat.toLowerCase().indexOf("patch") >= 0) {
            disabledMap.patch = false;
          } else {
            disabledMap.topicals = false;
          }
        } else if (cat.indexOf("ORL") >= 0) {
          if (
            cat.toLowerCase().indexOf("spray") >= 0 ||
            cat.toLowerCase().indexOf("nasal") >= 0
          ) {
            disabledMap.spray = false;
          } else if (cat.toLowerCase().indexOf("capsules") >= 0) {
            disabledMap.pills = false;
          }
        }
      });
      setDisabledFormats(disabledMap);
      setDetermineDisabled(false);
    }, [recs, moodSelectorStep, determineDisabled]);

    useEffect(() => {
      if (!selectedFormats) {
        setSelectedFormats(existingFormats);
      }
    }, [existingFormats, selectedFormats]);

    function selectFormat(format: any) {
      let existingFormats = { ...selectedFormats };
      existingFormats[format.id] = !existingFormats[format.id];
      setSelectedFormats({ ...existingFormats });
      if (adjustment) {
        dispatch(SetRecPreferenceAdjustment("formats", { ...existingFormats }));
      } else {
        dispatch(SetRecFinderPreference("formats", { ...existingFormats }));
        dispatch(SetRecFinderPreference("limit", 16));
      }
    }

    return (
      <div className="c-mood-selector__step c-mood-selector__step--5" ref={ref}>
        <div className="c-mood-selector__step__inner" ref={ref}>
          <div className="l-main l-maxwidth l-maxwidth--small">
            <header className="c-mood-selector__header">
              <h1>How do you want to use it?</h1>
              <p className="c-mood-selector__question-progress">
                Question 5 of 5
              </p>
            </header>
            <div className="c-mood-selector__formats">
              {formats &&
                formats.map((format: any, index: number) => {
                  return (
                    <button
                      key={
                        adjustment
                          ? "adjustment-" + format.id + index
                          : format.id + index
                      }
                      onClick={() => selectFormat(format)}
                      className={classNames("c-mood-selector__format", {
                        "c-mood-selector__format--selected":
                          selectedFormats[format.id] &&
                          !disabledFormats[format.id],
                        "c-mood-selector__format--disabled":
                          disabledFormats[format.id],
                      })}
                    >
                      <div className="c-mood-selector__format__icon">
                        <ProductIcon iconId={format.id} />
                      </div>
                      <span>{format.label}</span>
                      <CheckmarkIcon variant="white" />
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
        <CSSTransition
          classNames="c-mood-selector__action"
          timeout={500}
          appear
          in
        >
          <div
            className={classNames(
              "c-mood-selector__standard-action c-mood-selector__standard-action__step"
            )}
          >
            <Button type="button" onClick={nextStep} variant="">
              Next
            </Button>
            <Button type="button" onClick={goToResults} variant="outlined">
              View Recommendations
            </Button>
          </div>
        </CSSTransition>
      </div>
    );
  }
);

export default Step5;
