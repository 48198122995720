import React, { useEffect, useRef, useState } from 'react';
import classNames                             from 'classnames';
import { useDispatch }                        from 'react-redux';
import { CSSTransition }                      from 'react-transition-group';
import { InventoryFilterProps } from 'store/inventory/types';
import { SetDrawerOpen }        from 'store/system/actions';
import FilterForm               from './FilterForm';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import '../FiltersSort.scss';
import './Filters.scss';
interface FilterProps {
  display:        boolean
  closeFilters:   any
  setFilters:     any
  filterSettings: InventoryFilterProps
}
function Filter({ 
  display, 
  closeFilters, 
  setFilters, 
}:FilterProps) {
  const dispatch = useDispatch();

  const [showFilters, setShowFilters]         = useState(false);
  const [filterMaxHeight, setFilterMaxHeight] = useState(100000);
  const [includeFilters, setIncludeFilters]   = useState(false);
  
  const sizerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contentElement = document.querySelector('.c-inventory-filters-sort__content');

    if (contentElement) {
      if (showFilters) {
        disableBodyScroll(contentElement);
      } else {
        enableBodyScroll(contentElement);
      }
    }

    return () => clearAllBodyScrollLocks();
  }, [showFilters]);

  useEffect(() => {
    if(sizerRef.current !== null){
      setFilterMaxHeight((window.outerHeight || window.innerHeight) + 16);
    }
  }, [sizerRef]);

  useEffect(() => {
    let hideTimeout:any;
    if(display){
      dispatch(SetDrawerOpen(true));
    } else {
      hideTimeout = setTimeout(() => {
        dispatch(SetDrawerOpen(false));
      }, 1000);
    }
    return function __cleanup() {
      clearTimeout(hideTimeout);
    }
  }, [display, dispatch]);

  useEffect(() => {
    let displayTimeout:any;
    if(display){
      setIncludeFilters(true);
      setShowFilters(true);
    } else {
      setShowFilters(false);
      displayTimeout = setTimeout(() => {
        setIncludeFilters(false);
      }, 1000);
    }

    return function __cleanup() {
      clearTimeout(displayTimeout);
    }
  }, [display]);
  return (
    <>
    { includeFilters && 
      <>
        <CSSTransition 
          classNames="c-inventory-filters-sort-"
          timeout={1000}
          appear
          in={showFilters}
        >
          <section className={classNames("c-inventory-filters-sort c-inventory-filters-sort--filter")} >
            <div className="c-inventory-filters-sort__backdrop" onClick={closeFilters}></div>
            <div className="c-inventory-filters-sort__content" style={{maxHeight: filterMaxHeight}}>
              <div className="c-inventory-filters-sort__content__inner">
                <div className="c-inventory-filters-sort__header">
                  <h1>Filter Products</h1>
                  <button className="c-inventory-filters-sort__clear" onClick={() => setFilters({})}>Clear</button>
                </div>
                <FilterForm onSubmit={setFilters}   />
              </div>
            </div>
          </section>
        </CSSTransition>
      </>
    }
    <div id="page-sizer" ref={sizerRef}></div>
    </>
  )
}

export default Filter;