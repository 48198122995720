import { DispensariesType, HoursOfOperationType } from "constants/dispensary";

interface DispensaryType {
  [key: string]: DispensariesType;
}

export interface LocationsState {
  userLocationID: string;
  userLocationString: string | null;
  filter: string;
  dispensaries?: null | DispensaryType;
  hasDistances: boolean;
  loadingDistances: boolean;
  originID: string;
  loadingDispensaries: boolean;
  loadingSetDispensaries: boolean;
  error: string;
  storeId: string;
}

export const SET_DISPENSARIES = "SET_DISPENSARIES";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_LOCATION_FILTER = "SET_LOCATION_FILTER";
export const SET_LOCATION_DISTANCE = "SET_LOCATION_DISTANCE";
export const SET_LOCATION_DISTANCES = "SET_LOCATION_DISTANCES";
export const SET_LOADING_DISTANCES = "SET_LOADING_DISTANCES";
export const FETCH_DISPENSARIES = "FETCH_DISPENSARIES";
export const FETCH_DISPENSARIES_SUCCESS = "FETCH_DISPENSARIES_SUCCESS";
export const FETCH_DISPENSARIES_FAILED = "FETCH_DISPENSARIES_FAILED";
export const SET_DISPENSARY_IS_OPEN = "SET_DISPENSARY_IS_OPEN";
export const SET_DISPENSARY_IS_OPEN_SUCCESS = "SET_DISPENSARY_IS_OPEN_SUCCESS";
export const SET_DISPENSARY_IS_OPEN_FAILED = "SET_DISPENSARY_IS_OPEN_FAILED";
export const RESET_HAS_DISTANCES = "RESET_HAS_DISTANCES";

interface SetUserLocation {
  type: typeof SET_USER_LOCATION;
  locationID: string;
  locationString: string;
}

interface SetLocationFilter {
  type: typeof SET_LOCATION_FILTER;
  filter: string;
}

interface SetLocations {
  type: typeof SET_DISPENSARIES;
  dispensaries: object;
}

interface SetLocationDistance {
  type: typeof SET_LOCATION_DISTANCE;
  dispensaryId: string | null | undefined;
  distance: string;
}

interface SetLocationDistances {
  type: typeof SET_LOCATION_DISTANCES;
  allDistances: any;
}

interface SetLoadingDistances {
  type: typeof SET_LOADING_DISTANCES;
  userLocationID: string;
}

interface FetchDispensaries {
  type: typeof FETCH_DISPENSARIES;
}

interface FetchDispensariesSuccess {
  type: typeof FETCH_DISPENSARIES_SUCCESS;
  dispensaries: any;
}

interface FetchDispensariesFailed {
  type: typeof FETCH_DISPENSARIES_FAILED;
  error: any;
}

interface SetDispensaryIsOpen {
  type: typeof SET_DISPENSARY_IS_OPEN;
  slug: string;
  hoursOfOperation: HoursOfOperationType;
  timezone: string;
}

interface SetDispensaryIsOpenSuccess {
  type: typeof SET_DISPENSARY_IS_OPEN_SUCCESS;
  isOpen: boolean;
  slug: string;
}

interface SetDispensaryIsOpenFailed {
  type: typeof SET_DISPENSARY_IS_OPEN_FAILED;
  error: any;
}

interface ResetHasDistances {
  type: typeof RESET_HAS_DISTANCES;
}

export type LocationsActionTypes =
  | FetchDispensaries
  | FetchDispensariesFailed
  | FetchDispensariesSuccess
  | SetDispensaryIsOpen
  | SetDispensaryIsOpenSuccess
  | SetDispensaryIsOpenFailed
  | SetUserLocation
  | SetLocationFilter
  | SetLocations
  | SetLocationDistance
  | SetLocationDistances
  | SetLoadingDistances
  | ResetHasDistances;
