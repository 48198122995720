import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "store";
import { FetchDispensaries } from "store/locations/actions";
import { FetchCartPrice } from "store/cart/actions";
// import { PlaceOrder, FetchPreorder }  from 'store/orders/actions';
import { SetSigninRedirect, SetResetKioskTimer } from "store/system/actions";
import { FetchAccountSettings } from "store/customer/actions";
// import Button                         from 'components/Button';
import IconButton from "components/IconButton";
import CloseIcon from "components/svgs/Close";
import Avatar from "components/svgs/Avatar";
import LocationIcon from "components/svgs/Location";
import DispensaryLocation from "components/Dispensaries/DispensaryLocation";
// import { OrderItems, OrderTotals }    from 'components/Order';
import { LoadingDispensaryLocation } from "components/Loading";
import JaneFrame from "components/JaneFrame";

import "./Order.scss";

function Order({ history }: RouteComponentProps) {
  const dispatch = useDispatch();
  const dispensarySlug = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const dispensaryType = useSelector(
    (state: RootState) => state.cart.activeDispensaryType
  );
  const cart = useSelector((state: RootState) => state.cart);
  const cartPrice = useSelector((state: RootState) => state.cart.price);
  //   const cartPriceError        = useSelector((state:RootState) => state.cart.cartPriceError);
  const allLocations = useSelector(
    (state: RootState) => state.locations.dispensaries
  );
  const placingOrder = useSelector(
    (state: RootState) => state.orders.placingOrder
  );
  //   const orderPlaced           = useSelector((state:RootState) => state.orders.orderPlaced);
  const latestOrder = useSelector(
    (state: RootState) => state.orders.latestOrder
  );
  const customer = useSelector((state: RootState) => state.customer.customer);
  const isKiosk = useSelector((state: RootState) => state.system.isKiosk);
  //   const orderOnly             = useSelector((state:RootState) => state.customer.orderOnlyDetails);
  const geoLocationDisabled = useSelector(
    (state: RootState) => state.system.geoLocationDisabled
  );
  const geoLocationDismissed = useSelector(
    (state: RootState) => state.system.geoLocationDismissed
  );
  let statePreorder = useSelector((state: RootState) => state.orders.preorder);

  const [activeDispensary, setActiveDispensary] = useState<any>(
    !dispensarySlug ? false : null
  );
  const [fetchingPrice, setFetchingPrice] = useState<any>(false);
  //   const [fetchOrder, setFetchOrder]             = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(SetResetKioskTimer(true));
    }, 600);

    return () => {
      clearTimeout(timeout);
    };
  }, [isKiosk, dispatch]);

  useEffect(() => {
    if (!customer && !isKiosk) {
      history.replace({
        pathname: "/account/signin",
        state: { signInRedirect: "/order" },
      });
    }
  }, [customer, history, isKiosk]);

  useEffect(() => {
    if (!latestOrder || !statePreorder) {
      return;
    }
    const { slug } = activeDispensary;
    const { orderId } = latestOrder;
    history.replace("/order-confirmation/" + slug + "/" + orderId);
  }, [statePreorder, activeDispensary, latestOrder, history, dispatch]);

  //   useEffect(() => {
  //     if(!latestOrder) { return; }
  //     const {orderId} = latestOrder;
  //     if(orderId && dispensarySlug){
  //       dispatch(FetchPreorder(orderId, dispensarySlug));
  //     }
  //   }, [latestOrder, fetchOrder, dispensarySlug,  dispatch])

  //   useEffect(() => {
  //     if(orderPlaced && latestOrder){
  //       setFetchOrder(true);
  //     }
  //   }, [orderPlaced, latestOrder])

  useEffect(() => {
    if (allLocations === null && dispensarySlug) {
      dispatch(FetchDispensaries());
    }
    if (allLocations && dispensarySlug) {
      const location = allLocations[dispensarySlug];
      if (location) {
        setActiveDispensary(location);
      }
    }
  }, [allLocations, dispensarySlug, dispatch]);

  useEffect(() => {
    if (!fetchingPrice) {
      setFetchingPrice(true);
      dispatch(FetchCartPrice(cart));
    }
  }, [cart, cartPrice, fetchingPrice, dispatch]);

  function goBack() {
    history.goBack();
  }

  useEffect(() => {
    if (customer && customer.readyToLoad) {
      dispatch(FetchAccountSettings({ accessToken: customer.accessToken }));
    }
  }, [customer, dispatch]);

  return (
    <section
      className={classNames("l-main c-order", {
        "c-order--submitting": placingOrder,
      })}
    >
      <div className="l-maxwidth">
        <button onClick={goBack} className="c-order__close">
          <CloseIcon variant="charcoal" /> <span>Close</span>
        </button>
        <h1>View Order</h1>
        <div
          className={classNames("c-order__inner", {
            "c-order__inner--submitting": placingOrder,
          })}
        >
          {activeDispensary ? (
            <DispensaryLocation
              dispensaryFilter="all"
              location={activeDispensary}
            />
          ) : geoLocationDisabled || geoLocationDismissed || !dispensarySlug ? (
            <IconButton
              to="/locations"
              variant="select-dispensary"
              type="Link"
              Icon={LocationIcon}
              iconColor="charcoal"
              backgroundColor="white"
              textColor="charcoal"
              withCaret={true}
            >
              Select a dispensary
            </IconButton>
          ) : (
            <LoadingDispensaryLocation />
          )}
          {!isKiosk && (
            <>
              {customer && customer.readyToLoad ? (
                <IconButton
                  variant="view-profile"
                  type="Link"
                  to={{
                    pathname: "/account/add-reservation-details",
                    state: { signInRedirect: "/order" },
                  }}
                  Icon={Avatar}
                  iconColor="charcoal"
                  backgroundColor="white"
                  textColor="charcoal"
                  style={{ border: 0 }}
                  withCaret={true}
                ></IconButton>
              ) : customer &&
                dispensaryType &&
                dispensaryType?.indexOf("medical") > -1 &&
                !customer.medicalId ? (
                <IconButton
                  variant="add-details"
                  type="Link"
                  to={{
                    pathname: "/account/add-reservation-details",
                    state: { signInRedirect: "/order" },
                  }}
                  Icon={Avatar}
                  iconColor="red"
                  backgroundColor="white"
                  textColor="red"
                  style={{ border: 0 }}
                  withCaret={true}
                  caretColor="red"
                >
                  Add Reservation Details
                </IconButton>
              ) : customer &&
                dispensaryType &&
                dispensaryType?.indexOf("medical") <= -1 &&
                !customer.documentId ? (
                <IconButton
                  variant="add-details"
                  type="Link"
                  to={{
                    pathname: "/account/add-reservation-details",
                    state: { signInRedirect: "/order" },
                  }}
                  Icon={Avatar}
                  iconColor="red"
                  backgroundColor="white"
                  textColor="red"
                  style={{ border: 0 }}
                  withCaret={true}
                  caretColor="red"
                >
                  Add Reservation Details
                </IconButton>
              ) : !customer ? (
                <IconButton
                  variant="add-details"
                  type="Link"
                  replace={true}
                  to={{
                    pathname: "/account/signin",
                    state: {
                      signInRedirect: "/account/add-reservation-details",
                    },
                  }}
                  onClick={() => dispatch(SetSigninRedirect("/order"))}
                  Icon={Avatar}
                  iconColor="red"
                  backgroundColor="white"
                  textColor="red"
                  style={{ border: 0 }}
                  withCaret={true}
                  caretColor="red"
                >
                  Add reservation details
                </IconButton>
              ) : (
                <IconButton
                  variant="view-profile"
                  type="Link"
                  to={{
                    pathname: "/account/add-reservation-details",
                    state: { signInRedirect: "/order" },
                  }}
                  Icon={Avatar}
                  iconColor="charcoal"
                  backgroundColor="white"
                  textColor="charcoal"
                  style={{ border: 0 }}
                  withCaret={true}
                >
                  {customer.firstName} {customer.lastName}
                </IconButton>
              )}
            </>
          )}
        </div>

        <hr className="c-order__hr"></hr>

        {/* Jane FRAME CALLED  */}
        <JaneFrame history={history} />
      </div>
    </section>
  );
}

export default withRouter(Order);
