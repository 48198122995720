import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Aroused: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-feeling i-feeling--aroused" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 414.6 414.6" xmlSpace="preserve">
      <g>
        <g>
          <g>
            <line className="i-feeling--aroused--st0" stroke={color} x1="207.3" y1="136.2" x2="207.3" y2="184.9"/>
            <line className="i-feeling--aroused--st0" stroke={color} x1="280.6" y1="190.7" x2="272.2" y2="199.1"/>
            <line className="i-feeling--aroused--st0" stroke={color} x1="134" y1="190.7" x2="142.4" y2="199.1"/>
          </g>
        </g>
        <line className="i-feeling--aroused--st0" stroke={color} x1="207.3" y1="136.2" x2="207.3" y2="184.9"/>
        <line className="i-feeling--aroused--st0" stroke={color} x1="331.7" y1="239" x2="287.9" y2="247.6"/>
        <line className="i-feeling--aroused--st0" stroke={color} x1="82.9" y1="239" x2="126.6" y2="247.6"/>
        <line className="i-feeling--aroused--st0" stroke={color} x1="86.2" y1="278.4" x2="329" y2="278.4"/>
        <path fill={color} d="M255.8,277.8c0-26.8-21.8-48.5-48.5-48.5s-48.5,21.7-48.5,48.5H255.8z"/>
        <path stroke={color} d="M285.2,239"/>
      </g>
    </svg>

  )
}

export default Aroused;