import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import './AllLocations.scss';
import PlacesSearch from 'components/PlacesSearch';
import Dispensaries from 'components/Dispensaries';
import { FetchDispensaries } from 'store/locations/actions';

function AllLocations() {
  const dispatch            = useDispatch();
  const user                = useSelector((state : RootState) => state.user);
  const mapsReady           = useSelector((state : RootState) => state.system.mapsReady);
  const loadingDispensaries = useSelector((state : RootState) => state.locations.loadingDispensaries);
  const allLocations        = useSelector((state: RootState) => state.locations.dispensaries)
  const locationsError      = useSelector((state: RootState) => state.locations.error)
  
  useEffect(() => {
    if(!loadingDispensaries && !allLocations && !locationsError){
      dispatch(FetchDispensaries());
    }
  }, [allLocations, locationsError, loadingDispensaries, dispatch])

  return(
    <>
    <main className="l-main all-locations">
        <h1 className="l-maxwidth t-page-title">All Locations</h1>
        {
          mapsReady && 
            <>
              <PlacesSearch onSubmit={() => {}}  />
              <Dispensaries user={user} />
            </>
        }
    </main>
    </>
  )
}

export default AllLocations;