import React              from 'react';
import classNames         from 'classnames';
import { CheckboxProps }  from './types';
import './Fields.scss';

// General input to be used by forms
// Placeholders or labels are to be used independent of one another as the desired design would clash otherwise.
const renderField = ({ 
  input, 
  className = '', 
  options, 
  meta 
}: CheckboxProps ) => {
  const {active, touched, invalid, dirty, submitFailed, initial} = meta;
  const showInvalid = (invalid && touched) || (submitFailed && !touched);
  const formControlClass = 'form-control form-control--checkbox';
  return (
    <div className={"i-checkbox-group " + className}>
    {
      options.map((option, index) => {
        let LabelHTML:any = null
        if(option.labelHTML){
          LabelHTML = option.labelHTML;
        }
        const isChecked = input.value.indexOf(option.name) !== -1;
        return (
          <div key={option.name} className={classNames(formControlClass, {'form-control--invalid': showInvalid, 'form-control--active': active, 'form-control--dirty': (dirty||initial)})}>
            <label className="form-control__checkbox-label">
              <input type="checkbox"
                name={`${input.name}[${index}]`}
                value={option.name}

                checked={input.value.indexOf(option.name) !== -1}
                onChange={(event) => {
                    const newValue = [...input.value];
                    if (event.target.checked) {
                        newValue.push(option.name);
                    } else {
                        newValue.splice(newValue.indexOf(option.name), 1);
                    }

                    return input.onChange(newValue);
                }}
              />
              <div className={classNames("form-control__styled-checkbox", {'form-control__styled-checkbox--checked': isChecked})}></div>
              {
                option.label ? 
                  <span>{option.label}</span>
                : option.labelHTML ?
                    <LabelHTML />
                : null
              }
            </label>
          </div>
        )
      })
    }
    </div>
  )
}
export default renderField;