import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const PlusCircle: FunctionComponent<IconType> = ({ variant = "charcoal"}) => {
  const color = colors[variant];
  return(
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12" r="11.25" stroke={color} strokeWidth="1.5"/>
      <path d="M16.5 11.2H13.3267V8H11.6566V11.2H8.5V12.7H11.6566V16H13.3267V12.7H16.5V11.2Z" fill={color} />
    </svg>
  )
}

export default PlusCircle;