import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { RootState } from "store";
import { Input } from "components/Forms/Fields";
import CheckmarkIcon from "components/svgs/Checkmark";
import { TermsOfService } from "./index";

interface ProfileProductsToAvoidProps {
  activeStep: string;
  goNext: any;
  change: any;
  saveStep: boolean;
  config: Array<string>;
}

function ProductsToAvoid({
  activeStep,
  goNext,
  change,
  saveStep,
  config,
}: ProfileProductsToAvoidProps) {
  const activeStepIndex = useSelector(
    (state: RootState) => state.system.profileStepIndex
  );
  const preferences: any = useSelector(
    (state: RootState) => state.profile.preferences
  );

  const [isPrev, setIsPrev] = useState(false);
  const [saved, setSaved] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const [productArray, setProductArray] = useState<any>(null);

  const isActive = activeStep === "products-to-avoid";
  const { productFormatsToAvoid } = preferences || [];

  useEffect(() => {
    if (config && !selectedProducts && !productArray) {
      let treatArray: any = [];
      let selectedTreats: any = {};
      config.forEach((format: any) => {
        treatArray.push({
          id: format.slug,
          label: format.name,
        });
        selectedTreats[format.slug] =
          productFormatsToAvoid?.indexOf(format.slug) >= 0;
      });
      setProductArray(treatArray);
      setSelectedProducts(selectedTreats);
    }
  }, [config, selectedProducts, productArray, productFormatsToAvoid]);

  useEffect(() => {
    if (saveStep && isActive && !saved) {
      setSaved(true);
      let productsToAvoid: any = [];
      Object.keys(selectedProducts).forEach((product: any) => {
        if (selectedProducts[product]) {
          productsToAvoid.push(product);
        }
      });
      change("productFormatsToAvoid", productsToAvoid);
      goNext();
    }
  }, [saveStep, isActive, saved, change, goNext, selectedProducts]);

  useEffect(() => {
    let timer: any = null;

    if (isPrev) {
      timer = setTimeout(() => {
        setIsPrev(activeStepIndex > 7);
      }, 1000);
    } else {
      setIsPrev(activeStepIndex > 7);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPrev, activeStepIndex]);

  useEffect(() => {
    if (!isActive && saved) {
      setSaved(false);
    }
  }, [saved, isActive]);

  function selectProduct(value: string) {
    let newProducts: any = { ...selectedProducts };
    if (newProducts[value]) {
      newProducts[value] = false;
    } else {
      newProducts[value] = true;
    }
    setSelectedProducts({ ...newProducts });
  }

  return (
    <CSSTransition
      in={isActive}
      classNames={isPrev ? "c-profile__step--prev-" : "c-profile__step-"}
      timeout={1000}
      appear
    >
      <div className={classNames("c-profile__step")}>
        <div className="slug">Question 7 of 8</div>
        <h3>Are there any product types you don’t want to use?</h3>
        <Field
          component={Input}
          name="productFormatsToAvoid"
          type="hidden"
          label={""}
          required
          className="firstName__input"
        />
        <div className="c-mood-selector__occasions">
          {productArray &&
            selectedProducts &&
            productArray.map((product: any) => {
              return (
                <div
                  tabIndex={isActive ? 0 : -1}
                  key={product.id}
                  onClick={() => selectProduct(product.id)}
                  className={classNames("c-mood-selector__occasion", {
                    "c-mood-selector__occasion--selected":
                      selectedProducts[product.id],
                  })}
                >
                  <span>{product.label}</span>
                  <div className="c-mood-selector__occasion__check">
                    <CheckmarkIcon variant="white" />
                  </div>
                </div>
              );
            })}
        </div>
        <TermsOfService />
      </div>
    </CSSTransition>
  );
}

export default ProductsToAvoid;
