import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Vapes: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--vapes" version="1.1" x="0px" y="0px" viewBox="0 0 89.2 45.3">
      <g>
        <path className="i-product-type--vapes--st0" stroke={color} d="M69.9,13.1c0,0-2.6,2.2-1.7,4.6s3.6,2.5,3.6,2.5s-0.1,2.3,1.8,2.7"/>
        <path className="i-product-type--vapes--st0" stroke={color} d="M83,17.4c0,0,2.4-0.2,2.2-3.5c0,0,3.3-0.9,3.4-3.4s-2.1-3.8-2.1-3.8s1-2-0.5-3.2"/>
        <path className="i-product-type--vapes--st0" stroke={color} d="M76.6,12.1c-0.4,1.6,1.2,4.6,1.6,6.2c0.5,1.6-0.5,3.4-0.5,3.4"/>
        <path className="i-product-type--vapes--st0" stroke={color} d="M78.5,0.5c0,0-0.8,3,0,4.6s2.5,3.2,2.6,4.7s-0.7,3.9-0.7,3.9"/>
      </g>
      <path className="i-product-type--vapes--st0" stroke={color} d="M44.5,43.4V30.8h36c1.8,0,3.2,1.4,3.2,3.2v6.2c0,1.8-1.4,3.2-3.2,3.2H44.5z"/>
      <rect x="23.8" y="28.1" transform="matrix(-4.490116e-11 1 -1 -4.490116e-11 67.2428 6.9246)" className="i-product-type--vapes--st0" stroke={color} width="12.6" height="18"/>
      <path className="i-product-type--vapes--st0" stroke={color} d="M0.5,40.5v-6.8c0-0.6,0.5-1,1-1h14.3v8.8H1.5C1,41.5,0.5,41,0.5,40.5z"/>
      <path className="i-product-type--vapes--st0" stroke={color} d="M27.5,37.1L27.5,37.1c0-1.8,1.5-3.3,3.3-3.3h8.3v6.7h-8.3C29,40.4,27.5,38.9,27.5,37.1z"/>
      <path className="i-product-type--vapes--st0" stroke={color} d="M39.2,44.1v-14c0-0.4,0.3-0.7,0.7-0.7h3.9c0.4,0,0.7,0.3,0.7,0.7v14c0,0.4-0.3,0.7-0.7,0.7h-3.9
        C39.5,44.8,39.2,44.5,39.2,44.1z"/>
      <path className="i-product-type--vapes--st0" stroke={color} d="M15.8,44.1v-14c0-0.4,0.3-0.7,0.7-0.7h3.9c0.4,0,0.7,0.3,0.7,0.7v14c0,0.4-0.3,0.7-0.7,0.7h-3.9
        C16.1,44.8,15.8,44.5,15.8,44.1z"/>
      <line className="i-product-type--vapes--st0" stroke={color} x1="77.8" y1="30.8" x2="77.8" y2="43.4"/>
      <line className="i-product-type--vapes--st0" stroke={color} x1="6.9" y1="32.7" x2="6.9" y2="41.5"/>
    </svg>
  )
}

export default Vapes;