import React                                          from 'react';
import { connect, useSelector }                       from 'react-redux';
import { Form, Field, reduxForm, InjectedFormProps }  from 'redux-form';
import { Link }                                       from 'react-router-dom';
import { RootState }                    from 'store';
import Button                           from 'components/Button';
import { Input, Checkbox }              from 'components/Forms/Fields';
import { SignUpValidator as validate }  from 'components/Forms/Validators';
import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH } from "components/Forms/Fields/constants";
import './SignUpForm.scss';

function SignUpForm({ 
  handleSubmit, 
  invalid 
}: InjectedFormProps) {

  const creatingCustomer = useSelector((state: RootState) => state.customer.creatingCustomer);

  function getCheckboxLabel() {
    return(
      <span>
        By creating this account you agree to the <Link to="/terms-of-use">terms of service</Link>, <Link to={{ pathname: "https://columbia.care/privacy-policy" }} target="_blank">privacy policy</Link> and for California residents the <Link to={{ pathname: "https://columbia.care/privacy-policy#california-residents"}} target="_blank">California section</Link> of our privacy policy.
      </span>
    );
  }
  
  return (
    <div className="c-signin-form">
      <Form className="c-signin-form__form" autoComplete="off" onSubmit={handleSubmit}>
        <Field 
          component={Input} 
          name="first_name" 
          type="text"
          label={"First Name"}
          required
          className="email__input"
          maxlength={NAME_MAX_LENGTH}
        /> 
        <Field 
          component={Input} 
          name="last_name" 
          type="text"
          label={"Last Name"}
          required
          className="email__input"
          maxlength={NAME_MAX_LENGTH}
        /> 
        <Field 
          component={Input} 
          name="email" 
          type="email"
          label={"Email"}
          required
          className="email__input"
          maxlength={EMAIL_MAX_LENGTH}
        /> 
        <Field 
          component={Input} 
          name="password" 
          type="password"
          label={"Password"}
          required
          autocomplete="new_password" 
          className="password__input"
        /> 
        <Field 
          component={Checkbox} 
          name="terms_agreed" 
          type="checkbox"
          options={[
            {name: 'terms_agreed_check', label: getCheckboxLabel() }
          ]}
          required
          className="checkbox__input"
        /> 
        <Button disabled={invalid || creatingCustomer} variant="login" type="submit">{creatingCustomer ? 'Creating Your Account' : 'Create Account'}</Button>
      </Form>
    </div>
  )
}

export default connect()(reduxForm({
  form: 'signUp',
  validate
})(SignUpForm));;