import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';

const Hamburger: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-hamburger" width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1H19.9009" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 4.49983H19.9009" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 7.99966H19.9009" stroke={color} strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Hamburger;