export interface EmbedDispensariesProps {
  recreational: string | null
  medical: string | null
}
export interface SystemState {
  showAgeGate: boolean
  geoLocationDisabled: boolean | null
  geoLocationDismissed: boolean | null
  geoLocating: boolean
  mapsReady: boolean
  siginRedirect?: string
  isKiosk: boolean | null
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  drawerOpen: boolean
  mobileNavOpen: boolean
  moodSelectorOpen: boolean
  moodSelectorStep: number
  windowHeight: number | null
  profileOpen: boolean
  profileStep: string | null
  profileStepIndex: number
  profileSteps: Array<string>
  resetKioskTimer: boolean
  isForageEmbed: boolean
  embedDispensaries?: EmbedDispensariesProps | null
}

export const SET_MAPS_READY = 'SET_MAPS_READY'
export const SET_AGE_GATE_PASSED = 'SET_AGE_GATE_PASSED'
export const SET_GEOLOCATION_DISABLED = 'SET_GEOLOCATION_DISABLED'
export const SET_GEOLOCATION_DISMISSED = 'SET_GEOLOCATION_DISMISSED'
export const SET_GEOLOCATING = 'SET_GEOLOCATING'
export const SET_SIGNIN_REDIRECT = 'SET_SIGNIN_REDIRECT'
export const SET_IS_KIOSK = 'SET_IS_KIOSK'
export const SET_DEVICE_SIZE = 'SET_DEVICE_SIZE'
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN'
export const SET_MOBILE_NAV_OPEN = 'SET_MOBILE_NAV_OPEN'
export const SET_MOOD_SELECTOR_OPEN = 'SET_MOOD_SELECTOR_OPEN'
export const SET_MOOD_SELECTOR_STEP = 'SET_MOOD_SELECTOR_STEP'
export const SET_WINDOW_HEIGHT = 'SET_WINDOW_HEIGHT'
export const SET_PROFILE_OPEN = 'SET_PROFILE_OPEN'
export const SET_PROFILE_STEP = 'SET_PROFILE_STEP'
export const SET_RESET_KIOSK_TIMER = 'SET_RESET_KIOSK_TIMER'
export const SET_FORAGE_EMBED = 'SET_FORAGE_EMBED'
export const SET_FORAGE_EMBED_DISPENSARY_TYPES = 'SET_FORAGE_EMBED_DISPENSARY_TYPES'
export const FORAGE_EMBED = 'forage_embed'

interface SetAgeGatePassed {
  type: typeof SET_AGE_GATE_PASSED
  dob: string
}

interface SetMapsReady {
  type: typeof SET_MAPS_READY
}

interface SetGeoLocationDisabled {
  type: typeof SET_GEOLOCATION_DISABLED
}

interface SetGeoLocating {
  type: typeof SET_GEOLOCATING
  geoLocating: boolean
}

interface SetSigninRedirect {
  type: typeof SET_SIGNIN_REDIRECT
  redirect: string
}

interface SetIsKiosk {
  type: typeof SET_IS_KIOSK
  isKiosk: boolean
}

interface SetDeviceSize {
  type: typeof SET_DEVICE_SIZE
  deviceSize: string
}

interface SetDrawerOpen {
  type: typeof SET_DRAWER_OPEN
  isOpen: boolean
}

interface SetMobileNavOpen {
  type: typeof SET_MOBILE_NAV_OPEN
  isOpen: boolean
}

interface SetGeoLocationDismissed {
  type: typeof SET_GEOLOCATION_DISMISSED
  isDismissed: boolean
}

interface SetMoodSelectorIsOpen {
  type: typeof SET_MOOD_SELECTOR_OPEN
  isOpen: boolean
}

interface SetMoodSelectorStep {
  type: typeof SET_MOOD_SELECTOR_STEP
  step: number
}

interface SetProfileIsOpen {
  type: typeof SET_PROFILE_OPEN
  isOpen: boolean
}

interface SetProfileStep {
  type: typeof SET_PROFILE_STEP
  step: string
}

interface SetWindowHeight {
  type: typeof SET_WINDOW_HEIGHT
  height: number
}

interface SetResetKioskTimer {
  type: typeof SET_RESET_KIOSK_TIMER
  reset: boolean
}
interface SetIsForageEmbed {
  type: typeof SET_FORAGE_EMBED
  isForageEmbed: boolean
}
interface setForageEmbedDispensaryTypes {
  type: typeof SET_FORAGE_EMBED_DISPENSARY_TYPES
  embedDispensaries: EmbedDispensariesProps
}

export type SystemActionTypes =
  | SetAgeGatePassed
  | SetGeoLocationDisabled
  | SetGeoLocating
  | SetMapsReady
  | SetSigninRedirect
  | SetIsKiosk
  | SetDeviceSize
  | SetDrawerOpen
  | SetMobileNavOpen
  | SetGeoLocationDismissed
  | SetMoodSelectorIsOpen
  | SetMoodSelectorStep
  | SetWindowHeight
  | SetProfileIsOpen
  | SetProfileStep
  | SetResetKioskTimer
  | SetIsForageEmbed
  | setForageEmbedDispensaryTypes
