import React          from 'react';
import { Link }       from 'react-router-dom';
import RightArrowIcon from 'components/svgs/RightArrow';
import './CircleArrowButton.scss';

interface CABProps {
  to?:      string
  variant?: string
  type?:    string,
  ariaLabel?:    string,
}

function CircleArrowButton({
  to,
  variant,
  type,
  ariaLabel
}: CABProps) {
  let className="c-circle-arrow-button";
  if(variant){
    className += " c-circle-arrow-button--" + variant
  }
  return (
    <>
    {
      type === 'button' ?
      <button className={className} aria-label={ariaLabel}>
        <RightArrowIcon />
      </button>
      : to ?

        <Link className={className} to={to} aria-label={ariaLabel}>
          <RightArrowIcon />
        </Link>
      :
        <button className={className} aria-label={ariaLabel}>
          <RightArrowIcon />
        </button>
    }
    </>
  )
}

export default CircleArrowButton;
