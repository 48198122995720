import {
  SET_REC_XY,
  SET_FILLOUT_FEELINGS,
  SET_REC_FINDER_OPEN,
  SET_REC_FINDER_STEP,
  SET_REC_FINDER_PREFERENCE,
  SET_REC_FINDER_PREFERENCE_ADJUSTMENT,
  APPLY_REC_FINDER_PREFERENCE_ADJUSTMENT,
  RESET_REC_FINDER_PREFERENCE_ADJUSTMENT,
  SET_RECS_LOCATION,
  FETCH_RECS,
  CUST_FETCH_RECS,
  FETCH_REC_COUNT,
  FETCH_REC_CONFIG,
  RecsActionTypes,
  SET_CAROUSEL_INDEX,
  SET_RECS_CHANGING,
  RESET_RECS,
  SET_RECOMMENDATION_PAGE,
  SET_SEARCH,
  SET_RECOMMENDATION_SORT,
  SET_RECOMMENDATION_FILTER,
  RESET_RECS_PREVIOUS_RESULT_DATA,
} from "./types";

export function SetRecXY(xy: any): RecsActionTypes {
  return {
    type: SET_REC_XY,
    xy,
  };
}

export function SetFillOutFeelings(fillOutFeelings: any): RecsActionTypes {
  return {
    type: SET_FILLOUT_FEELINGS,
    fillOutFeelings,
  };
}

export function SetRecFinderOpen(isOpen: boolean): RecsActionTypes {
  return {
    type: SET_REC_FINDER_OPEN,
    isOpen,
  };
}

export function SetRecFinderStep(step: number): RecsActionTypes {
  return {
    type: SET_REC_FINDER_STEP,
    step,
  };
}

export function FetchRecs(payload: any): RecsActionTypes {
  return {
    type: FETCH_RECS,
    payload,
  };
}

export function CustFetchRecs(payload: any): RecsActionTypes {
  return {
    type: CUST_FETCH_RECS,
    payload,
  };
}

export function FetchRecCount(payload: any): RecsActionTypes {
  return {
    type: FETCH_REC_COUNT,
    payload,
  };
}

export function FetchRecConfig(): RecsActionTypes {
  return {
    type: FETCH_REC_CONFIG,
  };
}

export function SetRecFinderPreference(
  id: string,
  value: any
): RecsActionTypes {
  return {
    type: SET_REC_FINDER_PREFERENCE,
    id,
    value,
  };
}

export function SetRecPreferenceAdjustment(
  id: string,
  value: any
): RecsActionTypes {
  return {
    type: SET_REC_FINDER_PREFERENCE_ADJUSTMENT,
    id,
    value,
  };
}

export function ApplyRecPrefernceAdjustment(): RecsActionTypes {
  return {
    type: APPLY_REC_FINDER_PREFERENCE_ADJUSTMENT,
  };
}

export function ResetRecPrefernceAdjustment(): RecsActionTypes {
  return {
    type: RESET_REC_FINDER_PREFERENCE_ADJUSTMENT,
  };
}

export function SetRecsLocation(activeDispensary: string): RecsActionTypes {
  return {
    type: SET_RECS_LOCATION,
    activeDispensary,
  };
}

export function SetCarouselIndex(index: number): RecsActionTypes {
  return {
    type: SET_CAROUSEL_INDEX,
    index,
  };
}

export function SetRecommendationPage(page: number): RecsActionTypes {
  return {
    type: SET_RECOMMENDATION_PAGE,
    page,
  };
}

export function SetRecsChanging(recsChanging: boolean): RecsActionTypes {
  return {
    type: SET_RECS_CHANGING,
    recsChanging,
  };
}
export function ResetRecs(): RecsActionTypes {
  return {
    type: RESET_RECS,
  };
}

export function SetSearch(searchQuery: string): RecsActionTypes {
  return {
    type: SET_SEARCH,
    searchQuery,
  };
}

export function SetRecommendationSort(sort: any): RecsActionTypes {
  return {
    type: SET_RECOMMENDATION_SORT,
    sort,
  };
}

export function SetRecommendationFilter(filter: any): RecsActionTypes {
  return {
    type: SET_RECOMMENDATION_FILTER,
    filter,
  };
}

export function ResetRecsPreviousData(): RecsActionTypes {
  return {
    type: RESET_RECS_PREVIOUS_RESULT_DATA,
  };
}
