import React, { FunctionComponent } from 'react';
import colors from 'sass/utils/colors.module.scss';
import './Logo.scss';


type LogoProps = {
  variant: string
}

const Logo: FunctionComponent<LogoProps> = ({ variant = 'charcoal' }) => {
  let fillColor = colors[variant] || colors['charcoal'];
  return ( 
    <svg className="i-logo" width="52" height="17" viewBox="0 0 52 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fillColor} d="M6.96484 8.24392C6.96484 5.33766 8.7283 3.49097 11.4392 3.49097C14.1501 3.49097 15.9314 5.33766 15.9314 8.24392C15.9314 11.1502 14.1665 12.979 11.4392 12.979C8.71187 12.979 6.96484 11.1323 6.96484 8.24392ZM13.8763 8.24392C13.8763 6.4165 13.0397 5.0996 11.4392 5.0996C9.83867 5.0996 9.00076 6.4165 9.00076 8.24392C9.00076 10.0713 9.85784 11.3704 11.4392 11.3704C13.0206 11.3704 13.8763 10.0535 13.8763 8.24392Z" />
      <path fill={fillColor} d="M31.6518 6.54454V12.7974H29.735V11.4626C29.4256 12.0131 28.5342 12.9763 26.7229 12.9763C24.958 12.9763 23.6122 12.0805 23.6122 10.4347C23.6122 8.6458 24.9211 7.89446 27.5047 7.52843C29.123 7.30963 29.7418 7.08946 29.7418 6.32161C29.7418 5.55377 29.0326 5.06113 28.0496 5.06113C26.7407 5.06113 26.104 5.70101 26.0137 6.87067H24.0489C24.1037 5.16984 25.2675 3.48828 27.9948 3.48828C30.1594 3.49103 31.6518 4.47905 31.6518 6.54454ZM29.735 8.86597V8.28114C29.2613 8.55636 28.5712 8.72011 27.7881 8.86597C26.2971 9.14119 25.6426 9.54162 25.6426 10.3466C25.6426 11.1516 26.3272 11.5163 27.1706 11.5163C28.4316 11.5163 29.7418 10.6576 29.7418 8.86597H29.735Z" />
      <path fill={fillColor} d="M42.1216 3.65627V12.7232C42.1216 15.5387 40.5937 17 37.8294 17C34.9747 17 33.8288 15.4107 33.7014 13.6011H35.6182C35.7086 14.5149 36.2535 15.4107 37.8732 15.4107C39.6011 15.4107 40.146 14.4048 40.146 12.759V11.5205C39.8913 12.0145 39.0507 12.9833 37.3639 12.9833C34.9624 12.9833 33.4536 11.0816 33.4536 8.30465C33.4536 5.32545 35.2171 3.47876 37.4734 3.47876C38.7056 3.47876 39.7284 4.0457 40.146 4.88648V3.66178L42.1216 3.65627ZM40.1391 8.2441C40.1391 6.27081 39.3026 5.13693 37.8664 5.13693C36.2836 5.13693 35.4841 6.52676 35.4841 8.37207C35.4841 10.2174 36.3206 11.3994 37.7746 11.3994C39.2287 11.3994 40.1391 10.2738 40.1391 8.2441Z" />
      <path fill={fillColor} d="M5.85171 5.61162H2.18241V1.93751H7.94787V0H0V12.7975H2.18241V7.49547H5.64771C5.63391 6.86127 5.70249 6.22798 5.85171 5.61162Z" />
      <path fill={fillColor} d="M23.0509 5.39285C23.1701 4.82294 23.3434 4.26583 23.5684 3.72918V3.65625H22.3677C20.6768 3.65625 19.8402 4.66216 19.4035 5.88686V3.65625H17.3292V12.7975H19.4035V9.32292C19.4035 6.12355 20.8397 5.39285 22.532 5.39285H23.0509Z" />
      <path fill={fillColor} d="M50.0148 10.1291C49.7259 10.9176 49.0222 11.3882 47.9611 11.3882C46.5235 11.3882 45.5419 10.4566 45.4145 8.72004H52.0001V8.35401C52.0001 5.24683 50.4174 3.49097 47.7626 3.49097C44.9066 3.49097 43.3813 5.61149 43.3813 8.22603C43.3813 10.8406 44.9079 12.979 47.9201 12.979C50.2229 12.979 51.4976 11.8313 51.9275 10.3521L50.0148 10.1291ZM47.7982 5.02666C49.1263 5.02666 49.8177 5.92249 49.9628 7.38525H45.4707C45.6883 5.9046 46.4715 5.02666 47.7982 5.02666Z" />
    </svg>
  )
}
export default Logo;
