import React, { useState, useEffect } from 'react';
import { useDispatch }   from 'react-redux';
import Sort                                                       from './Sort';
import Pill                                                       from 'components/Pill';
import SortIcon                                                   from 'components/svgs/Sort';
import { SetRecsChanging, SetRecommendationSort } from '../../../store/recommendations/actions';
import UseRecommendation from '../hooks/useRecommendation';

function RecommendationSort() {
  const dispatch        = useDispatch();
  const { sort: sortSetting }     = UseRecommendation();

  const [activePill, setActivePill]         = useState('');
  const [previousScroll, setPreviousScroll] = useState(0);

  useEffect(() => {    
    if(activePill !== '' && previousScroll === 0){
      setPreviousScroll(window.scrollY);
      document.body.classList.add('no-scroll');
    } else if(activePill === '') {
      setPreviousScroll(0);
      document.body.classList.remove('no-scroll');
      window.scrollTo(0, previousScroll);
    }
  }, [activePill, previousScroll]);

  function setSort(sort:string) {
    setActivePill('');
    dispatch(SetRecsChanging(true));
    setTimeout(() => {
        dispatch(SetRecommendationSort(sort));
    }, 250);
  }

  return(
    <>
      <section className="c-inventory-filter-sort c-recommended-inventory">
        <Pill Icon={SortIcon} active={activePill === 'sort'} onClick={() => activePill === 'sort' ? setActivePill('') : setActivePill('sort')}>
          <span className="body--xsmall">Sort</span>
        </Pill>
      </section>
      <Sort sortSetting={sortSetting} display={activePill === 'sort'} closeSort={() => setActivePill('')} setSort={setSort} />
    </>
  )
}

export default RecommendationSort;