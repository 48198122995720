import React from 'react';
import auth0 from 'auth0-js';

function SocialRedirect() {
  const webAuthDomain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const webAuthClientId = process.env.REACT_APP_AUTH0_CLIENT || '';
  window.webAuth = new auth0.WebAuth({
    domain:       webAuthDomain,
    clientID:     webAuthClientId
  });
  window.webAuth.popup.callback({state: 'forage'});
  return (<div></div>);
}

export default SocialRedirect;