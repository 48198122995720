import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Oil: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--oil" version="1.1" x="0px" y="0px" viewBox="0 0 31.4 72.9">
      <g>
        <g>
          <g>
            <path className="i-product-type--oil--st0" stroke={color} d="M7.3,29.2V33c-3.9,0.8-6.8,4.3-6.8,8.4v25.7c0,2.9,2.3,5.2,5.2,5.2h19.9c2.9,0,5.2-2.3,5.2-5.2V41.4
              c0-4.1-2.9-7.6-6.8-8.4v-3.8"/>
            <path className="i-product-type--oil--st0" stroke={color} d="M25.3,19.6H6.1c-0.7,0-1.2,0.6-1.2,1.2V28c0,0.7,0.6,1.2,1.2,1.2h19.2c0.7,0,1.2-0.6,1.2-1.2v-7.2
              C26.5,20.1,26,19.6,25.3,19.6z"/>
            <path className="i-product-type--oil--st0" stroke={color} d="M21.9,6.8c0-3.9-3.6-7-7.6-6.1C12,1.1,10.1,3,9.6,5.3c-0.3,1.5-0.1,3,0.5,4.2c0.7,1.4,1.1,3,0.9,4.6
              l-0.6,5.4H21l-0.6-5.4c-0.2-1.5,0-3.1,0.8-4.4C21.7,8.9,21.9,7.8,21.9,6.8z"/>
            <line className="i-product-type--oil--st0" stroke={color} x1="0.5" y1="42.5" x2="13.9" y2="42.5"/>
            <line className="i-product-type--oil--st0" stroke={color} x1="30.6" y1="42.5" x2="17.3" y2="42.5"/>
          </g>
          <line className="i-product-type--oil--st0" stroke={color} x1="0.5" y1="65.1" x2="30.9" y2="65.1"/>
        </g>
      </g>
      <g>
        <line className="i-product-type--oil--st0" stroke={color} x1="15.6" y1="58" x2="15.6" y2="61.3"/>
        <path className="i-product-type--oil--st0" stroke={color} d="M15.6,39c0,0,1.7,2.1,2.3,8.4c0.6,6.3-2.3,10.7-2.3,10.7s9.7-0.8,11.5-11.4c0,0-6.5,3.8-9.8,7.4"/>
        <path className="i-product-type--oil--st0" stroke={color} d="M15.6,39c0,0-1.7,2.1-2.3,8.4S15.6,58,15.6,58S5.9,57.3,4.1,46.6c0,0,6.5,3.8,9.8,7.4"/>
        <path className="i-product-type--oil--st0" stroke={color} d="M25.5,57.8c0,0-4.2,3.6-9.9,0.2"/>
        <path className="i-product-type--oil--st0" stroke={color} d="M5.6,57.8c0,0,4.2,3.6,9.9,0.2"/>
      </g>
    </svg>
  )
}

export default Oil;