import React, {FunctionComponent} from 'react';
import { IconType } from 'components/svgs/types';
import colors from 'sass/utils/colors.module.scss';
const Accessories: FunctionComponent<IconType> = ({ variant = 'charcoal'}) => {
  const color = colors[variant];
  return(
    <svg className="i-product-type i-product-type--accessories" version="1.1" x="0px" y="0px" viewBox="0 0 149 160.3">
      <polyline className="i-product-type--accessories--st0" stroke={color} points="27.6,79.9 27.6,158.6 147.3,158.6 147.3,65.9 54.1,65.9 "/>
      <rect x="1" y="29.6" transform="matrix(0.8851 -0.4653 0.4653 0.8851 -12.987 35.0515)" className="i-product-type--accessories--st0" stroke={color} width="127" height="28.5"/>
      <line className="i-product-type--accessories--st0" stroke={color} x1="129" y1="65.9" x2="129" y2="156.7"/>
      <line className="i-product-type--accessories--st0" stroke={color} x1="90.4" y1="14.1" x2="103.8" y2="39.3"/>
    </svg>
  )
}

export default Accessories;