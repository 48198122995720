import React, {useEffect, useState} from 'react';
import './SplashScreen.scss';
import { CSSTransition } from "react-transition-group";
import { SPLASH_SCREEN_TIMEOUT } from 'App';
import Logo from "../svgs/Logo";

function SplashScreen() {
  const [isActive, setIsActive] = useState<boolean>(true);
  
  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setIsActive(false);
      }, SPLASH_SCREEN_TIMEOUT - 300);
    }
  }, [isActive]);
  
  return (
    <CSSTransition
      in={isActive}
      classNames="c-splash-screen-"
      timeout={0}
      appear
    >
      <section className="c-splash-screen">
        <div className="c-splash-screen__content">
          <Logo variant="white" />
          <h1>Discover Cannabis</h1>
        </div>
      </section>
    </CSSTransition>
  )
}

export default SplashScreen;