import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import { FetchDiscounts } from "store/inventory/actions";
import { CircleArrowButton } from "components/Shared";
import { usePrevious } from "../../../hooks/usePrevious";
import "swiper/swiper.scss";
import "./FeaturedProducts.scss";

import {
  GradientCardLayout1,
  GradientCardLayout2,
  GradientCardLayout3,
  GradientCardLayout4,
  GradientCardLayout5,
  // DefaultGradient
} from "components/Gradients/Cards";

function FeaturedProducts() {
  const dispatch = useDispatch();
  const isMobile = useSelector((state: RootState) => state.system.isMobile);
  const isTablet = useSelector((state: RootState) => state.system.isTablet);
  const isDesktop = useSelector((state: RootState) => state.system.isDesktop);
  const customer = useSelector((state: RootState) => state.customer.customer);
  const productsToReview = useSelector(
    (state: RootState) => state.customer.productsToReview
  );
  const activeDispensary = useSelector(
    (state: RootState) => state.cart.activeDispensary
  );
  const discountsLoading = useSelector(
    (state: RootState) => state.inventory.discountsLoading
  );
  const discountProducts = useSelector(
    (state: RootState) => state.inventory.discountProducts
  );
  const discountsError = useSelector(
    (state: RootState) => state.inventory.discountsError
  );
  const noDiscounts = useSelector(
    (state: RootState) => state.inventory.noDiscounts
  );

  const [controlledSwiper, setControlledSwiper] = useState<any>(null);
  const [isEnd, setIsEnd] = useState(false);
  const [featuredProducts, setFeaturedProducts] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [gradientToUse, setGradientToUse] = useState<any>([]);
  const previousActiveDispensary = usePrevious(activeDispensary);

  useEffect(() => {
    if (
      (!discountsLoading &&
        !discountProducts.length &&
        !noDiscounts &&
        !discountsError &&
        customer) ||
      !previousActiveDispensary ||
      previousActiveDispensary !== activeDispensary
    ) {
      dispatch(FetchDiscounts());
    } else if (discountProducts.length) {
      setFeaturedProducts(discountProducts);
    } else if (noDiscounts) {
      setFeaturedProducts(null);
    }
  }, [
    discountsLoading,
    discountProducts,
    discountsError,
    noDiscounts,
    customer,
    previousActiveDispensary,
    activeDispensary,
    dispatch,
  ]);

  useEffect(() => {
    if (gradientToUse.length === 0 && featuredProducts) {
      setGradientToUse(
        featuredProducts.map(() => Math.floor(Math.random() * 5) + 1)
      );
    }
  }, [gradientToUse, featuredProducts]);

  function changePage(page: number) {
    controlledSwiper.slideTo(page, 500);
  }

  function nextPage() {
    controlledSwiper.slideNext();
  }

  return (
    <section className={classNames("c-featured-products", {})}>
      <div
        className={classNames("c-featured-products__inner", {
          "c-featured-products__inner--loaded":
            activeDispensary && featuredProducts && featuredProducts.length,
          "c-featured-products__inner--just-padding":
            (!activeDispensary ||
              !featuredProducts ||
              !featuredProducts.length) &&
            (!productsToReview || !productsToReview.length),
          "c-featured-products__inner--half-padding":
            activeDispensary &&
            featuredProducts &&
            featuredProducts.length &&
            productsToReview &&
            productsToReview.length,
        })}
      >
        <div className="slug">FEATURED THIS WEEK</div>
        <Swiper
          slidesPerView={isMobile ? 1 : "auto"}
          spaceBetween={isMobile ? 8 : isTablet ? 16 : 44}
          onSwiper={setControlledSwiper}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
            setIsEnd(swiper.isEnd);
          }}
        >
          {featuredProducts &&
            activeDispensary &&
            featuredProducts.map((product: any, index: number) => {
              const gradientFeelings =
                product.feelingData &&
                product.feelingData.length &&
                product.feelingData
                  .slice(0, 3)
                  .map((feeling: any) => feeling.feeling.toLowerCase());
              return (
                <SwiperSlide
                  key={
                    "featured-product-" +
                    product.product.productId +
                    "-" +
                    index
                  }
                >
                  <Link
                    to={
                      "/dispensary/" +
                      activeDispensary +
                      "/product/" +
                      product.product.productId
                    }
                    className={classNames(
                      "c-featured-products__product__inner",
                      {
                        "c-featured-products__product__inner--no-feelings":
                          product &&
                          (product.feelingData === null ||
                            product.feelingData.length === 0),
                      }
                    )}
                  >
                    <div className="meta">
                      {product.discounts.map((discount: any, key: number) => (
                        <div key={key}>{discount.name}</div>
                      ))}
                    </div>
                    <div className="c-featured-products__product__text">
                      <span className="c-featured-products__product__text__name">
                        {product.product.productName}
                      </span>
                      <span className="c-featured-products__product__text__vendor">
                        {product.product.brandName}
                      </span>
                    </div>
                    <CircleArrowButton type="button" />
                    <figure>
                      {product &&
                      (product.feelingData === null ||
                        product.feelingData.length === 0) ? (
                        <></>
                      ) : gradientToUse[index] === 1 ? (
                        <GradientCardLayout1
                          feelings={gradientFeelings}
                          animate={false}
                        />
                      ) : gradientToUse[index] === 2 ? (
                        <GradientCardLayout2
                          feelings={gradientFeelings}
                          animate={false}
                        />
                      ) : gradientToUse[index] === 3 ? (
                        <GradientCardLayout3
                          feelings={gradientFeelings}
                          animate={false}
                        />
                      ) : gradientToUse[index] === 4 ? (
                        <GradientCardLayout4
                          feelings={gradientFeelings}
                          animate={false}
                        />
                      ) : (
                        <GradientCardLayout5
                          feelings={gradientFeelings}
                          animate={false}
                        />
                      )}
                    </figure>
                  </Link>
                </SwiperSlide>
              );
            })}
        </Swiper>
        {isDesktop && controlledSwiper && featuredProducts && (
          <div className="c-featured-products__nav">
            <button
              className="c-featured-products__nav__prev"
              onClick={() => controlledSwiper.slidePrev()}
            >
              PREV
            </button>
            <button
              className="c-featured-products__nav__next"
              disabled={isEnd}
              onClick={() => controlledSwiper.slideNext()}
            >
              NEXT
            </button>
          </div>
        )}
        {(isMobile || isTablet) && featuredProducts && featuredProducts.length && (
          <div className="c-featured-products__nav">
            <div className="c-featured-products__nav__pages">
              {[...Array(featuredProducts.length)].map((e, i) => (
                <span
                  className={classNames(
                    "c-featured-products__nav__pages__page",
                    {
                      "c-featured-products__nav__pages__page--active":
                        i === activeIndex,
                    }
                  )}
                  key={"page" + i}
                  onClick={() => changePage(i)}
                >
                  0{i + 1}
                </span>
              ))}
            </div>
            <div
              className={classNames("c-featured-products__nav__next", {
                "c-featured-products__nav__next--disabled": isEnd,
              })}
              onClick={() => nextPage()}
            >
              Next
            </div>
          </div>
        )}
      </div>
      <Link
        to={
          activeDispensary
            ? "/dispensary/" + activeDispensary + "/products"
            : "/locations"
        }
        className={classNames(
          "c-featured-products__cta c-button c-button--outlined",
          {
            "c-featured-products__shop-all--padded":
              featuredProducts && featuredProducts.length,
          }
        )}
      >
        <span>Shop all products</span>
      </Link>
    </section>
  );
}

export default FeaturedProducts;
