import { call, put, takeLatest, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import axios from "axios";
import {
  KIOSK_PLACE_ORDER,
  KIOSK_PLACE_ORDER_SUCCESS,
  KIOSK_PLACE_ORDER_FAILED,
} from "store/kiosk-orders/types";
import { sagaURL } from "store/sagas";
import { setAuthorizationHeader } from "../../utils/auth";

function callKioskPlaceOrder(
  cart: any,
  activeDispensary: string,
  recPreviousResultData: any
) {
  if (recPreviousResultData?.recPreferences) {
    delete recPreviousResultData?.recPreferences?.isReturnInventory;
    delete recPreviousResultData?.recPreferences?.limit;
  }

  const orderData = {
    dateOfOrder: "2020-09-21",
    productIds: cart.items.map((item: any) => parseInt(item.productId, 10)),
    quantities: cart.items.map((item: any) => parseInt(item.quantity, 10)),
    discountAmounts: cart.items.map((item: any) => item.discountsAmounts),
    discountNames: cart.items.map((item: any) => item.discountsNames),
    prices: cart.items.map((item: any) => item.unitPrice),
    subTotal: cart.price.subTotal,
    salesTax: cart.price.taxes,
    discount: cart.price.discount,
    total: cart.price.total,
    isDelivery: false,
    orderSource: "Forage",
    dispensarySlug: activeDispensary,
    uuid: cart.uuid,
    recommendationInput: recPreviousResultData?.recPreferences || null,
    recommendationResult: recPreviousResultData?.recProductIds || null,
  };

  setAuthorizationHeader(null);
  axios.defaults.headers.common["slug"] = activeDispensary;

  return axios
    .post(sagaURL, {
      headers: {
        "Content-Type": "application/json",
        slug: activeDispensary,
      },
      query: `mutation createKioskPreOrder (
        $data: CreateKioskPreOrderInput
      ) {
        createKioskPreOrder(data: $data){
        orderId
        status
        transaction {
          transactionId
          transactionDate
          voidDate
          isVoid
          subtotal
          totalDiscount
          totalBeforeTax
          tax
          total
          paid
          changeDue
          totalItems
          terminalName
          checkInDate
          invoiceNumber
          transactionType
          loyaltyEarned
          loyaltySpent
          lastModifiedDateUTC
          cashPaid
          debitPaid
          electronicPaid
          electronicPaymentMethod
          checkPaid
          creditPaid
          taxSummary
          orderType
          wasPreOrdered
          orderSource
          invoiceName
          isReturn
        }
        orderDate
        orderType
        orderSource
        rejectedReason
      }
    }`,
      variables: {
        data: orderData,
      },
    })
    .then(({ data }) => {
      const {
        data: { createKioskPreOrder },
      } = data;

      return createKioskPreOrder;
    })
    .catch((error) => {
      throw error;
    });
}

function* kioskPlaceOrder(action: any) {
  try {
    const activeDispensary: any = yield select(selectors.activeDispensary);
    const recPreviousResultData: any = yield select(
      selectors.recPreviousResultData
    );

    const order = yield call(
      callKioskPlaceOrder,
      action.cart,
      activeDispensary,
      recPreviousResultData
    );
    yield put({ type: KIOSK_PLACE_ORDER_SUCCESS, order });
  } catch (e) {
    yield put({ type: KIOSK_PLACE_ORDER_FAILED, error: e });
  }
}

function* kioskOrdersSaga() {
  yield takeLatest(KIOSK_PLACE_ORDER, kioskPlaceOrder);
}

export default kioskOrdersSaga;
