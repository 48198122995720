import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import './CardGradients.scss';
import Noise from 'images/noise.png';
import {isIOS, isSafari} from 'react-device-detect';
import feelingColors from 'sass/utils/feelingColors.module.scss';
import chroma from 'chroma-js';
import { renderGradientPlaceholder } from "./index";

const BLUR = 20;

function blob1(ctx: any, productColors: any) {

  if (!productColors[1]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[1]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[1]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[1]]).alpha(0.0).css();
  // blob1/Path
  ctx.save();
  ctx.beginPath();
  ctx.bezierCurveTo(452.1, 968.8, 392.9, -384.1, 174.0, 232.5);
  ctx.bezierCurveTo(255.1, 281.0, 800.1, -306.1, 810.6, 100.5);
  ctx.bezierCurveTo(562.4, 888.3, 555.3, 1131.0, 503.7, 1249.9);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  ctx.transform(-0.47668, 0.884, 0.884, 0.468, 5863.9, 10062.1);
  gradient = ctx.createRadialGradient(-6155.1, -9024.2, 0.0, -6155.1, -9024.2, 594.6);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

function blob2(ctx: any, productColors: any) {

  if (!productColors[2]) {
    return
  }

  var gradient;
  const colorStep1 = chroma(feelingColors[productColors[2]]).alpha(1).css();
  const colorStep2 = chroma(feelingColors[productColors[2]]).alpha(0.5).css();
  const colorStep3 = chroma(feelingColors[productColors[2]]).alpha(0.0).css();
  // blob2/Path
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(2129.6, -358.0);
  ctx.bezierCurveTo(1045.3, 528.1, 289.8, 600.1, 77.6, 495.0);
  ctx.bezierCurveTo(-134.6, 389.9, 147.2, -3.6, 226.6, -100.0);
  ctx.bezierCurveTo(539.2, 14.4, 595.6, 1.0, 418.6, -119.0);
  ctx.bezierCurveTo(1054.6, 38.1, 1213.8, 187.9, 1129.6, 358.0);
  ctx.closePath();
  ctx.filter = `blur(${BLUR}px)`;
  gradient = ctx.createRadialGradient(273.3, 49.9, 0.0, 273.3, 49.9, 689.3);
  gradient.addColorStop(0.00, colorStep1);
  gradient.addColorStop(0.55, colorStep2);
  gradient.addColorStop(1.00, colorStep3);
  ctx.fillStyle = gradient;
  ctx.fill();
  ctx.restore();
}

interface GCL4Props {
  feelings: Array<string>
  animate: boolean
}

function GradientCardLayout4({feelings, animate}: GCL4Props) {
  const gradRef = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLCanvasElement>(null);
  const [ref1Transform, setRef1Transform] = useState('');
  const [productColors, setProductColors] = useState<any>([]);


  useEffect(() => {
    if (productColors !== null && productColors.length === 0 && feelings !== null && feelings.length > 0) {
      setProductColors(feelings);
    }
  }, [productColors, feelings]);

  function animateRef1() {
    const scaleInt = 1 + (Math.random() * (100 - 0) + 0) / 100;
    const rotateInt = 10 * (Math.random() * (60 - 0) + 0) / 100;
    setRef1Transform('scale(' + scaleInt + ') rotate(' + rotateInt + 'deg)');
  }

  useEffect(() => {
    let animationId = 0;

    if (ref1.current) {
      var ctx = ref1.current.getContext("2d");
      if (ctx) {
        animationId = requestAnimationFrame(() => {
          blob1(ctx, productColors);
          blob2(ctx, productColors);
          animateRef1();
        });
      }
    }

    return () => cancelAnimationFrame(animationId);
  }, [productColors]);

  return (
    <>
      {renderGradientPlaceholder(productColors)}
      {
        productColors && productColors.length &&
        <div className="c-card-gradient c-card-gradient--layout-4"
             style={{backgroundColor: feelingColors[productColors[0]]}} ref={gradRef}>
          <div style={{backgroundImage: 'url(' + Noise + ')'}}
               className={classNames('c-card-gradient__noise', {'c-card-gradient__noise--safari': isIOS || isSafari})}></div>
          <canvas id="canvas" style={{transform: animate ? ref1Transform : ''}}
                  className={classNames('', {'c-card-gradient--safari-blur': isIOS || isSafari})} width="1060"
                  height="535" ref={ref1}></canvas>
        </div>
      }
    </>
  )
}

export default GradientCardLayout4;