import { 
  PLACE_ORDER, 
  RESET_ORDER_PLACED, 
  FETCH_PREORDER, 
  OrdersActionTypes 
} from './types';
import { CartState } from '../cart/types';
export function PlaceOrder(cart:CartState): OrdersActionTypes {
  return {
    type: PLACE_ORDER,
    cart
  }
}

export function ResetOrderPlaced(): OrdersActionTypes{
  return {
    type: RESET_ORDER_PLACED
  }
}

export function FetchPreorder(orderId: number, dispensarySlug: string): OrdersActionTypes{
  return {
    type: FETCH_PREORDER,
    orderId,
    dispensarySlug
  }
}