import React from "react";
import {
  Accessories,
  Beverages,
  Candy,
  Cartridge,
  Edibles,
  Extract,
  Flower,
  Oil,
  Patch,
  Pills,
  PreRolls,
  Spray,
  Topicals,
  Vapes,
} from "components/svgs/ProductTypes";
interface ProductIconProps {
  productType?: string;
  iconId?: string;
  variant?: string;
}
function ProductIcon({
  productType,
  iconId,
  variant = "charcoal",
}: ProductIconProps) {
  function Icon() {
    if (productType) {
      if (productType.split("-").length === 2) {
        const category = productType.split("-")[0].toLowerCase();
        const secondary = productType.split("-")[1].toLowerCase();
        switch (category) {
          case "flw":
            if (secondary.indexOf("pre") >= 0) {
              return <PreRolls variant={variant} />;
            } else {
              return <Flower variant={variant} />;
            }
          case "vpr":
            if (secondary.indexOf("cartridge") >= 0) {
              return <Cartridge variant={variant} />;
            } else {
              return <Vapes variant={variant} />;
            }
          case "cnc":
            return <Extract variant={variant} />;
          case "edb":
            if (secondary.indexOf("candy") >= 0) {
              return <Candy variant={variant} />;
            } else {
              return <Edibles variant={variant} />;
            }
          case "bev":
            return <Beverages variant={variant} />;
          case "tpc":
            if (secondary.indexOf("patch") >= 0) {
              return <Patch variant={variant} />;
            } else {
              return <Topicals variant={variant} />;
            }
          case "orl":
            if (secondary.indexOf("capsules") >= 0) {
              return <Pills variant={variant} />;
            } else {
              return <Oil variant={variant} />;
            }
          case "gear":
            if (secondary.indexOf("batter") >= 0) {
              return <Vapes variant={variant} />;
            } else {
              return <Accessories variant={variant} />;
            }
          case "merc":
            // if(secondary.indexOf('accessories') >=0) {
            return <Accessories variant={variant} />;
          // }
          case "cbd":
            if (secondary.indexOf("vap") >= 0) {
              return <Vapes variant={variant} />;
            } else if (secondary.indexOf("oral") >= 0) {
              return <Spray variant={variant} />;
            } else if (secondary.indexOf("edible") >= 0) {
              return <Edibles variant={variant} />;
            } else {
              console.log(
                "Failed to find icon. Category:" +
                  category +
                  " Subcategory:" +
                  secondary
              );
              return <Oil variant={variant} />;
            }
          case "mfmj":
            if (secondary.indexOf("tablet") >= 0) {
              return <Vapes variant={variant} />;
            } else {
              return <></>;
            }
          default:
            console.log(
              "Failed to find icon. Category:" +
                category +
                " Subcategory:" +
                secondary
            );
            return <></>;
        }
      } else {
        return <Flower variant={variant} />;
      }
    } else {
      switch (iconId) {
        case "accessories":
          return <Accessories variant={variant} />;
        case "beverages":
          return <Beverages variant={variant} />;
        case "candy":
          return <Candy variant={variant} />;
        case "cartridge":
          return <Cartridge variant={variant} />;
        case "edibles":
          return <Edibles variant={variant} />;
        case "extract":
          return <Extract variant={variant} />;
        case "flower":
          return <Flower variant={variant} />;
        case "oil":
          return <Oil variant={variant} />;
        case "patch":
          return <Patch variant={variant} />;
        case "pills":
          return <Pills variant={variant} />;
        case "prerolls":
          return <PreRolls variant={variant} />;
        case "spray":
          return <Spray variant={variant} />;
        case "topicals":
          return <Topicals variant={variant} />;
        case "vapes":
          return <Vapes variant={variant} />;
        default:
          return <PreRolls variant={variant} />;
      }
    }
  }

  return (
    <div>
      <Icon />
    </div>
  );
}

export default ProductIcon;
